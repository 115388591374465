const form = {
  formID: "modal-form",
  formField: {
    fecha: {
      name: "fecha",
      type: "date",
      label: "Fecha",
      errorMsg: "La fecha no puede ser quedar vacia",
    },
    motivo: {
      name: "motivo",
      type: "text",
      label: "Motivo",
      errorMsg: "El motivo no puede quedar vacio",
    },
  },
};

export default form;
