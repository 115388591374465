import { examenApi } from "../view/examen.service";

export const createExamen = examenApi.injectEndpoints({
  endpoints: (builder) => ({
    createExamen: builder.mutation({
      query: (data) => {
        data.nombre = data.nombre.trim();

        return {
          url: "/v1/api/examenentidad/",
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: [{ type: "Examenes", id: "LIST" }],
    }),
  }),
});

export const { useCreateExamenMutation } = createExamen;
