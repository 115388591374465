/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

//React Hooks and Others
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TreeComponent from "components/TreeView/tree_view";
import { AREA_LIST_URL, AREA_NEW_URL } from "config/bases_url";
import PropTypes from "prop-types";
import {
  admin_state,
  setEditAdmin,
  setFlag,
  setIsChildAdd,
  setNoHereAdmin,
} from "slices";

//area-service
import { useGetAreasQuery } from "services/principal/area.service";
import { useEffect } from "react";
import ComponentsPrivatization from "config/component_privatization";
import { COORDINADOR_ALMACEN } from "roles";
import { CircularProgress } from "@mui/material";

import { useRedirectForm } from "hooks/useRedirectForm";

function DataTableArea({ tableHeader }) {
  const dispatch = useDispatch();
  const { edit, isToAddChild } = useSelector(admin_state);

  const {
    data: areas_data,
    isError: isErrorG,
    isLoading: isLoadingG,
    error: errorG,
  } = useGetAreasQuery(undefined, {
    refetchOnReconnect: true,
  });

  useRedirectForm(
    isLoadingG,
    null,
    isErrorG,
    errorG,
    "Hubo problemas para cargar la información de las áreas",
    AREA_LIST_URL,
  );

  useEffect(() => {
    if (isToAddChild) {
      dispatch(setIsChildAdd(false));
    }
    if (edit) {
      dispatch(setEditAdmin(null));
    }
  }, []);

  const handleClickCreate = () => {
    dispatch(setEditAdmin(null));
    dispatch(setIsChildAdd(false));
    dispatch(setNoHereAdmin(false));
    dispatch(setFlag("CREATE"));
  };

  return (
    <MDBox pt={6} pb={3}>
      <Card>
        <MDBox
          display="flex"
          justifyContent={"space-between"}
          alignItems={"center"}
          m={2}
          p={3}
        >
          <MDTypography variant="h5" fontWeight="medium">
            {tableHeader}
          </MDTypography>
          <ComponentsPrivatization permitted={COORDINADOR_ALMACEN}>
            <Link to={AREA_NEW_URL}>
              <MDButton
                onClick={handleClickCreate}
                variant="gradient"
                color="info"
                size="medium"
              >
                Nuevo
              </MDButton>
            </Link>
          </ComponentsPrivatization>
        </MDBox>
        <MDBox
          display="flex"
          justifyContent={isLoadingG ? "center" : "flex-start"}
          alignItems={"center"}
        >
          {isLoadingG ? (
            <CircularProgress sx={{ marginBottom: "3rem" }} color="inherit" />
          ) : (
            <MDBox p={3} mt={-4.5} width="-webkit-fill-available">
              <TreeComponent areas={areas_data} />
            </MDBox>
          )}
        </MDBox>
      </Card>
    </MDBox>
  );
}

DataTableArea.propTypes = {
  tableHeader: PropTypes.string.isRequired,
};

export default DataTableArea;
