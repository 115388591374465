/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as Yup from "yup";
import checkout from "./form";

const {
  formField: {
    nombre,
    modalidad,
    plan_de_estudio,
    rvoe,
    identificador,
    nivel,
    fecha_expedicion,
    duracion,
    calificacion_minima,
    calificacion_maxima,
    calificacion_aprobatoria,
  },
} = checkout;

const validations = [
  Yup.object().shape({
    [nombre.name]: Yup.string().required(nombre.errorMsg),
    [modalidad.name]: Yup.string().required(modalidad.errorMsg),
    [rvoe.name]: Yup.string().required(rvoe.errorMsg),
    [identificador.name]: Yup.string().required(identificador.errorMsg),
    [nivel.name]: Yup.string().required(nivel.errorMsg),
    [duracion.name]: Yup.string().required(duracion.errorMsg),
    [plan_de_estudio.name]: Yup.string().required(plan_de_estudio.errorMsg),
    [fecha_expedicion.name]: Yup.date().max(
      new Date(),
      "La fecha proporcionada no es válida"
    ),
    [calificacion_aprobatoria.name]: Yup.number().min(
      1,
      "la calificacion no puede ser menor a 1"
    ),
    [calificacion_maxima.name]: Yup.number().min(
      1,
      "la calificacion no puede ser menor a 1"
    ),
    [calificacion_minima.name]: Yup.number().min(
      1,
      "la calificacion no puede ser menor a 1"
    ),
  }),
];

export default validations;
