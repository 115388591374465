import { ACTIVOS_REGEX } from "helpers/regexs";
import { NAME_AREA } from "helpers/regexs";
import * as Yup from "yup";
import checkout from "./form";

const {
  formField: {
    fecha_de_facturacion,
    fecha_cedula,
    numero_de_inventario,
    estado,
    descripcion,
    metodo_de_adquisicion,
    proveedor,
    numero_de_factura,
    numero_de_serie,
    valor,
    tipo,
    llave,
    marca,
    modelo,
  },
} = checkout;

const validations = [
  Yup.object().shape({
    [metodo_de_adquisicion.name]: Yup.string().required(
      metodo_de_adquisicion.errorMsg
    ),
    [tipo.name]: Yup.string().required(tipo.errorMsg),
    [numero_de_serie.name]: Yup.string()
      .matches(
        ACTIVOS_REGEX,
        "No puede contener caracteres extraños, a parte de (-)(_)(/)"
      )
      .required(numero_de_serie.errorMsg),

    [numero_de_factura.name]: Yup.string().matches(
      ACTIVOS_REGEX,
      "No puede contener caracteres extraños, a parte de (-)(_)(/)"
    ),
    [valor.name]: Yup.string().required(valor.errorMsg),
    [proveedor.name]: Yup.string().matches(
      NAME_AREA,
      "El nombre del proveedor no puede contener caracteres extraños"
    ),
    [fecha_cedula.name]: Yup.date().max(
      new Date(),
      "La fecha proporcionada no es válida"
    ),
    [fecha_de_facturacion.name]: Yup.date().max(
      new Date(),
      "La fecha proporcionada no es válida"
    ),
  }),
  Yup.object().shape({
    [numero_de_inventario.name]: Yup.string()
      .required(numero_de_inventario.errorMsg)
      .matches(NAME_AREA, "No puede contener caracteres extraños"),
    [llave.name]: Yup.string()
      .required(llave.errorMsg)
      .matches(NAME_AREA, "No puede contener caracteres extraños"),
    [estado.name]: Yup.string().required(estado.errorMsg),
    [descripcion.name]: Yup.string().required(descripcion.errorMsg),
    [marca.name]: Yup.string().matches(
      NAME_AREA,
      "No puede contener caracteres extraños"
    ),
    [modelo.name]: Yup.string().matches(
      NAME_AREA,
      "No puede contener caracteres extraños"
    ),
  }),
];

export default validations;
