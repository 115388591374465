// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import {HOME_URL} from "../../../../config/bases_url";
import {Form, Formik} from "formik";
import {NAME_ENTIDAD} from "../../../../helpers/regexs";
import {useRedirectForm} from "../../../../hooks/useRedirectForm";
import {useState} from "react";
import {useEditEntidadMutation} from "../../../../services";

// Settings page components
import FormField from "../../../../components/FormField";

import * as Yup from "yup";
import MDButton from "../../../../components/MDButton";
import MDImage from "../../../../components/MDImage/image";
import ComponentsPrivatization from "../../../../config/component_privatization";
import {COORDINADOR_DOCENTE} from "../../../../roles";
import no_foto from "../../../../assets/images/404.jpg";
import {useSnackbar} from "notistack";
import {getModifiedFields} from "helpers/functions";

const style = {
    width: "100%",
    cursor: "pointer",
    borderRadius: "0.5rem",
    marginRight: "2em",
    paddingRight: "0.5em",
    paddingLeft: "0.5em",
    maxWidth: "160px",
};

function EntidadInfo() {
    let authUser = JSON.parse(sessionStorage.getItem("user"));

    const initialValues = {
        nombre: authUser.perfil.entidad.nombre,
        logo: authUser.perfil.entidad.logo,
        municipio: authUser.perfil.entidad.municipio,
        localidad: authUser.perfil.entidad.localidad,
        clave_del_centro: authUser.perfil.entidad.clave_del_centro,
    };

    const [editar, setEditar] = useState(false);

    const [editEntidad, {isSuccess, isLoading, isError, error}] =
        useEditEntidadMutation();
    const {enqueueSnackbar} = useSnackbar();

    useRedirectForm(
        isLoading,
        isSuccess,
        isError,
        error,
        "Entidad editada",
        HOME_URL
    );

    const handleSubmit = async (values, actions) => {
        if (editar) {
            const modifiedFields = getModifiedFields(initialValues, values);
            if (Object.keys(modifiedFields).length !== 0) {
                await editEntidad({
                    id: authUser.perfil.entidad.id,
                    ...modifiedFields,
                });
                authUser.perfil.entidad = {
                    ...authUser.perfil.entidad,
                    ...modifiedFields,
                };
                sessionStorage.setItem("user", JSON.stringify(authUser));
            }
            actions.setSubmitting(false);
            setEditar(!editar);
        } else {
            setEditar(!editar);
        }
    };

    return (
        <Card id="basic-info" sx={{overflow: "visible"}}>
            <MDBox p={3}>
                <MDTypography variant="h5" fontSize="1em">
                    Información de la Entidad
                </MDTypography>
            </MDBox>

            <Formik
                initialValues={initialValues}
                validationSchema={Yup.object({
                    nombre: Yup.string()
                        .required("El nombre es requerido.")
                        .matches(
                            NAME_ENTIDAD,
                            "El nombre no puede contener caracteres extraños"
                        ),
                    municipio: Yup.string().required("El Municipio es requerido."),
                    localidad: Yup.string().required("La localidad es requerida."),
                    clave_del_centro: Yup.string().required(
                        "La clave del centro es requerida."
                    ),
                })}
                onSubmit={handleSubmit}
            >
                {({
                      touched,
                      values,
                      errors,
                      isValid,
                      handleChange,
                      handleBlur,
                      setFieldValue,
                  }) => (
                    <Form id={"change_entidad"} autoComplete="off">
                        <MDBox pb={3} px={3}>
                            <MDBox variant="gradient" display="flex"
                                   flexDirection={{xs: "column", sm: "row"}}
                                   alignItems={"center"}>
                                <MDImage
                                    id="logo-image"
                                    htmlFor="logo-image"
                                    disabled={!editar}
                                    style={style}
                                    src={
                                        authUser.perfil.entidad.logo !== null
                                            ? authUser.perfil.entidad.logo
                                            : no_foto
                                    }
                                    handleChange={(e, isValid) => {
                                        if (isValid) setFieldValue("logo", e.target.files[0]);
                                        else {
                                            enqueueSnackbar(
                                                "El tamaño de la imagen no debe ser mayor a 1MB",
                                                {
                                                    variant: "error",
                                                    autoHideDuration: 4000,
                                                }
                                            );
                                        }
                                    }}
                                />
                                <MDBox
                                    display="flex"
                                    alignItems="flex-start"
                                    flexDirection="column"
                                    mt={3}
                                    style={{width: "100%"}}
                                >
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12}>
                                            <FormField
                                                variant={"outlined"}
                                                label="Nombre(s)"
                                                name="nombre"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                value={values.nombre}
                                                error={errors.nombre && touched.nombre}
                                                success={
                                                    values.nombre.length > 0 && !errors.nombre && editar
                                                }
                                                onInput={(e) =>
                                                    (e.target.value = ("" + e.target.value).toUpperCase())
                                                }
                                                InputProps={{
                                                    readOnly: !editar,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <FormField
                                                variant={"outlined"}
                                                label="Localidad"
                                                name="localidad"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                value={values.localidad}
                                                error={errors.localidad && touched.localidad}
                                                success={
                                                    values.localidad.length > 0 &&
                                                    !errors.localidad &&
                                                    editar
                                                }
                                                onInput={(e) =>
                                                    (e.target.value = ("" + e.target.value).toUpperCase())
                                                }
                                                InputProps={{
                                                    readOnly: !editar,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <FormField
                                                variant={"outlined"}
                                                label="Municipio"
                                                name="municipio"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                value={values.municipio}
                                                error={errors.municipio && touched.municipio}
                                                success={
                                                    values.municipio.length > 0 &&
                                                    !errors.municipio &&
                                                    editar
                                                }
                                                onInput={(e) =>
                                                    (e.target.value = ("" + e.target.value).toUpperCase())
                                                }
                                                InputProps={{
                                                    readOnly: !editar,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <FormField
                                                variant={"outlined"}
                                                label="Clave"
                                                name="clave_del_centro"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                value={values.clave_del_centro}
                                                error={
                                                    errors.clave_del_centro && touched.clave_del_centro
                                                }
                                                success={
                                                    values.clave_del_centro.length > 0 &&
                                                    !errors.clave_del_centro &&
                                                    editar
                                                }
                                                onInput={(e) =>
                                                    (e.target.value = ("" + e.target.value).toUpperCase())
                                                }
                                                InputProps={{
                                                    readOnly: !editar,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </MDBox>
                            </MDBox>
                            <ComponentsPrivatization permitted={[COORDINADOR_DOCENTE]}>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    mt={3}
                                    display="flex"
                                    justifyContent={editar ? "space-between" : "flex-end"}
                                >
                                    {editar && (
                                        <MDButton
                                            onClick={(e) => setEditar(!editar)}
                                            variant="gradient"
                                            color="primary"
                                        >
                                            cancelar
                                        </MDButton>
                                    )}
                                    <MDButton
                                        disabled={
                                            editar &&
                                            (!isValid ||
                                                (values.nombre === authUser.perfil.entidad.nombre &&
                                                    values.municipio ===
                                                    authUser.perfil.entidad.municipio &&
                                                    values.localidad ===
                                                    authUser.perfil.entidad.localidad &&
                                                    values.clave_del_centro ===
                                                    authUser.perfil.entidad.clave_del_centro))
                                        }
                                        type="submit"
                                        variant="gradient"
                                        color="dark"
                                    >
                                        {editar ? "guardar" : "editar"}
                                    </MDButton>
                                </Grid>
                            </ComponentsPrivatization>
                        </MDBox>
                    </Form>
                )}
            </Formik>
        </Card>
    );
}

export default EntidadInfo;
