/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DataTable from "examples/Tables/DataTable";

//React Hooks and Others
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { CircularProgress, Icon, Tooltip } from "@mui/material";
import { setEditAdmin, setNoHereAdmin } from "slices";
import Swal from "sweetalert2";
import {
  TIPO_EDIT_URL,
  TIPO_NEW_URL,
  TIPOS_ACTIVOS_URL,
} from "config/bases_url";
import PropTypes from "prop-types";
import {
  useDeleteTipoMutation,
  useGetTiposQuery,
} from "services/catalogo/tipos.service";
import ComponentsPrivatization, {
  removeColumns,
} from "config/component_privatization";
import { COORDINADOR_ALMACEN } from "roles";
import { style_icon } from "helpers/global_vars";
import { useRedirectForm } from "hooks/useRedirectForm";
import usePagination from "hooks/usePagination";

function DataTableTipos({ tableHeader }) {
  const dispatch = useDispatch();

  const {
    offset,
    setOffset,
    limit,
    busqueda,
    setBusqueda,
    handleNext,
    handlePrevious,
  } = usePagination();

  const {
    data,
    isLoading: isLoadingG,
    isError: isErrorG,
    error: errorG,
  } = useGetTiposQuery(
    {
      filter_params: {
        buscar: busqueda,
        offset: offset,
        limit: limit,
        query: "{id,nombre,base}",
      },
      shape: {
        id: "id",
        nombre: "nombre",
        base: "base",
      },
    },
    {
      refetchOnReconnect: true,
    }
  );

  //constantes para manejar la paginacion
  const dataTipos = data?.results ? data.results : data;
  const countTotal = data?.count;
  const previous = data?.previous;
  const next = data?.next;

  const [
    deleteTipo,
    {
      isSuccess: isSuccessD,
      isLoading: isLoadingD,
      isError: isErrorD,
      error: errorD,
    },
  ] = useDeleteTipoMutation();

  const dataTable = {
    columns: [
      {
        Header: "Nombre",
        accessor: "nombre",
      },
      {
        Header: "Tipo",
        accessor: "base",
        Cell: ({ value }) => value.toUpperCase(),
      },
      {
        Header: "Acciones",
        accessor: "actions",
        width: "10%",
        Cell: ({ row }) => {
          const { original } = row;

          return (
            <ComponentsPrivatization permitted={COORDINADOR_ALMACEN}>
              <MDBox display="flex">
                <MDBox px={0.2}>
                  <Tooltip placement="top" title="Editar">
                    <Icon
                      style={style_icon}
                      onClick={() => handleClickEdit(original)}
                      fontSize="small"
                    >
                      edit
                    </Icon>
                  </Tooltip>
                </MDBox>
                {original.borrable ? (
                  <MDBox px={0.2}>
                    <Tooltip placement="top" title="Eliminar">
                      <Icon
                        style={style_icon}
                        onClick={() => handleClickErase(original)}
                        fontSize="small"
                      >
                        remove_circle
                      </Icon>
                    </Tooltip>
                  </MDBox>
                ) : null}
              </MDBox>
            </ComponentsPrivatization>
          );
        },
      },
    ],
    rows: dataTipos ?? [],
  };

  useRedirectForm(
    isLoadingG,
    null,
    isErrorG,
    errorG,
    "Hubo problemas para cargar la información",
    TIPOS_ACTIVOS_URL
  );

  useRedirectForm(
    isLoadingD,
    isSuccessD,
    isErrorD,
    errorD,
    "Tipo de activo eliminado",
    TIPOS_ACTIVOS_URL
  );

  const handleClickErase = (tipo) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: `¡Si eliminas el catálogo: "${tipo.nombre}", esta acción no se podrá revertir!`,
      icon: "warning",
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Eliminar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteTipo(tipo.id);
      }
    });
  };

  const navigate = useNavigate();

  const handleClickEdit = (tipo) => {
    dispatch(setNoHereAdmin(false));
    dispatch(setEditAdmin(tipo));
    navigate(TIPO_EDIT_URL(tipo.id));
  };

  const handleClickCreate = () => {
    dispatch(setNoHereAdmin(false));
    dispatch(setEditAdmin(null));
  };

  return (
    <MDBox pt={6} pb={3}>
      <Card>
        <MDBox
          display="flex"
          justifyContent={"space-between"}
          alignItems={"center"}
          m={2}
          p={3}
        >
          <MDTypography variant="h5" fontWeight="medium">
            {tableHeader}
          </MDTypography>

          <ComponentsPrivatization permitted={COORDINADOR_ALMACEN}>
            <Link to={TIPO_NEW_URL}>
              <MDButton
                variant="gradient"
                color="info"
                size="medium"
                onClick={handleClickCreate}
              >
                nuevo
              </MDButton>
            </Link>
          </ComponentsPrivatization>
        </MDBox>
        <MDBox display="flex" justifyContent={"center"} alignItems={"center"}>
          {isLoadingG ? (
            <CircularProgress sx={{ marginBottom: "3rem" }} color="inherit" />
          ) : (
            <DataTable
              table={removeColumns(
                dataTable,
                () =>
                  !JSON.parse(sessionStorage.getItem("user")).groups.some(
                    (role) => role.name === COORDINADOR_ALMACEN
                  ),
                ["actions"]
              )}
              canSearch
              paginationApi
              countTotal={countTotal}
              next={next}
              previous={previous}
              handleNext={handleNext}
              handlePrevious={handlePrevious}
              offset={offset}
              setOffset={setOffset}
              setBusqueda={setBusqueda}
            />
          )}
        </MDBox>
      </Card>
    </MDBox>
  );
}

DataTableTipos.propTypes = {
  tableHeader: PropTypes.string.isRequired,
};

export default DataTableTipos;
