import {userApi} from "../../view/users.service";
import {buildFiltersUrl, reshape_response} from "../../../../../helpers/functions";

export const getPlantilla = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getPlantilla: builder.query({
      query: ({filter_params}) => ({
        url: buildFiltersUrl("/v2/api/management/usuarios/", filter_params),
        method: "GET",
      }),
      providesTags: (result) =>
        Array.isArray(result)
          ? [
            ...result.map(({id}) => ({
              type: "Users",
              id,
            })),
            {type: "Users", id: "LIST"},
          ]
          : [{type: "Users", id: "LIST"}],
      transformResponse: (response, meta, args) => {
        if (Array.isArray(response)) {
          return response?.map((el) => reshape_response(el, args?.shape));
        } else {
          const resultsTransformed = response?.results?.map((el) =>
            reshape_response(el, args?.shape)
          );

          // Devolvemos los resultados transformados junto con las propiedades de paginación
          return {
            results: resultsTransformed,
            count: response.count,
            next: response.next,
            previous: response.previous,
          };
        }
      },
    }),
  }),
})

export const {
  useGetPlantillaQuery,
} = getPlantilla;