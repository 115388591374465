/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import checkout from "./form";

const {
  formField: {
    nombre,
    nombre_corto,
    modalidad,
    firmante,
    expedicion,
    fecha_fin,
    fecha_inicio,
    duracion,
    estudiantes,
    localizacion,
  },
} = checkout;

const initialValues = {
  [nombre.name]: "",
  [nombre_corto.name]: "",
  [modalidad.name]: "",
  [modalidad.name]: "",
  [firmante.name]: "",
  [expedicion.name]: new Date().toISOString().slice(0, 10),
  [fecha_fin.name]: new Date().toISOString().slice(0, 10),
  [fecha_inicio.name]: new Date().toISOString().slice(0, 10),
  [duracion.name]: "",
  [localizacion.name]: "",
  [estudiantes.name]: [],
};

export default initialValues;
