import * as React from "react";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import ModalBase from "components/ModalBase/modal_base";
import { useCloseModal } from "hooks/useCloseModal";
import AsignaturaForm from "./asignatura-form";
import {
  useCreateAsignaturaMutation,
  useEditAsignaturaMutation,
  useEditCicloInternoMutation,
  useGetCicloInternoByIdQuery,
} from "services";
import * as Yup from "yup";
import { addAsig, editAsig } from "slices";
import { useDispatch } from "react-redux";
import { getModifiedFields } from "helpers/functions";

const ModalAsignatura = ({
  open,
  handleClose,
  ciclo_interno_id,
  asignatura = undefined,
}) => {
  const [editCicloInterno] = useEditCicloInternoMutation();
  const dispatch = useDispatch();

  const { data: ciclo_interno } = useGetCicloInternoByIdQuery(ciclo_interno_id);

  const [
    createAsignatura,
    {
      isSuccess: isSuccessAC,
      isLoading: isLoadingAC,
      error: errorAC,
      isError: isErrorAC,
    },
  ] = useCreateAsignaturaMutation();

  const [
    editAsignatura,
    {
      isSuccess: isSuccessAE,
      isLoading: isLoadingAE,
      error: errorAE,
      isError: isErrorAE,
    },
  ] = useEditAsignaturaMutation();

  useCloseModal(
    isLoadingAC,
    isSuccessAC,
    isErrorAC,
    errorAC,
    "Asignatura creada correctamente",
    handleClose,
  );

  useCloseModal(
    isLoadingAE,
    isSuccessAE,
    isErrorAE,
    errorAE,
    "Asignatura editada correctamente",
    handleClose,
  );

  const submitForm = async (values, actions) => {
    const { dependencias, ...rest } = values;
    try {
      if (!asignatura) {
        await createAsignatura({
          rest,
          dependencias: dependencias.map((el) => el.url),
        })
          .unwrap()
          .then((res) => {
            editCicloInterno({
              id: ciclo_interno?.id,
              asignaturas: [...ciclo_interno?.asignaturas, res],
            });
            dispatch(
              addAsig({
                ciclo_interno_id: ciclo_interno?.id,
                asignatura: res,
              }),
            );
            actions.setSubmitting(false);
            actions.resetForm();
          });
      } else {
        const modifiedFields = getModifiedFields(asignatura, values);
        if (Object.keys(modifiedFields).length !== 0) {
          await editAsignatura({
            id: asignatura.id,
            ...modifiedFields,
            dependencias: modifiedFields.dependencias.map((el) => el.url),
          })
            .unwrap()
            .then((res) => {
              dispatch(
                editAsig({
                  ciclo_interno_id: ciclo_interno?.id,
                  asignatura: res,
                }),
              );
            });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <ModalBase open={open} handleClose={handleClose}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} lg={12}>
          <Formik
            initialValues={{
              nombre: "",
              identificador: 0,
              clave: "",
              asistencia_requerida: 0,
              dependencias: [],
            }}
            onSubmit={submitForm}
            validationSchema={Yup.object().shape({
              nombre: Yup.string().required("El campo es requerido"),
              identificador: Yup.number()
                .required("El campo es requerido")
                .min(0, "El valor debe ser superior a 0"),
              clave: Yup.string().required("El campo es requerido"),
              asistencia_requerida: Yup.number("Debe entrar un valor numérico")
                .min(0, "El minimo valor aceptado es 0")
                .max(100, "El mayor valor aceptado es 100")
                .required("Debe introducir un porciento de asistencia"),
            })}
          >
            {({
              values,
              errors,
              touched,
              isValid,
              handleChange,
              setFieldValue,
            }) => {
              React.useEffect(() => {
                if (asignatura) {
                  setFieldValue("nombre", asignatura.nombre, true);
                  setFieldValue("clave", asignatura.clave, true);
                  setFieldValue(
                    "identificador",
                    asignatura.identificador,
                    true,
                  );
                  setFieldValue(
                    "asistencia_requerida",
                    asignatura.asistencia_requerida,
                    true,
                  );
                  setFieldValue("dependencias", asignatura.dependencias);
                }
              }, [asignatura]);
              return (
                <Form
                  key={"asignaturas-fomr-modal"}
                  id={"asignaturas-fomr-modal"}
                  autoComplete="off"
                >
                  <MDBox p={1}>
                    <AsignaturaForm
                      carreraId={ciclo_interno.carrera.id}
                      formData={{
                        values,
                        touched,
                        errors,
                        handleChange,
                        setFieldValue,
                      }}
                    />
                    <MDBox
                      mt={2}
                      width="100%"
                      display="flex"
                      justifyContent="space-between"
                    >
                      <MDButton
                        onClick={handleClose}
                        variant="gradient"
                        color="primary"
                      >
                        cancelar
                      </MDButton>
                      <MDButton
                        disabled={!isValid}
                        type="submit"
                        variant="gradient"
                        color="dark"
                      >
                        {asignatura ? "editar" : "aceptar"}
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </ModalBase>
  );
};

ModalAsignatura.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ModalAsignatura;
