const form = {
  formId: "new-activo-form",
  formField: {
    resourcetype: {
      name: "resourcetype",
      label: "Tipo de Activo*",
      type: "text",
      errorMsg: "El tipo de activo es requerido.",
    },
    tipo: {
      name: "tipo",
      label: "Tipo de Catálogo*",
      type: "text",
      errorMsg: "El tipo de catálogo es requerido.",
    },
    descripcion: {
      name: "descripcion",
      label: "Descripción*",
      type: "text",
      errorMsg: "La descripción es obligatoria",
    },
    metodo_de_adquisicion: {
      name: "metodo_de_adquisicion",
      label: "Método de Adquisición*",
      type: "text",
      errorMsg: "Debe introducir un método de adquisición.",
    },
    marca: {
      name: "marca",
      label: "Marca",
      type: "text",
    },
    modelo: {
      name: "modelo",
      label: "Modelo",
      type: "text",
    },
    fecha_de_facturacion: {
      name: "fecha_de_facturacion",
      label: "Fecha de Facturación",
      type: "date",
    },
    fecha_cedula: {
      name: "fecha_cedula",
      label: "Fecha de Cédula",
      type: "date",
    },
    proveedor: {
      name: "proveedor",
      label: "Proveedor*",
      type: "text",
      errorMsg: "Debe proporcionar un proveedor",
    },
    numero_de_factura: {
      name: "numero_de_factura",
      label: "No. de Factura*",
      type: "text",
      errorMsg: "Debe introducir un número de factura.",
    },
    valor: {
      name: "valor",
      label: "Precio*",
      type: "tel",
      errorMsg: "El valor del producto es requerido",
      invalidMsg: "El valor no puede ser menor a 0",
    },
    estado: {
      name: "estado",
      label: "Estado*",
      type: "text",
      errorMsg: "El estado del activo es obligatorio.",
    },
    cantidad: {
      name: "cantidad",
      label: "Cantidad*",
      type: "tel",
      errorMsg: "La cantidad es obligatoria",
      invalidMsg: "La cantidad no puede ser menor a 1",
    },
    foto: {
      name: "foto",
      label: "Foto",
      type: "file",
      accept: "image/jpeg, image/png",
    },
    unidad_de_medida: {
      name: "unidad_de_medida",
      label: "Unidad de medida*",
      type: "text",
      errorMsg: "Debe proveer una unidad de medida",
    },
  },
};

export default form;
