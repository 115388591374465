/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DataTable from "examples/Tables/DataTable";

//React Hooks and Others
import PropTypes from "prop-types";
import { useGetLotesQuery } from "services";
import { CircularProgress } from "@mui/material";
import { useRedirectForm } from "hooks/useRedirectForm";
import usePagination from "hooks/usePagination";

function DataTableMovimientosDevolucion({ tableHeader }) {
  const {
    offset,
    setOffset,
    limit,
    busqueda,
    setBusqueda,
    handleNext,
    handlePrevious,
  } = usePagination();

  const {
    activos_devolucion,
    isLoading: isLoadingG,
    isError: isErrorG,
    error: errorG,
  } = useGetLotesQuery(
    {
      filter_params: {
        buscar: busqueda,
        offset: offset,
        limit: limit,
        type: "Devolución",
        query:
          "{id,creacion,,operaciones{activo{id,llave,numero_de_inventario,descripcion,borrable,numero_de_serie,estado{nombre}}}}",
      },
      shape: {
        idLote: "id",
        id: "operaciones[].activo.id",
        llave: "operaciones[].activo.llave",
        numero_de_inventario: "operaciones[].activo.numero_de_inventario",
        descripcion: "operaciones[].activo.descripcion",
        borrable: "operaciones[].activo.borrable",
        numero_de_serie: "operaciones[].activo.numero_de_serie",
        estado: "operaciones[].activo.estado.nombre",
        creacion: "creacion",
      },
    },
    {
      refetchOnReconnect: true,
    }
  );

  //constantes para manejar la paginacion
  const dataLotes = activos_devolucion?.results
    ? activos_devolucion.results
    : activos_devolucion;
  const countTotal = activos_devolucion?.count;
  const previous = activos_devolucion?.previous;
  const next = activos_devolucion?.next;

  const dataTable = {
    columns: [
      {
        Header: "No. inventario",
        accessor: "numero_de_inventario",
        Cell: ({ value }) => {
          return value ? value : "-------------";
        },
      },
      {
        Header: "Descripcion",
        accessor: "descripcion",
      },
      {
        Header: "Estado",
        accessor: "estado",
      },
      {
        Header: "Fecha",
        accessor: "creacion",
      },
      {
        Header: "Nombre y Apellidos",
        accessor: "personal",
        width: "20%",
        Cell: ({ value }) => {
          return value ? value : "-------------";
        },
      },
    ],
    rows: dataLotes ?? [],
  };

  useRedirectForm(
    isLoadingG,
    null,
    isErrorG,
    errorG,
    "Hubo problemas para cargar la información",
    null
  );

  return (
    <MDBox pt={6} pb={3}>
      <Card>
        <MDBox
          display="flex"
          justifyContent={"space-between"}
          alignItems={"center"}
          m={2}
          p={3}
        >
          <MDTypography variant="h5" fontWeight="medium">
            {tableHeader}
          </MDTypography>
        </MDBox>

        <MDBox display="flex" justifyContent={"center"} alignItems={"center"}>
          {isLoadingG ? (
            <CircularProgress sx={{ marginBottom: "3rem" }} color="inherit" />
          ) : (
            <DataTable
              table={dataTable}
              canSearch
              paginationApi
              countTotal={countTotal}
              next={next}
              previous={previous}
              handleNext={handleNext}
              handlePrevious={handlePrevious}
              offset={offset}
              setOffset={setOffset}
              setBusqueda={setBusqueda}
            />
          )}
        </MDBox>
      </Card>
    </MDBox>
  );
}

DataTableMovimientosDevolucion.propTypes = {
  tableHeader: PropTypes.string.isRequired,
};

export default DataTableMovimientosDevolucion;
