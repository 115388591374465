import { buildFiltersUrl } from "helpers/functions";
import customFetchBase from "../../principal/customFetchBase";
import { createApi } from "@reduxjs/toolkit/query/react";
import { reshape_response } from "helpers/functions";

export const estudiantesPorCursoApi = createApi({
  reducerPath: "estudiantesPorCurso",
  baseQuery: customFetchBase,
  tagTypes: ["EstudiantesPorCurso"],
  endpoints: (builder) => ({
    getEstudiantesPorCurso: builder.query({
      query: ({ filter_params }) => ({
        url: buildFiltersUrl(
          `/v1/api/distribuciondeestudianteporcurso/`,
          filter_params
        ),
        method: "GET",
      }),
      providesTags: (result) =>
        Array.isArray(result)
          ? [
              ...result.map(({ id }) => ({
                type: "EstudiantesPorCurso",
                id,
              })),
              { type: "EstudiantesPorCurso", id: "LIST" },
            ]
          : [{ type: "EstudiantesPorCurso", id: "LIST" }],
      transformResponse: (response, meta, args) => {
        if (Array.isArray(response)) {
          return response?.map((el) => reshape_response(el, args.shape));
        } else {
          const resultsTransformed = response?.results?.map((el) =>
            reshape_response(el, args.shape)
          );
          return {
            results: resultsTransformed,
            count: response.count,
            next: response.next,
            previous: response.previous,
          };
        }
      },
    }),
    getEstudiantesPorCursoById: builder.query({
      query: ({ id, filter_params }) => ({
        url: buildFiltersUrl(
          `/v1/api/distribuciondeestudianteporcurso/${id}/`,
          filter_params
        ),
        method: "GET",
      }),
      providesTags: (result, error, id) => [
        { type: "EstudiantesPorCurso", id },
      ],
    }),
    createEstudiantesPorCurso: builder.mutation({
      query: (data) => ({
        url: "/v1/api/distribuciondeestudianteporcurso/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "EstudiantesPorCurso", id: "LIST" }],
    }),
    deleteEstudiantesPorCurso: builder.mutation({
      query: (id) => ({
        url: `/v1/api/distribuciondeestudianteporcurso/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "EstudiantesPorCurso", id: "LIST" }],
    }),
    editEstudiantesPorCurso: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `/v1/api/distribuciondeestudianteporcurso/${id}/`,
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: (result, error, { id }) =>
        result
          ? [
              { type: "EstudiantesPorCurso", id },
              { type: "EstudiantesPorCurso", id: "LIST" },
            ]
          : [{ type: "EstudiantesPorCurso", id: "LIST" }],
    }),
    sellarEstudiantesPorCurso: builder.mutation({
      query: (body) => ({
        url: `/v1/api/distribuciondeestudianteporcurso/sellar/`,
        method: "PATCH",
        body: body,
      }),
      invalidatesTags: (result, error, { id }) =>
        result
          ? [
              { type: "EstudiantesPorCurso", id },
              { type: "EstudiantesPorCurso", id: "LIST" },
            ]
          : [{ type: "EstudiantesPorCurso", id: "LIST" }],
    }),
  }),
});

export const {
  useGetEstudiantesPorCursoQuery,
  useLazyGetEstudiantesPorCursoQuery,
  useCreateEstudiantesPorCursoMutation,
  useEditEstudiantesPorCursoMutation,
  useSellarEstudiantesPorCursoMutation,
  useDeleteEstudiantesPorCursoMutation,
  useLazyGetEstudiantesPorCursoByIdQuery,
  useGetEstudiantesPorCursoByIdQuery,
} = estudiantesPorCursoApi;
