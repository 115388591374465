import {cursosApi} from "../view/cursos.service";

export const editCurso = cursosApi.injectEndpoints({
    endpoints: (builder) => ({
        editCurso: builder.mutation({
            query: ({id, ...rest}) => {
                rest.nombre = rest.nombre?.trim();

                return {
                    url: `/v1/api/curso/${id}/`,
                    method: "PATCH",
                    body: rest,
                };
            },
            invalidatesTags: (result, error, {id}) =>
                result
                    ? [
                        {type: "Cursos", id},
                        {type: "Cursos", id: "LIST"},
                    ]
                    : [{type: "Cursos", id: "LIST"}],
        }),
    }),
})

export const {
    useEditCursoMutation,
} = editCurso;