import "../styles/calificaciones.css";

// Images
import cursosCren from "assets/images/tecnoschool/cren_benito/cursosCren.png";
import sierra_cursos from "assets/images/tecnoschool/sierra/sierra_cursos.png";
import { formatoAEquivalenteHoras } from "helpers/functions";
import {
  Document,
  Page,
  Image,
  Text,
  Font,
  StyleSheet,
  View,
  Svg,
  Line,
} from "@react-pdf/renderer";
import Montserrat from "../../../../assets/fonts/Montserrat/static/Montserrat-Bold.ttf";
import MontserratBoldItalic from "../../../../assets/fonts/Montserrat/static/Montserrat-BoldItalic.ttf";
import { chunkSubstr, fechaConLetras } from "../../../../helpers/functions";
import { useMemo } from "react";

Font.register({
  family: "Montserrat",
  fonts: [
    { src: Montserrat, fontWeight: "bold" },
    { src: MontserratBoldItalic, fontStyle: "italic", fontWeight: "bold" },
  ],
});

const styles = StyleSheet.create({
  textSubtitle: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: "13pt",
    lineHeight: "1.5",
    textTransform: "uppercase",
  },
  texWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  botton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  marginBottom: {
    marginBottom: "25px",
  },
  bottonOtro: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  bottonSpaceEvenly: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  bottonCertificado: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
});

const MyPDF = ({ curso, qrImageSrc, estudiante, firma }) => {
  const claveEntidad = JSON.parse(sessionStorage.getItem("user"))?.perfil
    ?.entidad?.clave_del_centro;

  return (
    <Document>
      <Page
        wrap={false}
        size={"letter"}
        style={{
          marginTop: "3mm",
          height: "279.4mm",
          paddingLeft: "0.19cm",
          paddingRight: "0.19cm",
          paddingTop: "0.2cm",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
        }}
      >
        <View
          style={{
            display: "flex",
            marginTop: "2mm",
            alignItems: "center",
          }}
        >
          <Image
            component="img"
            src={claveEntidad === "13DLN0002G" ? sierra_cursos : cursosCren}
            variant="gradient"
            style={{
              height: "3.26cm",
              width: "16.5cm",
            }}
          />
        </View>

        <View style={[styles.texWrapper, { marginTop: "25px" }]}>
          <Text style={styles.textSubtitle}>
            {claveEntidad === "13DLN0002G"
              ? "LA DIRECCIÓN DE LA ESCUELA NORMAL SIERRA HIDALGUENSE"
              : "LA DIRECCIÓN DEL CENTRO REGIONAL DE EDUCACIÓN NORMAL"}
          </Text>
          <Text style={styles.textSubtitle}>
            {claveEntidad === "13DLN0002G"
              ? "OTORGA LA PRESENTE"
              : " “BENITO JUÁREZ” OTORGA LA PRESENTE"}
          </Text>
          <Text
            style={[
              styles.textSubtitle,
              {
                marginTop: "10px",
                fontSize: "45pt",
                color: "#621032",
                letterSpacing: "3mm",
              },
            ]}
          >
            CONSTANCIA
          </Text>
          <Text style={styles.textSubtitle}>A:</Text>
          <Text style={[styles.textSubtitle, { fontSize: "16pt" }]}>
            {`${estudiante?.estudiante.user.first_name} ${estudiante?.estudiante.user.apellido_paterno} ${estudiante?.estudiante.user.apellido_materno}`}
          </Text>
        </View>
        <View style={[styles.texWrapper, styles.marginBottom]}>
          <Text
            style={[
              styles.textSubtitle,
              {
                textAlign: "justify",
                width: "80%",
                fontSize: "11pt",
                letterSpacing: "0.1mm",
              },
            ]}
          >
            Por haber concluido satisfactoriamente el curso extracurricular "
            {curso?.nombreCurso}" en el periodo del{" "}
            {fechaConLetras(curso?.fecha_inicio)} al{" "}
            {fechaConLetras(curso?.fecha_fin)}{" "}
            {claveEntidad === "13DLN0002G"
              ? `en modalidad ${curso?.modalidad.toLowerCase()}`
              : ""}
            , con una duración de{" "}
            {formatoAEquivalenteHoras(curso?.duracionCurso)} horas.
          </Text>
          <Text
            style={[
              styles.textSubtitle,
              {
                textAlign: "center",
                marginTop: "40px",
              },
            ]}
          >
            Atentamente
          </Text>
        </View>
        <View style={[styles.bottonOtro, { marginBottom: "25px" }]}>
          <Image
            className="firma"
            src={firma?.firma}
            variant="gradient"
            style={{
              maxWidth: "4cm",
              maxHeight: "4cm",
              marginBottom: "5px",
            }}
          />

          <Svg height={3}>
            <Line
              x1={150}
              x2={450}
              y1={0}
              y2={0}
              stroke="black"
              strokeWidth={2}
            />
          </Svg>
          <Text style={styles.textSubtitle}>{firma?.nombre}</Text>
          <Text style={styles.textSubtitle}>{firma?.cargo}</Text>
        </View>

        <View style={styles.marginBottom}>
          <View style={styles.bottonSpaceEvenly}>
            <View style={styles.bottonCertificado}>
              <Image
                src={qrImageSrc}
                alt="QR Code"
                style={{ width: "3.6cm", height: "3.6cm" }}
              />
            </View>
            <View style={[styles.bottonOtro, { alignItems: "flex-start" }]}>
              <Text
                style={{
                  fontSize: "10pt",
                }}
              >
                Sello Digital:
              </Text>
              <Text
                style={{
                  marginTop: "8px",
                  maxWidth: "14cm",
                  fontSize: "10pt",
                  textAlign: "justify",
                  fontFamily: "Courier-Bold",
                }}
              >
                {chunkSubstr(firma.sello, 59)?.map((e, index) => {
                  return (
                    <Text
                      key={index}
                      style={{
                        fontFamily: "Courier-Bold",
                      }}
                    >
                      {e}{" "}
                    </Text>
                  );
                })}
              </Text>
            </View>
          </View>

          <Text
            style={{
              fontFamily: "Montserrat",
              fontWeight: "bold",
              fontStyle: "italic",
              direction: "rtl",
              marginRight: "2.1cm",
              fontSize: "7pt",
              marginBottom: "1.5cm",
            }}
          >
            {curso?.localizacion}, a {fechaConLetras(curso?.expedicion)}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default MyPDF;
