import QrCode from "components/QrCode/qr_code";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import ModalBase from "components/ModalBase/modal_base";
import "./styles/etiqueta.css";

const style = {
  border: "1px solid",
  borderRadius: "1rem",
  width: "auto",
  padding: "1rem",
  height: "auto",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const EtiquetaPDF = ({ open, handleClose, activo }) => {
  const printDocument = () => {
    const etiqueta = document.getElementById(activo.descripcion);
    html2canvas(etiqueta, { scale: 20 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg", 1.0); // aumentar calidad de la imagen JPEG
      const pdf = new jsPDF("l", "mm", [62, 30]);
      pdf.addImage(imgData, "JPEG", 0, 0, 62, 30, "", "FAST"); // ajustar calidad y velocidad de la imagen JPEG
      pdf.save(`${String(activo.descripcion).toLowerCase()}_etiqueta.pdf`);
    });
  };

  return (
    <ModalBase open={open} handleClose={handleClose}>
      <MDBox sx={style} mb={2}>
        <div id={activo.descripcion} className="container pdf pdf-margin">
          <div className="qr-container">
            <QrCode id={activo.id} />
          </div>
          <div className="info">
            <p>
              <b> {activo?.entidad.nombre} </b>
            </p>
            <p>{activo?.descripcion}</p>
            <p>
              <b>NS:</b> {activo?.numero_de_serie}
            </p>
            <p>
              <b>INVE:</b> {activo?.numero_de_inventario}
            </p>
          </div>
        </div>
      </MDBox>
      <MDButton
        type="button"
        variant="gradient"
        color="dark"
        onClick={() => printDocument()}
      >
        imprimir
      </MDButton>
    </ModalBase>
  );
};

export default EtiquetaPDF;
