import { Autocomplete, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { useId } from "react";
import FormField from "components/FormField";
import PropTypes from "prop-types";
import { useGetPlantillaQuery } from "services";
import {
  GroupHeader,
  GroupItems,
} from "../../../../layouts/almacen/pages/activo-resguardar/ActivoResguardar";

const FormModal = ({ formData }) => {
  const uid = useId();
  const {
    values,
    errors,
    touched,
    formField,
    handleChange,
    handleBlur,
    setFieldValue,
  } = formData;
  const { fecha, cantidad, personal } = formField;
  const { fecha: fechaV, cantidad: cantidadV, personal: personalV } = values;
  const {
    data: personal_data,
    isError,
    isLoading,
    error,
  } = useGetPlantillaQuery(
    {
      filter_params: {
        estudiantes: "false",
        query: "{id,url,email,first_name,apellido_paterno,apellido_materno}",
      },
      shape: {
        id: "id",
        url: "url",
        email: "email",
        nombre: "first_name apellido_paterno apellido_materno",
      },
    },
    {
      refetchOnReconnect: true,
    },
  );

  React.useEffect(() => {
    if (isError) {
      if (Array.isArray(error)) {
        error.forEach((el) => enqueueSnackbar(el, { variant: "error" }));
      } else {
        enqueueSnackbar(error.data.detail, { variant: "error" });
      }
    }
  }, [isLoading]);

  const [personalValue, setPersonalValue] = React.useState();

  React.useEffect(() => {
    setFieldValue(personal.name, personalValue);
  }, [personalValue]);

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5" color="dark">
          Asignar activo:
        </MDTypography>
      </MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              key={"0"}
              label={fecha.label}
              type={fecha.type}
              name={fecha.name}
              error={errors.fecha && touched.fecha}
              success={fechaV.length > 0 && !errors.fecha}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              key={"1"}
              type={cantidad.type}
              label={cantidad.label}
              name={cantidad.name}
              error={errors.cantidad && touched.cantidad}
              success={cantidadV > 0 && !errors.cantidad}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            {personal_data && (
              <Autocomplete
                id={uid}
                options={personal_data}
                onChange={(event, option) => {
                  if (option) {
                    // Si se seleccionó una opción, establece la URL en el campo correspondiente del formulario
                    setFieldValue("personal", option.url);
                  } else {
                    // Si se borró la selección, establece la URL en null
                    setFieldValue("personal", null);
                  }
                }}
                isOptionEqualToValue={(option, value) =>
                  option.nombre === value.nombre
                }
                getOptionLabel={(option) => `${option.nombre}`}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <FormField
                    id="personal"
                    name={"personal"}
                    label="Personal"
                    success={params.inputProps.value.length > 0}
                    {...params}
                  />
                )}
                renderGroup={(params) => (
                  <li key={params.key}>
                    <GroupHeader>{params.group}</GroupHeader>
                    <GroupItems>{params.children}</GroupItems>
                  </li>
                )}
              />
            )}
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
};

FormModal.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default FormModal;
