import {buildFiltersUrl, reshape_response} from "../../../../helpers/functions";
import {cursosApi} from "../view/cursos.service";

export const getCursoById = cursosApi.injectEndpoints({
    endpoints: (builder) => ({
        getCursoById: builder.query({
            query: ({id, filter_params}) => ({
                url: buildFiltersUrl(`/v1/api/curso/${id}/`, filter_params),
                method: "GET",
            }),
            providesTags: (result, error, id) => [{type: "Cursos", id}],
            transformResponse: (response, meta, args) => {
                return args.shape ? reshape_response(response, args.shape) : response;
            },
        }),
    }),
})

export const {
    useGetCursoByIdQuery,
    useLazyGetCursoByIdQuery
} = getCursoById;