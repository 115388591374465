/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// NewUser page components
import FormField from "components/FormField";
import {useGetCarrerasQuery} from "services";
import {MenuItem} from "@mui/material";

function UserInfo({formData, editar}) {
    const {formField, values, errors, touched, handleChange} = formData;
    const {
        nombre,
        apellido_paterno,
        apellido_materno,
        numero_de_control,
        curp,
        preasignacion_de_carrera,
        usuario_wifi,
        password_wifi,
    } = formField;

    const {
        nombre: nombreV,
        apellido_paterno: apellido_paternoV,
        apellido_materno: apellido_maternoV,
        email_institucional: email_institucionalV,
        numero_de_control: numero_de_controlV,
        curp: curpV,
        preasignacion_de_carrera: preasignacion_de_carreraV,
        usuario_wifi: usuario_wifiV,
        password_wifi: password_wifiV,
    } = values;

    const {data: preasignacion_de_carreras} = useGetCarrerasQuery(
        {
            filter_params: {
                query: "{id,url,nombre,plan_de_estudio}",
                ordering: "nombre,plan_de_estudio",
            },
            shape: {
                id: "id",
                nombre: "nombre",
                plan_de_estudio: "plan_de_estudio",
                url: "url",
            },
        },
        {
            refetchOnReconnect: true,
        }
    );

    return (
        <MDBox>
            <MDBox mt={1.625}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={curp.type}
                            label={curp.label}
                            name={curp.name}
                            value={curpV}
                            onInput={(e) =>
                                (e.target.value = ("" + e.target.value)
                                    .replace(/\s/g, "")
                                    .toUpperCase())
                            }
                            placeholder={curp.placeholder}
                            error={errors.curp && touched.curp}
                            success={curpV.length > 0 && !errors.curp}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={nombre.type}
                            label={nombre.label}
                            name={nombre.name}
                            value={nombreV}
                            placeholder={nombre.placeholder}
                            error={errors.nombre && touched.nombre}
                            success={nombreV.length > 0 && !errors.nombre}
                            onInput={(e) =>
                                (e.target.value = ("" + e.target.value).toUpperCase())
                            }
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={apellido_paterno.type}
                            label={apellido_paterno.label}
                            name={apellido_paterno.name}
                            value={apellido_paternoV}
                            placeholder={apellido_paterno.placeholder}
                            error={errors.apellido_paterno && touched.apellido_paterno}
                            success={
                                apellido_paternoV?.length > 0 && !errors.apellido_paterno
                            }
                            onInput={(e) =>
                                (e.target.value = ("" + e.target.value).toUpperCase())
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={apellido_materno.type}
                            label={apellido_materno.label}
                            name={apellido_materno.name}
                            value={apellido_maternoV}
                            placeholder={apellido_materno.placeholder}
                            error={errors.apellido_materno && touched.apellido_materno}
                            success={apellido_maternoV.length > 0 && !errors.apellido_materno}
                            onInput={(e) =>
                                (e.target.value = ("" + e.target.value).toUpperCase())
                            }
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={numero_de_control.type}
                            label={numero_de_control.label}
                            name={numero_de_control.name}
                            value={numero_de_controlV}
                            onInput={(e) => {
                                e.target.value = ("" + e.target.value)
                                    .replace(/\s/g, "")
                                    .toUpperCase();
                            }}
                            placeholder={numero_de_control.placeholder}
                            error={errors.numero_de_control && touched.numero_de_control}
                            success={
                                numero_de_controlV.length > 0 && !errors.numero_de_control
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            select
                            id="preasignacion_de_carrera"
                            value={preasignacion_de_carreraV}
                            label={preasignacion_de_carrera.label}
                            name={preasignacion_de_carrera.name}
                            error={
                                errors.preasignacion_de_carrera &&
                                touched.preasignacion_de_carrera
                            }
                            success={
                                preasignacion_de_carreraV?.length > 0 &&
                                !errors.preasignacion_de_carrera
                            }
                            onChange={handleChange}
                            fullWidth
                        >
                            <MenuItem key={""} value={""}>
                                No Seleccionado
                            </MenuItem>
                            {preasignacion_de_carreras?.map(
                                ({nombre, id, url, plan_de_estudio}) => (
                                    <MenuItem key={id} value={url}>
                                        {`${nombre} ${plan_de_estudio}`}
                                    </MenuItem>
                                )
                            )}
                        </FormField>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={usuario_wifi.type}
                            label={usuario_wifi.label}
                            name={usuario_wifi.name}
                            value={usuario_wifiV}
                            onInput={(e) => {
                                e.target.value = ("" + e.target.value)
                                    .replace(/\s/g, "")
                                    .toUpperCase();
                            }}
                            placeholder={usuario_wifi.placeholder}
                            error={errors.usuario_wifi && touched.usuario_wifi}
                            success={usuario_wifiV.length > 0}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={password_wifi.type}
                            label={password_wifi.label}
                            name={password_wifi.name}
                            value={password_wifiV}
                            onInput={(e) => {
                                e.target.value = ("" + e.target.value)
                                    .replace(/\s/g, "")
                                    .toUpperCase();
                            }}
                            placeholder={password_wifi.placeholder}
                            error={errors.password_wifi && touched.password_wifi}
                            success={password_wifiV?.length > 0}
                        />
                    </Grid>
                </Grid>
            </MDBox>
        </MDBox>
    );
}

// typechecking props for UserInfo
UserInfo.propTypes = {
    formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default UserInfo;
