import * as React from "react";
import PropTypes from "prop-types";

import { Form, Formik } from "formik";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import FormModal from "./components/Form/form_modal";
import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useDevolverActivosMutation } from "services";
import ModalBase from "components/ModalBase/modal_base";
import { useCloseModal } from "hooks/useCloseModal";

const ModalDevolucion = ({ open, handleClose, activo }) => {
  const { formId, formField } = form;

  const [
    devolverActivo,
    {
      isSuccess: isSuccessD,
      isLoading: isLoadingD,
      isError: isErrorD,
      error: errorD,
    },
  ] = useDevolverActivosMutation();

  useCloseModal(
    isLoadingD,
    isSuccessD,
    isErrorD,
    errorD,
    "Activo dado de baja correctamente",
    handleClose
  );

  const submitForm = (values, actions) => {
    actions.setSubmitting(false);
    actions.resetForm();

    if (activo) {
      devolverActivo({
        activos: [activo],
        fecha: values.fecha,
        motivo: values.motivo,
      });
    }
  };

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
  };

  return (
    <ModalBase open={open} handleClose={handleClose}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} lg={12}>
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ values, errors, touched, isValid, handleChange }) => (
              <Form key={formId} id={formId} autoComplete="off">
                <MDBox p={1}>
                  <FormModal
                    formData={{
                      values,
                      touched,
                      errors,
                      handleChange,
                      formField,
                    }}
                  />
                  <MDBox
                    mt={2}
                    width="100%"
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <MDButton
                      disabled={!isValid}
                      type="submit"
                      variant="gradient"
                      color="dark"
                    >
                      aceptar
                    </MDButton>
                  </MDBox>
                </MDBox>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </ModalBase>
  );
};

ModalDevolucion.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ModalDevolucion;
