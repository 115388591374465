import ModalBase from "components/ModalBase/modal_base";
import FormField from "components/FormField";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useCreateEvaluacionesMutation } from "services";
import { useEditEvaluacionesMutation } from "services";
import { useCloseModal } from "hooks/useCloseModal";
import { Grid } from "@mui/material";
import React from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

const calcularAsist = (values) =>
  (Number(values.asistencias) /
    (Number(values.asistencias) + Number(values.faltas))) *
  100;

const CalificarModal = ({
  open,
  handleClose,
  distribucion_profesor,
  distribucion_estudiante,
  estudiantes,
  edit,
}) => {
  const [actual, setActual] = React.useState(distribucion_estudiante);
  const [to_send, set_to_send] = React.useState([]);
  const [createEvaluaciones, { isSuccess, isError, isLoading, error }] =
    useCreateEvaluacionesMutation();
  const [
    editEvaluaciones,
    {
      isSuccess: isSuccessE,
      isError: isErrorE,
      isLoading: isLoadingE,
      error: errorE,
    },
  ] = useEditEvaluacionesMutation();

  React.useEffect(() => {
    if (!edit) setActual(distribucion_estudiante);
    else setActual(edit);
  }, [distribucion_estudiante]);

  useCloseModal(
    isLoading,
    isSuccess,
    isError,
    error,
    "Calificación(es) asignada(s) correctamente",
    handleClose
  );

  useCloseModal(
    isLoadingE,
    isSuccessE,
    isErrorE,
    errorE,
    "Calificación editada correctamente",
    handleClose
  );

  const initialValues = {
    distribucion_profesor: distribucion_profesor ?? "",
    distribucion_estudiante: distribucion_estudiante?.url_dist_estu ?? "",
    faltas: 0,
    asistencias: 0,
    nota_final: 0,
  };

  const validationSchemas = Yup.object().shape({
    faltas: Yup.number()
      .required("Debe introducir una cantidad de faltas")
      .min(0)
      .max(99, "La cantidad de faltas debe ser menor a 100"),
    asistencias: Yup.number()
      .required("Debe introducir una cantidad de asistencias")
      .min(0)
      .max(99, "La cantidad de asistencias debe ser menor a 100"),
    nota_final: Yup.number()
      .required("Debe introducir una nota final")
      .min(5, "La nota minima es 5")
      .max(10, "La nota máxima es 10"),
  });

  const submitForm = async (to_send, actions) => {
    if (!edit) {
      if (to_send.length > 0) {
        await Promise.all(
          to_send.map(async (el) => {
            let asistencia = calcularAsist(el);

            // Si la asistencia es menor que 85, la nota final será 5
            if (asistencia < 85) {
              el.nota_final = 5;
            }
            await createEvaluaciones(el);
          })
        );
      }
    } else {
      // Calcula la asistencia cada vez que se envíe el formulario
      let asistencia = calcularAsist(to_send);

      // Si la asistencia es menor que 85, la nota final será 5
      if (asistencia < 85) {
        to_send.nota_final = 5;
      }
      await editEvaluaciones({
        id: edit.ordinario.id_dist_eval,
        ...Object.fromEntries(
          Object.entries(to_send).filter(
            ([k, v]) =>
              !(
                edit[k] === v ||
                k === "distribucion_profesor" ||
                k === "distribucion_estudiante"
              )
          )
        ),
      });
    }
    if (isSuccess || isSuccessE) {
      actions.setSubmitting(false);
      actions.resetForm();
      set_to_send([]);
      setActual({});
    }
  };

  const handleSubmit = (values, actions) => {
    if (!edit) {
      if (
        !to_send.some((item) => JSON.stringify(item) === JSON.stringify(values))
      ) {
        submitForm([...to_send, values], actions);
      }
    } else {
      submitForm(values, actions);
    }
  };

  const handleNext = (values) => {
    set_to_send([...to_send, values]);
    setActual(estudiantes[estudiantes.indexOf(actual) + 1]);
  };

  return (
    <ModalBase open={open} handleClose={handleClose}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} lg={12}>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchemas}
          >
            {({
              values,
              errors,
              touched,
              isValid,
              handleBlur,
              setFieldValue,
            }) => {
              React.useEffect(() => {
                if (!edit) {
                  setFieldValue(
                    "distribucion_estudiante",
                    actual?.url_dist_estu ?? 0,
                    true
                  );
                }
                setFieldValue("faltas", actual?.ordinario?.faltas ?? 0, true);
                setFieldValue(
                  "asistencias",
                  actual?.ordinario?.asistencias ?? 0,
                  true
                );
                setFieldValue(
                  "nota_final",
                  actual?.ordinario?.nota_final ?? 0,
                  true
                );
              }, [actual]);
              return (
                <Form key={"asignacion_notas"} id={"notas_notas"}>
                  <MDBox p={0.5}>
                    <MDBox
                      lineHeight={0}
                      display="flex"
                      gap={1}
                      justifyContent={"center"}
                    >
                      <MDTypography variant="h5" mb={3} color="dark">
                        Asignar nota a:
                      </MDTypography>
                      <MDTypography variant="h5" color="secondary" mb={3}>
                        {`${actual?.nombre}`}
                      </MDTypography>
                    </MDBox>
                    <MDBox mt={1.625}>
                      <Grid
                        container
                        spacing={3}
                        display={"flex"}
                        justifyContent={"center"}
                      >
                        <Grid item xs={12} sm={3}>
                          <FormField
                            type={"tel"}
                            label={"Nota Final"}
                            onBlur={handleBlur}
                            name={"nota_final"}
                            value={Number(values.nota_final)}
                            error={errors.nota_final && touched.nota_final}
                            success={
                              values.nota_final >= 5 && !errors.nota_final
                            }
                            onInput={(e) => {
                              let value = ("" + e.target.value).replace(
                                /[^(\d|\.)]/g,
                                ""
                              );
                              e.target.value = isNaN(Number(value))
                                ? 0
                                : Number(value).toFixed();
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <FormField
                            label={"Asistencias"}
                            type={"tel"}
                            name={"asistencias"}
                            onBlur={handleBlur}
                            value={Number(values.asistencias)}
                            error={errors.asistencias && touched.asistencias}
                            success={
                              values.asistencias >= 0 && !errors.asistencias
                            }
                            onInput={(e) => {
                              let value = ("" + e.target.value).replace(
                                /[^(\d|\.)]/g,
                                ""
                              );
                              e.target.value = isNaN(Number(value))
                                ? 0
                                : Number(value);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <FormField
                            label={"Faltas"}
                            type={"tel"}
                            name={"faltas"}
                            onBlur={handleBlur}
                            value={Number(values.faltas)}
                            error={errors.faltas && touched.faltas}
                            success={values.faltas >= 0 && !errors.faltas}
                            onInput={(e) => {
                              let value = ("" + e.target.value).replace(
                                /[^(\d|\.)]/g,
                                ""
                              );
                              e.target.value = isNaN(Number(value))
                                ? 0
                                : Number(value);
                            }}
                          />
                        </Grid>
                      </Grid>
                    </MDBox>

                    <MDBox
                      mt={2}
                      width="100%"
                      display="flex"
                      justifyContent="space-between"
                    >
                      {!edit && (
                        <MDButton
                          disabled={
                            !isValid ||
                            estudiantes.indexOf(actual) ===
                              estudiantes.length - 1
                          }
                          type="button"
                          onClick={() => handleNext(values)}
                          variant="gradient"
                          color="primary"
                        >
                          siguiente
                        </MDButton>
                      )}
                      <MDButton
                        disabled={!isValid}
                        type="submit"
                        variant="gradient"
                        color="dark"
                      >
                        aceptar
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </ModalBase>
  );
};

export default CalificarModal;
