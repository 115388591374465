// formik components
import { Form, Formik } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// NewUser page components
import AddUser from "./components/AddUser/user.add";

// NewUser layout schemas for form and form
import validations from "./schemas/validations";
import form from "./schemas/form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import MDTypography from "components/MDTypography";
import { USUARIOS_URL } from "config/bases_url";
import { admin_state, setNoHereAdmin } from "slices";
import initialValues from "./schemas/initialValues";
import { useRedirectForm } from "hooks/useRedirectForm";
import { Step, StepLabel, Stepper } from "@mui/material";
import {
  useCreateUserMutation,
  useEditAdministrativoMutation,
  useLazyGetUserByIdQuery,
} from "services";
import TelefonosCRUD, {
  telefonoIcon,
} from "examples/TelefonosCRUD/telefonos.add";
import { getModifiedFields } from "helpers/functions";
import FormErrorHandler from "../../../../handlers/FormErrorHandler/form-error.handler";
import { ErrorBundary } from "../../../../config/ErrorBundary/error-bundary";

function getSteps() {
  return ["Información del Usuario", "Teléfonos"];
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <AddUser formData={formData} />;
    case 1:
      return <TelefonosCRUD formData={formData} />;
    default:
      return null;
  }
}

// Función que filtra los campos a excluir
const filterFields = (data, excludeFields) => {
  return Object.fromEntries(
    Object.entries(data).filter(([key, value]) => {
      if (excludeFields.includes(key)) {
        if (key === "telefonos" && Array.isArray(value) && value.length === 0) {
          return false; // Excluir el campo "telefono" si es un arreglo vacío
        }
        return !(typeof value === "string" || value === null || value === "");
      }
      return true;
    })
  );
};

function NewUser() {
  const { id } = useParams();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;
  const handleBack = () => setActiveStep(activeStep - 1);
  const [oldValues, setOldValues] = useState();

  const [
    createUser,
    {
      isSuccess: isSuccessC,
      isLoading: isLoadingC,
      isError: isErrorC,
      error: errorC,
    },
  ] = useCreateUserMutation();

  const [
    editUser,
    {
      isSuccess: isSuccessE,
      isLoading: isLoadingE,
      isError: isErrorE,
      error: errorE,
    },
  ] = useEditAdministrativoMutation();

  const [getUserById, { data: usuario }] = useLazyGetUserByIdQuery();

  const { formId, formField } = form;
  const currentValidation = validations[0];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { noHere } = useSelector(admin_state);

  useEffect(() => {
    if (noHere) {
      navigate(USUARIOS_URL);
    }
  }, []);

  useRedirectForm(
    isLoadingC,
    isSuccessC,
    isErrorC,
    errorC,
    "Usuario creado",
    USUARIOS_URL
  );

  useRedirectForm(
    isLoadingE,
    isSuccessE,
    isErrorE,
    errorE,
    "Usuario editado",
    USUARIOS_URL
  );

  const submitForm = async (values, actions) => {
    const excludeFields = ["foto", "telefonos"]; // Campos a excluir

    try {
      if (!id) {
        const data = filterFields(values, excludeFields);
        await createUser(data);
      } else {
        const modifiedFields = getModifiedFields(oldValues, values);
        if (Object.keys(modifiedFields).length !== 0) {
          const data = filterFields(modifiedFields, excludeFields);
          await editUser({ id: id, ...data });
        }
      }
      navigate(USUARIOS_URL);
      dispatch(setNoHereAdmin(true));
      if (isSuccessC || isSuccessE) {
        actions.setSubmitting(false);
        actions.resetForm();
      }
    } catch (error) {
      console.error(error);
      actions.setSubmitting(false);
    }
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  return (
    <MDBox mt={5} mb={20}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} lg={8}>
          <MDBox mt={6} mb={8} textAlign="center">
            <MDBox mb={1}>
              <MDTypography variant="h3" fontWeight="bold">
                {!id ? "Agregar Usuario" : "Editar Usuario"}
              </MDTypography>
            </MDBox>
            <MDTypography variant="h5" fontWeight="regular" color="secondary">
              {!id
                ? "Entre la información relacionada al usuario a agregar"
                : `Edite la información relacionada al usuario: ${usuario?.user.first_name} ${usuario?.user.apellido_paterno} ${usuario?.user.apellido_materno}`}
            </MDTypography>
          </MDBox>
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              isValid,
              setFieldValue,
              setErrors,
            }) => {
              useEffect(() => {
                if (id) {
                  getUserById(id)
                    .unwrap()
                    .then((res) => {
                      setOldValues({
                        apellido_paterno: res?.user?.apellido_paterno,
                        apellido_materno: res?.user?.apellido_materno,
                        telefonos: res?.telefonos?.map((el) => ({
                          ...el,
                          isCreated: false,
                          icon: telefonoIcon(el.tipo),
                        })),
                        entidad: res?.entidad?.url,
                        correo: res?.user?.email,
                        groups: res?.user?.groups.map((e) => {
                          return e.url;
                        }),
                        nombre: res?.user?.first_name,
                        foto: res?.user?.foto,
                      });
                      setFieldValue(
                        formField.apellido_paterno.name,
                        res.user.apellido_paterno,
                        true
                      );
                      setFieldValue(
                        formField.apellido_materno.name,
                        res.user.apellido_materno,
                        true
                      );
                      setFieldValue(
                        formField.telefonos.name,
                        res?.telefonos?.map((el) => ({
                          ...el,
                          isCreated: false,
                          icon: telefonoIcon(el.tipo),
                        })) || [],
                        true
                      );
                      setFieldValue(
                        formField.entidad.name,
                        res.entidad.url,
                        true
                      );
                      setFieldValue(formField.foto.name, res.user.foto, true);
                      setFieldValue(
                        formField.correo.name,
                        res.user.email,
                        true
                      );
                      setFieldValue(
                        formField.groups.name,
                        res.user.groups.map((e) => {
                          return e.url;
                        }),
                        true
                      );
                      setFieldValue(
                        formField.nombre.name,
                        res.user.first_name,
                        true
                      );
                    });
                }
              }, []);

              return (
                <ErrorBundary renderOnError={() => <FormErrorHandler />}>
                  <Form id={formId} autoComplete="off">
                    <Card sx={{ height: "100%" }}>
                      <MDBox mx={2} mt={-3}>
                        <Stepper activeStep={activeStep} alternativeLabel>
                          {steps.map((label) => (
                            <Step key={label}>
                              <StepLabel>{label}</StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                      </MDBox>
                      <MDBox p={3}>
                        <MDBox>
                          {getStepContent(activeStep, {
                            values,
                            touched,
                            formField,
                            errors,
                            setErrors,
                            setFieldValue,
                          })}
                          <MDBox
                            mt={2}
                            width="100%"
                            display="flex"
                            justifyContent="space-between"
                          >
                            <MDButton
                              onClick={(e) =>
                                activeStep === 0
                                  ? navigate(USUARIOS_URL)
                                  : handleBack()
                              }
                              type="button"
                              variant="gradient"
                              color={activeStep === 0 ? "primary" : "dark"}
                            >
                              {activeStep === 0 ? "cancelar" : "atrás"}
                            </MDButton>
                            {activeStep !== 0 && (
                              <MDButton
                                onClick={(e) => navigate(USUARIOS_URL)}
                                type="button"
                                variant="gradient"
                                color="primary"
                              >
                                cancelar
                              </MDButton>
                            )}
                            <MDButton
                              type="submit"
                              variant="gradient"
                              color="dark"
                            >
                              {isLastStep ? "aceptar" : "siguiente"}
                            </MDButton>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </Card>
                  </Form>
                </ErrorBundary>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default NewUser;
