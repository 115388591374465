import * as React from "react";
import MDBox from "components/MDBox";
import { Link, useParams } from "react-router-dom";
import { Card, CircularProgress, Grid, Icon, Tooltip } from "@mui/material";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import ComponentsPrivatization from "config/component_privatization";
import { CURSOS_LIST_URL } from "../../../../../config/bases_url";
import CheckBox from "../../../../../components/CheckBox";
import ModalFirmar from "../../../../../components/ModalFirmar";
import { useSnackbar } from "notistack";
import { CONSTANCIAS, COORDINADOR_DOCENTE, FIRMANTE } from "roles";
import { style_icon } from "helpers/global_vars";
import { useGetCursoByIdQuery, useGetEstudiantesPorCursoQuery } from "services";

import { formatoAEquivalenteHoras } from "helpers/functions";
import useModalHandler from "../../../../../hooks/useModalHandler";
import { pdf } from "@react-pdf/renderer";
import MyPDF from "layouts/escuela/pdf/reactPdf/pdf-plantilla";
import { generarURL } from "helpers/functions";
import { QRCodeCanvas } from "qrcode.react";
import { saveAs } from "file-saver";
import { removeColumns } from "config/component_privatization";
import { useGetFirmaCursosQuery } from "services";
import { useDeleteFirmaCursoMutation } from "services";
import Swal from "sweetalert2";


const CursoShow = () => {
  const { id } = useParams();
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [checkedAll, setCheckedAll] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { open, handleOpen, handleClose } = useModalHandler();

  const { data: curso } = useGetCursoByIdQuery(
    {
      id,
      filter_params: {
        query:
          "{id,nombre,nombre_corto,duracion,expedicion,localizacion,fecha_inicio,fecha_fin,firmante{firma,cargo,user{first_name,apellido_paterno,apellido_materno}},modalidad{nombre}}",
      },
      shape: {
        id: "id",
        nombreCurso: "nombre",
        nombreCorto: "nombre_corto",
        duracionCurso: "duracion",
        expedicion: "expedicion",
        localizacion: "localizacion",
        fecha_inicio: "fecha_inicio",
        fecha_fin: "fecha_fin",
        firmante:
          "firmante.user.apellido_paterno firmante.user.apellido_materno firmante.user.first_name",
        firma: "firmante.firma",
        cargo: "firmante.cargo",
        modalidad: "modalidad.nombre",
      },
    },
    { refetchOnReconnect: true }
  );

  const { data: estudiantes_curso, isLoading } = useGetEstudiantesPorCursoQuery(
    {
      filter_params: {
        curso: id,
        ordering:
          "estudiante__user__apellido_paterno,estudiante__user__apellido_materno,estudiante__user__first_name",
        query: `{id,url,estudiante{id,url,curp,numero_de_control,user{first_name,apellido_paterno,apellido_materno}},firmado,cadena_unica}`,
      },
      shape: {
        id: "id",
        estudiante: "estudiante",
        cadena_unica: "cadena_unica",
        firmado: "firmado",
        url: "url",
      },
    },
    {
      refetchOnReconnect: true,
    }
  );

  const { data: getFirmaCurso } = useGetFirmaCursosQuery({
    filter_params: {
      signed_object__curso: id,
      query:
        "{id,firmante{cargo,firma,user{first_name,apellido_paterno,apellido_materno}},fecha_creacion,sello,signed_object{id}}",
    },
    shape: {
      nombre: "firmante.user.first_name firmante.user.apellido_paterno firmante.user.apellido_materno",
      cargo: "firmante.cargo",
      firma: "firmante.firma",
      fecha: "fecha_creacion",
      sello: "sello",
      id: "id",
      dist_est: "signed_object.id",
    },
  });

   const [
    deleteFirmaCurso,
  ] = useDeleteFirmaCursoMutation();

  React.useEffect(() => {
    if (!checkedAll) {
      setSelectedItems([]);
    } else {
      setSelectedItems(estudiantes_curso.filter((el) => el.firmado === false));
    }
  }, [checkedAll]);

  const data = {
    columns: [
      {
        Header: () => {
          return (
            <CheckBox
              id={"No."}
              checked={
                checkedAll && selectedItems.length === estudiantes_curso.length
              }
              onChange={() => {
                setCheckedAll(!checkedAll);
              }}
            />
          );
        },
        accessor: "checkbox",
        width: "10%",
        Cell: ({ row }) => {
          const { original } = row;
          const { firmado } = original;
          return (
            <CheckBox
              disabled={firmado}
              id={Number(row.id) + 1}
              checked={selectedItems.includes(row.original)}
              onChange={() => {
                const el = row.original;

                if (selectedItems.includes(el)) {
                  setSelectedItems((prevItems) =>
                    prevItems.filter((item) => item !== el)
                  );
                } else {
                  setSelectedItems((prevItems) => [...prevItems, el]);
                }
              }}
            />
          );
        },
      },
      {
        Header: "Matricula",
        accessor: "numero_de_control",
        width: "40%",
        Cell: ({
          row: {
            original: {
              estudiante: { numero_de_control },
            },
          },
        }) => numero_de_control ?? "---",
      },
      {
        Header: "Nombre",
        accessor: "nombre",
        width: "40%",
        Cell: ({
          row: {
            original: {
              estudiante: {
                user: { first_name, apellido_paterno, apellido_materno },
              },
            },
          },
        }) => `${apellido_paterno} ${apellido_materno} ${first_name}` ?? "---",
      },
      {
        Header: "CURP",
        accessor: "curp",
        width: "60%",
        Cell: ({
          row: {
            original: {
              estudiante: { curp },
            },
          },
        }) => curp ?? "---",
      },
      {
        Header: "Certificado",
        accessor: "sello",
        width: "10%",
        Cell: ({ row }) => {
          const { original } = row;
          const { firmado } = original;
          const nombreCompleto = `${original?.estudiante.user.apellido_paterno} ${original?.estudiante.user.apellido_materno} ${original?.estudiante.user.first_name}`;
          return (
            firmado &&
            curso && (
              <MDBox display="flex">
                <ComponentsPrivatization
                  permitted={[COORDINADOR_DOCENTE, FIRMANTE, CONSTANCIAS]}
                >
                  <MDBox px={0.2}>
                    <Tooltip
                      placement="top"
                      title="Ver certificado de constancia extracurricular firmado digitalmente"
                    >
                      <Icon
                        style={style_icon}
                        fontSize="small"
                        onClick={() => printPDF(original)}
                      >
                        picture_as_pdf
                      </Icon>
                    </Tooltip>
                  </MDBox>
                  <MDBox px={0.2}>
                    <Tooltip
                      placement="top"
                      title="Descargar certificado en formato PDF"
                    >
                      <Icon
                        style={style_icon}
                        fontSize="small"
                        onClick={() => downloadPDF(original, nombreCompleto)}
                      >
                        download
                      </Icon>
                    </Tooltip>
                  </MDBox>
                  <MDBox px={0.2}>
                    <Tooltip
                      placement="top"
                      title="Eliminar la firma de la boleta"
                    >
                      <Icon
                        style={style_icon}
                        onClick={() => handleClickEraseFirma(original)}
                        fontSize="small"
                      >
                        delete
                      </Icon>
                    </Tooltip>
                  </MDBox>
                </ComponentsPrivatization>
              </MDBox>
            )
          );
        },
      },
    ],
    rows: estudiantes_curso ?? [],
  };

  const printPDF = (e) => {
    const firmaCorrespondiente = getFirmaCurso.find(
      (elem) => elem.dist_est === e.id
    );
    const canvas = document.getElementById(
      `qrCodeCertificado-${firmaCorrespondiente.dist_est}`
    );
    if (canvas) {
      const dataURL = canvas.toDataURL();
      if (dataURL) {
        let obj = pdf(
          <MyPDF
            curso={curso}
            estudiante={e}
            qrImageSrc={dataURL}
            firma={firmaCorrespondiente}
          />
        ).toBlob();
        obj.then(function (blob) {
          let url = URL.createObjectURL(blob);
          window.open(url, "_blank");
        });
      }
    }
  };

  const downloadPDF = async (e, nombreCompleto) => {
    const firmaCorrespondiente = getFirmaCurso.find(
      (elem) => elem.dist_est === e.id
    );
    const canvas = document.getElementById(
      `qrCodeCertificado-${firmaCorrespondiente.dist_est}`
    );
    if (canvas) {
      const dataURL = canvas.toDataURL();
      if (dataURL) {
        let obj = await pdf(
          <MyPDF
            curso={curso}
            estudiante={e}
            qrImageSrc={dataURL}
            firma={firmaCorrespondiente}
          />
        ).toBlob();
        saveAs(obj, `${nombreCompleto}, ${curso?.nombreCurso}.pdf`);
      }
    }
  };

  const downloadALL = async () => {
    for (let cursoFirmado of getFirmaCurso) {
      let estudianteCorrespondiente = estudiantes_curso.find(
        (elem) => elem.id === cursoFirmado.dist_est
      );
      const nombreCompleto = `${estudianteCorrespondiente?.estudiante.user.apellido_paterno} ${estudianteCorrespondiente?.estudiante.user.apellido_materno} ${estudianteCorrespondiente?.estudiante.user.first_name}`;
      let canvas = document.getElementById(
        `qrCodeCertificado-${cursoFirmado.dist_est}`
      );
      if (canvas) {
        const dataURL = canvas.toDataURL();
        if (dataURL) {
          let obj = await pdf(
            <MyPDF
              curso={curso}
              estudiante={estudianteCorrespondiente}
              qrImageSrc={dataURL}
              firma={cursoFirmado}
            />
          ).toBlob();
          saveAs(obj, `${nombreCompleto}, ${curso?.nombreCurso}.pdf`);
        }
      }
    }
  };

  const handleClickEraseFirma = (e) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: `¡Si eliminas la firma de la boleta esta acción no se podrá revertir!`,
      icon: "warning",
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Eliminar",
    }).then((result) => {
      if (result.isConfirmed) {
        let estudianteCorrespondiente = getFirmaCurso.find(
          (elem) => elem.dist_est === e.id
        );
        deleteFirmaCurso(estudianteCorrespondiente.id);
      }
    });
  };

  return (
    <>
      {open && (
        <ModalFirmar
          open={open}
          handleClose={handleClose}
          selected={selectedItems}
          curso={true}
        />
      )}
      {getFirmaCurso?.map((estudiante) => {
        return (
          <QRCodeCanvas
            key={estudiante.dist_est}
            id={`qrCodeCertificado-${estudiante.dist_est}`}
            style={{
              width: "3.6cm",
              height: "3.6cm",
              display: "none",
            }}
            value={`${generarURL(estudiante.dist_est, true)}`}
            bgColor={"#fff"}
            size={90}
            level={"H"}
            imageSettings={{ height: 3.6, width: 3.6 }}
          />
        );
      })}
      <MDBox pt={6} pb={3}>
        <Card>
          <Grid container>
            <Grid item xs={12} sx={{ mx: "auto" }}>
              <MDBox>
                <Grid
                  container
                  display="flex"
                  spacing={3}
                  flexDirection={"row-reverse"}
                  justifyContent={"space-between"}
                  p={3}
                >
                  <Grid
                    item
                    xs={12}
                    sm={2}
                    gap={2}
                    pr={3}
                    display={"flex"}
                    justifyContent={"end"}
                  >
                    {estudiantes_curso?.some((e) => e.sello !== null) && (
                      <MDButton
                        variant="gradient"
                        color="dark"
                        size="medium"
                        onClick={() => downloadALL()}
                        circular
                        iconOnly
                      >
                        <Tooltip
                          placement="top"
                          title="Descargar todas las constancias firmadas en formato PDF"
                        >
                          <Icon>download</Icon>
                        </Tooltip>
                      </MDButton>
                    )}
                    <ComponentsPrivatization permitted={FIRMANTE}>
                      <MDBox>
                        <MDButton
                          variant="gradient"
                          color="primary"
                          size="medium"
                          onClick={() =>
                            selectedItems.length === 0
                              ? enqueueSnackbar(
                                "Debe seleccionar en la tabla al menos un estudiante para poder realizar la firma.",
                                {
                                  variant: "error",
                                  autoHideDuration: 5000,
                                }
                              )
                              : handleOpen()
                          }
                        >
                          Firmar
                        </MDButton>
                      </MDBox>
                    </ComponentsPrivatization>
                    <Link to={CURSOS_LIST_URL}>
                      <MDButton variant="gradient" color="info" size="medium">
                        Atras
                      </MDButton>
                    </Link>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={10}
                    display={"flex-col"}
                    justifyContent="flex-start"
                  >
                    <Grid container>
                      <Grid item xs={12} sm={4} mb={1}>
                        <MDBox display="flex" gap={1} alignItems="baseline">
                          <MDTypography
                            variant="h6"
                            fontWeight="bold"
                            color="dark"
                          >
                            Curso:
                          </MDTypography>
                          <MDTypography
                            variant="h6"
                            fontWeight="regular"
                            color="secondary"
                          >
                            {curso?.nombreCurso ?? ""}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={4} mb={1}>
                        <MDBox display="flex" gap={1} alignItems="baseline">
                          <MDTypography
                            variant="h6"
                            fontWeight="bold"
                            color="dark"
                          >
                            Duracion:
                          </MDTypography>
                          <MDTypography
                            variant="h6"
                            fontWeight="regular"
                            color="secondary"
                          >
                            {formatoAEquivalenteHoras(curso?.duracionCurso) ??
                              ""}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid item xs={12} sm={4} mb={1}>
                        <MDBox display="flex" gap={1} alignItems="baseline">
                          <MDTypography
                            variant="h6"
                            fontWeight="bold"
                            color="dark"
                          >
                            Nombre corto:
                          </MDTypography>
                          <MDTypography
                            variant="h6"
                            fontWeight="regular"
                            color="secondary"
                          >
                            {curso?.nombreCorto ?? ""}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={4} mb={1}>
                        <MDBox display="flex" gap={1} alignItems="baseline">
                          <MDTypography
                            variant="h6"
                            fontWeight="bold"
                            color="dark"
                          >
                            Expedición:
                          </MDTypography>
                          <MDTypography
                            variant="h6"
                            fontWeight="regular"
                            color="secondary"
                          >
                            {curso?.expedicion ?? ""}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid item xs={12} sm={4} mb={1}>
                        <MDBox display="flex" gap={1} alignItems="baseline">
                          <MDTypography
                            variant="h6"
                            fontWeight="bold"
                            color="dark"
                          >
                            Fecha inicio:
                          </MDTypography>
                          <MDTypography
                            variant="h6"
                            fontWeight="regular"
                            color="secondary"
                          >
                            {curso?.fecha_inicio ?? ""}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={4} mb={1}>
                        <MDBox display="flex" gap={1} alignItems="baseline">
                          <MDTypography
                            variant="h6"
                            fontWeight="bold"
                            color="dark"
                          >
                            Fecha fin:
                          </MDTypography>
                          <MDTypography
                            variant="h6"
                            fontWeight="regular"
                            color="secondary"
                          >
                            {curso?.fecha_fin ?? ""}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid item xs={12} sm={4} mb={1}>
                        <MDBox display="flex" gap={1} alignItems="baseline">
                          <MDTypography
                            variant="h6"
                            fontWeight="bold"
                            color="dark"
                          >
                            Firmante:
                          </MDTypography>
                          <MDTypography
                            variant="h6"
                            fontWeight="regular"
                            color="secondary"
                          >
                            {curso?.firmante ?? ""}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={4} mb={1}>
                        <MDBox display="flex" gap={1} alignItems="baseline">
                          <MDTypography
                            variant="h6"
                            fontWeight="bold"
                            color="dark"
                          >
                            Modalidad:
                          </MDTypography>
                          <MDTypography
                            variant="h6"
                            fontWeight="regular"
                            color="secondary"
                          >
                            {curso?.modalidad ?? ""}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <MDBox mt={3} mb={1} ml={0.5} px={3}>
                  <MDBox
                    display="flex"
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    {isLoading ? (
                      <CircularProgress
                        sx={{ marginBottom: "3rem" }}
                        color="inherit"
                      />
                    ) : (
                      <Grid item xs={12} sx={{ mx: "auto" }}>
                        <DataTable
                          canSearch
                          table={removeColumns(
                            data,
                            () =>
                              !JSON.parse(
                                sessionStorage.getItem("user")
                              ).groups.some((role) => role.name === FIRMANTE),
                            ["checkbox"]
                          )}
                          showTotalEntries={false}
                          entriesPerPage={false}
                          isSorted={false}
                          indice={
                            !JSON.parse(
                              sessionStorage.getItem("user")
                            ).groups.some((role) => role.name === FIRMANTE)
                          }
                        />
                      </Grid>
                    )}
                  </MDBox>
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
        </Card>
      </MDBox>
    </>
  );
};

export default CursoShow;
