// @mui material components
import React, { useState } from "react";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DataTable from "examples/Tables/DataTable";

//React Hooks and Others
import { CircularProgress, Icon, Tooltip, useMediaQuery } from "@mui/material";

import PropTypes from "prop-types";

import { style_icon } from "helpers/global_vars";
import { useRedirectForm } from "hooks/useRedirectForm";
import {
  useCleanAsignaturasPorProfesorMutation,
  useGetAsignaturasPorProfesorQuery,
  useGetFechaEvaluacionQuery,
  useLazyGetAsignaturasPorProfesorByIdQuery,
  useLazyNotasEstudiantesQuery,
} from "services";
import { COORDINADOR_DOCENTE, PROFESOR } from "roles";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import ComponentsPrivatization from "config/component_privatization";
import ModalNotasPdf from "components/PDF/modal_calificacion_pdf";
import usePagination from "hooks/usePagination";

import { paridad } from "./calificaciones_show";
import useModalHandler from "../../../../../../hooks/useModalHandler";
import ActaAcreditacion from "layouts/escuela/pdf/reactPdf/acreditacion_acta";
import { pdf } from "@react-pdf/renderer";
import FechaDeEvaluaciones from "../compFechaEval";
import ModalFechEval from "components/ModalFechaEvaluacion/modalFecha";
import { convertirDivision } from "../reportes";
import { FiltersComponent } from "../Filters";

export function ordenarPorNombreCompleto(a, b) {
  return a.profesor_name.localeCompare(b.profesor_name);
}

export function ordenarPorIdentificador(a, b) {
  return a.grupo_ident.localeCompare(b.grupo_ident);
}

export default function CalificacionesTable({
  tableHeader,
  ciclo_id,
  cicle_name,
  activo,
  division,
  ciclo_url,
}) {
  const authUser = JSON.parse(sessionStorage.getItem("user"));
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const [values, setValues] = useState({
    profesor: "",
    grupo: "",
  });
  const [borrarActa, { isSuccess, isLoading, isError, error }] =
    useCleanAsignaturasPorProfesorMutation();
  const {
    open: openPDF,
    handleOpen: handleOpenPDF,
    handleClose: handleClosePDF,
  } = useModalHandler();

  //Props del modal de fecha evaluacion
  const {
    open: openFechaEval,
    handleOpen: handleOpenFechaEval,
    handleClose: handleCloseFechaEval,
  } = useModalHandler();
  const [profesor_distribucion, setProfDist] = useState({
    id: "",
    url: "",
    examen: "",
  });

  //TODO: agregar query a getAsignatudas por profesor query
  const [distProfbyId, { data: distProf }] =
    useLazyGetAsignaturasPorProfesorByIdQuery();

  const {
    busqueda,
    setBusqueda,
    offset,
    setOffset,
    limit,
    handleNext,
    handlePrevious,
  } = usePagination();

  const {
    data: data,
    isError: isErrorG,
    isLoading: isLoadingG,
    error: errorG,
    isFetching,
  } = useGetAsignaturasPorProfesorQuery(
    {
      filter_params: {
        estados_de_alumnos: "Activo",
        buscar: busqueda,
        ordering:
          "distribucion_grupo__ciclo_interno__numero_de_ciclo,distribucion_grupo__grupo__identificador,asignatura__nombre,profesor__user__apellido_paterno,profesor__user__apellido_materno,profesor__user__apellido_firstname",
        limit: limit,
        offset: offset,
        ciclos_internos: division.ciclos_internos?.join(","),
        distribucion_grupo__ciclo: ciclo_id,
        ...(values.grupo !== "" && { distribucion_grupo: values.grupo }),
        ...(authUser?.groups?.some((e) => e.name === PROFESOR) && {
          profesor: authUser?.perfil.resourcetype?.find(
            (e) => e.resourcetype === PROFESOR
          ).id,
        }),
        ...(values.profesor !== "" && {
          profesor: values.profesor,
        }),
        query:
          "{id,acta,url,distribucion_grupo{id,grupo{identificador},ciclo_interno{numero_de_ciclo,carrera{id,nombre,plan_de_estudio,modalidad}}},asignatura{nombre, clave},profesor{id,user{first_name, apellido_paterno, apellido_materno}}}",
      },
      shape: {
        id: "id",
        url: "url",
        semestre: "distribucion_grupo.ciclo_interno.numero_de_ciclo",
        asignatura_name: "asignatura.nombre",
        asignatura_clave: "asignatura.clave",
        grupo_ident: "distribucion_grupo.grupo.identificador",
        grupo_id: "distribucion_grupo.id",
        acta: "acta",
        profesor_id: "profesor.id",
        profesor_name:
          "profesor.user.apellido_paterno profesor.user.apellido_materno profesor.user.first_name",
        carrera_id: "distribucion_grupo.ciclo_interno.carrera.id",
        carrera_nombre: "distribucion_grupo.ciclo_interno.carrera.nombre",
        carrera_plan_de_estudio:
          "distribucion_grupo.ciclo_interno.carrera.plan_de_estudio",
        carrera_modalidad: "distribucion_grupo.ciclo_interno.carrera.modalidad",
      },
    },
    {
      refetchOnReconnect: true,
    }
  );

  const [getNotas] = useLazyNotasEstudiantesQuery();

  const { data: fechas } = useGetFechaEvaluacionQuery({
    filter_params: {
      examen__nombre: "Ordinario",
      query: "{id,inicio,fin,profesor{id},abierto,accesible}",
      division_numero: convertirDivision(division),
      profesor__distribucion_grupo__ciclo: ciclo_id,
    },
  });

  //constantes para manejar la paginacion
  const dataRest = data?.results
    ? [...(data.results ?? [])]
    : [...(data ?? [])];
  const countTotal = data?.count;
  const previous = data?.previous;
  const next = data?.next;
  const profesoresOrdenados = dataRest
    ?.map((e) => {
      const { profesor_id, profesor_name } = e;
      return {
        profesor_id,
        profesor_name,
      };
    })
    .sort((a, b) => ordenarPorNombreCompleto(a, b));

  const gruposOrdenados = dataRest
    ?.map((e) => {
      const { grupo_id, grupo_ident } = e;
      return {
        grupo_id,
        grupo_ident,
      };
    })
    .sort((a, b) => ordenarPorIdentificador(a, b));

  const dataTable = {
    columns: [
      {
        Header: "Semestre",
        accessor: "semestre",
        width: "10%",
      },
      {
        Header: "Grupo",
        accessor: "grupo_ident",
      },
      {
        Header: "Clave de Asignatura",
        accessor: "asignatura_clave",
      },
      {
        Header: "Asignatura",
        accessor: "asignatura_name",
      },
      {
        Header: "Profesor",
        accessor: "profesor_name",
      },
      {
        Header: "Acciones",
        accessor: "actions",
        width: "10%",
        Cell: ({ row }) => {
          const { original } = row;
          return (
            <MDBox display="flex">
              <MDBox px={0.2}>
                <Tooltip
                  placement="top"
                  title="Ver toda la información de la calificación en vista de detalles (Puede acceder a esta funcionalidad con doble click en cualquier parte de la fila)"
                >
                  <Icon
                    style={style_icon}
                    onClick={() => handleClickShow(original)}
                    fontSize="small"
                  >
                    find_in_page
                  </Icon>
                </Tooltip>
              </MDBox>
              <ComponentsPrivatization permitted={COORDINADOR_DOCENTE}>
                <MDBox px={0.2}>
                  <Tooltip
                    placement="top"
                    title="Ver acta de calificaciones del grupo sin firmar"
                  >
                    <Icon
                      style={style_icon}
                      onClick={() => handleClickActa(original)}
                      fontSize="small"
                      color="success"
                    >
                      picture_as_pdf
                    </Icon>
                  </Tooltip>
                </MDBox>
              </ComponentsPrivatization>
              {original.acta && (
                <>
                  <ComponentsPrivatization
                    permitted={[COORDINADOR_DOCENTE, PROFESOR]}
                  >
                    <MDBox px={0.2}>
                      <Tooltip
                        placement="top"
                        title="Ver acta de calificaciones del grupo firmada"
                      >
                        <Icon
                          style={style_icon}
                          onClick={() => handleClickNavigate(original.acta)}
                          fontSize="small"
                          color="error"
                        >
                          picture_as_pdf
                        </Icon>
                      </Tooltip>
                    </MDBox>
                    <MDBox px={0.2}>
                      <Tooltip placement="top" title="Ver acta de acreditación">
                        <Icon
                          style={style_icon}
                          fontSize="small"
                          onClick={() => printPDF(original, cicle_name)}
                        >
                          book
                        </Icon>
                      </Tooltip>
                    </MDBox>
                  </ComponentsPrivatization>
                  <ComponentsPrivatization permitted={[COORDINADOR_DOCENTE]}>
                    {activo && (
                      <MDBox display="flex">
                        {fechas?.length !== 0 && (
                          <MDBox px={0.2}>
                            <Tooltip
                              placement="top"
                              title="Cambiar la fecha de calificación de esta asignatura"
                            >
                              <Icon
                                style={style_icon}
                                onClick={() =>
                                  handlePermisoEvaluacion(original)
                                }
                                fontSize="small"
                              >
                                access_time
                              </Icon>
                            </Tooltip>
                          </MDBox>
                        )}
                        <MDBox px={0.2}>
                          <Tooltip
                            placement="top"
                            title="Eliminar acta firmada de la base de datos"
                          >
                            <Icon
                              style={style_icon}
                              onClick={() => handleClickEliminate(original)}
                              fontSize="small"
                            >
                              remove_circle
                            </Icon>
                          </Tooltip>
                        </MDBox>
                      </MDBox>
                    )}
                  </ComponentsPrivatization>
                </>
              )}
            </MDBox>
          );
        },
      },
    ],

    rows: dataRest ?? [],
  };

  useRedirectForm(
    isLoadingG,
    null,
    isErrorG,
    errorG,
    "Hubo problemas para cargar la información",
    `/ciclos/${cicle_name}/calificaciones`
  );

  useRedirectForm(
    isLoading,
    isSuccess,
    isError,
    error,
    "Acta eliminada",
    `/ciclos/${cicle_name}/calificaciones`
  );

  const handleClickShow = (el) => {
    navigate(
      `/ciclos/${cicle_name}/calificaciones/ordinaria/grupo/${el.grupo_ident}/profesor/${el.id}/semestre/${division.division_verbose}`
    );
  };

  const handleClickNavigate = (el) => {
    window.open(el, "_blank");
  };

  const handleClickEliminate = (el) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: `¡Si eliminas el acta, la acción no se podrá revertir!`,
      icon: "warning",
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Eliminar",
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          borrarActa({
            id: el.id,
            acta: null,
          });
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  const handlePermisoEvaluacion = (original) => {
    setProfDist({
      id: original.id,
      url: original.url,
      examen: "Ordinario",
    });
    handleOpenFechaEval();
  };

  const handleClickActa = (e) => {
    distProfbyId(e.id);
    handleOpenPDF();
  };

  const printPDF = async (original) => {
    await getNotas({
      id: original.id,
      filter_params: {
        query:
          "{estudiante{id,url,distribucion_grupo{grupo{identificador}}, estudiante{numero_de_control, user{first_name, apellido_paterno, apellido_materno}}}, notas{id,nota_final,aprobado,asistencias,porcentaje_de_asistencia,faltas,examen{nombre}}}",
      },
    })
      .unwrap()
      .then((res) => {
        let obj = pdf(
          <ActaAcreditacion
            info={original}
            cicle_name={cicle_name}
            notas={res}
          />
        ).toBlob();
        obj.then(function (blob) {
          let url = URL.createObjectURL(blob);
          window.open(url, "_blank");
        });
      });
  };

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <ModalFechEval
        open={openFechaEval}
        handleClose={handleCloseFechaEval}
        distribucion_profesor={profesor_distribucion}
      />

      {distProf && (
        <ModalNotasPdf
          open={openPDF}
          handleClose={handleClosePDF}
          profesor={distProf.id}
          semestre={paridad(division)}
          ordinaria={"1"}
          cicle_name={cicle_name}
        />
      )}
      <MDBox display={"flex"} flexDirection={"column"} m={2} gap={2} p={3}>
        <MDTypography variant="h5" fontWeight="medium" mb={1}>
          {tableHeader}:
        </MDTypography>
        {fechas && activo && (
          <FechaDeEvaluaciones
            fechas={fechas}
            ciclo_id={ciclo_id}
            examen={"Ordinario"}
            division={division}
            ciclo_url={ciclo_url}
          />
        )}
      </MDBox>

      <MDBox display="flex" justifyContent={"center"} alignItems={"center"}>
        {isLoadingG || isFetching ? (
          <CircularProgress sx={{ marginBottom: "3rem" }} color="inherit" />
        ) : (
          <DataTable
            table={dataTable}
            canSearch
            doubleClick={handleClickShow}
            paginationApi
            countTotal={countTotal}
            next={next}
            previous={previous}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            setBusqueda={setBusqueda}
            offset={offset}
            setOffset={setOffset}
            filtersComponent={
              <FiltersComponent
                values={values}
                handleChange={handleChange}
                profesores={profesoresOrdenados}
                grupos={gruposOrdenados}
              />
            }
          />
        )}
      </MDBox>
    </>
  );
}

CalificacionesTable.propTypes = {
  tableHeader: PropTypes.string.isRequired,
};
