/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React from "react";

// react-router-dom components
import {Link, useLocation, useNavigate} from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import fondo from "assets/images/fondos/fondoPizarra.jpg";

import {LOGIN, RESET_PASSWORD_CONFIRM} from "config/bases_url";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import {useResetPasswordMutation} from "services/principal/auth.service";
import Sinfondo from "assets/images/tecnoschool/Sinfondo.png";
import {useRedirectForm} from "../../../hooks/useRedirectForm";
import {useAuthRedirect} from "../../../hooks/useAuthRedirect.";

function useQuery() {
    const {search} = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function ResetPassword() {
    const navigate = useNavigate();
    const [resetPassword, {data, isLoading, isSuccess, error, isError}] =
        useResetPasswordMutation();

    useAuthRedirect()

    useRedirectForm(isLoading, isSuccess, isError, error, data?.detail, LOGIN);


    const handleSubmit = (values, actions) => {
        resetPassword({
            email: values.email,
            url: `${window.location.origin}${RESET_PASSWORD_CONFIRM}`,
        })
            .unwrap()
            .then(() => {
                actions.setSubmitting(false);
                actions.resetForm();
            });
    };

    return (
        <BasicLayout image={fondo}>
            <Card>
                <MDBox
                    component="img"
                    src={Sinfondo}
                    variant="gradient"
                    bgColor="dark"
                    coloredShadow="dark"
                    borderRadius="xxl"
                    mx={2}
                    mt={-10}
                    p={2}
                    mb={1}
                    sx={{boxShadow: "0rem 0rem 0.625rem 0.3rem rgb(0 0 0 / 40%)"}}
                />

                <MDBox pt={3} pb={3} px={3}>
                    <Formik
                        initialValues={{
                            email: "",
                        }}
                        validationSchema={Yup.object({
                            email: Yup.string()
                                .email("Debe proporcionar una dirección de correo válida")
                                .required("Debe proporcionar una dirección de correo"),
                        })}
                        onSubmit={handleSubmit}
                    >
                        {({
                              values,
                              touched,
                              errors,
                              isValid,
                              handleChange,
                              handleBlur,
                          }) => {
                            return (
                                <Form id={"reset_password"} autoComplete="off">
                                    <MDBox>
                                        <MDTypography
                                            variant="gradient"
                                            color="dark"
                                            fontWeight="bold"
                                            typography="button"
                                            textGradient
                                        >
                                            Ingresa tu cuenta de correo electrónico asociado.
                                        </MDTypography>
                                    </MDBox>
                                    <MDBox mt={2}>
                                        <MDInput
                                            type="email"
                                            label="Correo"
                                            name="email"
                                            value={values.email}
                                            error={errors.email && touched.email}
                                            success={values.email.length > 0 && !errors.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder="ejemplo@servidor.com"
                                            fullWidth
                                        />
                                    </MDBox>

                                    <MDBox mt={3}>
                                        <MDButton
                                            disabled={!isValid}
                                            variant="gradient"
                                            color="dark"
                                            type="submit"
                                            fullWidth
                                        >
                                            Recuperar Contraseña
                                        </MDButton>
                                    </MDBox>
                                    <MDBox mt={2} textAlign="center">
                                        <MDTypography variant="button" color="text">
                                            <MDTypography
                                                component={Link}
                                                to={LOGIN}
                                                variant="gradient"
                                                color="dark"
                                                fontWeight="medium"
                                                textGradient
                                            >
                                                Cancelar
                                            </MDTypography>
                                        </MDTypography>
                                    </MDBox>
                                </Form>
                            );
                        }}
                    </Formik>
                </MDBox>
            </Card>
        </BasicLayout>
    );
}

export default ResetPassword;
