import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React from "react";
import FormField from "components/FormField";
import PropTypes from "prop-types";

const FormModal = ({ formData }) => {
  const { values, errors, touched, formField, handleChange } = formData;
  const { fecha, motivo } = formField;
  const { fecha: fechaV, motivo: motivoV } = values;

  return (
    <>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Hacer Devolución:</MDTypography>
      </MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              key={"0"}
              label={"Fecha"}
              type={fecha.type}
              name={fecha.name}
              value={fechaV}
              error={errors.fecha && touched.fecha}
              success={fechaV.length > 0 && !errors.fecha}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              key={"1"}
              type={motivo.type}
              label={motivo.label}
              name={motivo.name}
              error={errors.motivo && touched.motivo}
              success={motivoV.length > 0 && !errors.motivo}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
};

FormModal.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default FormModal;
