import { asignaturasApi } from "../view/asignaturas.service";
import { cicloInternoApi } from "../../ciclo_interno_carrera.service";
import { enqueueSnackbar } from "notistack";
import { showErrorMessages } from "../../../../hooks/useRedirectForm";

export const deleteAsignatura = asignaturasApi.injectEndpoints({
  endpoints: (builder) => ({
    deleteAsignatura: builder.mutation({
      query: ({ id }) => ({
        url: `/v1/api/asignaturas/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Asignaturas", id: "LIST" }],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          if (arg.ciid) {
            dispatch(
              cicloInternoApi.util.invalidateTags([
                { type: "CicloInterno", id: arg.ciId },
              ]),
            );
            enqueueSnackbar("Se elimino correctamente la asignatura", {
              variant: "success",
            });
          }
        } catch (error) {
          showErrorMessages(error.error, enqueueSnackbar);
        }
      },
    }),
  }),
});

export const { useDeleteAsignaturaMutation } = deleteAsignatura;
