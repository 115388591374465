export const ACTIVOS_REGEX = /^[\wñÑ\.\s-_\/]*$/;
export const NAME_REGEX = /^[A-Za-záéíóúÁÉÍÓÚñÑ\s]*$/;
export const NAME_CATALOGO_REGEX = /^[-A-Za-záéíóúñÑÁÉÍÓÚ/_\.\s\d]*$/;
export const NAME_AREA = /^[A-Za-záéíóúÁÉÍÓÚñÑ0-9\s\.-_\/]*$/;
export const NAME_ENTIDAD = /^[A-Za-záéíóúÁÉÍÓÚñÑ0-9\s\.-_\/,\'\"]*$/;
export const CURP_REGEX =
  /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
export const RFC_REGEX =
  /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
export const PHONE_REGEX =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const EMAIL_REGEX =
  /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;
