import { QrReader } from "react-qr-reader";
import PropTypes from "prop-types";
import { useLazyGetActivoByIdQuery } from "services";
import { useState } from "react";
import ModalActivoShow from "components/ModalShow/modal_activo.show";
import { useRedirectForm } from "hooks/useRedirectForm";

const QrScan = () => {
  const [getActivoById, { isLoading, isError, error, data }] =
    useLazyGetActivoByIdQuery();
  const [openShow, setOpenShow] = useState(false);
  const handleOpenShow = () => setOpenShow(true);
  const handleCloseShow = () => setOpenShow(false);

  const previewStyle = {
    height: 240,
    width: 320,
  };

  useRedirectForm(
    isLoading,
    null,
    isError,
    error,
    "Hubo problemas para cargar la información",
    ACTIVOS_CONSUMIBLES_RESGUARDOS_LIST_URL
  );

  return (
    <>
      <QrReader
        resolution={800}
        style={previewStyle}
        constraints={{ facingMode: "environment" }}
        onResult={(result) => {
          if (result)
            getActivoById(result.text)
              .unwrap()
              .then(() => {
                //Abre el modal de el ver detalles del activo
                handleOpenShow();
              })
              .catch((error) => console.error(error));
        }}
      />
      {data && (
        <ModalActivoShow
          open={openShow}
          handleClose={handleCloseShow}
          activo={data}
        />
      )}
    </>
  );
};

QrScan.propTypes = {
  openShow: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default QrScan;
