import { createApi } from "@reduxjs/toolkit/query/react";
import {
  reshape_response,
  buildFiltersUrl,
  appendFormData,
} from "helpers/functions";
import customFetchBase from "services/principal/customFetchBase";

export const entidadAPI = createApi({
  reducerPath: "entidadAPI",
  baseQuery: customFetchBase,
  tagTypes: ["Entidad"],
  endpoints: (builder) => ({
    getEntidades: builder.query({
      query: ({ filter_params }) => ({
        url: buildFiltersUrl("/v1/api/entidad/", filter_params),
        method: "GET",
      }),
      providesTags: (result) =>
        Array.isArray(result)
          ? [
              ...result.map(({ id }) => ({
                type: "Entidad",
                id,
              })),
              { type: "Entidad", id: "LIST" },
            ]
          : [{ type: "Entidad", id: "LIST" }],
      transformResponse: (response, meta, args) => {
        if (Array.isArray(response)) {
          return response?.map((el) => reshape_response(el, args?.shape));
        } else {
          const resultsTransformed = response?.results?.map((el) =>
            reshape_response(el, args?.shape)
          );

          // Devolvemos los resultados transformados junto con las propiedades de paginación
          return {
            results: resultsTransformed,
            count: response.count,
            next: response.next,
            previous: response.previous,
          };
        }
      },
    }),

    getEntidadById: builder.query({
      query: (id) => ({
        url: `/v1/api/entidad/${id}/`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "Entidad", id }],
    }),

    createEntidad: builder.mutation({
      query: (data) => {
        const formData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
          appendFormData(formData, value, key);
        });
        return {
          url: "/v1/api/entidad/",
          method: "POST",
          body: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
      },
      invalidatesTags: [{ type: "Entidad", id: "LIST" }],
    }),

    deleteEntidad: builder.mutation({
      query: (id) => ({
        url: `/v1/api/entidad/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Entidad", id: "LIST" }],
    }),

    editEntidad: builder.mutation({
      query: (data) => {
        const formData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
          appendFormData(formData, value, key);
        });
        return {
          url: `/v1/api/entidad/${data.id}/`,
          method: "PATCH",
          body: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
      },
      invalidatesTags: (result, error, { id }) =>
        result
          ? [
              { type: "Entidad", id },
              { type: "Entidad", id: "LIST" },
            ]
          : [{ type: "Entidad", id: "LIST" }],
    }),
  }),
});

export const {
  useGetEntidadesQuery,
  useCreateEntidadMutation,
  useEditEntidadMutation,
  useDeleteEntidadMutation,
  useLazyGetEntidadByIdQuery,
} = entidadAPI;
