import MDBox from "components/MDBox";
import ResguardoPdf from "../resguardo";
import PrintRoot, { NoPrint } from "./print";
import PrintButton from "./PrintButton";

function ResguardoReceipt({ lote }) {
  return (
    <div
      style={{
        width: "210mm",
        margin: "auto",
        backgroundColor: "white",
        marginRight: "10px",
      }}
    >
      <PrintRoot>
        <NoPrint>
          <MDBox
            sx={{
              display: "grid",
              justifyItems: "flex-end",
              marginRight: "10px",
            }}
          >
            <PrintButton />
          </MDBox>
        </NoPrint>
        <ResguardoPdf lote={lote} />
      </PrintRoot>
    </div>
  );
}

export default ResguardoReceipt;
