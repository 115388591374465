/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { NAME_CATALOGO_REGEX } from "helpers/regexs";
import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { nombre },
} = checkout;

const validations = [
  Yup.object().shape({
    [nombre.name]: Yup.string()
      .min(5, "Debe tener 5 o más caracteres")
      .max(50, "No se permiten más de 50 caracteres")
      .required(nombre.errorMsg)
      .matches(
        NAME_CATALOGO_REGEX,
        "El nombre no puede contener caracteres extraños"
      ),
  }),
];

export default validations;
