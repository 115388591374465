/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import * as Yup from "yup";
import checkout from "./form";
import {CURP_REGEX, NAME_REGEX} from "helpers/regexs";

const {
    formField: {
        nombre,
        apellido_paterno,
        apellido_materno,
        numero_de_control,
        telefonos,
        genero,
        tipo_sangre,
        curp,
        preasignacion_de_carrera,
    },
} = checkout;

const validations = [
    Yup.object().shape({
        [nombre.name]: Yup.string()
            .required(nombre.errorMsg)
            .matches(NAME_REGEX, "El nombre no puede contener caracteres extraños"),
        [apellido_paterno.name]: Yup.string()
            .required(apellido_paterno.errorMsg)
            .matches(NAME_REGEX, "El apellido no puede contener caracteres extraños"),
        [apellido_materno.name]: Yup.string()
            .required(apellido_materno.errorMsg)
            .matches(NAME_REGEX, "El apellido no puede contener caracteres extraños"),
        [numero_de_control.name]: Yup.string().required(numero_de_control.errorMsg),
        [preasignacion_de_carrera.name]: Yup.string().required(
            preasignacion_de_carrera.errorMsg
        ),
        [curp.name]: Yup.string()
            .matches(CURP_REGEX, curp.invalidMsg)
            .required(curp.errorMsg),
    }),
    Yup.object().shape({
        [genero.name]: Yup.string().required(genero.errorMsg),
        [tipo_sangre.name]: Yup.string(),
    }),
    Yup.object().shape({
        [telefonos.name]: Yup.array(),
    }),
];

export default validations;
