import {createSlice} from "@reduxjs/toolkit";

import {isRefreshNeeded} from "config/axios";
import {authApi} from "../../services";

const initialState = {
    user_info: JSON?.parse(sessionStorage.getItem("user")),
    authenticated: isRefreshNeeded(sessionStorage?.getItem("token")).valid
        ? true
        : false,
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    extraReducers: (builder) => {
        builder.addMatcher(authApi.endpoints.login.matchFulfilled, (state, action) => {
            state.authenticated = true;
            state.user_info = action.payload.user;
        })
        builder.addMatcher(authApi.endpoints.logoutUser.matchFulfilled, (state, action) => {
            state.authenticated = false;
            state.user_info = null;
        })
    }
});

export const auth_state = (state) => state.auth;

export default authSlice.reducer;
