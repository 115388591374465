/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// NewUser page components
import FormField from "components/FormField";
import {Autocomplete} from "@mui/material";
import {useGetCarrerasPorGrupoQuery} from "services";

function Promover({ formData }) {
  const { formField, values, setFieldValue, errors, touched } = formData;
  const { grupo_id_clonar, identificador } = formField;
  const {
    grupo_id_clonar: grupo_id_clonarV,
    ciclo_id: ciclo_idV,
    identificador: identificadorV,
  } = values;

  const { data: gruposEscolares_data } = useGetCarrerasPorGrupoQuery(
    {
      filter_params: {
        ciclo: ciclo_idV,
        query:
          "{id,grupo{identificador},ciclo_interno{numero_de_ciclo,carrera{nombre,duracion,plan_de_estudio}}}",
      },
      shape: {
        id: "id",
        identificador: "grupo.identificador",
        carrera: "ciclo_interno.carrera.nombre",
        semestre: "ciclo_interno.numero_de_ciclo",
        duracion: "ciclo_interno.carrera.duracion.identificador",
        plan_de_estudio: "ciclo_interno.carrera.plan_de_estudio",
      },
    },
    {
      refetchOnReconnect: true,
    }
  );

  return (
    <MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              type={identificador.type}
              name={identificador.name}
              label={identificador.label}
              value={identificadorV}
              success={identificadorV.length > 0 && !errors.identificador}
              error={errors.identificador && touched.identificador}
              onInput={(e) =>
                (e.target.value = ("" + e.target.value).toUpperCase())
              }
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Autocomplete
              id="distribuir-CicloInterno-Anterior"
              options={gruposEscolares_data ?? []}
              onChange={(e, value) => {
                setFieldValue(grupo_id_clonar.name, value.id);
              }}
              value={
                gruposEscolares_data?.find(
                  (opcion) => opcion.id === grupo_id_clonarV
                ) || null
              }
              isOptionEqualToValue={(option, value) => {
                return `${option.id}` === `${value.id}`;
              }}
              getOptionLabel={(option) =>
                `${option.carrera} ${option.plan_de_estudio}- ${option.duracion} - ${option.semestre} - ${option.identificador}`
              }
              renderInput={(params) => (
                <FormField
                  id="distribucionCIAnterior"
                  name={"distribucionCIAnterior"}
                  label="Grupo a clonar"
                  {...params}
                />
              )}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for UserInfo
Promover.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Promover;
