/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import * as Yup from "yup";
import checkout from "./form";
import { NAME_CATALOGO_REGEX } from "helpers/regexs";

const {
  formField: { identificador, ciclo_interno },
} = checkout;

const validations = [
  Yup.object().shape({
    [identificador.name]: Yup.string()
      .min(1, "Debe tener 1 o más caracteres")
      .max(50, "No se permiten más de 50 caracteres")
      .matches(
        NAME_CATALOGO_REGEX,
        "El nombre no puede contener caracteres extraños, excepto el punto"
      )
      .required(identificador.errorMsg),
    [ciclo_interno.name]: Yup.string().required(ciclo_interno.errorMsg),
  }),
];

export default validations;
