import {userApi} from "../../view/users.service";
import {reshape_response} from "../../../../../helpers/functions";

export const getUserById = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserById: builder.query({
      query: ({ id }) => ({
        url: `/v3/api/registro/${id}/`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "Users", id }],
      transformResponse: (response, meta, args) => {
        return reshape_response(response, args?.shape);
      },
    }),
  }),
})

export const {
  useGetUserByIdQuery,
  useLazyGetUserByIdQuery,
} = getUserById;