import {cursosApi} from "../view/cursos.service";
import {buildFiltersUrl, reshape_response} from "../../../../helpers/functions";

export const getCursos = cursosApi.injectEndpoints({
    endpoints: (builder) => ({
        getCursos: builder.query({
            query: ({filter_params}) => ({
                url: buildFiltersUrl("/v1/api/curso/", filter_params),
                method: "GET",
            }),
            providesTags: (result) =>
                Array.isArray(result)
                    ? [
                        ...result.map(({id}) => ({
                            type: "Cursos",
                            id,
                        })),
                        {type: "Cursos", id: "LIST"},
                    ]
                    : [{type: "Cursos", id: "LIST"}],
            transformResponse: (response, meta, args) => {
                if (Array.isArray(response)) {
                    return response?.map((el) => reshape_response(el, args.shape));
                } else {
                    const resultsTransformed = response?.results?.map((el) =>
                        reshape_response(el, args.shape)
                    );
                    return {
                        results: resultsTransformed,
                        count: response.count,
                        next: response.next,
                        previous: response.previous,
                    };
                }
            },
        }),
    }),
})

export const {
    useGetCursosQuery,
} = getCursos;