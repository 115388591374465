/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import MDButton from "../../../../components/MDButton";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import FormField from "../../../../components/FormField";
import Swal from "sweetalert2";
import {useChangePasswordMutation} from "../../../../services/principal/auth.service";
import {useEffect} from "react";

function ChangePassword() {
  const [changePassword, { isLoading, isError, error, isSuccess }] =
    useChangePasswordMutation();

  useEffect(() => {
    if (isSuccess) {
      Swal.fire(
        "¡Hecho!",
        "¡Se actualizó la contraseña correctamente!",
        "success"
      );
    }

    if (isError) {
      if (Array.isArray(error?.data?.error)) {
        error?.data?.new_password2.forEach((el) =>
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${el}`,
          })
        );
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error?.data?.new_password2,
        });
      }
    }
  }, [isLoading]);

  const handleSubmit = async (values, actions) => {
    await changePassword({
      old_password: values.old_password,
      new_password1: values.new_pass,
      new_password2: values.re_pass,
    });

    actions.setSubmitting(false);
    actions.resetForm();
  };

  return (
    <Card id="change-password">
      <MDBox p={3}>
        <MDTypography variant="h5">Cambiar Contraseña</MDTypography>
      </MDBox>
      <Formik
        initialValues={{ old_password: "", new_pass: "", re_pass: "" }}
        validationSchema={Yup.object({
          old_password: Yup.string().required(
            "Debe proveer la contraseña anterior"
          ),
          new_pass: Yup.string().required("Debe proveer una nueva contraseña"),
          re_pass: Yup.string()
            .oneOf(
              [Yup.ref("new_pass"), null],
              "Las contraseñas deben coincidir"
            )
            .required("Debe repetir la contraseña"),
        })}
        onSubmit={handleSubmit}
      >
        {({ values, touched, errors, handleChange, handleBlur, isValid }) => {
          return (
            <Form id={"change_password"} autoComplete="off">
              <MDBox pb={3} px={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormField
                      variant={"outlined"}
                      label="Contraseña anterior"
                      name="old_password"
                      value={values.old_password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.old_password && touched.old_password}
                      success={
                        values.old_password.length > 0 && !errors.old_password
                      }
                      type="password"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormField
                      variant={"outlined"}
                      label="Nueva contraseña"
                      name="new_pass"
                      value={values.new_pass}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.new_pass && touched.new_pass}
                      success={values.new_pass.length > 0 && !errors.new_pass}
                      type="password"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormField
                      variant={"outlined"}
                      label="Confirmar contraseña"
                      name="re_pass"
                      value={values.re_pass}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.re_pass && touched.re_pass}
                      success={values.re_pass.length > 0 && !errors.re_pass}
                      type="password"
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  mt={3}
                  display="flex"
                  flexDirection="row-reverse"
                >
                  <MDButton
                    disabled={
                      !isValid ||
                      (values.new_pass.length === 0 &&
                        values.re_pass.length === 0 &&
                        values.old_password.length === 0)
                    }
                    type="submit"
                    variant="gradient"
                    color="dark"
                  >
                    cambiar
                  </MDButton>
                </Grid>
              </MDBox>
            </Form>
          );
        }}
      </Formik>
    </Card>
  );
}

export default ChangePassword;
