/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useEffect, useState } from "react";

// react-router-dom components
import { NavLink, useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import SidenavList from "examples/Sidenav/SidenavList";
import SidenavItem from "examples/Sidenav/SidenavItem";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";

import SinTexto from "assets/images/tecnoschool/SinTexto.png";

// Material Dashboard 2 PRO React context
import {
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  useMaterialUIController,
} from "context";
import { HOME_URL } from "config/bases_url";
import { checkPerm } from "helpers/functions";

function Sidenav({ color, brandName, routes, ...rest }) {
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
  const [openNestedCollapseLevel2, setOpenNestedCollapseLevel2] =
    useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } =
    controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];
  const items = pathname.split("/").slice(1);
  const itemParentName = items[1];
  const itemParentNameLevel2 = items[1];
  const itemName = items[items.length - 1];

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    setOpenCollapse(collapseName);
    setOpenNestedCollapse(itemParentName);
    setOpenNestedCollapse(itemParentNameLevel2);
  }, []);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(
        dispatch,
        window.innerWidth < 1200 ? false : transparentSidenav
      );
      setWhiteSidenav(
        dispatch,
        window.innerWidth < 1200 ? false : whiteSidenav
      );
    }

    /**
         The event listener that's calling the handleMiniSidenav function when resizing the window.
         */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  const renderNestedCollapseLevel2 = (collapse) => {
    const template = collapse.map(
      ({ name, route, key, href, roles, icon, type }) =>
        href && checkPerm(roles) ? (
          <Link
            key={key}
            href={href}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavItem name={name} nested />
          </Link>
        ) : checkPerm(roles) ? (
          <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
            <SidenavItem
              type={type}
              name={name}
              active={route === pathname}
              nested
              icon={icon}
              noPadding
            />
          </NavLink>
        ) : null
    );

    return template;
  };

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse) =>
    collapse.map(({ name, route, key, href, roles, collapse, icon, type }) => {
      let returnValue;
      if (collapse && checkPerm(roles)) {
        returnValue = (
          <SidenavCollapse
            key={key}
            color={color}
            name={name}
            icon={icon}
            active={key === itemParentNameLevel2}
            open={openNestedCollapseLevel2 === key}
            onClick={({ currentTarget }) =>
              openNestedCollapseLevel2 === key &&
              currentTarget.classList.contains("MuiBox-root")
                ? setOpenNestedCollapseLevel2(false)
                : setOpenNestedCollapseLevel2(key)
            }
            noMargin
          >
            {renderNestedCollapseLevel2(collapse)}
          </SidenavCollapse>
        );
      } else if (href && checkPerm(roles)) {
        returnValue = (
          <Link
            key={key}
            href={href}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavItem name={name} nested />
          </Link>
        );
      } else if (checkPerm(roles)) {
        returnValue = (
          <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
            <SidenavItem
              type={type}
              name={name}
              active={route === pathname}
              nested
              icon={icon}
              noPadding
            />
          </NavLink>
        );
      } else return;

      return returnValue;
    });

  // Render the all the collpases from the routes.js
  const renderCollapse = (collapses) =>
    collapses.map(
      ({ name, collapse, route, href, key, roles, for_all, icon, type }) => {
        let returnValue;

        if ((collapse && checkPerm(roles)) || (collapse && for_all)) {
          returnValue = (
            <SidenavCollapse
              key={key}
              color={color}
              name={name}
              icon={icon}
              active={key === itemParentName}
              open={openNestedCollapse === key}
              onClick={({ currentTarget }) =>
                openNestedCollapse === key &&
                currentTarget.classList.contains("MuiBox-root")
                  ? setOpenNestedCollapse(false)
                  : setOpenNestedCollapse(key)
              }
              noMargin
            >
              {renderNestedCollapse(collapse)}
            </SidenavCollapse>
          );
        } else if (checkPerm(roles)) {
          returnValue = href ? (
            <Link
              href={href}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <SidenavItem
                color={color}
                name={name}
                icon={icon}
                active={key === itemName}
              />
            </Link>
          ) : (
            <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
              <SidenavItem
                type={type}
                color={color}
                name={name}
                active={key === itemName}
                icon={icon}
                noPadding
              />
            </NavLink>
          );
        } else return;
        return <SidenavList key={key}>{returnValue}</SidenavList>;
      }
    );

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    ({
      type,
      name,
      icon,
      title,
      collapse,
      noCollapse,
      key,
      href,
      route,
      roles,
      for_all,
    }) => {
      let returnValue;

      if (type === "item" && checkPerm(roles)) {
        returnValue = (
          <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
            <SidenavItem
              type={type}
              icon={icon}
              color={color}
              name={name}
              active={key === itemName}
            />
          </NavLink>
        );
      } else if (type === "collapse") {
        if (href && for_all) {
          returnValue = (
            <Link
              href={href}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <SidenavCollapse
                name={name}
                icon={icon}
                active={key === collapseName}
                noCollapse={noCollapse}
              />
            </Link>
          );
        } else if (
          (noCollapse && route && checkPerm(roles)) ||
          (noCollapse && for_all)
        ) {
          returnValue = (
            <NavLink to={route} key={key}>
              <SidenavCollapse
                name={name}
                icon={icon}
                noCollapse={noCollapse}
                active={key === collapseName}
              >
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
            </NavLink>
          );
        } else if (checkPerm(roles) || for_all) {
          returnValue = (
            <SidenavCollapse
              key={key}
              name={name}
              icon={icon}
              active={key === collapseName}
              open={openCollapse === key}
              onClick={() =>
                openCollapse === key
                  ? setOpenCollapse(false)
                  : setOpenCollapse(key)
              }
            >
              {collapse ? renderCollapse(collapse) : null}
            </SidenavCollapse>
          );
        } else {
          returnValue = null;
        }
      } else if (type === "title") {
        if (checkPerm(roles)) {
          returnValue = (
            <MDTypography
              key={key}
              color={textColor}
              display="block"
              variant="caption"
              fontWeight="bold"
              textTransform="uppercase"
              pl={2.75}
              mt={2}
              mb={1}
            >
              {title}
            </MDTypography>
          );
        } else returnValue = null;
      } else if (type === "divider" && checkPerm(roles)) {
        returnValue = (
          <Divider
            key={key}
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
            variant="middle"
            sx={{ marginY: 0 }}
          />
        );
      }

      return returnValue;
    }
  );

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox
          component={NavLink}
          to={HOME_URL}
          display="flex"
          alignItems="center"
        >
          <MDBox
            component="img"
            src={SinTexto}
            alt="Brand"
            width="3rem"
            ml={-1}
          />

          <MDBox
            width={"100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <MDTypography
              component="h4"
              variant="button"
              fontWeight="medium"
              color={textColor}
              fontSize="18px"
              sx={{ whiteSpace: "normal" }}
            >
              {brandName}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List>{renderRoutes}</List>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  brandName: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
};

export default Sidenav;
