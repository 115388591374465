// @mui material components
import React from "react";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

//React Hooks and Others
import { Card, Grid } from "@mui/material";
import no_foto from "assets/images/not_found_image.jpg";
import MDInput from "components/MDInput";

export default function Generales() {
  const authUser = JSON.parse(sessionStorage.getItem("user"));

  return (
    <MDBox pt={6} pb={3}>
      <Card>
        <MDBox
          mt={-4}
          borderRadius="xxl"
          variant="gradient"
          sx={{ boxShadow: "0rem 0rem 0.625rem 0.3rem rgb(0 0 0 / 40%)" }}
          width={"95%"}
          ml={"2.5%"}
          display="flex"
          px={2}
          flexDirection={{ xs: "column", sm: "row" }}
          alignItems={"center"}
        >
          <MDBox
            component="img"
            src={authUser.foto !== null ? authUser.foto : no_foto}
            borderRadius="section"
            mx={2}
            my={2}
            maxWidth={"160px"}
          />
          <MDBox
            display="flex"
            alignItems="flex-start"
            flexDirection={"column"}
          >
            <MDTypography variant="h5" fontWeight="regular">
              {`${authUser.apellido_paterno} ${authUser.apellido_materno} ${authUser.first_name}`}
            </MDTypography>
            <Grid item xs={12} mb={1} display={"flex"}>
              <MDTypography
                variant="h6"
                fontWeight="bold"
                verticalAlign="text-top"
              >
                Carrera:
              </MDTypography>
              <MDTypography variant="h6" fontWeight="regular" ml={1}>
                {`${authUser.perfil.preasignacion_de_carrera.nombre}`}
              </MDTypography>
            </Grid>
          </MDBox>
        </MDBox>

        <MDBox
          display="flex"
          justifyContent={"center"}
          alignItems={"flex-start"}
          m={2}
          p={3}
        >
          {authUser && (
            <MDBox>
              <Grid container spacing={2}>
                <Grid item xs={12} mb={1} sm={6}>
                  <MDInput
                    variant="outlined"
                    id="curp-read-only"
                    label="CURP:"
                    defaultValue={`${authUser.perfil.curp}`}
                    InputProps={{
                      readOnly: true,
                    }}
                    style={{ width: "100%" }}
                  />
                </Grid>

                <Grid item xs={12} mb={1} sm={6}>
                  <MDInput
                    variant="outlined"
                    id="nacimiento-read-only"
                    label="Fecha de Nacimiento:"
                    placeholder={"Ej: 1/1/2000"}
                    InputProps={{
                      readOnly: true,
                    }}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12} mb={1} sm={4}>
                  <MDInput
                    variant="outlined"
                    id="sexo-read-only"
                    label="Sexo:"
                    defaultValue={`${authUser.perfil.genero.genero}`}
                    placeholder={"Ej: Mujer"}
                    InputProps={{
                      readOnly: true,
                    }}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12} mb={1} sm={4}>
                  <MDInput
                    variant="outlined"
                    id="sangre-read-only"
                    label="Tipo de Sangre:"
                    defaultValue={
                      authUser.perfil.tipo_sangre !== ("" || null)
                        ? `${authUser.perfil.tipo_sangre}`
                        : ""
                    }
                    placeholder={"Ej: O+"}
                    InputProps={{
                      readOnly: true,
                    }}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12} mb={1} sm={4}>
                  <MDInput
                    variant="outlined"
                    id="movil-read-only"
                    label="T Móvil:"
                    defaultValue={
                      authUser.perfil.telefonos.find(
                        (e) => e.tipo === "Móvil"
                      ) !== undefined
                        ? `${
                            authUser.perfil.telefonos.find(
                              (e) => e.tipo === "Móvil"
                            ).telefono
                          }`
                        : ""
                    }
                    placeholder="Ej: 12345-67890"
                    InputProps={{
                      readOnly: true,
                    }}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12} mb={1} sm={4}>
                  <MDInput
                    variant="outlined"
                    id="matricula-read-only"
                    label="Matrícula:"
                    defaultValue={`${authUser.perfil.numero_de_control}`}
                    InputProps={{
                      readOnly: true,
                    }}
                    style={{ width: "100%" }}
                  />
                </Grid>

                <Grid item xs={12} mb={1} sm={4}>
                  <MDInput
                    variant="outlined"
                    id="ciclo-read-only"
                    label="Ciclo:"
                    placeholder="Ej: 2022-2023 NON"
                    defaultValue={
                      authUser.perfil.recorrido[
                        authUser.perfil.recorrido.length - 1
                      ]?.nombre ?? "NO POSEE"
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12} mb={1} sm={4}>
                  <MDInput
                    variant="outlined"
                    id="grupo-read-only"
                    label="Grupo:"
                    placeholder="Ej: Grupo A"
                    defaultValue={
                      authUser.perfil.grupo_actual?.identificador ?? "NO POSEE"
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12} mb={1} sm={6}>
                  <MDInput
                    variant="outlined"
                    id="correo-read-only"
                    label="Correo Institucional:"
                    defaultValue={authUser.email ?? "NO POSEE"}
                    placeholder="Ej: ejemplo@ejemplo.com"
                    InputProps={{
                      readOnly: true,
                    }}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12} mb={1} sm={6}>
                  <MDInput
                    variant="outlined"
                    id="correo-personal-read-only"
                    label="Correo Personal:"
                    defaultValue={authUser.perfil.correo_personal ?? "NO POSEE"}
                    placeholder="Ej: ejemplo@ejemplo.com"
                    InputProps={{
                      readOnly: true,
                    }}
                    style={{ width: "100%" }}
                  />
                </Grid>

                <Grid item xs={12} mb={1} sm={6}>
                  <MDInput
                    variant="outlined"
                    id="user-wifi-read-only"
                    label="Usuario Wifi:"
                    defaultValue={authUser.perfil.usuario_wifi}
                    placeholder="Ej: usuario"
                    InputProps={{
                      readOnly: true,
                    }}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12} mb={1} sm={6}>
                  <MDInput
                    variant="outlined"
                    id="password-wifi-read-only"
                    label="Contraseña Wifi:"
                    defaultValue={authUser.perfil.password_wifi}
                    placeholder="Ej: contraseña"
                    InputProps={{
                      readOnly: true,
                    }}
                    style={{ width: "100%" }}
                  />
                </Grid>
              </Grid>
            </MDBox>
          )}
        </MDBox>
      </Card>
    </MDBox>
  );
}
