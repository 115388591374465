import _ from "lodash";

export const toIsoDate = (isoDate, onlyYear = false) => {
  if (typeof isoDate !== "string" || !isoDate.includes("-")) {
    return "2000";
  }

  const parts = isoDate.split("-");
  if (parts.length !== 3) {
    return "2000";
  }

  const year = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // Los meses en JavaScript van de 0 (enero) a 11 (diciembre)
  const day = parseInt(parts[2], 10);

  const date = new Date(year, month, day);

  if (!onlyYear)
    return date.toLocaleDateString("es-ES", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  else
    return date.toLocaleDateString("es-ES", {
      year: "numeric",
    });
};

export function buildFiltersUrl(base, parametros) {
  let searchParams = new URLSearchParams();
  for (let param in parametros) {
    if (parametros[param]) searchParams.append(param, parametros[param]);
  }
  if (parametros) return base + "?" + searchParams.toString();
  else return base;
}

export const formatoE164Telefono = (e) => {
  // Reemplazar todo lo que no sean números
  let formattedValue = e.target.value.replace(/[^0-9]/g, "");

  // Añadir el símbolo "+" al principio
  formattedValue = `+${formattedValue}`;

  // Si el código de país es "1", asegurarse de que haya 11 dígitos en total, eliminando el "1" adicional si es necesario
  if (formattedValue.startsWith("+1") && formattedValue.length > 11) {
    formattedValue = formattedValue.slice(0, 11) + formattedValue.slice(12);
  }

  // Agregar los espacios necesarios en el número de teléfono según las convenciones del formato E.164
  formattedValue = formattedValue.replace(
    /^(\+\d{1,3})(\d{3})(\d{3})(\d{4})$/,
    "$1 $2 $3 $4",
  );

  // Asignar el valor formateado al input
  e.target.value = formattedValue;
};

export function destructurarTelefonos(telefonos) {
  return telefonos.map((telefono) =>
    Object.fromEntries(
      Object.entries(telefono).filter(([key]) =>
        telefono.isCreated
          ? ["tipo", "telefono"].includes(key)
          : ["id", "tipo", "telefono"].includes(key),
      ),
    ),
  );
}

export function checkPerm(permitted) {
  const user = JSON.parse(sessionStorage.getItem("user"));
  if (Array.isArray(permitted)) {
    return user?.groups.some((role) => permitted.includes(role.name));
  } else if (typeof permitted === "string") {
    return user?.groups.some((role) => role.name === permitted);
  } else {
    return user?.is_staff;
  }
}

// Tranformar la respuesta final a una forma especifica pasando un objeto shape con las configuraciones pertinentes
export function reshape_response(response, shape) {
  function reshape(response, shape) {
    return Object.keys(shape).reduce((reshapedData, key) => {
      const transformer = shape[key];
      reshapedData[key] = resolvePath(response, transformer);
      return reshapedData;
    }, {});
  }

  function resolvePath(response, path) {
    if (typeof path === "function") {
      return path(response);
    } else if (path.includes(" ")) {
      const parts = path.split(" ");
      return parts.map((part) => resolveSinglePath(response, part)).join(" ");
    } else {
      return resolveSinglePath(response, path);
    }
  }

  function resolveSinglePath(response, path) {
    const arrayRegex = /\[\d*\]/;
    const pathSegments = path.split(".");

    let currentObject = response;
    for (let i = 0; i < pathSegments.length; i++) {
      const segment = pathSegments[i];

      if (segment.match(arrayRegex)) {
        const [base, index] = segment.split(/[\[\]]/).filter(Boolean);

        if (base) {
          currentObject = currentObject ? currentObject[base] : undefined;
        }

        if (currentObject === undefined) {
          return null;
        }

        if (index !== undefined && index !== "") {
          currentObject = currentObject[Number(index)];
          if (currentObject === undefined) {
            return null;
          }
        } else {
          currentObject = Array.isArray(currentObject)
            ? currentObject.map((item) =>
                resolveSinglePath(item, pathSegments.slice(i + 1).join(".")),
              )
            : [];
          break;
        }
      } else {
        currentObject = currentObject ? currentObject[segment] : undefined;
        if (currentObject === undefined) {
          return null;
        }
      }
    }

    return currentObject;
  }

  return reshape(response, shape);
}

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(",")[1]); // Esto retorna solo la parte de Base64, excluyendo el prefijo "data:image/..."
    reader.onerror = (error) => reject(error);
  });
}

export function reemplazarUrl(url, numero) {
  return url.replace(/v[1-9]/, `v${numero}`);
}

export function eliminarFrase(arregloFrases, cadena) {
  const cadenaMinusculas = cadena.toLowerCase();
  for (let frases of arregloFrases) {
    if (cadenaMinusculas.includes(frases)) {
      return cadena.replace(new RegExp(frases, "gi"), "").trim();
    }
  }
  return cadena.toUpperCase();
}

export function formatoAEquivalenteHoras(formato) {
  if (typeof formato !== "string" || !formato) {
    return null;
  }

  let dias = 0;
  let horas = 0;
  const partes = formato.split(" ");

  if (partes.length === 1) {
    const horasMinSec = partes[0].split(":");
    horas = parseInt(horasMinSec[0]);
    if (isNaN(horas)) {
      return null;
    }
  } else if (partes.length === 2) {
    dias = parseInt(partes[0]);
    if (isNaN(dias)) {
      return null;
    }
    const horasMinSec = partes[1].split(":");
    horas = parseInt(horasMinSec[0]);
    if (isNaN(horas)) {
      return null;
    }
  } else {
    console.warn("Formato inesperado:", formato);
    return null;
  }

  // Convertir todo a horas y sumarlo
  const totalHoras = dias * 24 + horas;

  return totalHoras;
}

export function generarURL(id, curso) {
  // Determina la URL base dependiendo del entorno
  const urlBase = window.location.origin.includes(
    !process.env.REACT_APP_LOCAL_DEV ? "herokuapp" : "localhost",
  )
    ? "https://tecnoschool-services-dev.herokuapp.com/"
    : "https://services.tecnoschool.mx/"; // Asume que tienes una URL diferente para producción

  // Codifica el objeto en base64
  const objeto = curso
    ? JSON.stringify({ curso: id })
    : JSON.stringify({ notas: id });
  const codificadoBase64 = btoa(objeto);

  return `${urlBase}?query=${codificadoBase64}`;
}

export const chunkSubstr = (str, size) => {
  const numChunks = Math.ceil(str?.length / size);
  const chunks = new Array(numChunks);

  for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
    chunks[i] = str?.substr(o, size);
  }

  return chunks;
};

export const changePalabra = (numb) => {
  const numberStr = numb.toString();
  switch (numberStr) {
    case "1":
      return "PRIMERO";
    case "2":
      return "SEGUNDO";
    case "3":
      return "TERCERO";
    case "4":
      return "CUARTO";
    case "5":
      return "QUINTO";
    case "6":
      return "SEXTO";
    case "7":
      return "SÉPTIMO";
    case "8":
      return "OCTAVO";
    case "9":
      return "NOVENO";
    case "10":
      return "DÉCIMO";
    default:
      break;
  }
};

export const numb_to_letter = (numb) => {
  const simple = (numb) => {
    switch (numb) {
      case "1":
        return "UNO";
      case "2":
        return "DOS";
      case "3":
        return "TRES";
      case "4":
        return "CUATRO";
      case "5":
        return "CINCO";
      case "6":
        return "SEIS";
      case "7":
        return "SIETE";
      case "8":
        return "OCHO";
      case "9":
        return "NUEVE";
      case "10":
        return "DIEZ";
      default:
        break;
    }
  };
  // Convertir a string
  const numberStr = numb.toString();

  // Separar parte entera y decimal
  const [integer, decimal] = numberStr.split(".");

  // Convertir parte entera
  const integerStr = simple(integer);

  // Convertir parte decimal
  const decimalStr = simple(decimal);

  // Si no hay parte decimal, devolver solo la parte entera
  if (!decimal) return integerStr;

  // Si hay parte decimal, unir ambas partes con "y"
  return `${integerStr} ${decimalStr}`;
};

// Función que retorna los campos modificados
export const getModifiedFields = (originalData, newData) => {
  return Object.fromEntries(
    Object.entries(newData).filter(([key, value]) => {
      return !_.isEqual(value, originalData[key]);
    }),
  );
};

export function appendFormData(formData, data, prefix = "") {
  if (Array.isArray(data)) {
    if (data.length > 0 && typeof data[0] !== "object")
      formData.append(prefix, data.join(","));
    else formData.append(prefix, JSON.stringify(data));
  } else {
    formData.append(prefix, data);
  }
}

export function transformTelefonos(telefonos) {
  if (!telefonos) {
    return null;
  }

  return telefonos.map((el) =>
    !el.isCreated
      ? {
          id: el.id,
          tipo: el.tipo,
          telefono: el.telefono,
        }
      : {
          tipo: el.tipo,
          telefono: el.telefono,
        },
  );
}

export function fechaConLetras(fechaStr) {
  if (fechaStr !== undefined) {
    const meses = [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre",
    ];

    const partes = fechaStr?.split("-");
    const año = partes[0];
    const mes = meses[parseInt(partes[1], 10) - 1]; // Los meses están basados en 0, por eso restamos 1
    const dia = parseInt(partes[2], 10); // Convertimos el día a número para eliminar ceros iniciales
    return `${dia} de ${mes} de ${año}`;
  }
}
