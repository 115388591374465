import {userApi} from "../../view/users.service";
import {reshape_response} from "../../../../../helpers/functions";

export const getPlantillaById = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getPlantillaById: builder.query({
      query: ({id}) => ({
        url: `/v2/api/management/usuarios/${id}/`,
        method: "GET",
      }),
      providesTags: (id) => [{type: "Users", id}],
      transformResponse: (response, meta, args) => {
        return reshape_response(response, args?.shape);
      },
    }),
  }),
})

export const {
  useGetPlantillaByIdQuery,
  useLazyGetPlantillaByIdQuery,
} = getPlantillaById;