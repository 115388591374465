import {carrerasApi} from "../../view/carreras.service";

export const getCarreraById = carrerasApi.injectEndpoints({
  endpoints: (builder) => ({
    getCarreraById: builder.query({
      query: (id) => ({
        url: `/v1/api/carreras/${id}/`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{type: "Carreras", id}],
    }),
  })
})

export const {
  useGetCarreraByIdQuery,
  useLazyGetCarreraByIdQuery
} = getCarreraById;