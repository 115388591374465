import { firmaBoletaApi } from "../view/firmaDeBoletaDeNotas.jsx";
import { estudiantesPorGrupoApi } from "../../../distribuciones/distrubucionDeEstudiantesPorGrupo.service.jsx";

export const firmarBoleta = firmaBoletaApi.injectEndpoints({
  endpoints: (builder) => ({
    firmarBoleta: builder.mutation({
      query: (data) => {
        return {
          url: "/v1/api/firmadeboletadenota/",
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: [{ type: "Boletas", id: "LIST" }],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          queryFulfilled.then(() => {
            dispatch(
              estudiantesPorGrupoApi.util.invalidateTags([
                { type: "EstudiantesPorGrupo", id: "LIST" },
              ])
            );
          });
        } catch (error) {
          console.log(error);
        }
      },
    }),
  }),
});

export const { useFirmarBoletaMutation } = firmarBoleta;
