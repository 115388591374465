import * as React from "react";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import FormModal from "./components/Form/form_modal";
import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import validations from "./schemas/validations";
import { useDarBajaMutation } from "services";
import ModalBase from "components/ModalBase/modal_base";
import { useCloseModal } from "hooks/useCloseModal";

const ModalForm = ({ open, handleClose, activo }) => {
  const currentValidation = validations[0];
  const { formId, formField } = form;
  const [darBaja, { isSuccess, isError, isLoading, error }] =
    useDarBajaMutation();

  const submitForm = (values, actions) => {
    actions.setSubmitting(false);
    actions.resetForm();

    if (activo) {
      darBaja({
        activo,
        fecha: values.fecha,
        cantidad: values.cantidad,
      });
    }
  };

  useCloseModal(
    isLoading,
    isSuccess,
    isError,
    error,
    "Activo dado de baja correctamente",
    handleClose
  );

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
  };

  return (
    <ModalBase open={open} handleClose={handleClose}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} lg={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              isValid,
              setFieldValue,
              handleBlur,
              handleChange,
            }) => (
              <Form key={formId} id={formId} autoComplete="off">
                <MDBox p={1}>
                  <FormModal
                    formData={{
                      values,
                      touched,
                      errors,
                      setFieldValue,
                      handleBlur,
                      handleChange,
                      formField,
                    }}
                    activo={activo}
                  />
                  <MDBox
                    mt={2}
                    width="100%"
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <MDButton
                      disabled={!isValid}
                      type="submit"
                      variant="gradient"
                      color="dark"
                    >
                      aceptar
                    </MDButton>
                  </MDBox>
                </MDBox>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </ModalBase>
  );
};

ModalForm.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ModalForm;
