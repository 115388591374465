import { certificadoXMLApi } from "../view/xml";

export const sealCertificadoElectronico = certificadoXMLApi.injectEndpoints({
  endpoints: (builder) => ({
    sealCertificadoElectronico: builder.mutation({
      query: (body) => {
        return {
          url: "/v1/api/xml/edu/xml/seal/",
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: (result, error, { id }) =>
        result
          ? [
              { type: "CertificadoXML", id },
              { type: "CertificadoXML", id: "LIST" },
            ]
          : [{ type: "CertificadoXML", id: "LIST" }],
    }),
  }),
});

export const { useSealCertificadoElectronicoMutation } =
  sealCertificadoElectronico;
