import { createApi } from "@reduxjs/toolkit/query/react";
import { buildFiltersUrl } from "helpers/functions";
import { reshape_response } from "helpers/functions";
import customFetchBase from "services/principal/customFetchBase";

export const adqApi = createApi({
  reducerPath: "adqApi",
  baseQuery: customFetchBase,
  tagTypes: ["Adq"],
  endpoints: (builder) => ({
    getAdqs: builder.query({
      query: ({ filter_params }) => ({
        url: buildFiltersUrl("/v1/api/metodo-adquisicion/", filter_params),
        method: "GET",
      }),
      providesTags: (result) =>
        Array.isArray(result)
          ? [
              ...result.map(({ id }) => ({
                type: "Adq",
                id,
              })),
              { type: "Adq", id: "LIST" },
            ]
          : [{ type: "Adq", id: "LIST" }],
      transformResponse: (response, meta, args) => {
        if (Array.isArray(response)) {
          return response?.map((el) => reshape_response(el, args?.shape));
        } else {
          const resultsTransformed = response?.results?.map((el) =>
            reshape_response(el, args?.shape)
          );

          // Devolvemos los resultados transformados junto con las propiedades de paginación
          return {
            results: resultsTransformed,
            count: response.count,
            next: response.next,
            previous: response.previous,
          };
        }
      },
    }),

    getAdqsById: builder.query({
      query: (id) => ({
        url: `/v1/api/metodo-adquisicion/${id}/`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "Adq", id }],
    }),

    createAdq: builder.mutation({
      query: (adq) => ({
        url: "/v1/api/metodo-adquisicion/",
        method: "POST",
        body: adq,
      }),
      invalidatesTags: [{ type: "Adq", id: "LIST" }],
    }),

    deleteAdq: builder.mutation({
      query: (id) => ({
        url: `/v1/api/metodo-adquisicion/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Adq", id: "LIST" }],
    }),

    editAdq: builder.mutation({
      query: (body) => ({
        url: `/v1/api/metodo-adquisicion/${body.id}/`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, { id }) =>
        result
          ? [
              { type: "Adq", id },
              { type: "Adq", id: "LIST" },
            ]
          : [{ type: "Adq", id: "LIST" }],
    }),
  }),
});

export const {
  useGetAdqsQuery,
  useCreateAdqMutation,
  useEditAdqMutation,
  useDeleteAdqMutation,
  useLazyGetAdqsByIdQuery,
  use,
} = adqApi;
