import { fechaEvaluacionApi } from "../view/fecha_evaluacion.service";
import {
  buildFiltersUrl,
  reshape_response,
} from "../../../../helpers/functions";

export const getFechaEvaluacion = fechaEvaluacionApi.injectEndpoints({
  endpoints: (builder) => ({
    getFechaEvaluacion: builder.query({
      query: ({ filter_params }) => ({
        url: buildFiltersUrl("/v1/api/permisoevaluacion/", filter_params),
        method: "GET",
      }),
      providesTags: (result) =>
        Array.isArray(result)
          ? [
              ...result.map(({ id }) => ({
                type: "Fecha Evaluacion",
                id,
              })),
              { type: "Fecha Evaluacion", id: "LIST" },
            ]
          : [{ type: "Fecha Evaluacion", id: "LIST" }],
    }),
  }),
});

export const { useGetFechaEvaluacionQuery } = getFechaEvaluacion;
