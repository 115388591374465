/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================
 
 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)
 
 Coded by www.creative-tim.com
 
 =========================================================
 
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// formik components
import { Form, Formik } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// NewUser layout schemas for form and form feilds
import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import { useNavigate, useParams } from "react-router-dom";
import * as React from "react";
import { useEffect, useState } from "react";
import MDTypography from "components/MDTypography";
import { useRedirectForm } from "hooks/useRedirectForm";
import {
  useCreateEstadoPorEstudianteMutation,
  useCreateEstudianteMutation,
  useEditEstadoPorEstudianteMutation,
  useEditEstudianteMutation,
  useGetCiclosQuery,
  useGetEstadoPorEstudianteQuery,
  useGetGruposQuery,
  useLazyGetEstudianteByIdQuery,
} from "services";
import { ESTUDIANTES_LIST_URL } from "config/bases_url";
import UserInfo from "./components/UserInfo/user_info";
import TelefonosCRUD, {
  telefonoIcon,
} from "examples/TelefonosCRUD/telefonos.add";
import Specifics from "./components/Specifics/specifics";
import { getModifiedFields, reemplazarUrl } from "helpers/functions";
import { ESTUDIANTE } from "roles";
import FormErrorHandler from "../../../../handlers/FormErrorHandler/form-error.handler";
import { ErrorBundary } from "../../../../config/ErrorBundary/error-bundary";

function getSteps() {
  return ["Información Estudiante", "Datos especiales", "Teléfonos"];
}

function getStepContent(stepIndex, formData, id) {
  switch (stepIndex) {
    case 0:
      return <UserInfo formData={formData} editar={id ? true : false} />;
    case 1:
      return <Specifics formData={formData} />;
    case 2:
      return <TelefonosCRUD formData={formData} />;
    default:
      return null;
  }
}

// Función que filtra los campos a excluir
const filterFields = (data, excludeFields) => {
  return Object.fromEntries(
    Object.entries(data).filter(([key, value]) => {
      if (excludeFields.includes(key)) {
        if (key === "telefonos" && Array.isArray(value) && value.length === 0) {
          return false; // Excluir el campo "telefono" si es un arreglo vacío
        }
        if (key === "groups") return false;
        return !(typeof value === "string" || value === null || value === "");
      }
      return true;
    })
  );
};

function NewEstudiante() {
  const { id } = useParams();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const [oldValues, setOldValues] = useState();
  const handleBack = () => setActiveStep(activeStep - 1);

  const [
    createEstudiante,
    {
      isSuccess: isSuccessC,
      isLoading: isLoadingC,
      isError: isErrorC,
      error: errorC,
    },
  ] = useCreateEstudianteMutation();

  const [
    editEstudiante,
    {
      isSuccess: isSuccessE,
      isLoading: isLoadingE,
      isError: isErrorE,
      error: errorE,
    },
  ] = useEditEstudianteMutation();

  const { data: estado } = useGetEstadoPorEstudianteQuery(
    {
      filter_params: {
        estudiante: id,
        query: "{estado, id}",
      },
      shape: {
        id: "id",
        estado: "estado",
      },
    },
    {
      refetchOnReconnect: true,
      skip: !id,
    }
  );
  console.log(estado);
  const [estadoIdState, setEstadoIdState] = useState();
  const [
    editEstado,
    {
      isSuccess: isSuccessEE,
      isLoading: isLoadingEE,
      isError: isErrorEE,
      error: errorEE,
    },
  ] = useEditEstadoPorEstudianteMutation();

  const [
    createEstado,
    {
      isSuccess: isSuccessCE,
      isLoading: isLoadingCE,
      isError: isErrorCE,
      error: errorCE,
    },
  ] = useCreateEstadoPorEstudianteMutation();

  const navigate = useNavigate();

  const [getEstudianteById, { data: estudiante }] =
    useLazyGetEstudianteByIdQuery();

  const { data: ciclos } = useGetCiclosQuery("", {
    refetchOnReconnect: true,
  });

  const { data: grupos } = useGetGruposQuery(
    {
      filter_params: {
        query: "{name, url, id}",
      },
      shape: {
        id: "id",
        name: "name",
        url: "url",
      },
    },
    {
      refetchOnReconnect: true,
    }
  );
  const rol = grupos?.find((e) => e.name === ESTUDIANTE);

  useRedirectForm(
    isLoadingC,
    isSuccessC,
    isErrorC,
    errorC,
    "Nuevo estudiante creado",
    ESTUDIANTES_LIST_URL
  );

  useRedirectForm(isLoadingCE, null, isErrorCE, errorCE);

  useRedirectForm(isLoadingEE, null, isErrorEE, errorEE);

  useRedirectForm(
    isLoadingEE,
    isSuccessEE,
    isErrorEE,
    errorEE,
    "Actualizado el estado del estudiante",
    ESTUDIANTES_LIST_URL
  );

  useRedirectForm(
    isLoadingE,
    isSuccessE,
    isErrorE,
    errorE,
    "Estudiante editado",
    ESTUDIANTES_LIST_URL
  );
  const submitForm = async (values, actions) => {
    const excludeFields = ["foto", "telefonos"]; // Campos a excluir
    const excludeFieldsEditing = [
      "foto",
      "telefonos",
      "groups",
      "url_cambio_password",
    ]; // Campos a excluir en edicion
    try {
      if (!id) {
        const data = filterFields(values, excludeFields);
        createEstudiante({
          ...data,
          ciclo: ciclos[ciclos?.length - 1]?.url,
        });
      } else {
        const modifiedFields = getModifiedFields(oldValues, values);

        if (Object.keys(modifiedFields).length !== 0) {
          let data = filterFields(modifiedFields, excludeFieldsEditing);
          if (estado[0] && modifiedFields.estado !== undefined) {
            editEstado({ id: estadoIdState, estado: modifiedFields.estado });
          } else if (
            modifiedFields.estado !== undefined &&
            modifiedFields.estado !== ""
          ) {
            getEstudianteById({ id: id, filter_params: { query: "{url}" } })
              .unwrap()
              .then((res) => {
                createEstado({
                  ciclo: ciclos[ciclos?.length - 1]?.url,
                  estado: modifiedFields.estado,
                  estudiante: reemplazarUrl(res?.url, 1),
                });
              });
          }
          if (Object.keys(data).length !== 0) {
            editEstudiante({
              id: id,
              ...data,
            });
          }
        }
      }
      navigate(ESTUDIANTES_LIST_URL);
      if (isSuccessC || isSuccessE) {
        actions.setSubmitting(false);
        actions.resetForm();
      }
    } catch (error) {
      console.error(error);
      actions.setSubmitting(true);
    }
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  return (
    <MDBox mt={5} mb={20}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} lg={8}>
          <MDBox mt={6} mb={8} textAlign="center">
            <MDBox mb={1}>
              <MDTypography variant="h3" fontWeight="bold">
                {!id ? "Agregar Estudiante" : "Editar Estudiante"}
              </MDTypography>
            </MDBox>
            <MDTypography variant="h5" fontWeight="regular" color="secondary">
              {!id
                ? "Introduzca la información relacionada para agregar un nuevo Estudiante"
                : `Edite la información relacionada al Estudiante: ${estudiante?.user.apellido_paterno} ${estudiante?.user.apellido_materno} ${estudiante?.user.first_name}`}
            </MDTypography>
          </MDBox>
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              setFieldError,
              isValid,
              setFieldValue,
              setErrors,
              handleChange,
            }) => {
              useEffect(() => {
                if (id) {
                  getEstudianteById({
                    id: id,
                    filter_params: {
                      query:
                        "{user{first_name,apellido_materno,apellido_paterno,email,foto},url,curp,telefonos,numero_de_control,genero{url},tipo_sangre,preasignacion_de_carrera{url},usuario_wifi,password_wifi}",
                    },
                  })
                    .unwrap()
                    .then((res) => {
                      setOldValues((prevState) => ({
                        ...prevState,
                        nombre: res?.user.first_name,
                        apellido_materno: res?.user.apellido_materno,
                        apellido_paterno: res?.user.apellido_paterno,
                        curp: res?.curp,
                        telefonos:
                          res?.telefonos?.map((el) => ({
                            ...el,
                            isCreated: false,
                            icon: telefonoIcon(el.tipo),
                          })) || [],
                        numero_de_control: res?.numero_de_control,
                        genero: res?.genero.url,
                        tipo_sangre: res?.tipo_sangre,
                        foto: res?.user.foto,
                        preasignacion_de_carrera:
                          res?.preasignacion_de_carrera?.url ?? "",
                        ciclo: "",
                        usuario_wifi: res?.usuario_wifi,
                        password_wifi: res?.password_wifi,
                      }));
                      setFieldValue(
                        formField.nombre.name,
                        res?.user.first_name,
                        true
                      );
                      setFieldValue(
                        formField.apellido_paterno.name,
                        res?.user.apellido_paterno,
                        true
                      );
                      setFieldValue(
                        formField.telefonos.name,
                        res?.telefonos?.map((el) => ({
                          ...el,
                          isCreated: false,
                          icon: telefonoIcon(el.tipo),
                        })) || [],
                        true
                      );
                      setFieldValue(
                        formField.apellido_materno.name,
                        res?.user.apellido_materno,
                        true
                      );
                      setFieldValue(
                        formField.numero_de_control.name,
                        res?.numero_de_control,
                        true
                      );
                      setFieldValue(formField.foto.name, res?.user.foto, true);
                      setFieldValue(
                        formField.genero.name,
                        res?.genero.url,
                        true
                      );
                      setFieldValue(
                        formField.preasignacion_de_carrera?.name,
                        res?.preasignacion_de_carrera?.url ?? "",
                        true
                      );
                      setFieldValue(
                        formField.tipo_sangre.name,
                        res?.tipo_sangre,
                        true
                      );
                      setFieldValue(formField.curp.name, res?.curp, true);
                      setFieldValue(
                        formField.usuario_wifi.name,
                        res?.usuario_wifi,
                        true
                      );
                      setFieldValue(
                        formField.password_wifi.name,
                        res?.password_wifi,
                        true
                      );
                    });
                }
              }, [id]);
              useEffect(() => {
                if (Array.isArray(estado) && estado.length !== 0) {
                  setFieldValue(
                    formField.estado.name,
                    estado[estado.length - 1].estado
                  );
                  setEstadoIdState(estado[estado.length - 1].id);
                  setOldValues((prevState) => ({
                    ...prevState,
                    estado: estado[estado.length - 1].estado,
                  }));
                }
              }, [estado]);
              useEffect(() => {
                if (rol !== undefined) {
                  setFieldValue(formField.groups.name, [rol?.id]);
                }
              }, [rol]);

              return (
                <ErrorBundary renderOnError={() => <FormErrorHandler />}>
                  <Form id={formId} autoComplete="off">
                    <Card sx={{ height: "100%" }}>
                      <MDBox mx={2} mt={-3}>
                        <Stepper activeStep={activeStep} alternativeLabel>
                          {steps.map((label) => (
                            <Step key={label}>
                              <StepLabel>{label}</StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                      </MDBox>
                      <MDBox p={3}>
                        <MDBox>
                          {getStepContent(
                            activeStep,
                            {
                              values,
                              touched,
                              formField,
                              setFieldError,
                              setErrors,
                              errors,
                              setFieldValue,
                              handleChange,
                            },
                            id
                          )}
                          <MDBox
                            mt={2}
                            width="100%"
                            display="flex"
                            justifyContent="space-between"
                          >
                            <MDButton
                              onClick={(e) =>
                                activeStep === 0
                                  ? navigate(ESTUDIANTES_LIST_URL)
                                  : handleBack()
                              }
                              type="button"
                              variant="gradient"
                              color={activeStep === 0 ? "primary" : "dark"}
                            >
                              {activeStep === 0 ? "cancelar" : "atrás"}
                            </MDButton>
                            {activeStep !== 0 && (
                              <MDButton
                                onClick={(e) => navigate(ESTUDIANTES_LIST_URL)}
                                type="button"
                                variant="gradient"
                                color="primary"
                              >
                                cancelar
                              </MDButton>
                            )}
                            <MDButton
                              disabled={isLastStep && !isValid}
                              type="submit"
                              variant="gradient"
                              color="dark"
                            >
                              {isLastStep ? "aceptar" : "siguiente"}
                            </MDButton>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </Card>
                  </Form>
                </ErrorBundary>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default NewEstudiante;
export { filterFields };
