import * as React from "react";
import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import no_foto from "assets/images/not_found_image.jpg";
import PrintRoot, { NoPrint } from "layouts/almacen/pdf/printing/print";
import PrintButton from "layouts/almacen/pdf/printing/PrintButton";

const EstudianteShow = ({ rowData }) => {
  return (
    <MDBox pt={1} pb={1}>
      <PrintRoot>
        <MDBox display="flex" alignItems={"center"}>
          <MDBox
            component="img"
            src={rowData.foto !== null ? rowData.foto : no_foto}
            borderRadius="100%"
            mx={2}
            p={2}
            width={"150px"}
            height={"150px"}
          />
          <MDBox display="flex" alignItems="flex-start" flexDirection="column">
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="flex-end"
              width="100%"
            >
              <MDTypography variant="h5" fontWeight="regular" marginTop="15px">
                {`${rowData.apellido_paterno} ${rowData.apellido_materno} ${rowData.nombre}`}
              </MDTypography>
              <NoPrint>
                <MDBox
                  sx={{
                    marginRight: "20px",
                    marginTop: "10px",
                  }}
                >
                  <PrintButton />
                </MDBox>
              </NoPrint>
            </MDBox>
            <Grid container spacing={1} pr={2} py={3}>
              <Grid item xs={12} mb={1} sm={6}>
                <MDInput
                  variant="outlined"
                  id="curp-read-only"
                  label="CURP:"
                  defaultValue={`${rowData.curp}`}
                  InputProps={{
                    readOnly: true,
                  }}
                  style={{ width: "100%" }}
                />
              </Grid>

              <Grid item xs={12} mb={1} sm={6}>
                <MDInput
                  variant="outlined"
                  id="sexo-read-only"
                  label="Sexo:"
                  defaultValue={`${rowData.genero}`}
                  placeholder={"Ej: Mujer"}
                  InputProps={{
                    readOnly: true,
                  }}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} mb={1} sm={6}>
                <MDInput
                  variant="outlined"
                  id="sangre-read-only"
                  label="Tipo de Sangre:"
                  defaultValue={
                    rowData.tipo_sangre !== "" ? `${rowData.tipo_sangre}` : ""
                  }
                  placeholder={"Ej: O+"}
                  InputProps={{
                    readOnly: true,
                  }}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} mb={1} sm={6}>
                <MDInput
                  variant="outlined"
                  id="movil-read-only"
                  label="T Móvil:"
                  defaultValue={
                    rowData.telefonos.find((e) => e.tipo === "Móvil") !==
                    undefined
                      ? `${
                          rowData.telefonos.find((e) => e.tipo === "Móvil")
                            .telefono
                        }`
                      : ""
                  }
                  placeholder="Ej: 12345-67890"
                  InputProps={{
                    readOnly: true,
                  }}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} mb={1} sm={6}>
                <MDInput
                  variant="outlined"
                  id="matricula-read-only"
                  label="Matrícula:"
                  defaultValue={`${rowData.numero_de_control}`}
                  InputProps={{
                    readOnly: true,
                  }}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} mb={1} sm={6}>
                <MDInput
                  variant="outlined"
                  id="correo-read-only"
                  label="Correo Institucional:"
                  defaultValue={rowData.email}
                  placeholder="Ej: ejemplo@ejemplo.com"
                  InputProps={{
                    readOnly: true,
                  }}
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      </PrintRoot>
    </MDBox>
  );
};

export default EstudianteShow;
