import {userApi} from "../../view/users.service";
import {appendFormData, transformTelefonos} from "../../../../../helpers/functions";

export const createUser = userApi.injectEndpoints({
  endpoints: (builder) => ({
    createUser: builder.mutation({
      query: (body) => {
        
        if (body.telefonos)
          body.telefonos = transformTelefonos((body.telefonos))

        const formData = new FormData()
        Object.entries(body).forEach(([key, value]) => {
          appendFormData(formData, value, key);
        });

        return {
          url: "/v3/api/registro/",
          method: "POST",
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          body: formData,
        };
      },
      invalidatesTags: [{type: "Users", id: "LIST"}],
    }),
  }),
})

export const {
  useCreateUserMutation,
} = createUser;