export const dataTableActivo = {
  columns: [
    { Header: "Operación", accessor: "operacion" },
    { Header: "Fecha", accessor: "fecha" },
    { Header: "Cantidad", accessor: "cantidad" },
    {
      Header: "Personal",
      accessor: "personal",
      Cell: ({ value }) => {
        return value
          ? value?.user.first_name + " " + value?.user.last_name
          : "---------------";
      },
    },
    {
      Header: "Motivo",
      accessor: "motivo",
      Cell: ({ value }) => {
        return value ? value : "---------------";
      },
    },
  ],
};

export const dataTablePersonal = {
  columns: [
    { Header: "Operación", accessor: "operacion" },
    { Header: "Fecha", accessor: "fecha" },
    { Header: "Cantidad", accessor: "cantidad" },
    {
      Header: "Activo",
      accessor: "activo",
      Cell: ({ value }) => {
        return value
          ? value.descripcion + " " + value.modelo + " " + value.marca
          : "---------------";
      },
    },
    {
      Header: "Motivo",
      accessor: "motivo",
      Cell: ({ value }) => {
        return value ? value : "---------------";
      },
    },
  ],
};

export const dataTableEntidad = {
  columns: [
    { Header: "Nombre", accessor: "nombre" },
    { Header: "Municipio", accessor: "municipio" },
    { Header: "Localidad", accessor: "localidad" },
    { Header: "Clave del Centro", accessor: "clave_del_centro" },
  ],
};

export const dataTableEvaluaciones = {
  columns: [
    { Header: "Nombre", accessor: "nombre" },
    { Header: "Nota Final", accessor: "nota_final" },
    { Header: "Asistencia", accessor: "asistencia" },
  ],
};
