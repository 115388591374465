import MDBox from "components/MDBox";

import React, { useCallback, useEffect, useState } from "react";
import { Autocomplete, Card, Icon } from "@mui/material";
import MDButton from "components/MDButton";
import { COORDINADOR_DOCENTE } from "roles";
import { useGetCiclosQuery, useToggleDivisionMutation } from "services";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import Swal from "sweetalert2";
import { calificaciones, setCarreraSelect } from "slices";
import { useDispatch, useSelector } from "react-redux";

const SelectCarrerasCalificaciones = ({
  carreras,
  ind,
  division,
  setDivision,
}) => {
  const dispatch = useDispatch();
  const { carreraSelect } = useSelector(calificaciones);

  const { data: ciclos } = useGetCiclosQuery("");
  const [toggleDivision, { isSuccess: isSuccessDivision }] =
    useToggleDivisionMutation();

  const authUser = JSON.parse(sessionStorage.getItem("user"));

  useEffect(() => {
    if (isSuccessDivision) {
      const carreraActualizada = ciclos[ind].carreras.find(
        (carrera) => carrera.id === carreraSelect.id
      );

      dispatch(setCarreraSelect(carreraActualizada));
    }
  }, [ciclos]);

  //Funcion encargada de cambiar la division(espacio de tiempo en el que se encuentran cursando las carreras ej, si estas en el 1er Semestre
  //las carreras estan cursando sus semestres impares) en la que se estan mostrando las calificaciones
  const handleDivision = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      const newValue = e.currentTarget.value; // Usa currentTarget para obtener el valor del botón
      if (division.division !== newValue) {
        setDivision(carreraSelect.divisiones[newValue - 1]);
      }
    },
    [carreraSelect]
  );

  //Funcion encargada de cerrar la division ya que la vista de las divisiones solo esta para el coordinador docente, los profesores solo ven\
  //la division abierta por tanto es necesario cerrar 1 para que capturen datos en la siguiente.
  const handleToggleDivision = (div) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: `¡La siguiente acción cambiará el estado del ${div.type} de ${
        !div.cerrada ? "abierto" : "cerrado"
      } a ${div.cerrada ? "abierto" : "cerrado"}.`,
      icon: "question",
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Cambiar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await toggleDivision(div.id).unwrap();

          enqueueSnackbar(
            `Ha cambiado el estado de abierto con éxito del: ${div.division_verbose}`,
            {
              variant: "success",
              autoHideDuration: 3000,
            }
          );
        } catch (error) {
          enqueueSnackbar(
            `Hubo algún problema al intentar cerrar el: ${div.division_verbose}`,
            {
              variant: "error",
              autoHideDuration: 3000,
            }
          );
        }
      }
    });
  };

  const handleChange = (e) => {
    dispatch(setCarreraSelect(e));
  };

  return (
    <Card style={{ marginBottom: "24px" }}>
      <MDBox display="flex" flexDirection="column" m={2}>
        <MDTypography variant="h4" fontWeight="bold">
          Mostrando las calificaciones asociadas a la carrera:
        </MDTypography>
        <MDBox mt={3} width={"70%"}>
          <Autocomplete
            disableClearable
            id="carrera"
            options={carreras}
            value={carreraSelect}
            onChange={(e, value) => handleChange(value)}
            getOptionLabel={(option) =>
              `${option.nombre} ${option.plan_de_estudio}`
            }
            isOptionEqualToValue={(option, value) => {
              return `${option.id}` === `${value.id}`;
            }}
            renderInput={(params) => (
              <MDInput
                id="input-carrera"
                name={"carrera-input"}
                label=""
                {...params}
              />
            )}
          />
        </MDBox>
        {authUser.groups.some((role) => role.name === COORDINADOR_DOCENTE) && (
          <MDBox display="flex" mr={2} mt={3}>
            {carreraSelect?.divisiones.map((div) => {
              return (
                <MDButton
                  value={div.division}
                  variant={
                    division.division_verbose === div.division_verbose
                      ? "outlined"
                      : "gradient"
                  }
                  color={
                    div.cerrada === true
                      ? "error"
                      : division.division_verbose === div.division_verbose
                        ? "success"
                        : "secondary"
                  }
                  size="medium"
                  onClick={handleDivision}
                  sx={{
                    marginRight: "10px",
                    paddingLeft: "16px",
                    paddingRight: "16px",
                  }}
                  key={`button-${div.id}`}
                >
                  {div.division_verbose}
                  <MDBox
                    display="flex"
                    sx={{
                      marginLeft: "10px",
                      cursor: "alias",
                      border: 0,
                      borderRadius: "0.2rem",
                      backgroundColor: "lightsteelblue",
                    }}
                  >
                    <Icon
                      onClick={(e) => {
                        e.stopPropagation();
                        handleToggleDivision(div);
                      }}
                    >
                      edit
                    </Icon>
                  </MDBox>
                </MDButton>
              );
            })}
          </MDBox>
        )}
      </MDBox>
    </Card>
  );
};

export default SelectCarrerasCalificaciones;
