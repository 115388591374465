/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

const form = {
  formId: "nuevo-user",
  formField: {
    nombre: {
      name: "nombre",
      label: "Nombre(s)",
      type: "text",
      errorMsg: "El nombre es requerido.",
    },
    apellido_materno: {
      name: "apellido_materno",
      label: "Apellido Materno",
      type: "text",
      errorMsg: "Se debe entrar el apellido materno",
    },
    apellido_paterno: {
      name: "apellido_paterno",
      label: "Apellido Paterno",
      type: "text",
      errorMsg: "Se debe entrar el apellido paterno",
    },
    correo: {
      name: "correo",
      label: "Correo",
      type: "email",
      errorMsg: "El correo es requerido.",
      invalidMsg: "Entre un correo válido",
      placeholder: "Correo",
    },
    telefonos: {
      name: "telefonos",
      label: "Teléfono",
      type: "tel",
      placeholder: "Teléfono",
    },
    foto: {
      name: "foto",
      label: "Foto",
      type: "file",
      placeholder: "Foto",
    },
    groups: {
      name: "groups",
      label: "Rol",
      type: "text",
      placeholder: "Roles",
      errorMsg: "Debe añadir uno o varios roles",
    },
    url_cambio_password: {
      name: "url_cambio_password",
      label: "url_cambio_password",
      type: "text",
    },
  },
};

export default form;
