import * as React from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Autocomplete, styled, Tooltip } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import FormField from "components/FormField";
import { useNavigate } from "react-router-dom";
import { ACTIVOS_RESGUARDOS_LIST_URL } from "config/bases_url";
import MDButton from "components/MDButton";
import {
  useAsignarActivosMutation,
  useGetActivosQuery,
  useGetPlantillaQuery,
} from "services";
import { useRedirectForm } from "hooks/useRedirectForm";
import MDInput from "components/MDInput";
import FormErrorHandler from "../../../../handlers/FormErrorHandler/form-error.handler";
import { ErrorBundary } from "../../../../config/ErrorBundary/error-bundary";

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.dark.focus,
}));

const GroupItems = styled("ul")({
  padding: 0,
});

const ActivoResguardar = () => {
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);
  const navigate = useNavigate();

  const {
    data: personal_data,
    isLoading: isLoadingG,
    isError: isErrorG,
    error: errorG,
  } = useGetPlantillaQuery(
    {
      filter_params: {
        estudiantes: "false",
        query:
          "{id,url,email,first_name,apellido_paterno,apellido_materno,perfil}",
      },
      shape: {
        id: "id",
        url: "perfil.resourcetype[0].url",
        email: "email",
        nombre: "first_name apellido_paterno apellido_materno",
      },
    },
    {
      refetchOnReconnect: true,
    },
  );

  const {
    data: almacen,
    isError: isErrorR,
    isLoading: isLoadingR,
    error: errorR,
  } = useGetActivosQuery(
    {
      filter_params: {
        disposicion: "Almacén",
        type: "Tangibles",
        query: "{id,descripcion,url}",
      },
      shape: {
        id: "id",
        url: "url",
        descripcion: "descripcion",
      },
    },
    {
      refetchOnReconnect: true,
    },
  );

  const [
    resguardar,
    {
      isSuccess: isSuccessC,
      isLoading: isLoadingC,
      isError: isErrorC,
      error: errorC,
    },
  ] = useAsignarActivosMutation();

  React.useEffect(() => {
    setLeft(almacen);
  }, [almacen]);

  useRedirectForm(
    isLoadingC,
    isSuccessC,
    isErrorC,
    errorC,
    "Activo resguardado",
    ACTIVOS_RESGUARDOS_LIST_URL,
  );

  useRedirectForm(isLoadingG, null, isErrorG, errorG, null, null);

  useRedirectForm(isLoadingR, null, isErrorR, errorR, null, null);

  const handleSubmit = (values, actions) => {
    if (right.length > 0 && values.fecha !== "" && values.personal !== "") {
      resguardar({
        activo: right,
        personal: values?.personal,
        fecha: values?.fecha,
      })
        .unwrap()
        .then(() => {
          actions.setSubmitting(false);
          actions.resetForm();
        })
        .catch((err) => console.log(err));
    }
  };

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items?.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const customList = (title, items) => {
    const [search, setSearch] = React.useState("");

    function handleChangeSearch(e) {
      setSearch(e.target.value);
    }

    const resultados =
      search.trim() === ""
        ? items
        : items.filter((objeto) => {
            const regex = new RegExp(search.trim(), "i"); // Creamos una expresión regular a partir de la cadena de búsqueda
            const valores = Object.values(objeto).join(" "); // Unimos todos los valores en una sola cadena para facilitar la búsqueda
            return regex.test(valores); // Devolvemos true si la cadena de búsqueda coincide con algún valor del objeto
          });

    return (
      <Card>
        <Tooltip
          placement="top"
          title="Puede buscar en cualquier campo del activo aunque no se muestre"
        >
          <MDInput
            variant="outlined"
            type="text"
            label="Buscar:"
            value={search}
            onChange={handleChangeSearch}
            sx={{ mb: 1 }}
          />
        </Tooltip>
        <CardHeader
          sx={{ px: 2, py: 1 }}
          avatar={
            <Checkbox
              onClick={handleToggleAll(items)}
              checked={
                numberOfChecked(items) === items?.length && items?.length !== 0
              }
              indeterminate={
                numberOfChecked(items) !== items?.length &&
                numberOfChecked(items) !== 0
              }
              disabled={items?.length === 0}
              inputProps={{
                "aria-label": "todos los elementos fueron seleccionados",
              }}
            />
          }
          title={title}
          subheader={`${numberOfChecked(items)}/${items?.length} seleccionados`}
        />
        <Divider />
        <List
          sx={{
            width: 300,
            height: 230,
            bgcolor: "background.paper",
            overflow: "auto",
          }}
          dense
          component="div"
          role="list"
        >
          {resultados?.map((value) => {
            const labelId = `transfer-list-all-item-${value}-label`;

            return (
              <ListItem
                key={value.id}
                role="listitem"
                onClick={handleToggle(value)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      "aria-labelledby": labelId,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={`${value.descripcion.slice(0, 52)}${
                    value.descripcion.length > 50 ? "..." : ""
                  }`}
                />
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Card>
    );
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ height: "100%", mt: 3, mb: 5 }}
    >
      <Grid item xs={12} lg={8}>
        <MDBox mt={3} mb={4} textAlign="center">
          <MDBox mb={1}>
            <MDTypography variant="h3" fontWeight="bold">
              Crear nuevo resguardo
            </MDTypography>
          </MDBox>
          <MDTypography variant="h5" fontWeight="regular" color="secondary">
            Introduzca la información correspondiente para completar el
            resguardo
          </MDTypography>
        </MDBox>
        <Formik
          initialValues={{
            personal: "",
            fecha: new Date().toISOString().slice(0, 10),
          }}
          validationSchema={Yup.object().shape({
            personal: Yup.string()
              .nullable()
              .required("Debe seleccionar una persona"),
            fecha: Yup.string().required("Debe entrar una fecha"),
          })}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            isValid,
            handleChange,
            handleBlur,
            setFieldValue,
          }) => {
            return (
              <ErrorBundary renderOnError={() => <FormErrorHandler />}>
                <Form id={"resguardo_form"} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox p={3}>
                      <MDBox p={1}>
                        <MDTypography variant="h5" fontWeight="bold">
                          Información:
                        </MDTypography>
                        <Grid container direction="column">
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                              {personal_data && (
                                <Autocomplete
                                  id="transfer-list-autocomplete"
                                  options={personal_data}
                                  onChange={(event, option) => {
                                    if (option) {
                                      // Si se seleccionó una opción, establece la URL en el campo correspondiente del formulario
                                      setFieldValue("personal", option.url);
                                    } else {
                                      // Si se borró la selección, establece la URL en null
                                      setFieldValue("personal", null);
                                    }
                                  }}
                                  isOptionEqualToValue={(option, value) =>
                                    option.nombre === value.nombre
                                  }
                                  getOptionLabel={(option) =>
                                    `${option.nombre}`
                                  }
                                  renderInput={(params) => (
                                    <FormField
                                      id="personal"
                                      name={"personal"}
                                      label="Personal"
                                      success={
                                        params.inputProps.value.length > 0
                                      }
                                      {...params}
                                    />
                                  )}
                                  renderGroup={(params) => (
                                    <li key={params.key}>
                                      <GroupHeader>{params.group}</GroupHeader>
                                      <GroupItems>{params.children}</GroupItems>
                                    </li>
                                  )}
                                />
                              )}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormField
                                type="date"
                                name="fecha"
                                error={errors.fecha && touched.fecha}
                                success={
                                  values.fecha.length > 0 && !errors.fecha
                                }
                                label="Fecha"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            spacing={2}
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Grid item xs={12}>
                              <MDTypography variant="h5" fontWeight="bold">
                                Menú:
                              </MDTypography>
                            </Grid>
                            <Grid item>{customList("Opciones", left)}</Grid>
                            <Grid item>
                              <Grid
                                container
                                direction="column"
                                alignItems="center"
                              >
                                <MDButton
                                  sx={{ my: 0.5 }}
                                  variant="gradient"
                                  color="dark"
                                  size="small"
                                  onClick={handleCheckedRight}
                                  disabled={leftChecked.length === 0}
                                  aria-label="mover a la derecha"
                                >
                                  &gt;
                                </MDButton>
                                <MDButton
                                  sx={{ my: 0.5 }}
                                  variant="gradient"
                                  color="dark"
                                  size="small"
                                  onClick={handleCheckedLeft}
                                  disabled={rightChecked.length === 0}
                                  aria-label="mover a la izquierda"
                                >
                                  &lt;
                                </MDButton>
                              </Grid>
                            </Grid>
                            <Grid item>{customList("A asignar", right)}</Grid>
                          </Grid>
                        </Grid>
                        <MDBox
                          mt={3}
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                        >
                          <MDButton
                            onClick={(e) =>
                              navigate(ACTIVOS_RESGUARDOS_LIST_URL)
                            }
                            variant="gradient"
                            color="primary"
                          >
                            cancelar
                          </MDButton>
                          <MDButton
                            disabled={
                              right.length > 0 && isValid ? false : true
                            }
                            type="submit"
                            variant="gradient"
                            color="dark"
                          >
                            asignar
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              </ErrorBundary>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default ActivoResguardar;
export { GroupHeader, GroupItems };
