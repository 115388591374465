import { buildFiltersUrl, reshape_response } from "helpers/functions";
import customFetchBase from "../../principal/customFetchBase";
import { createApi } from "@reduxjs/toolkit/query/react";

export const evaluacionesApi = createApi({
  reducerPath: "evaluacionesApi",
  baseQuery: customFetchBase,
  tagTypes: ["Evaluaciones", "GruposPorEvaluaciones", "Examenes"],
  endpoints: (builder) => ({
    getEvaluaciones: builder.query({
      query: ({ filter_params }) => ({
        url: buildFiltersUrl(
          "/v1/api/distribuciondeevaluacion/",
          filter_params
        ),
        method: "GET",
      }),
      providesTags: (result) =>
        Array.isArray(result)
          ? [
              ...result.map(({ id }) => ({
                type: "GruposPorEvaluaciones",
                id,
              })),
              { type: "GruposPorEvaluaciones", id: "LIST" },
            ]
          : [{ type: "GruposPorEvaluaciones", id: "LIST" }],
      transformResponse: (response, meta, args) => {
        if (Array.isArray(response)) {
          return response?.map((el) => reshape_response(el, args.shape));
        } else {
          const resultsTransformed = response?.results?.map((el) =>
            reshape_response(el, args.shape)
          );
          return {
            results: resultsTransformed,
            count: response.count,
            next: response.next,
            previous: response.previous,
          };
        }
      },
    }),
    getEvaluacionesById: builder.query({
      query: (id) => ({
        url: `/v1/api/distribuciondeevaluacion/${id}/`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "Evaluaciones", id }],
    }),
    createEvaluaciones: builder.mutation({
      query: (data) => ({
        url: "/v1/api/distribuciondeevaluacion/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "Evaluaciones", id: "LIST" }],
    }),
    deleteEvaluaciones: builder.mutation({
      query: (id) => ({
        url: `/v1/api/distribuciondeevaluacion/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Evaluaciones", id: "LIST" }],
    }),
    editEvaluaciones: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `/v1/api/distribuciondeevaluacion/${id}/`,
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: (result, error, { id }) =>
        result
          ? [
              { type: "Evaluaciones", id },
              { type: "Evaluaciones", id: "LIST" },
            ]
          : [{ type: "Evaluaciones", id: "LIST" }],
    }),
    notasEstudiantes: builder.query({
      query: ({ id, filter_params }) => ({
        url: buildFiltersUrl(
          `/v1/api/distribuciondeprofesorporasignatura/${id}/notas`,
          filter_params
        ),
        method: "GET",
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "Evaluaciones",
                id,
              })),
              { type: "Evaluaciones", id: "LIST" },
            ]
          : [{ type: "Evaluaciones", id: "LIST" }],
      transformResponse: (response) => {
        return response.map((el) => {
          const { estudiante, notas } = el;

          const {
            distribucion_grupo: {
              grupo: { identificador },
            },
            estudiante: {
              numero_de_control,
              user: { first_name, apellido_paterno, apellido_materno },
            },
          } = estudiante;

          const new_notas = notas.reduce((acum, el) => {
            acum[
              el.examen.nombre
                .toLowerCase()
                .normalize("NFD")
                .replaceAll(" ", "_")
            ] = {
              id_dist_eval: el?.id,
              nota_final: el?.nota_final,
              aprobado: el?.aprobado,
              asistencias: el?.asistencias,
              porcentaje_de_asistencia: el?.porcentaje_de_asistencia,
              faltas: el?.faltas,
              _all: el,
            };
            return acum;
          }, {});

          return {
            id_dist_estu: estudiante.id,
            grupo: identificador,
            url_dist_estu: estudiante.url,
            numero_de_control,
            nombre: `${apellido_paterno} ${apellido_materno} ${first_name}`,
            apellido_paterno: apellido_paterno,
            apellido_materno: apellido_materno,
            first_name: first_name,
            ...new_notas,
          };
        });
      },
    }),
  }),
});

export const {
  useNotasEstudiantesQuery,
  useLazyNotasEstudiantesQuery,
  useGetEvaluacionesQuery,
  useLazyGetEvaluacionesQuery,
  useCreateEvaluacionesMutation,
  useEditEvaluacionesMutation,
  useDeleteEvaluacionesMutation,
  useLazyGetEvaluacionesByIdQuery,
} = evaluacionesApi;
