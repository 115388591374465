import * as React from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import MDTypography from "components/MDTypography";
import { styled, Tooltip } from "@mui/material";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

function not(a, b) {
  if (!Array.isArray(a) || !Array.isArray(b)) {
    return a;
  }

  const seen = new Set(b.map((x) => x.nombre));
  return a.filter((valueA) => !seen.has(valueA.nombre));
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.dark.focus,
}));

const GroupItems = styled("ul")({
  padding: 0,
});

/**
 * Componente TransferList generico para la seleccion de datos segun tablas relacionadas.
 *
 * @prop {array} data Arreglo de datos a mostrar en la lista izquierda.
 * @prop {array} rightData Arreglo de datos a mostrar en la lista derecha.
 * @prop {string} generalTitle Titulo amostrar encima de la tabla en el MDTypography.
 * @prop {function} setSubmittingData Funcion de control de estado del componente padre para enviar los datos seleccionados.
 **/

const styles = {
  height: 230,
  gap: "0.5rem",
  display: "flex",
  flexDirection: "column",
  bgcolor: "background.paper",
  overflow: "auto",
  scrollbarColor: "#6b6b6b #2b2b2b",
  "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
    borderRadius: "1rem",
  },
  "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
    borderRadius: 8,
    backgroundColor: "gray",
    minHeight: 24,
    border: "2px solid #2b2b2b",
  },
  "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
    backgroundColor: "#959595",
  },
  "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
    backgroundColor: "#959595",
  },
  "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#959595",
  },
  "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
    backgroundColor: "#2b2b2b",
  },
};

const TransferList = ({
  data,
  noTitle,
  generalTitle,
  setSubmittingData,
  rightData,
  edit = false,
  pagination,
  titleRight,
}) => {
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState(not(data, rightData) ?? []);
  const [right, setRight] = React.useState(rightData ?? []);
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  React.useEffect(() => {
    setSubmittingData(right);
  }, [right]);

  React.useEffect(() => {
    // Verifica si estamos en modo de edición
    const isEditMode = data.length > 0 && rightData.length > 0;

    // Verifica si estamos en modo de creación
    const isCreateMode = data.length > 0 && rightData.length === 0;

    if (isEditMode && (left.length === 0 || right.length === 0)) {
      // Los elementos en initialRight van directamente a rightList.
      setRight(rightData);

      // Filtramos los elementos de initialLeft que también estén en initialRight.
      const filteredLeft = data.filter(
        (leftItem) => !right.some((rightItem) => rightItem.id === leftItem.id)
      );
      setLeft(filteredLeft);
    } else if (isCreateMode && left.length === 0) {
      setLeft(data);
    }
  }, [data, rightData]);

  React.useEffect(() => {
    setLeft(not(data, rightData));
  }, [data]);

  const handleToggle = (value) => () => {
    if (edit) {
      if (checked.indexOf(value) === -1) {
        setChecked([value]);
      } else {
        setChecked([]);
      }
    } else {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];
      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      setChecked(newChecked);
    }
  };

  const handleCheckedRight = () => {
    if (edit) {
      if (leftChecked.length === 1 && right.length === 0) {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
      }
    } else {
      setRight(right.concat(leftChecked));
      setLeft(not(left, leftChecked));
      setChecked(not(checked, leftChecked));
    }
  };

  const handleCheckedLeft = () => {
    if (edit) {
      if (rightChecked.length === 1) {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
      }
    } else {
      setLeft(left.concat(rightChecked));
      setRight(not(right, rightChecked));
      setChecked(not(checked, rightChecked));
    }
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items?.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const reduceName = (name) => {
    if (name.length > 52) {
      return `${name.slice(0, 50)}...`;
    }
    return name;
  };

  const customList = (title, items = [], pagination, noBuscar) => {
    const [search, setSearch] = React.useState("");

    function handleChangeSearch(e) {
      setSearch(e.target.value);
    }

    const resultados =
      search.trim() === ""
        ? items
        : items.filter((objeto) => {
          const regex = new RegExp(search.trim(), "i"); // Creamos una expresión regular a partir de la cadena de búsqueda
          const valores = Object.values(objeto).join(" "); // Unimos todos los valores en una sola cadena para facilitar la búsqueda
          return regex.test(valores); // Devolvemos true si la cadena de búsqueda coincide con algún valor del objeto
        });

    return (
      <Card>
        {!noBuscar && (
          <MDInput
            id={`buscar-local ${title}`}
            variant="outlined"
            type="text"
            label="Buscar"
            value={search}
            onChange={handleChangeSearch}
            sx={{ mb: 1 }}
          />
        )}
        <CardHeader
          sx={{ px: 2, py: 1 }}
          avatar={
            <Checkbox
              onClick={handleToggleAll(items)}
              checked={
                numberOfChecked(items) === items?.length && items?.length !== 0
              }
              indeterminate={
                numberOfChecked(items) !== items?.length &&
                numberOfChecked(items) !== 0
              }
              disabled={items?.length === 0}
              inputProps={{
                "aria-label": "todos los elementos fueron seleccionados",
              }}
            />
          }
          title={title}
          subheader={`${numberOfChecked(items)}/${items?.length} seleccionados`}
        />
        <Divider />
        <List sx={styles} dense component="div" role="list">
          {Array.isArray(resultados) &&
            resultados?.map((value) => {
              const labelId = `transfer-list-all-item-${value}-label`;
              return (
                <ListItem
                  key={value.id}
                  role="listitem"
                  button
                  onClick={handleToggle(value)}
                >
                  <ListItemIcon sx={{ minWidth: "auto" }}>
                    <Checkbox
                      checked={checked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                      sx={{ padding: "3px 9px" }}
                    />
                  </ListItemIcon>
                  {value?.nombre.length > 52 ? (
                    <Tooltip title={value?.nombre} placement="top">
                      <ListItemText
                        id={labelId}
                        primary={reduceName(value?.nombre)}
                      />
                    </Tooltip>
                  ) : (
                    <ListItemText id={labelId} primary={value?.nombre} />
                  )}
                </ListItem>
              );
            })}
          <ListItem />
        </List>
        {pagination && pagination.showLoadMoreButton && (
          <MDButton
            onClick={pagination.loadMore}
            variant="gradient"
            color="light"
          >
            Cargar más
          </MDButton>
        )}
      </Card>
    );
  };

  return (
    <Grid container sx={{ height: "100%", mt: 3, mb: 5, p: 0.2 }}>
      {!noTitle && (
        <Grid item xs={12} sm={12} mb={2}>
          <MDTypography variant="h5" fontWeight="bold">
            {generalTitle ?? "Menú"}
          </MDTypography>
        </Grid>
      )}
      <Grid item xs={12} sm={12} justifyContent="space-between" mb={2}>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={5}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {customList("Opciones", left, pagination, true)}
          </Grid>
          <Grid
            item
            xs={12}
            sm={2}
            justifyContent={"center"}
            alignItems="center"
            display={"flex"}
            flexDirection={"column"}
          >
            <MDButton
              sx={{ my: 0.5, mx: 2 }}
              variant="gradient"
              color="dark"
              size="small"
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="mover a la derecha"
            >
              &gt;
            </MDButton>
            <MDButton
              sx={{ my: 0.5, mx: 2 }}
              variant="gradient"
              color="dark"
              size="small"
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="mover a la izquierda"
            >
              &lt;
            </MDButton>
          </Grid>
          <Grid item xs={12} sm={5}>
            {customList(titleRight ?? "A asignar", right, false, true)}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TransferList;
export { GroupHeader, GroupItems };
