import client from "config/axios";

const customFetchBase = async ({url, method, body, params, headers = {"Content-Type": "application/json",}}) => {
  try {
    const result = await client({url, method, data: body, params, headers});
    return {data: result.data};
  } catch (axiosError) {
    const err = axiosError.response || {data: axiosError.message};
    return {
      error: {
        status: err.status,
        data: err.data,
      },
    };
  }
};

export default customFetchBase;
