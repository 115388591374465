import { buildFiltersUrl, reshape_response } from "helpers/functions";
import customFetchBase from "../../principal/customFetchBase";
import { createApi } from "@reduxjs/toolkit/query/react";

export const estadoPorEstudianteApi = createApi({
  reducerPath: "estadoPorEstudianteApi",
  baseQuery: customFetchBase,
  tagTypes: ["EstadoPorEstudiante"],
  endpoints: (builder) => ({
    getEstadoPorEstudiante: builder.query({
      query: ({ filter_params }) => ({
        url: buildFiltersUrl(
          "/v1/api/distribuciondeestadodeestudiante/",
          filter_params
        ),
        method: "GET",
      }),
      providesTags: (result) =>
        Array.isArray(result)
          ? [
              ...result.map(({ id }) => ({
                type: "EstadoPorEstudiante",
                id,
              })),
              { type: "EstadoPorEstudiante", id: "LIST" },
            ]
          : [{ type: "EstadoPorEstudiante", id: "LIST" }],
      transformResponse: (response, meta, args) => {
        if (Array.isArray(response)) {
          return response?.map((el) => reshape_response(el, args.shape));
        } else {
          const resultsTransformed = response?.results?.map((el) =>
            reshape_response(el, args.shape)
          );
          return {
            results: resultsTransformed,
            count: response.count,
            next: response.next,
            previous: response.previous,
          };
        }
      },
    }),
    getEstadoPorEstudianteById: builder.query({
      query: (id) => ({
        url: `/v1/api/distribuciondeestadodeestudiante/${id}/`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [
        { type: "EstadoPorEstudiante", id },
      ],
    }),
    createEstadoPorEstudiante: builder.mutation({
      query: (data) => ({
        url: "/v1/api/distribuciondeestadodeestudiante/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "EstadoPorEstudiante", id: "LIST" }],
    }),
    deleteEstadoPorEstudiante: builder.mutation({
      query: (id) => ({
        url: `/v1/api/distribuciondeestadodeestudiante/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "EstadoPorEstudiante", id: "LIST" }],
    }),
    editEstadoPorEstudiante: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `/v1/api/distribuciondeestadodeestudiante/${id}/`,
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: (result, error, { id }) =>
        result
          ? [
              { type: "EstadoPorEstudiante", id },
              { type: "EstadoPorEstudiante", id: "LIST" },
            ]
          : [{ type: "EstadoPorEstudiante", id: "LIST" }],
    }),
  }),
});

export const {
  useGetEstadoPorEstudianteQuery,
  useLazyGetEstadoPorEstudianteQuery,
  useCreateEstadoPorEstudianteMutation,
  useEditEstadoPorEstudianteMutation,
  useDeleteEstadoPorEstudianteMutation,
  useLazyGetEstadoPorEstudianteByIdQuery,
} = estadoPorEstudianteApi;
