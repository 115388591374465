import { Navigate } from "react-router-dom";
import {
  BOLETAS_DETALLES_QR_URL,
  CURSOS_DETALLES_QR_URL,
  ESTUDIANTES_DETALLES_QR_URL,
  HOME_URL,
  LOGIN,
  RESET_PASSWORD,
  RESET_PASSWORD_CONFIRM,
  TRABAJADORES_DETALLES_QR_URL,
} from "../bases_url";
import SignInBasic from "../../layouts/authentication/sign-in/basic";
import ResetPassword from "../../layouts/authentication/reset-password/reset-password";
import NewPassword from "../../layouts/authentication/reset-password-confirm/reset-password-confirm";
import CursoDetallesQr from "../../layouts/escuela/data-tables/cursos/components/curso_detalles_qr";
import EstudianteDetallesQr from "../../layouts/estudiantes/qr/estudiantes_info-qr";
import TrabajadoresDetallesQr from "../../layouts/trabajadores/qr/trabajadores_info-qr";
import BoletasDetallesQr from "../../layouts/escuela/data-tables/grupos/components/boletas_detalles_qr";

export const publicRoutes = [
  {
    path: "*",
    element: <Navigate to={HOME_URL} />,
  },
  {
    path: LOGIN,
    element: <SignInBasic />,
  },
  {
    path: RESET_PASSWORD,
    element: <ResetPassword />,
  },
  {
    path: RESET_PASSWORD_CONFIRM,
    element: <NewPassword />,
  },
  {
    path: CURSOS_DETALLES_QR_URL,
    element: <CursoDetallesQr />,
  },
  {
    path: ESTUDIANTES_DETALLES_QR_URL,
    element: <EstudianteDetallesQr />,
  },
  {
    path: TRABAJADORES_DETALLES_QR_URL,
    element: <TrabajadoresDetallesQr />,
  },
  {
    path: BOLETAS_DETALLES_QR_URL,
    element: <BoletasDetallesQr />,
  },
];
