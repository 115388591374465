import { Grid, Icon, MenuItem } from "@mui/material";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import PhoneList from "examples/CategoriesListPhone/phone.list";
import { useState } from "react";
import Swal from "sweetalert2";
import { v4 as uuid } from "uuid";

const tipos = [
  {
    tipo: "Fijo",
  },
  {
    tipo: "Móvil",
  },
];
export const telefonoIcon = (tipo) => {
  switch (tipo) {
    case "Fijo":
      return "house";
    case "Móvil":
      return "phone_android";
    default:
      return "house";
  }
};

const telefonoInitialValue = {
  tipo: "",
  telefono: "",
  isCreated: true,
};

const TelefonosCRUD = ({ formData }) => {
  const { setFieldValue, values, formField, errors, setErrors } = formData;
  const { telefonos: telefonosF } = formField;
  const { telefonos: telefonosV } = values;
  const [isEditing, setIsEditing] = useState(false);
  const [telefonoS, setTelefono] = useState(telefonoInitialValue);

  const handleClickEdit = (e, id, description, name, isCreated) => {
    setTelefono({
      id: id,
      tipo: name,
      telefono: description,
      isCreated,
    });
    setIsEditing(true);
  };

  const handleChange = (e) => {
    setTelefono({
      ...telefonoS,
      [e.target.name]: e.target.value,
    });
    setErrors({});
  };

  const handleBlur = () => {
    if (telefonoS.telefono.length === 10) setErrors({});
    else
      setErrors({
        telefono:
          "El número de teléfono debe tener una longitud mínima de 10 caracteres",
      });
  };

  const handleClickRemove = (e, id) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: `¡Se eliminará el número de teléfono seleccionado!`,
      icon: "warning",
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Eliminar",
    }).then((result) => {
      if (result.isConfirmed) {
        setFieldValue(
          telefonosF.name,
          telefonosV.filter((el) => el.id !== id)
        );
      }
    });
  };

  const handleClick = () => {
    const telefonoToAdd = {
      id: uuid(),
      icon: telefonoIcon(telefonoS.tipo),
      ...telefonoS,
    };

    // Verificar si el número de teléfono ya existe en el arreglo
    const telefonoExists = telefonosV.some(
      (t) => t.description === telefonoS.telefono
    );

    // Si el número de teléfono no existe, agregarlo al arreglo
    if (!telefonoExists && !isEditing) {
      setFieldValue(telefonosF.name, [telefonoToAdd, ...telefonosV]);
      if (telefonoToAdd.telefono.length > 10) {
        setErrors({
          telefono: "El número de teléfono no debe tener más de 10 caracteres",
        });
        return;
      }
    } else if (!telefonoExists && isEditing) {
      if (telefonoToAdd.telefono.length > 10) {
        setErrors({
          telefono: "El número de teléfono no debe tener más de 10 caracteres",
        });
        return;
      }
      setFieldValue(
        telefonosF.name,
        telefonosV.map((el) =>
          el.id === telefonoS.id && isEditing ? telefonoToAdd : el
        )
      );
      setIsEditing(false);
    } else {
      // Si el número de teléfono ya existe, mostrar un mensaje de error o tomar alguna otra acción apropiada
      setErrors({ telefono: "El número de teléfono ya existe" });
      setFieldValue(telefonosF.name, telefonosV);
    }

    setErrors({});
    setTelefono(telefonoInitialValue);
  };

  return (
    <MDBox>
      {errors.telefono && (
        <MDAlert color="error">
          <Icon fontSize="small">error</Icon>&nbsp;
          <MDTypography variant={"h6"} fontWeight="medium" ml={2} color="light">
            {errors.telefono}
          </MDTypography>
        </MDAlert>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={5}>
          <MDBox mb={1.5}>
            <MDInput
              select
              variant="standard"
              label="Tipo"
              name={"tipo"}
              value={telefonoS.tipo}
              placeholder={"Tipo de teléfono"}
              onChange={(e) => handleChange(e)}
              fullWidth
            >
              <MenuItem key={0} value={""} disabled>
                No Seleccionado
              </MenuItem>
              {tipos.map((el) => (
                <MenuItem key={el.tipo} value={el.tipo}>
                  {el.tipo}
                </MenuItem>
              ))}
            </MDInput>
          </MDBox>
        </Grid>
        <Grid item xs={12} sm={5}>
          <MDBox mb={1.5}>
            <MDInput
              type={"tel"}
              label={"Teléfono"}
              name={"telefono"}
              variant="standard"
              onBlur={handleBlur}
              placeholder={"Número de teléfono"}
              value={telefonoS.telefono}
              onChange={handleChange}
              onInput={(e) => {
                let value = ("" + e.target.value).replace(/[^(\d|\.)]/g, "");
                e.target.value =
                  value === "" ? "" : isNaN(Number(value)) ? "" : Number(value);
              }}
              fullWidth
              error={
                (telefonoS.telefono.length > 10 ||
                  telefonoS.telefono.length < 10) &&
                errors.telefono
              }
              success={
                telefonoS.telefono.length === 10 &&
                telefonoS.telefono.length !== ""
              }
            />
          </MDBox>
        </Grid>
        <Grid item xs={12} sm={2}>
          <MDBox mb={1.5}>
            <MDButton
              disabled={
                telefonoS.tipo === "" ||
                telefonoS.telefono === "" ||
                errors.telefono !== undefined
              }
              type="button"
              variant="outlined"
              color="info"
              onClick={handleClick}
            >
              {isEditing ? "Editar" : "Agregar"}
            </MDButton>
          </MDBox>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item mb={3} xs={12} sm={12}>
          <PhoneList
            title="Telefonos Actuales"
            categories={telefonosV}
            handleClickRemove={handleClickRemove}
            handleClickEdit={handleClickEdit}
          />
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default TelefonosCRUD;
