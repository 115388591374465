import * as React from "react";
import PropTypes from "prop-types";
import QrScan from "./components/QrScan/qr_scan";
import { Grid } from "@mui/material";
import MDButton from "../../components/MDButton";
import ModalBase from "components/ModalBase/modal_base";

const ModalQr = ({ open, handleClose }) => {
  return (
    <ModalBase open={open} handleClose={handleClose}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} lg={8}>
          <QrScan />
        </Grid>
      </Grid>
      <MDButton
        type="button"
        width="100%"
        variant="gradient"
        color="dark"
        onClick={() => handleClose()}
      >
        cerrar
      </MDButton>
    </ModalBase>
  );
};

ModalQr.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ModalQr;
