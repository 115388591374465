import * as React from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import no_foto from "assets/images/not_found_image.jpg";
import {useGetAsignaturasPorProfesorQuery} from "services";
import DataTable from "examples/Tables/DataTable";
import ComponentsPrivatization from "config/component_privatization";
import {COORDINADOR_DOCENTE} from "roles";
import {CircularProgress, Grid, Icon, Tooltip} from "@mui/material";
import {style_icon} from "helpers/global_vars";

const RegistroShow = ({rowData}) => {
  const [tipoContrato, setTipoContrato] = React.useState("");
  React.useEffect(() => {
    if (rowData.resourcetype.includes("Profesor")) {
      setTipoContrato(true);
    } else {
      setTipoContrato(false);
    }
  }, [rowData]);

  const {data: asig_por_prof, isLoading: isLoadingAP} =
    useGetAsignaturasPorProfesorQuery(
      {
        filter_params: {
          profesor: rowData.resourcetypeFull[0].id,
          query:
            "{id,url,distribucion_grupo{grupo{identificador}},asignatura{nombre,carrera,ciclo_interno}}",
        },
        shape: {
          id: "id",
          url: "url",
          grupo_ident: "distribucion_grupo.grupo.identificador",
          asignatura_name: "asignatura.nombre",
          carrera: "asignatura.carrera",
          ciclo_interno: "asignatura.ciclo_interno",
        },
      },
      {
        refetchOnReconnect: true,
        skip: !tipoContrato,
      }
    );
  const tableAsignaturaPorProfesor = {
    columns: [
      {Header: "Carrera", accessor: "carrera"},
      {Header: "Ciclo Interno", accessor: "ciclo_interno"},
      {Header: "Grupo", accessor: "grupo_ident"},
      {Header: "Asignatura", accessor: "asignatura_name"},
      {
        Header: "Acciones",
        accessor: "actions",
        width: "1%",
        Cell: ({row}) => {
          const {original} = row;

          return (
            <MDBox display="flex">
              <ComponentsPrivatization permitted={COORDINADOR_DOCENTE}>
                <MDBox px={0.2}>
                  <Tooltip placement="top" title="Eliminar">
                    <Icon
                      style={style_icon}
                      onClick={() => handleClickEraseAxP(original)}
                      fontSize="small"
                    >
                      remove_circle
                    </Icon>
                  </Tooltip>
                </MDBox>
              </ComponentsPrivatization>
            </MDBox>
          );
        },
      },
    ],
    rows: asig_por_prof ?? [],
  };
  return (
    <MDBox pt={1} pb={1}>
      <MDBox display="flex" alignItems={"center"}>
        <MDBox
          component="img"
          src={rowData.foto !== null ? rowData.foto : no_foto}
          borderRadius="100%"
          mx={2}
          p={2}
          width={"150px"}
          height={"150px"}
        />
        <MDBox display="flex" alignItems="flex-start" flexDirection="column">
          <MDTypography variant="h5" fontWeight="regular" marginTop="15px">
            {`${rowData.apellido_paterno} ${rowData.apellido_materno} ${rowData.nombre}`}
          </MDTypography>

          <Grid container spacing={1} pr={2} py={3}>
            <Grid item xs={12} mb={1} sm={6}>
              <MDInput
                variant="outlined"
                id="correo-read-only"
                label="Correo Institucional:"
                defaultValue={rowData.email}
                placeholder="Ej: ejemplo@ejemplo.com"
                InputProps={{
                  readOnly: true,
                }}
                style={{width: "100%"}}
              />
            </Grid>
            <Grid item xs={12} mb={1} sm={6}>
              <MDInput
                variant="outlined"
                id="movil-read-only"
                label="T Móvil:"
                defaultValue={
                  rowData.telefonos.find((e) => e.tipo === "Móvil") !==
                  undefined
                    ? `${
                      rowData.telefonos.find((e) => e.tipo === "Móvil")
                        .telefono
                    }`
                    : ""
                }
                placeholder="Ej: 12345-67890"
                InputProps={{
                  readOnly: true,
                }}
                style={{width: "100%"}}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      {tipoContrato && (
        <MDBox
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            px: 2,
            py: 1,
          }}
        >
          <MDTypography variant={"h5"} fontWeight={"medium"} pl={4}>
            Asignaturas que imparte:
          </MDTypography>
          <MDBox display="flex" justifyContent={"center"} alignItems={"center"}>
            {isLoadingAP ? (
              <CircularProgress sx={{marginBottom: "3rem"}} color="inherit"/>
            ) : (
              <DataTable
                showTotalEntries={false}
                entriesPerPage={false}
                table={tableAsignaturaPorProfesor}
              />
            )}
          </MDBox>
        </MDBox>
      )}
    </MDBox>
  );
};

export default RegistroShow;
