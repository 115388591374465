/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import "flatpickr/dist/themes/material_green.css";
// NewUser page components
import FormField from "components/FormField";
import { MenuItem } from "@mui/material";
import { useGetFirmantesQuery, useGetModalidadesQuery } from "services";

function CursoInfo({ formData }) {
  const { formField, values, errors, touched, setFieldValue } = formData;

  const {
    nombre,
    nombre_corto,
    firmante,
    modalidad,
    duracion,
    expedicion,
    fecha_inicio,
    fecha_fin,
    localizacion,
  } = formField;

  const { data: firmantes } = useGetFirmantesQuery(
    {
      filter_params: {
        query: "{id,url,user{first_name,apellido_paterno,apellido_materno}}",
      },
      shape: {
        id: "id",
        url: "url",
        nombre: "user.first_name",
        apellido_paterno: "user.apellido_paterno",
        apellido_materno: "user.apellido_materno",
      },
    },
    { refetchOnReconnect: true, refetchOnMountOrArgChange: true }
  );

  const { data: modalidades } = useGetModalidadesQuery();

  const {
    fecha_inicio: fecha_inicioV,
    fecha_fin: fecha_finV,
    expedicion: expedicionV,
    duracion: duracionV,
    nombre: nombreV,
    nombre_corto: nombre_cortoV,
    firmante: firmanteV,
    modalidad: modalidadV,
    localizacion: localizacionV,
  } = values;

  return (
    <MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              type={nombre.type}
              label={nombre.label}
              name={nombre.name}
              value={nombreV}
              error={errors.nombre && touched.nombre}
              success={nombreV?.length > 0 && !errors.nombre}
              onInput={(e) =>
                (e.target.value = ("" + e.target.value).toUpperCase())
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <FormField
              type={nombre_corto.type}
              label={nombre_corto.label}
              name={nombre_corto.name}
              value={nombre_cortoV}
              error={errors.nombre_corto && touched.nombre_corto}
              success={nombre_cortoV?.length > 0 && !errors.nombre_corto}
              onInput={(e) =>
                (e.target.value = ("" + e.target.value).toUpperCase())
              }
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              select
              label={firmante.label}
              name={firmante.name}
              value={firmanteV}
              error={errors.firmante && touched.firmante}
              success={firmanteV?.length > 0 && !errors.firmante}
            >
              <MenuItem value="">Seleccione</MenuItem>
              {firmantes?.map((el) => (
                <MenuItem
                  key={el?.id}
                  value={el?.url}
                >{`${el?.nombre} ${el?.apellido_paterno} ${el?.apellido_materno}`}</MenuItem>
              ))}
            </FormField>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              select
              label={modalidad.label}
              name={modalidad.name}
              value={modalidadV}
              error={errors.modalidad && touched.modalidad}
              success={modalidadV?.length > 0 && !errors.modalidad}
            >
              <MenuItem value="">Seleccione</MenuItem>
              {modalidades?.map((el) => (
                <MenuItem key={el.id} value={el.url}>{`${el.nombre}`}</MenuItem>
              ))}
            </FormField>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <FormField
              type={duracion.type}
              label={duracion.label}
              name={duracion.name}
              value={duracionV}
              error={errors.duracion && touched.duracion}
              success={duracionV?.length > 0 && !errors.duracion}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormField
              type={fecha_inicio.type}
              label={fecha_inicio.label}
              name={fecha_inicio.name}
              value={fecha_inicioV}
              error={errors.fecha_inicio && touched.fecha_inicio}
              success={fecha_inicioV?.length > 0 && !errors.fecha_inicio}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              type={fecha_fin.type}
              label={fecha_fin.label}
              name={fecha_fin.name}
              value={fecha_finV}
              error={errors.fecha_fin && touched.fecha_fin}
              success={fecha_finV?.length > 0 && !errors.fecha_fin}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={expedicion.type}
              label={expedicion.label}
              name={expedicion.name}
              value={expedicionV}
              error={errors.expedicion && touched.expedicion}
              success={expedicionV?.length > 0 && !errors.expedicion}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={localizacion.type}
              label={localizacion.label}
              name={localizacion.name}
              value={localizacionV}
              error={errors.localizacion && touched.localizacion}
              success={localizacionV?.length > 0 && !errors.localizacion}
              onInput={(e) =>
                (e.target.value = ("" + e.target.value).toUpperCase())
              }
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for UserInfo
CursoInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default CursoInfo;
