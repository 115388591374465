import { examenApi } from "../view/examen.service";

export const editExamen = examenApi.injectEndpoints({
  endpoints: (builder) => ({
    editExamen: builder.mutation({
      query: ({ id, ...rest }) => {
        rest.nombre = rest.nombre?.trim();

        return {
          url: `/v1/api/examenentidad/${id}/`,
          method: "PATCH",
          body: rest,
        };
      },
      invalidatesTags: (result, error, { id }) =>
        result
          ? [
              { type: "Examenes", id },
              { type: "Examenes", id: "LIST" },
            ]
          : [{ type: "Examenes", id: "LIST" }],
    }),
  }),
});

export const { useEditExamenMutation } = editExamen;
