import * as React from "react";
import MDBox from "components/MDBox";
import { Link, useParams } from "react-router-dom";
import { Card, CircularProgress, Grid, Icon, Tooltip } from "@mui/material";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import {
  useGetAsignaturasPorProfesorByIdQuery,
  useGetFechaEvaluacionQuery,
  useNotasEstudiantesQuery,
} from "services";
import ComponentsPrivatization, {
  removeColumns,
} from "config/component_privatization";
import { style_icon } from "helpers/global_vars";
import { PROFESOR } from "roles";
import ModalNotasPdf from "components/PDF/modal_calificacion_pdf";
import ModalUpload from "components/ModaUpload/modal_upload";
import CalificarRegularizacionModal from "components/ModalCalificar/calificar_regularizacion_1.modal";
import { paridad } from "../ordinaria/calificaciones_show";
import useModalHandler from "hooks/useModalHandler";

function isEmpty(obj) {
  if (!obj) return true;
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

const SecondRegularizacionShow = () => {
  //State del modal para calificacar
  const {
    open: openCalificar,
    handleOpen: handleOpenCalificar,
    handleClose: handleCloseCalificar,
  } = useModalHandler();

  //State del modal para calificacar
  const {
    open: openPDF,
    handleOpen: handleOpenPDF,
    handleClose: handleClosePDF,
  } = useModalHandler();

  //State del modal para calificacar
  const {
    open: openUpload,
    handleOpen: handleOpenUpload,
    handleClose: handleCloseUpload,
  } = useModalHandler();

  const [editEvaluaciones, setEditEvaluaciones] = React.useState(undefined);

  const [estXeval, set_estXeval] = React.useState(undefined);

  const { cicle_name, profesor, grupo, semestre } = useParams();

  const { data: fechas, isLoading: isLoadingF } = useGetFechaEvaluacionQuery({
    filter_params: {
      profesor: profesor,
      examen__nombre: "Regularización 2",
      query: "{inicio,fin,abierto,accesible}",
    },
  });

  const {
    data: notas,
    isSuccess,
    isLoading: isLoadingN,
  } = useNotasEstudiantesQuery(
    {
      id: profesor,
      filter_params: {
        estudiante__estudiante__estados: "Activo,Baja Temporal",
        query:
          "{estudiante{id,url,distribucion_grupo{grupo{identificador}}, estudiante{numero_de_control, user{first_name, apellido_paterno, apellido_materno}}}, notas{id,nota_final,aprobado,asistencias,porcentaje_de_asistencia,faltas,examen{nombre}}}",
      },
    },
    {
      refetchOnReconnect: true,
    }
  );

  const notasReg2 =
    notas?.filter(
      (el) => !el.ordinario.aprobado && !el.regularización_1?.aprobado
    ) ?? [];

  //TODO: agregar query a getAsignatudas por profesor query
  const { data: distProf } = useGetAsignaturasPorProfesorByIdQuery(profesor);

  const data = {
    columns: [
      {
        Header: "Nombre",
        accessor: "nombre",
        width: "60%",
        Cell: ({ row }) => (
          <MDTypography
            textGradient
            fontWeight="regular"
            variant="h6"
            color={
              row?.original?.regularización_2?.aprobado ? "success" : "error"
            }
          >
            {row?.original?.nombre ?? "---"}
          </MDTypography>
        ),
      },
      {
        Header: "Ordinaria",
        accessor: "nota_final",
        Cell: ({ row }) => (
          <MDTypography
            textGradient
            fontWeight="regular"
            variant="h6"
            color={
              row.original.regularización_2?.aprobado ? "success" : "error"
            }
          >
            {row.original.ordinario?.nota_final ?? "---"}
          </MDTypography>
        ),
      },
      {
        Header: "Regularizacion 1",
        accessor: "regularización_1",
        Cell: ({ row }) => (
          <MDTypography
            textGradient
            fontWeight="regular"
            variant="h6"
            color={
              row.original.regularización_2?.aprobado ? "success" : "error"
            }
          >
            {row.original.regularización_1?.nota_final !== undefined
              ? row.original.regularización_1?.nota_final === 0
                ? "NP"
                : row.original.regularización_1?.nota_final
              : "---"}
          </MDTypography>
        ),
      },
      {
        Header: "Regularizacion 2",
        accessor: "regularización_2",
        Cell: ({ row }) => (
          <MDTypography
            textGradient
            fontWeight="regular"
            variant="h6"
            color={
              row.original.regularización_2?.aprobado ? "success" : "error"
            }
          >
            {row.original.regularización_2?.nota_final !== undefined
              ? row.original.regularización_2?.nota_final === 0
                ? "NP"
                : row.original.regularización_2?.nota_final
              : "---"}
          </MDTypography>
        ),
      },
      {
        Header: "Acciones",
        accessor: "actions",
        width: "5%",
        Cell: ({ row }) => {
          const { original } = row;
          return (
            <MDBox display="flex">
              {isEmpty(original.regularización_2) ? (
                <ComponentsPrivatization permitted={PROFESOR}>
                  <MDBox px={0.2}>
                    <Tooltip
                      placement="top"
                      title="Asignar nota al estudiante en esta regularización"
                    >
                      <Icon
                        style={style_icon}
                        onClick={() => handleClickCalificar(original)}
                        fontSize="small"
                      >
                        assignment_ind
                      </Icon>
                    </Tooltip>
                  </MDBox>
                </ComponentsPrivatization>
              ) : (
                <ComponentsPrivatization permitted={PROFESOR}>
                  <MDBox px={0.2}>
                    <Tooltip placement="top" title="Editar la calificación">
                      <Icon
                        style={style_icon}
                        onClick={() => handleClickCalificar(original)}
                        fontSize="small"
                      >
                        edit
                      </Icon>
                    </Tooltip>
                  </MDBox>
                </ComponentsPrivatization>
              )}
            </MDBox>
          );
        },
      },
    ],
    rows: notasReg2,
  };

  const handleClickCalificar = React.useCallback(
    async (elem) => {
      try {
        if (!isEmpty(elem.regularización_2)) setEditEvaluaciones(elem);
        else setEditEvaluaciones(undefined);
        set_estXeval(
          notas?.filter((e) => e.url_dist_estu === elem.url_dist_estu)[0]
        );
        handleOpenCalificar();
      } catch (err) {
        console.log(err);
      }
    },
    [handleOpenCalificar]
  );

  return (
    <>
      {distProf && (
        <CalificarRegularizacionModal
          open={openCalificar}
          handleClose={handleCloseCalificar}
          distribucion_profesor={distProf?.url}
          distribucion_estudiante={estXeval}
          estudiantes={notas?.filter(
            (el) =>
              !el.ordinario.aprobado &&
              !el.regularización_1?.aprobado &&
              el.regularización_2?.aprobado === undefined
          )}
          edit={editEvaluaciones}
          regtwo={true}
        />
      )}
      {distProf && (
        <ModalNotasPdf
          open={openPDF}
          handleClose={handleClosePDF}
          profesor={profesor}
          semestre={paridad(semestre)}
          ordinaria={"3"}
          cicle_name={cicle_name}
        />
      )}
      {distProf && (
        <ModalUpload
          open={openUpload}
          handleClose={handleCloseUpload}
          id={distProf?.id}
          ordinaria={"3"}
        />
      )}
      <MDBox pt={6} pb={3}>
        <Card>
          <Grid container>
            <Grid item xs={12} sx={{ mx: "auto" }}>
              <MDBox>
                <Grid
                  container
                  display="flex"
                  justifyContent={"space-between"}
                  alignItems={"baseline"}
                  m={2}
                  px={5}
                  py={3}
                >
                  <Grid item display={"flex-col"} justifyContent="flex-start">
                    <Grid item xs={12} sm={12} mb={1}>
                      <MDBox display="flex" gap={1} alignItems="baseline">
                        <MDTypography
                          variant="h6"
                          fontWeight="bold"
                          color="dark"
                        >
                          CARRERA:
                        </MDTypography>
                        <MDTypography
                          variant="h6"
                          fontWeight="regular"
                          color="secondary"
                        >
                          {distProf?.distribucion_grupo.ciclo_interno.carrera
                            .nombre ?? ""}
                        </MDTypography>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} sm={12} mb={1}>
                      <MDBox display="flex" gap={1} alignItems="baseline">
                        <MDTypography
                          variant="h6"
                          fontWeight="bold"
                          color="dark"
                        >
                          GRUPO:
                        </MDTypography>
                        <MDTypography
                          variant="h6"
                          fontWeight="regular"
                          color="secondary"
                        >
                          {grupo ?? ""}
                        </MDTypography>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} sm={12} mb={1}>
                      <MDBox display="flex" gap={1}>
                        <MDTypography
                          variant="h6"
                          fontWeight="bold"
                          color="dark"
                        >
                          ASIGNATURA:
                        </MDTypography>
                        <MDTypography
                          variant="h6"
                          fontWeight="regular"
                          color="secondary"
                        >
                          {distProf?.asignatura.nombre ?? ""}
                        </MDTypography>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} sm={12} mb={1}>
                      <MDBox display="flex" gap={1} alignItems="baseline">
                        <MDTypography
                          variant="h6"
                          fontWeight="bold"
                          color="dark"
                        >
                          PROFESOR:
                        </MDTypography>
                        <MDTypography
                          variant="h6"
                          fontWeight="regular"
                          color="secondary"
                        >
                          {distProf?.profesor.user
                            ? `${distProf?.profesor.user.apellido_paterno} ${distProf?.profesor.user.apellido_materno} ${distProf?.profesor.user.first_name}`
                            : ""}
                        </MDTypography>
                      </MDBox>
                    </Grid>
                  </Grid>

                  <Grid item gap={2} display={"flex"}>
                    <ComponentsPrivatization permitted={PROFESOR}>
                      {notas?.filter(
                        (el) => el?.regularización_2?.aprobado !== undefined
                      ).length ===
                        notas?.filter(
                          (el) =>
                            !el.ordinario.aprobado &&
                            !el?.regularización_1.aprobado
                        )?.length &&
                        notas?.length !== 0 &&
                        isSuccess &&
                        !distProf?.acta_r2 && (
                          <MDBox display="flex" gap={1}>
                            <MDButton
                              variant="gradient"
                              color="dark"
                              size="medium"
                              onClick={() => handleOpenUpload()}
                              circular
                              iconOnly
                            >
                              <Tooltip
                                placement="top"
                                title="Subir acta firmada en formato pdf, luego de subir el acta no podrá editar más las notas de los estudiantes"
                              >
                                <Icon>upload</Icon>
                              </Tooltip>
                            </MDButton>
                            <MDButton
                              variant="gradient"
                              color="dark"
                              size="medium"
                              onClick={() => handleOpenPDF()}
                              circular
                              iconOnly
                            >
                              <Tooltip
                                placement="top"
                                title="Imprimir Acta, podrá ver el pdf de la regularización correspondiente al grupo"
                              >
                                <Icon>print</Icon>
                              </Tooltip>
                            </MDButton>
                          </MDBox>
                        )}
                      {distProf?.acta_r2 && (
                        <MDButton
                          variant="gradient"
                          color="dark"
                          size="medium"
                          onClick={() =>
                            window.open(distProf.acta_r2, "_blank")
                          }
                          circular
                          iconOnly
                        >
                          <Tooltip
                            placement="top"
                            title="Ver acta firmada, mostrará el acta ya subida por el profesor al servidor"
                          >
                            <Icon>picture_as_pdf</Icon>
                          </Tooltip>
                        </MDButton>
                      )}
                    </ComponentsPrivatization>
                    <Link to={`/ciclos/${cicle_name}/calificaciones`}>
                      <MDButton variant="gradient" color="info" size="medium">
                        Atras
                      </MDButton>
                    </Link>
                  </Grid>
                </Grid>

                <MDBox mt={3} mb={1} ml={0.5}>
                  <MDBox
                    display="flex"
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    {isLoadingN || isLoadingF ? (
                      <CircularProgress
                        sx={{ marginBottom: "3rem" }}
                        color="inherit"
                      />
                    ) : (
                      <Grid item xs={12} sx={{ mx: "auto" }}>
                        <DataTable
                          indice
                          canSearch
                          table={removeColumns(
                            removeColumns(data, () => distProf?.acta_r2, [
                              "actions",
                            ]),
                            () =>
                              !JSON.parse(
                                sessionStorage.getItem("user")
                              ).groups.some((role) => role.name === PROFESOR),
                            ["actions"]
                          )}
                        />
                      </Grid>
                    )}
                  </MDBox>
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
        </Card>
      </MDBox>
    </>
  );
};

export default SecondRegularizacionShow;
