import "./styles/baja.css";

const BajaPdf = ({ lote }) => {
  const isoDate = lote.creacion;
  const date = new Date(isoDate);
  const dateString = date.toLocaleDateString("es-ES", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  let i = 1;

  const EstadoDelBien = () => {
    if (lote?.operaciones[0].activo.estado.nombre === "Buen estado") {
      return (
        <>
          <td>X</td>
          <td></td>
          <td></td>
        </>
      );
    } else if (lote?.operaciones[0].activo.estado.nombre === "Mal estado") {
      return (
        <>
          <td></td>
          <td>X</td>
          <td></td>
        </>
      );
    } else if (lote?.operaciones[0].activo.estado.nombre === "Regular estado") {
      return (
        <>
          <td></td>
          <td></td>
          <td>X</td>
        </>
      );
    } else {
      return (
        <>
          <td></td>
          <td></td>
          <td></td>
        </>
      );
    }
  };
  return (
    <div
      style={{
        marginLeft: "10px",
        marginRight: "10px",
        marginTop: "20px",
        height: "210mm",
      }}
    >
      <header className="title">
        <h1>Dirección de Almacenes, Inventarios, Bienes Muebles e Inmuebles</h1>
        <h1>Formato de bienes para baja</h1>
      </header>

      <section className="sectionBlock">
        <div className="block">
          <h2>Tipo de baja: robo( ) duplicado( ) siniestro( )</h2>
          <h2>activo fijo por inutilidad con ingreso al almacen( )</h2>
        </div>
      </section>

      <section className="solicitante">
        <table>
          <tbody>
            <tr>
              <td>
                Area administratica o plantel educativo solicitante: (direccion
                del plantel o area)
              </td>
              <td>cren "benito juarez"</td>
            </tr>
          </tbody>
        </table>
      </section>

      <section className="personal">
        <table className="personalTable">
          <tbody>
            <tr>
              <td>C.C.T:</td>
              <td className="value">
                {lote?.operaciones[0].activo.entidad.clave_del_centro}
              </td>
              <td>Fecha:</td>
              <td className="value">{dateString}</td>
            </tr>
            <tr>
              <td>MUNICIPIO:</td>
              <td className="value">
                {lote?.operaciones[0].activo.entidad.municipio}
              </td>
              <td>LOCALIDAD:</td>
              <td className="value">
                {lote?.operaciones[0].activo.entidad.localidad}
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <section className="inventory">
        <table>
          <tbody>
            <tr>
              <th className="number" rowSpan="2">
                No.
              </th>
              <th className="ninv" rowSpan="2">
                Clave y número de inventario
              </th>
              <th className="serie" rowSpan="2">
                Serie
              </th>
              <th className="description" rowSpan="2">
                Descripcion
              </th>
              <th colSpan="3">Estado del Bien</th>
            </tr>
            <tr>
              <th>Bueno</th>
              <th>regular</th>
              <th>malo</th>
            </tr>
            <tr>
              <td>{i++}</td>
              <td>
                {`${lote?.operaciones[0].activo.llave}/
                ${lote?.operaciones[0].activo.numero_de_inventario}`}
              </td>
              <td>{lote?.operaciones[0].activo.numero_de_serie}</td>
              <td>{lote?.operaciones[0].activo.descripcion}</td>
              {EstadoDelBien()}
            </tr>
          </tbody>
        </table>
      </section>

      <div
        className="footer"
        style={{
          position: "absolute",
          bottom: "0",
          right: "0",
          left: "0",
          margin: "auto",
          width: "205mm",
        }}
      >
        <table className="datos">
          <tbody>
            <tr>
              <td className="director">
                Director (A) del plantel o titular del area administrativa
                Nombre, firma y sello
              </td>
              <td className="vice">
                Vo.Bo. <br />
                NOMBRE, FIRMA Y SELLO DEL JEFE INMEDIATO
              </td>
              <td className="autorizo">
                L.C. nicolas cortes sánchez <br />
                autorizo <br />
                encargado de la dirección de almacenes, inventarios, bienes
                muebles e inmuebles
              </td>
              <td>nombre, firma y sello de recibido del almacenista</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BajaPdf;
