/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "new-grupo-escolar",
  formField: {
    identificador: {
      name: "identificador",
      label: "Identificador",
      type: "text",
      errorMsg: "El identificador es requerido.",
    },
    ciclo_interno: {
      name: "ciclo_interno",
      label: "Ciclo Interno",
      type: "text",
      errorMsg: "EL ciclo interno es obligatorio",
    },
    ciclo: {
      name: "ciclo",
      label: "Ciclo",
      type: "text",
      errorMsg: "El ciclo no puede quedar vacio",
    },
  },
};

export default form;
