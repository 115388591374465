// formik components
import { Form, Formik } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// NewCarrera page components
import AddCarrera from "./components/carrera-info";

// NewCarrera layout schemas for form and form feilds
import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import MDTypography from "components/MDTypography";
import { CARRERAS_LIST_URL } from "config/bases_url";
import {
  useCreateCarreraMutation,
  useCreateCicloInternoMutation,
  useEditCarreraMutation,
  useLazyGetCarreraByIdQuery,
  useLazyGetCiclosInternosQuery,
} from "services";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Icon,
} from "@mui/material";

import TabsCard from "components/TabsCard/tabs_card";
import TabContent from "./components/tab-content";
import { useDispatch, useSelector } from "react-redux";
import { addAsig, carrera_state, setCiclosI } from "slices";
import { CANCEL_NAVIGATION, useRedirectForm } from "hooks/useRedirectForm";
import { ErrorBundary } from "../../../../config/ErrorBundary/error-bundary";
import FormErrorHandler from "../../../../handlers/FormErrorHandler/form-error.handler";
import { getModifiedFields } from "helpers/functions";

function NewCarrera() {
  const { id } = useParams();
  const { formId, formField } = form;
  const currentValidation = validations[0];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { ciclos_internos: ciclos_internos_state } = useSelector(carrera_state);
  const [tabs, setTabs] = useState(ciclos_internos_state);

  const [
    editCarrera,
    {
      isSuccess: isSuccessEC,
      isLoading: isLoadingEC,
      error: errorEC,
      isError: isErrorEC,
    },
  ] = useEditCarreraMutation();
  const [getCarrerasById, { data: carrera }] = useLazyGetCarreraByIdQuery();
  const [
    createCarrera,
    {
      data: carrera_created,
      isSuccess: isSuccessCC,
      isLoading: isLoadingCC,
      error: errorCC,
      isError: isErrorCC,
    },
  ] = useCreateCarreraMutation();
  const [
    createCicloInterno,
    {
      isSuccess: isSuccessCCI,
      isLoading: isLoadingCCI,
      error: errorCCI,
      isError: isErrorCCI,
    },
  ] = useCreateCicloInternoMutation();
  const [getCiclosInternos, { isSuccess: isSuccessGCI }] =
    useLazyGetCiclosInternosQuery();

  useRedirectForm(
    isLoadingEC,
    isSuccessEC,
    isErrorEC,
    errorEC,
    "Carrera editada",
    CANCEL_NAVIGATION,
  );

  useRedirectForm(
    isLoadingCC,
    isSuccessCC,
    isErrorCC,
    errorCC,
    "Carrera creada",
    CANCEL_NAVIGATION,
  );

  useRedirectForm(
    isLoadingCCI,
    isSuccessCCI,
    isErrorCCI,
    errorCCI,
    "Ciclo interno creado",
    CANCEL_NAVIGATION,
  );

  const create_ciclo = async () => {
    if (carrera || isSuccessCC) {
      await createCicloInterno({
        numero_de_ciclo:
          ciclos_internos_state.length === 0
            ? tabs.length + 1
            : ciclos_internos_state.length + 1,
        carrera: isSuccessCC ? carrera_created.url : carrera.url,
      })
        .unwrap()
        .then((res) => {
          setTabs((prev) => [
            ...prev,
            {
              id: `${
                ciclos_internos_state.length === 0
                  ? tabs.length + 1
                  : ciclos_internos_state.length + 1
              }`,
              label: `${res.carrera.duracion.identificador} ${
                ciclos_internos_state.length === 0
                  ? tabs.length + 1
                  : ciclos_internos_state.length + 1
              }`,
              element: <TabContent ciclo_interno={res} />,
            },
          ]);
        });
    }
  };

  useEffect(() => {
    async function fillState() {
      if (id) {
        try {
          const res = await getCiclosInternos({
            filters: {
              carrera: id,
              ordering: "numero_de_ciclo",
              query:
                "{id,url,numero_de_ciclo,asignaturas,carrera{duracion{identificador}}}",
            },
            shape: {
              id: "id",
              url: "url",
              numero_de_ciclo: "numero_de_ciclo",
              asignaturas: "asignaturas",
              duracion: "carrera.duracion.identificador",
            },
          }).unwrap();

          const newArr = res.map((el, indx) => {
            if (el.asignaturas.length > 0) {
              el.asignaturas.map((asignatura) =>
                dispatch(
                  addAsig({
                    ciclo_interno_id: el.id,
                    asignatura,
                  }),
                ),
              );
            }

            return {
              id: `${indx}`,
              label: `${el.duracion} ${el.numero_de_ciclo}`,
              element: <TabContent ciclo_interno={el} />,
            };
          });
          setTabs(newArr);
          dispatch(setCiclosI(res));
        } catch (error) {
          console.error("Error al obtener los ciclos internos:", error);
        }
      }
    }

    fillState();
  }, []);

  const submitForm = async (values, actions) => {
    try {
      if (!id) {
        await createCarrera(values)
          .unwrap()
          .then(async (res) => {
            await createCicloInterno({
              numero_de_ciclo: ciclos_internos_state.length + 1,
              carrera: res.url,
            })
              .unwrap()
              .then((ci) => {
                setTabs([
                  ...tabs,
                  {
                    id: `${ciclos_internos_state.length + 1}`,
                    label: `${res.duracion.identificador} ${
                      ciclos_internos_state.length + 1
                    }`,
                    element: <TabContent ciclo_interno={ci} />,
                  },
                ]);
              });
          });
      } else {
        const modifiedFields = getModifiedFields(carrera, values);
        if (Object.keys(modifiedFields).length !== 0) {
          await editCarrera({ id: id, ...modifiedFields })
            .unwrap()
            .then((res) => {
              if (res.duracion) {
                setTabs((prev) =>
                  prev.map((el, ind) => ({
                    ...el,
                    label: `${res.duracion.identificador} ${
                      ciclos_internos_state.length + (ind + 1)
                    }`,
                  })),
                );
              }
            });
        }
      }
    } catch (error) {
      console.error(error);
      actions.setSubmitting(true);
    }
  };

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
  };

  return (
    <MDBox mb={20}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} lg={8}>
          <MDBox mt={1} mb={4} textAlign="center">
            <MDBox mb={0.5}>
              <MDTypography variant="h3" fontWeight="bold">
                Carreras
              </MDTypography>
            </MDBox>
            <MDTypography variant="h5" fontWeight="regular" color="secondary">
              {!id
                ? "Introduzca la información relacionada a la carrera por agregar"
                : `Edite la información relacionada a la carrera: ${carrera?.nombre}`}
            </MDTypography>
          </MDBox>
          <Card sx={{ height: "100%", padding: "0.5rem", gap: 0.5 }}>
            <Accordion defaultExpanded sx={{ borderRadius: "5px" }}>
              <AccordionSummary
                expandIcon={<Icon fontSize="medium">expand_more</Icon>}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <MDTypography
                  variant={"h6"}
                  fontWeight="bold"
                  color="secondary"
                >
                  Información de la carrera
                </MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <Formik
                  initialValues={initialValues}
                  validationSchema={currentValidation}
                  onSubmit={handleSubmit}
                >
                  {({ values, errors, touched, setFieldValue, isValid }) => {
                    useEffect(() => {
                      if (id) {
                        getCarrerasById(id)
                          .unwrap()
                          .then((res) => {
                            setFieldValue(
                              formField.nombre.name,
                              res?.nombre,
                              true,
                            );
                            setFieldValue(
                              formField.modalidad.name,
                              res?.modalidad,
                              true,
                            );
                            setFieldValue(
                              formField.identificador.name,
                              res?.identificador,
                              true,
                            );
                            setFieldValue(formField.rvoe.name, res?.rvoe, true);
                            setFieldValue(
                              formField.calificacion_maxima.name,
                              res?.calificacion_maxima,
                              true,
                            );
                            setFieldValue(
                              formField.calificacion_minima.name,
                              res?.calificacion_minima,
                              true,
                            );
                            setFieldValue(
                              formField.calificacion_aprobatoria.name,
                              res?.calificacion_aprobatoria,
                              true,
                            );
                            setFieldValue(
                              formField.fecha_expedicion.name,
                              res?.fecha_expedicion,
                              true,
                            );
                            setFieldValue(
                              formField.nivel.name,
                              res?.nivel.url,
                              true,
                            );
                            setFieldValue(
                              formField.duracion.name,
                              res?.duracion.url,
                              true,
                            );
                            setFieldValue(
                              formField.plan_de_estudio.name,
                              res?.plan_de_estudio,
                              true,
                            );
                          });
                      }
                    }, [id]);

                    return (
                      <ErrorBundary renderOnError={() => <FormErrorHandler />}>
                        <Form id={formId} autoComplete="off">
                          <Card sx={{ height: "100%" }}>
                            <MDBox p={3}>
                              <MDBox>
                                <AddCarrera
                                  isDisabled={isSuccessCC && !id}
                                  formData={{
                                    values,
                                    touched,
                                    formField,
                                    errors,
                                  }}
                                />
                                <MDBox
                                  mt={2}
                                  width="100%"
                                  display="flex"
                                  justifyContent="space-between"
                                >
                                  <MDButton
                                    onClick={() => {
                                      navigate(CARRERAS_LIST_URL);
                                    }}
                                    variant="gradient"
                                    color="primary"
                                  >
                                    cancelar
                                  </MDButton>
                                  <MDButton
                                    disabled={!isValid || isSuccessCC}
                                    type="submit"
                                    variant="gradient"
                                    color="dark"
                                  >
                                    {!id ? "aceptar" : "editar"}
                                  </MDButton>
                                </MDBox>
                              </MDBox>
                            </MDBox>
                          </Card>
                        </Form>
                      </ErrorBundary>
                    );
                  }}
                </Formik>
              </AccordionDetails>
            </Accordion>
            <Accordion
              disabled={!isSuccessCC && !carrera}
              sx={{ borderRadius: "5px" }}
            >
              <AccordionSummary
                expandIcon={<Icon fontSize="medium">expand_more</Icon>}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <MDTypography
                  variant={"h6"}
                  fontWeight="bold"
                  color="secondary"
                >
                  Ciclos Internos y Asignaturas
                </MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <TabsCard
                  tabs={tabs}
                  initial_tab={
                    isSuccessGCI && id
                      ? String(tabs.length + 1)
                      : String(ciclos_internos_state.length + 1)
                  }
                  onAddTab={create_ciclo}
                />
              </AccordionDetails>
            </Accordion>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default NewCarrera;
