/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewProduct page components
import FormField from "components/FormField";
import { useSelector } from "react-redux";
import { admin_state } from "slices";
import {
  useGetAdqsQuery,
  useGetTiposQuery,
  useGetUnidadesQuery,
} from "services";
import CurrencyInput from "react-currency-input-field";
import { useEffect, useState } from "react";

function ProductInfo({ formData }) {
  const { formField, values, errors, touched, handleChange, setFieldValue } =
    formData;
  const {
    cantidad,
    proveedor,
    unidad_de_medida,
    numero_de_factura,
    valor,
    metodo_de_adquisicion,
    tipo,
    fecha_de_facturacion,
    fecha_cedula,
  } = formField;
  const {
    cantidad: cantidadV,
    proveedor: proveedorV,
    numero_de_factura: numero_de_facturaV,
    metodo_de_adquisicion: metodo_de_adquisicionV,
    valor: valorV,
    resourcetype: resourcetypeV,
    tipo: tipoV,
    fecha_de_facturacion: fecha_de_facturacionV,
    fecha_cedula: fecha_cedulaV,
    unidad_de_medida: unidad_de_medidaV,
  } = values;

  const { data: adqs } = useGetAdqsQuery(
    {
      filter_params: {
        query: "{id,nombre,url}",
      },
      shape: {
        id: "id",
        nombre: "nombre",
        url: "url",
      },
    },
    {
      refetchOnReconnect: true,
    },
  );

  const { data: unidades } = useGetUnidadesQuery(
    {
      filter_params: {
        query: "{id,nombre,url}",
      },
      shape: {
        id: "id",
        nombre: "nombre",
        url: "url",
      },
    },
    {
      refetchOnReconnect: true,
    },
  );

  const { data: tipos } = useGetTiposQuery(
    {
      filter_params: {
        query: "{id,nombre,url,base}",
      },
      shape: {
        id: "id",
        nombre: "nombre",
        url: "url",
        base: "base",
      },
    },
    {
      refetchOnReconnect: true,
    },
  );

  const { clone } = useSelector(admin_state);

  const [amount, setAmount] = useState(valorV);

  useEffect(() => {
    if (!amount.isNaN) {
      setFieldValue(valor.name, amount);
    }
    if (/\,/g.test(amount)) {
      setAmount(amount.replace(",", ""));
    }
  }, [amount]);

  const handleOnChange = (event) => {
    const currentValue = event.target.value;

    const newValue = currentValue.replace(/[^0-9.]/g, "");

    if (!isNaN(newValue)) {
      setAmount(newValue);
    }

    event.target.value = newValue;
  };

  const handleMoneyBlur = () => {
    let formattedMoney = parseFloat(amount).toFixed(2);
    if (!formattedMoney.includes(".")) {
      formattedMoney += ".00";
    } else if (formattedMoney.split(".")[1].length === 1) {
      formattedMoney += "0";
    } else if (formattedMoney.split(".")[1].length > 2) {
      formattedMoney = parseFloat(amount).toFixed(2);
    }
    setAmount(formattedMoney);
  };

  return (
    <MDBox>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <FormField
            select
            disabled={clone ? true : false}
            id="metodo_adq"
            value={metodo_de_adquisicionV}
            label={metodo_de_adquisicion.label}
            name={metodo_de_adquisicion.name}
            error={
              errors.metodo_de_adquisicion && touched.metodo_de_adquisicion
            }
            success={
              metodo_de_adquisicionV.length > 0 && !errors.metodo_de_adquisicion
            }
            onChange={handleChange}
            fullWidth
          >
            <MenuItem key={""} value={""}>
              No Seleccionado
            </MenuItem>
            {adqs?.map((adq) => (
              <MenuItem key={adq.id} value={adq.url}>
                {adq.nombre}
              </MenuItem>
            ))}
          </FormField>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormField
            select
            id="tipo_catalogo"
            value={tipoV}
            label={tipo.label}
            name={tipo.name}
            onChange={handleChange}
            error={errors.tipo && touched.tipo}
            success={tipoV.length > 0 && !errors.tipo}
            disabled={resourcetypeV === "" || clone ? true : false}
            fullWidth
          >
            <MenuItem key={""} value={""}>
              No Seleccionado
            </MenuItem>
            {tipos?.map((tipo) =>
              tipo.base === "Consumible" ? (
                <MenuItem key={tipo.id} value={tipo.url}>
                  {tipo.nombre}
                </MenuItem>
              ) : null,
            )}
          </FormField>
        </Grid>
      </Grid>
      <MDBox mt={1}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              disabled={clone ? true : false}
              type={proveedor.type}
              name={proveedor.name}
              label={proveedor.label}
              error={errors.proveedor && touched.proveedor}
              success={proveedorV.length > 0 && !errors.proveedor}
              value={proveedorV}
              onInput={(e) =>
                (e.target.value = ("" + e.target.value).toUpperCase())
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              disabled={clone ? true : false}
              type={numero_de_factura.type}
              name={numero_de_factura.name}
              label={numero_de_factura.label}
              value={numero_de_facturaV}
              error={errors.numero_de_factura && touched.numero_de_factura}
              success={
                numero_de_facturaV.length > 0 && !errors.numero_de_factura
              }
              onInput={(e) =>
                (e.target.value = ("" + e.target.value).toUpperCase())
              }
            />
          </Grid>
        </Grid>
      </MDBox>

      <MDBox mt={1}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              label={fecha_de_facturacion.label}
              disabled={clone ? true : false}
              type={fecha_de_facturacion.type}
              name={fecha_de_facturacion.name}
              value={fecha_de_facturacionV}
              error={
                errors.fecha_de_facturacion && touched.fecha_de_facturacion
              }
              success={
                fecha_de_facturacionV.length > 0 && !errors.fecha_de_facturacion
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label={fecha_cedula.label}
              disabled={clone ? true : false}
              type={fecha_cedula.type}
              name={fecha_cedula.name}
              value={fecha_cedulaV}
              error={errors.fecha_cedula && touched.fecha_cedula}
              success={fecha_cedulaV.length > 0 && !errors.fecha_cedula}
            />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={1}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <FormField
              select
              id="unidad_de_medida"
              value={unidad_de_medidaV}
              label={unidad_de_medida.label}
              name={unidad_de_medida.name}
              onChange={handleChange}
              error={errors.unidad_de_medida && touched.unidad_de_medida}
              success={unidad_de_medidaV.length > 0 && !errors.unidad_de_medida}
              disabled={resourcetypeV === "" || clone ? true : false}
              fullWidth
            >
              <MenuItem key={""} value={""}>
                No Seleccionado
              </MenuItem>
              {unidades?.map((unidad) => (
                <MenuItem key={unidad.id} value={unidad.url}>
                  {unidad.nombre}
                </MenuItem>
              ))}
            </FormField>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              disabled={clone ? true : false}
              type={cantidad.type}
              name={cantidad.name}
              label={cantidad.label}
              error={errors.cantidad && touched.cantidad}
              success={cantidadV > 0 && !errors.cantidad}
              onInput={(e) => {
                let value = ("" + e.target.value).replace(/[^(\d|\.)]/g, "");
                e.target.value = isNaN(Number(value)) ? 0 : Number(value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} display="flex">
            <MDTypography mt={2.3} mr={1.5} variant="h6">
              &#36;
            </MDTypography>
            <CurrencyInput
              customInput={FormField}
              maxLength={12}
              type={valor.type}
              disabled={clone ? true : false}
              name={valor.name}
              value={valorV}
              label={valor.label}
              success={valorV > 0}
              decimalsLimit={2}
              onChange={handleOnChange}
              onBlur={handleMoneyBlur}
            />
            <MDTypography mt={2.3} mr={0.8} variant="h6">
              MX
            </MDTypography>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default ProductInfo;
