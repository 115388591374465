import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { cantidad, fecha },
} = checkout;

const validations = [
  Yup.object().shape({
    [fecha.name]: Yup.date().required(fecha.errorMsg),
    [cantidad.name]: Yup.string().required(cantidad.errorMsg),
  }),
];

export default validations;
