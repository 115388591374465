import {carrerasApi} from "../../view/carreras.service";

export const deleteCarrera = carrerasApi.injectEndpoints({
  endpoints: (builder) => ({
    deleteCarrera: builder.mutation({
      query: (id) => ({
        url: `/v1/api/carreras/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [{type: "Carreras", id: "LIST"}],
    }),
  })
})

export const {useDeleteCarreraMutation} = deleteCarrera