import MDBox from "components/MDBox";

import React, { useCallback, useEffect, useState } from "react";
import CalificacionesTable from "./components/ordinaria/calificaciones.table";
import PrimeraRegulacionTable from "./components/primera/regularizacion1.table";
import SegundaRegulacionTable from "./components/segunda/regularizacion2.table";
import { Autocomplete, Card, CircularProgress, Icon } from "@mui/material";
import MDButton from "components/MDButton";
import { COORDINADOR_DOCENTE } from "roles";
import { useGetCiclosQuery, useToggleDivisionMutation } from "services";
import TabsCardCalificaciones from "components/TabsCard/tabs_calificaciones";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import Swal from "sweetalert2";
import { calificaciones, setCarreraSelect } from "slices";
import { useDispatch, useSelector } from "react-redux";
import SelectCarrerasCalificaciones from "./components/selectCarreras";
import useActualizarReg2 from "./hooks/useActualizarReg2";
import useActualizarDivsion from "./hooks/useActualizarDvision";

function DataTableWithTabs({
  ciclo_id,
  cicle_name,
  activo,
  ind,
  carreras,
  ciclo_url,
}) {
  const dispatch = useDispatch();
  const { carreraSelect } = useSelector(calificaciones);
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { data: ciclos } = useGetCiclosQuery("");
  useEffect(() => {
    if (carreras.length !== 0 && Object.keys(carreraSelect).length === 0)
      dispatch(setCarreraSelect(carreras[0]));
    if (carreras.length === 0) {
      enqueueSnackbar(
        "Debe hacer todo el proceso de asignar alumnos, pofesores y asignaturas a un grupo para poder ver sus calificaciones",
        {
          variant: "error",
          autoHideDuration: 5000,
        }
      );
      useEffect(() => {
        navigate(-1);
      }, []);
    }
  }, [carreras]);

  const {
    division,
    divisionesIndexada,
    setDivision,
    loading: divisionLoading,
  } = useActualizarDivsion({
    carreraSelect: carreraSelect,
    carreras: carreras,
    activo: activo,
  });

  const {
    reg2,
    skip,
    id_ciclo,
    loading: reg2Loading,
  } = useActualizarReg2({
    ind: ind,
    divisionesIndexada: divisionesIndexada,
    division: division,
    ciclos: ciclos,
  });

  if (divisionLoading || reg2Loading) {
    return <CircularProgress sx={{ marginBottom: "3rem" }} color="inherit" />;
  }

  return (
    <MDBox pb={3} key={location.pathname}>
      {Object.keys(carreraSelect).length !== 0 && (
        <SelectCarrerasCalificaciones
          carreras={carreras}
          ind={ind}
          setDivision={setDivision}
          division={division}
        />
      )}

      <TabsCardCalificaciones
        initial_tab={"ordinaria"}
        tabs={[
          {
            id: "ordinaria",
            label: "Ordinaria",
            element: (
              <CalificacionesTable
                tableHeader={"Ordinaria"}
                cicle_name={cicle_name}
                ciclo_id={ciclo_id}
                activo={activo}
                carrera={carreraSelect.id}
                division={division}
                ciclo_url={ciclo_url}
              />
            ),
          },
          {
            id: "regulacion1",
            label: "Regularización 1",
            element: (
              <PrimeraRegulacionTable
                tableHeader={"Regularización 1"}
                cicle_name={cicle_name}
                ciclo_id={ciclo_id}
                activo={activo}
                carrera={carreraSelect.id}
                division={division}
                ciclo_url={ciclo_url}
              />
            ),
          },
          {
            id: "regulacion2",
            label: "Regularización 2",
            element: (
              <SegundaRegulacionTable
                tableHeader={"Regularización 2"}
                cicle_name={cicle_name}
                ciclo_id={id_ciclo}
                activo={activo}
                carrera={carreraSelect.id}
                ciclosInternos={reg2}
                division={division}
                skip={skip}
                ciclo_url={ciclo_url}
              />
            ),
          },
        ]}
      />
    </MDBox>
  );
}

export default DataTableWithTabs;
