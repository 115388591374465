export const COORDINADOR_ALMACEN = "Coordinador Almacen";
export const AUXILIAR_ALMACEN = "Auxiliar Almacen";
export const AUXILIAR_DOCENTE = "Auxiliar Control Escolar";
export const COORDINADOR_DOCENTE = "Coordinador Control Escolar";
export const ESTUDIANTE = "Estudiante";
export const PROFESOR = "Docente";
export const SUPERUSER = "Superuser";
export const CONSTANCIAS = "Constancias Extracurriculares";
export const RECURSOS_HUMANOS = "Recursos Humanos";
export const FIRMANTE = "Firmante";
export const FIRMANTE_CURSOS = "Firmante Cursos Extracurriculares";
export const FIRMANTE_BOLETA = "Firmante Boletas Estudiante";
export const FIRMANTE_CERTIFICADO = "Firmante Certificado Electrónico";
