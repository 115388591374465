import { firmaBoletaApi } from "../view/firmaDeBoletaDeNotas.jsx";
import {
  buildFiltersUrl,
  reshape_response,
} from "../../../../../helpers/functions";

export const getBoletas = firmaBoletaApi.injectEndpoints({
  endpoints: (builder) => ({
    getBoletas: builder.query({
      query: ({ filter_params }) => ({
        url: buildFiltersUrl("/v1/api/firmadeboletadenota/", filter_params),
        method: "GET",
      }),
      providesTags: (result) =>
        Array.isArray(result)
          ? [
              ...result.map(({ id }) => ({
                type: "Boletas",
                id,
              })),
              { type: "Boletas", id: "LIST" },
            ]
          : [{ type: "Boletas", id: "LIST" }],
      transformResponse: (response, meta, args) => {
        if (Array.isArray(response)) {
          return response?.map((el) => reshape_response(el, args.shape));
        } else {
          const resultsTransformed = response?.results?.map((el) =>
            reshape_response(el, args.shape)
          );
          return {
            results: resultsTransformed,
            count: response.count,
            next: response.next,
            previous: response.previous,
          };
        }
      },
    }),
  }),
});

export const { useGetBoletasQuery, useLazyGetBoletasQuery } = getBoletas;
