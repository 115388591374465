import { examenApi } from "../view/examen.service";

export const getExamenById = examenApi.injectEndpoints({
  endpoints: (builder) => ({
    getExamenById: builder.query({
      query: (id) => ({
        url: `/v1/api/examenentidad/${id}/`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "Examenes", id }],
    }),
  }),
});

export const { useGetExamenByIdQuery, useLazyGetExamenByIdQuery } =
  getExamenById;
