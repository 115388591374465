import { useState, useEffect } from "react";
export default function useActualizarReg2({
  ind,
  divisionesIndexada,
  division,
  ciclos,
}) {
  //los datos de reg2 son para pedir las evaluaciones de la reg2 correspondientes en escuelas normales ya que estos dependen del ciclo
  //interno anterior y no del actual
  const [reg2, setReg2] = useState([]);
  const [skip, setSkip] = useState(ind === 0); // se usa el skip para obligar a no realizar la peticion de reg2 teniendo
  //en cuenta que estamos en el primer ciclo
  const [id_ciclo, setIdCiclo] = useState(undefined);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (division && Object.keys(division).length > 0 && divisionesIndexada) {
      const ultimaDivisionKey = Object.keys(divisionesIndexada).length;
      const esPrimeraDivision = parseInt(division.division) === 1;
      const nuevoReg2 =
        ind === 0
          ? esPrimeraDivision
            ? []
            : divisionesIndexada[division.division - 1]?.ciclos_internos
          : esPrimeraDivision
            ? divisionesIndexada[ultimaDivisionKey]?.ciclos_internos
            : divisionesIndexada[division.division - 1]?.ciclos_internos;

      const nuevoSkip = ind === 0 && esPrimeraDivision;
      const nuevoIdCiclo =
        ind === 0
          ? esPrimeraDivision
            ? undefined
            : ciclos?.[ind]?.id
          : esPrimeraDivision
            ? ciclos?.[ind - 1]?.id
            : ciclos?.[ind]?.id;
      setReg2(nuevoReg2 || []);
      setSkip(nuevoSkip);
      setIdCiclo(nuevoIdCiclo);
      setLoading(false); // Los valores se han actualizado
    }
  }, [division, divisionesIndexada, ciclos, ind]);

  return {
    reg2,
    skip,
    id_ciclo,
    loading,
  };
}
