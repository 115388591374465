import {
  Card,
  CircularProgress,
  Grid,
  Icon,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import FormField from "../../../../components/FormField";
import MDButton from "../../../../components/MDButton";
import * as Yup from "yup";
import MDDropzone from "../../../../components/MDDropzone";
import cer from "../../../../assets/images/tecnoschool/cer.png";
import imagenXml from "../../../../assets/images/tecnoschool/imagenXml.jpeg";
import { style_icon } from "helpers/global_vars";
import { decryptPrivateKeyFromFile } from "helpers/cryptography";
import { useSnackbar } from "notistack";
import { useSendXMLbase64Mutation } from "services";
import { getBase64 } from "helpers/functions";

const style = {
  cursor: "pointer",
  borderRadius: "0.5rem",
  height: "60px",
};

export default function CertificadoElectronico() {
  const [errorUpload, setErrorUpload] = React.useState(null);
  const [fileUploaded, setFileUploaded] = React.useState(false);
  const [errorXmlUpload, setErrorXmlUpload] = React.useState(null);
  const [fileXmlUploaded, setFileXmlUploaded] = React.useState(false);
  const [shown, setShown] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [
    firmarCertificado,
    {
      isSuccess: isSuccessE,
      isError: isErrorE,
      isLoading: isLoadingE,
      error: errorE,
    },
  ] = useSendXMLbase64Mutation();

  const handleSubmit = async (values) => {
    const { key, password, xml } = values;
    try {
      if (!key) {
        throw new Error("No se seleccionó ningún archivo");
      } else {
        const decryptedPrivateKey = await decryptPrivateKeyFromFile(
          key,
          password
        );
        firmarCertificado({
          contenido: await getBase64(xml),
          code64: decryptedPrivateKey,
        });
      }
    } catch (error) {
      enqueueSnackbar("Contraseña incorrecta o formato de llave inválido.", {
        variant: "error",
        autoHideDuration: 5000,
      });
    }
  };

  const handleChangeShown = () => {
    setShown(!shown);
  };

  const hadleLimpiar = (setFieldValue) => {
    setErrorUpload(false);
    setErrorXmlUpload(false);
    setFileUploaded(false);
    setFileXmlUploaded(false);
    setFieldValue("password", "");
  };

  return (
    <MDBox pt={4} pb={3} pl={5} pr={5}>
      <Card>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} lg={12}>
            <Formik
              initialValues={{
                key: "",
                password: "",
                xml: "",
              }}
              onSubmit={handleSubmit}
              validationSchema={Yup.object().shape({
                key: Yup.string().required(
                  "Debe proporcionar su llave privada"
                ),
                password: Yup.string().required(
                  "Debe proporcionar su contraseña"
                ),
                xml: Yup.string().required("Debe proporcionar un archivo .xml"),
              })}
            >
              {({
                values,
                errors,
                touched,
                isValid,
                handleBlur,
                setFieldValue,
              }) => {
                return (
                  <Form key={"firmar"} id={"firmar_certificados"}>
                    <Grid
                      container
                      spacing={1}
                      display={"flex"}
                      justifyContent={"center"}
                    >
                      <Grid item xs={12} sm={10}>
                        <MDTypography variant="h5" fontWeight="bold" mt={3}>
                          Con este formulario puede firmar un documento xml.
                        </MDTypography>
                      </Grid>
                      <Grid item xs={12} sm={10}>
                        <FormField
                          type={shown ? "text" : "password"}
                          label={"Contraseña"}
                          onBlur={handleBlur}
                          name={"password"}
                          value={values.password}
                          error={errors.password && touched.password}
                          success={
                            values.password.length > 0 && !errors.password
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {values.password.length !== 0 ? (
                                  <Icon
                                    style={style_icon}
                                    onClick={handleChangeShown}
                                    fontSize="small"
                                    variant="outlined"
                                  >
                                    {shown ? "visibility_off" : "visibility"}
                                  </Icon>
                                ) : null}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={10}>
                        <MDBox display="flex" flexDirection="column">
                          <MDBox
                            mt={2}
                            width="100%"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                          >
                            {!fileUploaded && (
                              <MDDropzone
                                options={{
                                  maxFilesize: 1,
                                  acceptedFiles: ".key",
                                  dictDefaultMessage:
                                    "Suba su archivo .key aquí.",
                                  autoProcessQueue: false,
                                  init: function () {
                                    this.on("addedfile", (file) => {
                                      const validExts = [".key"];
                                      let fileExt = file.name;
                                      fileExt = fileExt.substring(
                                        fileExt.lastIndexOf(".")
                                      );
                                      if (!validExts.includes(fileExt)) {
                                        setErrorUpload(
                                          "Solo se aceptan archivos .key"
                                        );
                                        setFileUploaded(false);
                                      } else {
                                        setFieldValue("key", file, true);
                                        setErrorUpload(null);
                                        setFileUploaded(true);
                                      }
                                    });
                                  },
                                }}
                                file={fileUploaded}
                                style={{ minHeight: "80px", width: "100%" }}
                                id="key"
                              />
                            )}
                            {fileUploaded && (
                              <MDBox
                                component="img"
                                src={cer}
                                variant="gradient"
                                bgColor="dark"
                                style={style}
                              />
                            )}
                            <Tooltip placement="top" title={"Quitar archivo"}>
                              <MDBox ml={1}>
                                <MDButton
                                  type="submit"
                                  variant="gradient"
                                  color="dark"
                                  onClick={() => {
                                    setFieldValue("key", null),
                                      setFileUploaded(false);
                                  }}
                                  circular
                                  iconOnly
                                >
                                  <Icon> delete</Icon>
                                </MDButton>
                              </MDBox>
                            </Tooltip>
                            {errorUpload && (
                              <MDTypography
                                color="error"
                                variant="h6"
                                fontWeight="bold"
                                display="flex"
                                justifyContent="center"
                                textAlign="center"
                              >
                                {errorUpload}
                              </MDTypography>
                            )}
                          </MDBox>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={10}>
                        <MDBox display="flex" flexDirection="column">
                          <MDBox
                            mt={2}
                            width="100%"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                          >
                            {!fileXmlUploaded && (
                              <MDDropzone
                                options={{
                                  maxFilesize: 1,
                                  acceptedFiles: ".xml",
                                  dictDefaultMessage:
                                    "Suba el documento .xml aquí.",
                                  autoProcessQueue: false,
                                  init: function () {
                                    this.on("addedfile", (file) => {
                                      const validExts = [".xml"];
                                      let fileExt = file.name;
                                      fileExt = fileExt.substring(
                                        fileExt.lastIndexOf(".")
                                      );
                                      if (!validExts.includes(fileExt)) {
                                        setErrorXmlUpload(
                                          "Solo se aceptan archivos .xml"
                                        );
                                        setFileXmlUploaded(false);
                                      } else {
                                        setFieldValue("xml", file, true);
                                        setErrorXmlUpload(null);
                                        setFileXmlUploaded(true);
                                      }
                                    });
                                  },
                                }}
                                file={fileUploaded}
                                style={{ minHeight: "80px", width: "100%" }}
                                id="key"
                              />
                            )}
                            {fileXmlUploaded && (
                              <MDBox
                                component="img"
                                src={imagenXml}
                                variant="gradient"
                                bgColor="dark"
                                style={style}
                              />
                            )}
                            <Tooltip placement="top" title={"Quitar archivo"}>
                              <MDBox ml={1}>
                                <MDButton
                                  type="submit"
                                  variant="gradient"
                                  color="dark"
                                  onClick={() => {
                                    setFieldValue("xml", null),
                                      setFileXmlUploaded(false);
                                  }}
                                  circular
                                  iconOnly
                                >
                                  <Icon> delete</Icon>
                                </MDButton>
                              </MDBox>
                            </Tooltip>
                            {errorXmlUpload && (
                              <MDTypography
                                color="error"
                                variant="h6"
                                fontWeight="bold"
                                display="flex"
                                justifyContent="center"
                                textAlign="center"
                              >
                                {errorXmlUpload}
                              </MDTypography>
                            )}
                          </MDBox>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={10} pb={3}>
                        <MDBox
                          mt={2}
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                        >
                          <MDButton
                            variant="gradient"
                            color="dark"
                            onClick={() => hadleLimpiar(setFieldValue)}
                          >
                            cancelar
                          </MDButton>
                          {isLoadingE && (
                            <CircularProgress size={40} color="inherit" />
                          )}
                          <MDButton
                            disabled={!isValid}
                            type="submit"
                            variant="gradient"
                            color="light"
                          >
                            aceptar
                          </MDButton>
                        </MDBox>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
      </Card>
    </MDBox>
  );
}
