/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import FormField from "components/FormField";
import { MenuItem } from "@mui/material";
function AddTipo({ formData }) {
  const { formField, values, errors, touched, handleChange } = formData;
  const { nombre, base } = formField;
  const { nombre: nombreV, base: baseV } = values;

  return (
    <MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={nombre.type}
              label={nombre.label}
              name={nombre.name}
              value={nombreV}
              error={errors.nombre && touched.nombre}
              success={nombreV.length > 0 && !errors.nombre}
              onInput={(e) =>
                (e.target.value = ("" + e.target.value).toUpperCase())
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              select
              id="base"
              value={baseV}
              label={base.label}
              name={base.name}
              error={errors.base && touched.base}
              success={baseV.length > 0 && !errors.base}
              onChange={handleChange}
              fullWidth
            >
              <MenuItem key={"0"} value={""}>
                NO SELECCIONADO
              </MenuItem>
              <MenuItem key={"1"} value={"Tangible"}>
                TANGIBLE
              </MenuItem>
              <MenuItem key={"2"} value={"Consumible"}>
                CONSUMIBLE
              </MenuItem>
            </FormField>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for UserInfo
AddTipo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default AddTipo;
