import {carrerasApi} from "../../view/carreras.service";
import {appendFormData} from "../../../../../helpers/functions";

export const editCarrera = carrerasApi.injectEndpoints({
  endpoints: (builder) => ({
    editCarrera: builder.mutation({
      query: (data) => {
        const formData = new FormData();
        Object.entries(data).map(([key, value]) => {
          appendFormData(formData, key, value)
        });
        
        return {
          url: `/v1/api/carreras/${data.id}/`,
          method: "PATCH",
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          body: formData,
        };
      },
      invalidatesTags: (result, error, {id}) =>
        result
          ? [
            {type: "Carreras", id},
            {type: "Carreras", id: "LIST"},
          ]
          : [{type: "Carreras", id: "LIST"}],
    }),
  })
})

export const {useEditCarreraMutation} = editCarrera