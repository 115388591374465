import ModalBase from "components/ModalBase/modal_base";
import { CircularProgress, Grid, Icon, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Form, Formik } from "formik";
import { getModifiedFields } from "helpers/functions";
import { CANCEL_NAVIGATION } from "hooks/useRedirectForm";
import { useRedirectForm } from "hooks/useRedirectForm";
import { useSnackbar } from "notistack";
import React from "react";
import {
  useGetExamenesQuery,
  useEditFechaEvaluacionMutation,
  useGetFechaEvaluacionQuery,
} from "services";
import MyMobileTimePicker from "components/DateTimePicker/mobileTimePicker";
import dayjs from "dayjs";
import MDTypography from "components/MDTypography";
import { useCloseModal } from "hooks/useCloseModal";

const ModalFechEval = ({ open, handleClose, distribucion_profesor }) => {
  const fechas_iniciales = {
    inicio: dayjs.utc(),
    fin: dayjs.utc(),
  };

  const { data: fechas } = useGetFechaEvaluacionQuery({
    filter_params: {
      profesor: distribucion_profesor.id,
      query: "{inicio,fin,accesible,id}",
      examen__nombre: distribucion_profesor.examen,
    },
  });

  const [
    editFechaEval,
    {
      isSuccess: isSuccessE,
      isLoading: isLoadingE,
      isError: isErrorE,
      error: errorE,
    },
  ] = useEditFechaEvaluacionMutation();

  const { data: examenes } = useGetExamenesQuery(
    {
      filter_params: {
        query: "{id,examen{nombre}},borrable,compartido}",
      },
      shape: {
        id: "id",
        url: "url",
        nombre: "examen.nombre",
        borrable: "borrable",
        compartido: "compartido",
      },
    },
    {
      refetch_on_reconnect: true,
    }
  );

  const submitForm = async (values, actions) => {
    try {
      const modifiedFields = getModifiedFields(fechas[0], values);
      if (Object.keys(modifiedFields).length !== 0) {
        const examen_value = examenes.find(
          (e) => e.nombre === distribucion_profesor.examen
        );
        const newValues = {
          inicio: modifiedFields.inicio
            ? modifiedFields.inicio.format("YYYY-MM-DDTHH:mm:ss:Z")
            : values.inicio,
          fin: modifiedFields.fin
            ? modifiedFields.fin.format("YYYY-MM-DDTHH:mm:ss:Z")
            : values.fin,
        };
        await editFechaEval({
          id: fechas[0].id,
          profesor: distribucion_profesor.url,
          examen: examen_value.url,
          inicio: newValues.inicio,
          fin: newValues.fin,
        });
        actions.setSubmitting(false);
        actions.resetForm();
      }
    } catch (error) {
      console.error(error);
      actions.setSubmitting(true);
    }
  };

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
  };

  useCloseModal(
    isLoadingE,
    isSuccessE,
    isErrorE,
    errorE,
    `La fecha de evaluación del examen: ${distribucion_profesor.examen} de este profesor/asignatura fue editada correctamente.`,
    handleClose
  );

  return (
    <ModalBase open={open} handleClose={handleClose}>
      {fechas !== undefined && fechas?.length !== 0 && (
        <MDBox>
          <Formik initialValues={fechas_iniciales} onSubmit={handleSubmit}>
            {({ values, setFieldValue }) => {
              React.useEffect(() => {
                if (fechas.length !== 0) {
                  setFieldValue("inicio", fechas[0].inicio);
                  setFieldValue("fin", fechas[0].fin);
                }
              }, [fechas]);
              return (
                <Form>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                      <MDTypography variant="h6" fontWeight="medium" mb={1}>
                        Puede editar el rango de fechas de este profesor:
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <MyMobileTimePicker
                        label={"Inician capturas"}
                        name={"inicio"}
                        value={dayjs(values.inicio)}
                        onChange={(name, value) => setFieldValue(name, value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <MyMobileTimePicker
                        label={"Cierran capturas"}
                        name={"fin"}
                        value={dayjs(values.fin)}
                        onChange={(name, value) => setFieldValue(name, value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <MDBox
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        mt={3}
                      >
                        <MDButton
                          variant="gradient"
                          color="dark"
                          size="medium"
                          onClick={handleClose}
                        >
                          cancelar
                        </MDButton>
                        {isLoadingE && (
                          <Grid item mt={1}>
                            <CircularProgress size={30} color="inherit" />
                          </Grid>
                        )}
                        <MDButton
                          variant="gradient"
                          size="medium"
                          type="submit"
                          color="light"
                        >
                          Editar
                        </MDButton>
                      </MDBox>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </MDBox>
      )}
    </ModalBase>
  );
};

export default ModalFechEval;
