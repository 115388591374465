import { createApi } from "@reduxjs/toolkit/query/react";
import { buildFiltersUrl, reshape_response } from "helpers/functions";
import customFetchBase from "services/principal/customFetchBase";

export const reporteTareaApi = createApi({
  reducerPath: "reporteTareaApi",
  baseQuery: customFetchBase,
  tagTypes: ["Reporte Tarea"],
  endpoints: (builder) => ({
    getTask: builder.query({
      query: (id) => ({
        url: `/v1/api/task/${id}`,
        method: "GET",
      }),
      providesTags: (result) =>
        Array.isArray(result)
          ? [
              ...result.map(({ id }) => ({
                type: "Reporte Tarea",
                id,
              })),
              { type: "Reporte Tarea", id: "LIST" },
            ]
          : [{ type: "Reporte Tarea", id: "LIST" }],
    }),
  }),
});

export const { useLazyGetTaskQuery, useGetTaskQuery } = reporteTareaApi;
