// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DataTable from "examples/Tables/DataTable";

//React Hooks and Others
import { CircularProgress, Icon, Tooltip, useMediaQuery } from "@mui/material";

import PropTypes from "prop-types";
import { style_icon } from "helpers/global_vars";
import { useRedirectForm } from "hooks/useRedirectForm";
import { COORDINADOR_DOCENTE, PROFESOR } from "roles";
import { useNavigate } from "react-router-dom";
import {
  useCleanAsignaturasPorProfesorMutation,
  useGetEvaluacionesQuery,
  useGetFechaEvaluacionQuery,
  useLazyGetAsignaturasPorProfesorByIdQuery,
} from "services";
import Swal from "sweetalert2";
import ComponentsPrivatization from "config/component_privatization";
import React, { useState } from "react";
import ModalNotasPdf from "components/PDF/modal_calificacion_pdf";
import usePagination from "hooks/usePagination";
import {
  ordenarPorIdentificador,
  ordenarPorNombreCompleto,
} from "../ordinaria/calificaciones.table";
import { paridad } from "../ordinaria/calificaciones_show";
import useModalHandler from "../../../../../../hooks/useModalHandler";
import ReportesComponent, { convertirDivision } from "../reportes";
import FechaDeEvaluaciones from "../compFechaEval";
import ModalFechEval from "components/ModalFechaEvaluacion/modalFecha";
import { FiltersComponent } from "../Filters";

function SegundaRegulacionTable({
  tableHeader,
  ciclo_id,
  cicle_name,
  activo,
  division,
  ciclosInternos,
  skip,
  ciclo_url,
}) {
  const authUser = JSON.parse(sessionStorage.getItem("user"));
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const [values, setValues] = useState({
    profesor: "",
    grupo: "",
  });

  //State del modal para calificacar
  const {
    open: openPDF,
    handleOpen: handleOpenPDF,
    handleClose: handleClosePDF,
  } = useModalHandler();

  //Props del modal de fecha evaluacion
  const {
    open: openFechaEval,
    handleOpen: handleOpenFechaEval,
    handleClose: handleCloseFechaEval,
  } = useModalHandler();
  const [profesor_distribucion, setProfDist] = useState({
    id: "",
    url: "",
    examen: "",
  });

  //TODO: agregar query a getAsignatudas por profesor query
  const [distProfbyId, { data: distProf }] =
    useLazyGetAsignaturasPorProfesorByIdQuery();

  const {
    busqueda,
    setBusqueda,
    offset,
    setOffset,
    limit,
    handleNext,
    handlePrevious,
  } = usePagination();

  const {
    data: data,
    isError: isErrorG,
    isLoading: isLoadingG,
    error: errorG,
    isFetching,
  } = useGetEvaluacionesQuery(
    {
      filter_params: {
        estados_de_alumnos: "Activo,Baja Temporal",
        acta: "true",
        acta_r1: "true",
        aprobado: "false",
        buscar: busqueda,
        distribucion_profesor__distribucion_grupo__ciclo: ciclo_id,
        ciclos_internos: ciclosInternos?.join(","),
        distribuciones_de_profesores_distintas: "true",
        examen__nombre: "Regularización 1",
        limit: limit,
        offset: offset,
        query:
          "{distribucion_profesor{id,acta,acta_r1,acta_r2,profesor{id,url,user{first_name,apellido_materno,apellido_paterno}},asignatura{nombre,url,clave},distribucion_grupo{ciclo_interno{numero_de_ciclo},id,url,grupo{identificador}}}}",
        ...(values.grupo !== "" && {
          distribucion_profesor__distribucion_grupo: values.grupo,
        }),
        ...(authUser?.groups?.some((e) => e.name === PROFESOR) && {
          distribucion_profesor__profesor: authUser?.perfil.resourcetype?.find(
            (e) => e.resourcetype === PROFESOR
          ).id,
        }),
        ...(values.profesor !== "" && {
          distribucion_profesor__profesor: values.profesor,
        }),
      },
      shape: {
        id: "distribucion_profesor.id",
        semestre:
          "distribucion_profesor.distribucion_grupo.ciclo_interno.numero_de_ciclo",
        grupo_ident:
          "distribucion_profesor.distribucion_grupo.grupo.identificador",
        grupo_id: "distribucion_profesor.distribucion_grupo.id",
        acta: "distribucion_profesor.acta",
        acta_r1: "distribucion_profesor.acta_r1",
        acta_r2: "distribucion_profesor.acta_r2",
        asignatura: "distribucion_profesor.asignatura.nombre",
        asignatura_clave: "distribucion_profesor.asignatura.clave",
        profesor_name:
          "distribucion_profesor.profesor.user.apellido_paterno distribucion_profesor.profesor.user.apellido_materno distribucion_profesor.profesor.user.first_name",
        profesor_id: "distribucion_profesor.profesor.id",
        asignatura_url: "distribucion_profesor.asignatura.url",
        dist_grupo_url: "distribucion_profesor.distribucion_grupo.url",
        profesor_url: "distribucion_profesor.profesor.url",
      },
    },
    {
      refetchOnReconnect: true,
      skip: skip,
    }
  );

  const { data: fechas } = useGetFechaEvaluacionQuery({
    filter_params: {
      examen__nombre: "Regularización 2",
      query: "{id,inicio,fin,profesor{id},abierto,accesible,examen{nombre}}",
      division_numero: convertirDivision(division),
      profesor__distribucion_grupo__ciclo: ciclo_id,
    },
  });

  //constantes para manejar la paginacion
  const dataRest = data?.results ? data.results : data;
  const countTotal = data?.count;
  const previous = data?.previous;
  const next = data?.next;

  const profesoresOrdenados = dataRest
    ?.map((e) => {
      const { profesor_id, profesor_name } = e;
      return {
        profesor_id,
        profesor_name,
      };
    })
    .slice()
    .sort((a, b) => ordenarPorNombreCompleto(a, b));

  const gruposOrdenados = dataRest
    ?.map((e) => {
      const { grupo_id, grupo_ident } = e;
      return {
        grupo_id,
        grupo_ident,
      };
    })
    ?.slice()
    .sort((a, b) => ordenarPorIdentificador(a, b));

  const [borrarActa, { isSuccess, isLoading, isError, error }] =
    useCleanAsignaturasPorProfesorMutation();

  const dataTable = {
    columns: [
      {
        Header: "Semestre",
        accessor: "semestre",
        width: "10%",
      },
      {
        Header: "Grupo",
        accessor: "grupo_ident",
      },
      {
        Header: "Clave de Asignatura",
        accessor: "asignatura_clave",
      },
      {
        Header: "Asignatura",
        accessor: "asignatura",
      },
      {
        Header: "Profesor",
        accessor: "profesor_name",
      },
      {
        Header: "Acciones",
        accessor: "actions",
        width: "10%",
        Cell: ({ row }) => {
          const { original } = row;
          return (
            <MDBox display="flex">
              <MDBox px={0.2}>
                <Tooltip
                  placement="top"
                  title="Ver toda la información de la regulación en vista de detalles (Puede acceder a esta funcionalidad con doble click en cualquier parte de la fila)"
                >
                  <Icon
                    style={style_icon}
                    onClick={() => handleClickShow(original)}
                    fontSize="small"
                  >
                    find_in_page
                  </Icon>
                </Tooltip>
              </MDBox>
              <ComponentsPrivatization permitted={COORDINADOR_DOCENTE}>
                <MDBox px={0.2}>
                  <Tooltip
                    placement="top"
                    title="Ver acta de la regulación sin firmar"
                  >
                    <Icon
                      style={style_icon}
                      onClick={() => handleClickActa(original)}
                      fontSize="small"
                      color="success"
                    >
                      picture_as_pdf
                    </Icon>
                  </Tooltip>
                </MDBox>
              </ComponentsPrivatization>
              {original.acta_r2 && (
                <>
                  <ComponentsPrivatization
                    permitted={[COORDINADOR_DOCENTE, PROFESOR]}
                  >
                    <MDBox px={0.2}>
                      <Tooltip
                        placement="top"
                        title="Ver acta de la regulación firmada"
                      >
                        <Icon
                          style={style_icon}
                          onClick={() => handleClickNavigate(original.acta_r2)}
                          fontSize="small"
                          color="error"
                        >
                          picture_as_pdf
                        </Icon>
                      </Tooltip>
                    </MDBox>
                  </ComponentsPrivatization>
                  {activo && (
                    <ComponentsPrivatization permitted={[COORDINADOR_DOCENTE]}>
                      <MDBox display="flex">
                        {fechas?.length !== 0 && (
                          <MDBox px={0.2}>
                            <Tooltip
                              placement="top"
                              title="Cambiar la fecha de calificación de esta asignatura"
                            >
                              <Icon
                                style={style_icon}
                                onClick={() =>
                                  handlePermisoEvaluacion(original)
                                }
                                fontSize="small"
                              >
                                access_time
                              </Icon>
                            </Tooltip>
                          </MDBox>
                        )}
                        <MDBox px={0.2}>
                          <Tooltip
                            placement="top"
                            title="Eliminar acta firmada de la base de datos"
                          >
                            <Icon
                              style={style_icon}
                              onClick={() => handleClickEliminate(original)}
                              fontSize="small"
                            >
                              remove_circle
                            </Icon>
                          </Tooltip>
                        </MDBox>
                      </MDBox>
                    </ComponentsPrivatization>
                  )}
                </>
              )}
            </MDBox>
          );
        },
      },
    ],

    rows: !skip ? dataRest ?? [] : [],
  };

  useRedirectForm(
    isLoading,
    isSuccess,
    isError,
    error,
    "Acta eliminada",
    `/ciclos/${cicle_name}/calificaciones`
  );

  const handleClickShow = (el) => {
    navigate(
      `/ciclos/${cicle_name}/calificaciones/regularizacion2/grupo/${el.grupo_ident}/profesor/${el.id}/semestre/${division.division_verbose}`
    );
  };
  const handleClickNavigate = (el) => {
    window.open(el, "_blank");
  };

  const handleClickEliminate = (el) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: `¡Si eliminas el acta, la acción no se podrá revertir!`,
      icon: "warning",
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Eliminar",
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          borrarActa({
            id: el.id,
            acta_r2: null,
          });
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  const handlePermisoEvaluacion = (original) => {
    setProfDist({
      id: original.id,
      url: original.url,
      examen: "Regularización 2",
    });
    handleOpenFechaEval();
  };

  const handleClickActa = (e) => {
    distProfbyId(e.id);
    handleOpenPDF();
  };

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  if (skip) {
    return (
      <MDBox>
        <MDTypography variant="h5" fontWeight="medium" mb={1}>
          La tabla no contiene informacion ya que no existen datos para
          llenarla, siendo este el primer ciclo guardado.
        </MDTypography>
      </MDBox>
    );
  }

  return (
    <>
      <ModalFechEval
        open={openFechaEval}
        handleClose={handleCloseFechaEval}
        distribucion_profesor={profesor_distribucion}
      />
      {distProf && (
        <ModalNotasPdf
          open={openPDF}
          handleClose={handleClosePDF}
          profesor={distProf.id}
          ordinaria={"3"}
          cicle_name={cicle_name}
          semestre={paridad(division)}
        />
      )}

      <MDBox
        display={isSmallScreen ? "flex" : "flex"}
        flexDirection={isSmallScreen ? "column" : "row"}
        justifyContent={"space-between"}
        alignItems={"baseline"}
        m={2}
        gap={2}
        p={3}
      >
        <MDTypography variant="h5" fontWeight="medium" mb={1}>
          {tableHeader}:
        </MDTypography>

        <ComponentsPrivatization permitted={[COORDINADOR_DOCENTE]}>
          <ReportesComponent rep={2} ciclo={ciclo_id} division={division} />
        </ComponentsPrivatization>
      </MDBox>
      <MDBox px={3}>
        {fechas && (
          <FechaDeEvaluaciones
            fechas={fechas}
            ciclo_id={ciclo_id}
            examen={"Regularización 2"}
            division={division}
            ciclo_url={ciclo_url}
          />
        )}
      </MDBox>

      <MDBox display="flex" justifyContent={"center"} alignItems={"center"}>
        {isLoadingG || isFetching ? (
          <CircularProgress sx={{ marginBottom: "3rem" }} color="inherit" />
        ) : (
          <DataTable
            table={dataTable}
            canSearch
            doubleClick={handleClickShow}
            paginationApi
            countTotal={countTotal}
            next={next}
            previous={previous}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            setBusqueda={setBusqueda}
            offset={offset}
            setOffset={setOffset}
            filtersComponent={
              <FiltersComponent
                values={values}
                handleChange={handleChange}
                profesores={profesoresOrdenados}
                grupos={gruposOrdenados}
              />
            }
          />
        )}
      </MDBox>
    </>
  );
}

SegundaRegulacionTable.propTypes = {
  tableHeader: PropTypes.string.isRequired,
};

export default SegundaRegulacionTable;
