/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// formik components
import { Form, Formik } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// NewUser page components
import AddGrupoEscolar from "./components/grupo_info";

// NewUser layout schemas for form and form feilds
import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import { useNavigate, useParams } from "react-router-dom";
import * as React from "react";
import { useEffect, useState } from "react";
import MDTypography from "components/MDTypography";
import { GRUPOS_LIST_URL } from "config/bases_url";
import { useRedirectForm } from "hooks/useRedirectForm";
import {
  useCreateCarrerasPorGrupoMutation,
  useCreateGrupoEscolarMutation,
  useEditCarrerasPorGrupoMutation,
  useEditGrupoEscolarMutation,
  useGetCiclosQuery,
  useGetGrupoEscolarQuery,
  useLazyGetCarrerasPorGrupoByIdQuery,
} from "services";
import FormErrorHandler from "../../../../handlers/FormErrorHandler/form-error.handler";
import { ErrorBundary } from "../../../../config/ErrorBundary/error-bundary";
import { getModifiedFields } from "helpers/functions";

function NewGrupoEscolar() {
  const [idGrupo, setIdGrupo] = useState();
  const [oldValues, setOldValues] = useState();

  const { id, cicle_name } = useParams();

  const { data: ciclos } = useGetCiclosQuery("", {
    refetchOnReconnect: true,
  });

  const { data: grupos } = useGetGrupoEscolarQuery({
    filter_params: {
      estudiantes: true,
      ordering: "identificador",
      query: "{identificador,url}",
    },
    shape: {
      url: "url",
      identificador: "identificador",
    },
  });

  const [
    createGrupoEscolar,
    {
      isSuccess: isSuccessC,
      isLoading: isLoadingC,
      isError: isErrorC,
      error: errorC,
    },
  ] = useCreateGrupoEscolarMutation();
  const [
    editGrupoEscolar,
    {
      isSuccess: isSuccessE,
      isLoading: isLoadingE,
      isError: isErrorE,
      error: errorE,
    },
  ] = useEditGrupoEscolarMutation();
  const [
    createGrupoPorCarrera,
    {
      isSuccess: isSuccessCC,
      isLoading: isLoadingCC,
      isError: isErrorCC,
      error: errorCC,
    },
  ] = useCreateCarrerasPorGrupoMutation();

  const [
    editGrupoPorCarrera,
    {
      isSuccess: isSuccessGxC,
      isLoading: isLoadingGxC,
      isError: isErrorGxC,
      error: errorGxC,
    },
  ] = useEditCarrerasPorGrupoMutation();

  const { formId, formField } = form;
  const currentValidation = validations[0];

  const navigate = useNavigate();

  const [getCarreraPorGrupoById, { data: grupo }] =
    useLazyGetCarrerasPorGrupoByIdQuery();

  useRedirectForm(
    isLoadingC,
    isSuccessC,
    isErrorC,
    errorC,
    "Grupo Escolar creado",
    `${GRUPOS_LIST_URL}/${cicle_name}/grupos`
  );

  useRedirectForm(
    isLoadingCC,
    isSuccessCC,
    isErrorCC,
    errorCC,
    "Grupo Escolar creado",
    `${GRUPOS_LIST_URL}/${cicle_name}/grupos`
  );

  useRedirectForm(
    isLoadingGxC,
    isSuccessGxC,
    isErrorGxC,
    errorGxC,
    "Ciclo interno del Grupo Escolar editado",
    `${GRUPOS_LIST_URL}/${cicle_name}/grupos`
  );

  useRedirectForm(
    isLoadingE,
    isSuccessE,
    isErrorE,
    errorE,
    "Grupo Escolar editado",
    `${GRUPOS_LIST_URL}/${cicle_name}/grupos`
  );

  const submitForm = async (values, actions) => {
    try {
      if (!id) {
        if (grupos.some((e) => e.identificador === values.identificador)) {
          const sameIdentificador = grupos.find(
            (e) => e.identificador === values.identificador
          )?.url;
          await createGrupoPorCarrera({
            ciclo: values.ciclo,
            ciclo_interno: values.ciclo_interno,
            grupo: sameIdentificador,
          });
        } else {
          await createGrupoEscolar(values);
        }
      } else {
        const modifiedFields = getModifiedFields(oldValues, values);
        if (Object.keys(modifiedFields).length !== 0) {
          if (modifiedFields.identificador && !modifiedFields.ciclo_interno) {
            await editGrupoEscolar({
              id: idGrupo,
              identificador: values.identificador,
            });
          } else if (
            !modifiedFields.identificador &&
            modifiedFields.ciclo_interno
          ) {
            await editGrupoPorCarrera({
              id: id,
              ciclo_interno: values.ciclo_interno,
            });
          } else {
            await editGrupoEscolar({
              id: idGrupo,
              identificador: values.identificador,
            });
            await editGrupoPorCarrera({
              id: id,
              ciclo_interno: values.ciclo_interno,
            });
          }
        }
      }
      navigate(`${GRUPOS_LIST_URL}/${cicle_name}/grupos`);
      if (isSuccessC || isSuccessE) {
        actions.setSubmitting(false);
        actions.resetForm();
      }
    } catch (error) {
      console.error(error);
      actions.setSubmitting(true);
    }
  };

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
  };

  return (
    <MDBox mt={5} mb={20}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} lg={8}>
          <MDBox mt={6} mb={8} textAlign="center">
            <MDBox mb={1}>
              <MDTypography variant="h3" fontWeight="bold">
                Grupo Escolar
              </MDTypography>
            </MDBox>
            <MDTypography variant="h5" fontWeight="regular" color="secondary">
              {!id
                ? "Introduzca la información relacionada al Grupo Escolar por agregar"
                : `Edite la información relacionada al Grupo Escolar: ${grupo?.grupo?.identificador}`}
            </MDTypography>
          </MDBox>
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, setFieldValue }) => {
              useEffect(() => {
                if (id) {
                  getCarreraPorGrupoById(id)
                    .unwrap()
                    .then((res) => {
                      setOldValues({
                        identificador: res?.grupo?.identificador,
                        ciclo_interno: res?.ciclo_interno?.url,
                      });
                      setFieldValue(
                        formField.identificador.name,
                        res?.grupo?.identificador,
                        true
                      );
                      setFieldValue(
                        "ciclo_interno",
                        res?.ciclo_interno?.url,
                        true
                      );
                      setIdGrupo(res?.grupo?.id);
                    });
                }
              }, [id]);
              useEffect(() => {
                if (ciclos) {
                  setFieldValue("ciclo", ciclos[ciclos?.length - 1]?.url);
                }
              }, [ciclos]);

              return (
                <ErrorBundary renderOnError={() => <FormErrorHandler />}>
                  <Form id={formId} autoComplete="off">
                    <Card sx={{ height: "100%" }}>
                      <MDBox p={3}>
                        <MDBox>
                          <AddGrupoEscolar
                            formData={{
                              values,
                              touched,
                              formField,
                              errors,
                              setFieldValue,
                            }}
                          />

                          <MDBox
                            mt={2}
                            width="100%"
                            display="flex"
                            justifyContent="space-between"
                          >
                            <MDButton
                              onClick={(e) => {
                                navigate(
                                  `${GRUPOS_LIST_URL}/${cicle_name}/grupos`
                                );
                              }}
                              variant="gradient"
                              color="primary"
                            >
                              cancelar
                            </MDButton>
                            <MDButton
                              type="submit"
                              variant="gradient"
                              color="dark"
                            >
                              aceptar
                            </MDButton>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </Card>
                  </Form>
                </ErrorBundary>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default NewGrupoEscolar;
