import {asignaturasApi} from "../view/asignaturas.service";


export const getAsignaturaById = asignaturasApi.injectEndpoints({
  endpoints: (builder) => ({
    getAsignaturaById: builder.query({
      query: (id) => ({
        url: `/v1/api/asignaturas/${id}/`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{type: "Asignaturas", id}],
    }),
  }),
})

export const {
  useGetAsignaturaByIdQuery,
  useLazyGetAsignaturaByIdQuery
} = getAsignaturaById;