import {firmantesApi} from "../view/firmante.service";


export const getFirmanteById = firmantesApi.injectEndpoints({
    endpoints: (builder) => ({
        getFirmanteById: builder.query({
            query: (id) => ({
                url: `/v1/api/firmante/${id}/`,
                method: "GET",
            }),
            providesTags: (result, error, id) => [{type: "Firmantes", id}],
        }),
    }),
})

export const {
    useGetFirmanteByIdQuery,
    useLazyGetFirmanteByIdQuery
} = getFirmanteById;