import client from "config/axios";
import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "./customFetchBase";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: customFetchBase,
  tagTypes: ["Auth"],
  endpoints: (builder) => ({
    changePassword: builder.mutation({
      query: (payload) => ({
        url: `/v1/api/password/change/`,
        method: "POST",
        body: payload,
      }),
    }),
    login: builder.mutation({
      query: (payload) => ({
        url: "/v3/api/login/",
        body: payload,
        method: "POST",
      }),

      async onQueryStarted(args, { dispatch, queryFulfilled, getCacheEntry }) {
        try {
          await queryFulfilled;
          sessionStorage.setItem("token", getCacheEntry().data.access);
          sessionStorage.setItem("refresh", getCacheEntry().data.refresh);
          sessionStorage.setItem(
            "user",
            JSON.stringify(getCacheEntry().data.user)
          );
        } catch (error) {
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("refresh");
          sessionStorage.removeItem("user");
        }
      },
    }),
    modifyOwnProfile: builder.mutation({
      query: (body) => ({
        url: "/v2/api/user/",
        method: "PATCH",
        body,
      }),
      async onQueryStarted(args, { queryFulfilled, getCacheEntry }) {
        try {
          await queryFulfilled;
          sessionStorage.setItem("user", JSON.stringify(getCacheEntry().data));
        } catch (error) {
          console.log(error);
        }
      },
    }),
    logoutUser: builder.mutation({
      query: () => ({
        url: "/v1/api/logout/",
        method: "POST",
      }),
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled;
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("refresh");
          sessionStorage.removeItem("user");
        } catch (err) {
          console.log(err);
        }
      },
    }),
    resetPassword: builder.mutation({
      query: (body) => ({
        url: "/v1/api/password/reset/",
        method: "POST",
        body,
      }),
    }),
    resetPasswordConfirm: builder.mutation({
      query: (body) => ({
        url: "/v1/api/password/reset/confirm/",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useChangePasswordMutation,
  useModifyOwnProfileMutation,
  useResetPasswordMutation,
  useLoginMutation,
  useLogoutUserMutation,
  useResetPasswordConfirmMutation,
} = authApi;
