import {asignaturasApi} from "../view/asignaturas.service";


export const createAsignatura = asignaturasApi.injectEndpoints({
    endpoints: (builder) => ({
        createAsignatura: builder.mutation({
            query: (data) => {
                data.nombre = data.nombre.trim();

                return {
                    url: "/v1/api/asignaturas/",
                    method: "POST",
                    body: data,
                };
            },
            invalidatesTags: [{type: "Asignaturas", id: "LIST"}],
        }),
    }),
})

export const {
    useCreateAsignaturaMutation
} = createAsignatura;