/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// NewUser page components
import FormField from "components/FormField";

function CicloEdit({ formData }) {
  const { formField, values, errors, touched } = formData;
  const { nombre, fecha_inicio, fecha_fin } = formField;
  const {
    nombre: nombreV,
    fecha_inicio: fecha_inicioV,
    fecha_fin: fecha_finV,
  } = values;

  return (
    <MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              label={nombre.label}
              type={nombre.type}
              name={nombre.name}
              value={nombreV}
              error={errors.nombre && touched.nombre}
              success={nombreV?.length > 0 && !errors.nombre}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              label={fecha_inicio.label}
              type={fecha_inicio.type}
              name={fecha_inicio.name}
              value={fecha_inicioV}
              error={errors.fecha_inicio && touched.fecha_inicio}
              success={fecha_inicioV?.length > 0 && !errors.fecha_inicio}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label={fecha_fin.label}
              type={fecha_fin.type}
              name={fecha_fin.name}
              value={fecha_finV}
              error={errors.fecha_fin && touched.fecha_fin}
              success={fecha_finV?.length > 0 && !errors.fecha_fin}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for UserInfo
CicloEdit.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default CicloEdit;
