import { QRCodeCanvas } from "qrcode.react";

const QrCode = ({ id }) => {
  return (
    <div className="qrcode__container">
      <div style={{ marginLeft: "12px" }}>
        <QRCodeCanvas
          id="qrCode"
          style={{ margin: "10px 0 15px 0" }}
          value={id.toString(10)}
          size={90}
          bgColor={"#fff"}
          level={"H"}
        />
      </div>
    </div>
  );
};

export default QrCode;
