const form = {
  formID: "modal-form",
  formField: {
    fecha: {
      name: "fecha",
      type: "date",
      label: "Fecha",
      errorMsg: "La fecha no puede ser quedar vacia",
    },
    cantidad: {
      name: "cantidad",
      label: "Cantidad*",
      type: "tel",
      errorMsg: "La cantidad es obligatoria",
    },
  },
};

export default form;
