import MDBox from "components/MDBox";
import { Icon, Tooltip } from "@mui/material";
import MDButton from "components/MDButton";
import React from "react";
import {
  useDeleteAsignaturaMutation,
  useLazyGetAsignaturaByIdQuery,
} from "services";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { carrera_state } from "slices/CarrerasSlice/carreras.slice";
import { deleteAsig } from "slices";
import { CARRERAS_LIST_URL } from "config/bases_url";
import { useNavigate } from "react-router-dom";
import DataTable from "examples/Tables/DataTable";
import ComponentsPrivatization, {
  removeColumns,
} from "config/component_privatization";
import { COORDINADOR_DOCENTE } from "roles";
import MDTypography from "components/MDTypography";
import ModalAsignatura from "./modal-asignatura";
import { style_icon } from "helpers/global_vars";
import useModalHandler from "../../../../../hooks/useModalHandler";

export default function TabContent({ ciclo_interno }) {
  const { handleClose, open, handleOpen } = useModalHandler();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { asig } = useSelector(carrera_state);

  const [getAsignaturasById, { data: asignatura }] =
    useLazyGetAsignaturaByIdQuery();

  const [deleteAsignatura] = useDeleteAsignaturaMutation();

  const dataTable = {
    columns: [
      {
        Header: "Nombre",
        accessor: "nombre",
      },
      {
        Header: "Clave",
        accessor: "clave",
      },
      {
        Header: "% de Asistencia",
        accessor: "asistencia_requerida",
      },
      {
        Header: "Acciones",
        accessor: "actions",
        width: "10%",
        Cell: ({ row }) => {
          const { original } = row;

          return (
            <ComponentsPrivatization permitted={COORDINADOR_DOCENTE}>
              <MDBox display="flex">
                <MDBox px={0.2}>
                  <Tooltip placement="top" title="Editar">
                    <Icon
                      style={style_icon}
                      onClick={() => handleClickEdit(original.id)}
                      fontSize="small"
                    >
                      edit
                    </Icon>
                  </Tooltip>
                </MDBox>

                <MDBox px={0.2}>
                  <Tooltip placement="top" title="Eliminar">
                    <Icon
                      style={style_icon}
                      onClick={() => handleDeleteAsig(original)}
                      fontSize="small"
                    >
                      delete
                    </Icon>
                  </Tooltip>
                </MDBox>
              </MDBox>
            </ComponentsPrivatization>
          );
        },
      },
    ],
    rows: asig
      ?.filter((el) => el.ciclo_interno_id === ciclo_interno?.id)
      .map((el) => el.asignatura)
      .sort((a, b) => a.nombre.localeCompare(b.nombre)),
  };

  const handleDeleteAsig = ({ id, nombre }) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: `¡Si eliminas "${nombre}", la acción no se podrá revertir!`,
      icon: "warning",
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Eliminar",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteAsignatura({ id, ciId: ciclo_interno.id })
          .unwrap()
          .then(() => {
            dispatch(deleteAsig(id));
          });
      }
    });
  };

  const handleClickEdit = (id) => {
    getAsignaturasById(id)
      .unwrap()
      .then(() => {
        handleOpen();
      });
  };

  return (
    <MDBox>
      <ModalAsignatura
        open={open}
        handleClose={handleClose}
        ciclo_interno_id={ciclo_interno.id}
        asignatura={asignatura}
      />
      <MDBox
        display="flex"
        justifyContent={"space-between"}
        alignItems={"center"}
        m={2}
        p={3}
      >
        <MDTypography variant="h5" fontWeight="medium">
          Asignaturas
        </MDTypography>

        <ComponentsPrivatization permitted={COORDINADOR_DOCENTE}>
          <MDButton
            variant="gradient"
            color="info"
            size="medium"
            onClick={handleOpen}
          >
            nuevo
          </MDButton>
        </ComponentsPrivatization>
      </MDBox>
      <DataTable
        showTotalEntries={false}
        canSearch={false}
        table={removeColumns(
          dataTable,
          () =>
            !JSON.parse(sessionStorage.getItem("user")).groups.some(
              (role) => role.name === COORDINADOR_DOCENTE,
            ),
          ["actions"],
        )}
      />

      <MDBox mt={3} width="100%" display="flex" justifyContent="flex-end">
        <MDButton
          onClick={() => navigate(CARRERAS_LIST_URL)}
          variant="gradient"
          color="dark"
        >
          regresar
        </MDButton>
      </MDBox>
    </MDBox>
  );
}
