import {createApi} from "@reduxjs/toolkit/query/react";
import {buildFiltersUrl} from "helpers/functions";
import customFetchBase from "services/principal/customFetchBase";

export const cicloApi = createApi({
  reducerPath: "cicloApi",
  baseQuery: customFetchBase,
  tagTypes: ["Ciclo"],
  endpoints: (builder) => ({
    getCiclos: builder.query({
      query: (args) => {
        let url = "/v1/api/ciclo/";
        
        return {
          url: args === "" ? url : buildFiltersUrl(url, args.filter_params),
          method: "GET",
        };
      },
      providesTags: (result) =>
        result
          ? [
            ...result.map(({id}) => ({
              type: "Ciclo",
              id,
            })),
            {type: "Ciclo", id: "LIST"},
          ]
          : [{type: "Ciclo", id: "LIST"}],
    }),
    
    getCicloById: builder.query({
      query: (id) => ({
        url: `/v1/api/ciclo/${id}/`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{type: "Ciclo", id}],
    }),
    
    editCiclo: builder.mutation({
      query: (data) => {
        return {
          url: `/v1/api/ciclo/custom_update/`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: (result, error, {id}) =>
        result
          ? [
            {type: "Ciclo", id},
            {type: "Ciclo", id: "LIST"},
          ]
          : [{type: "Ciclo", id: "LIST"}],
    }),
    
    toggleDivision: builder.mutation({
      query: (id) => {
        return {
          url: `/v1/api/division/${id}/toggle/`,
          method: "POST",
        };
      },
      invalidatesTags: [{type: "Ciclo", id: "LIST"}],
    }),
    
    cerrarCiclo: builder.mutation({
      query: (id) => ({
        url: `/v1/api/ciclo/close/`,
        method: "POST",
      }),
      invalidatesTags: [{type: "Ciclo", id: "LIST"}],
    }),
  }),
});

export const {
  useGetCiclosQuery,
  useLazyGetCiclosQuery,
  useCerrarCicloMutation,
  useToggleDivisionMutation,
  useLazyGetCicloByIdQuery,
  useEditCicloMutation,
} = cicloApi;
