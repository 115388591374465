/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "nueva-unidad",
  formField: {
    nombre: {
      name: "nombre",
      label: "Nombre",
      type: "text",
      errorMsg: "El nombre es requerido.",
    },
    nombre_corto: {
      name: "nombre_corto",
      label: "Nombre Corto",
      type: "text",
      errorMsg: "El nombre corto es requerido.",
    },
    decimal: {
      name: "decimal",
      label: "Es decimal",
      type: "text",
      errorMsg: "Debe especificar si acepta decimales o no.",
    },
  },
};

export default form;
