import form from "./form";

const {
  formField: { fecha, motivo },
} = form;

const initialValues = {
  [fecha.name]: new Date().toISOString().slice(0, 10),
  [motivo.name]: "",
};

export default initialValues;
