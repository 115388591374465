/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { NAME_ENTIDAD } from "helpers/regexs";
import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { nombre, municipio, localidad, dominio, clave_del_centro },
} = checkout;

const validations = [
  Yup.object().shape({
    [nombre.name]: Yup.string()
      .required(nombre.errorMsg)
      .matches(NAME_ENTIDAD, "El nombre no puede contener caracteres extraños"),
    [municipio.name]: Yup.string().required(municipio.errorMsg),
    [localidad.name]: Yup.string().required(localidad.errorMsg),
    [clave_del_centro.name]: Yup.string().required(clave_del_centro.errorMsg),
    [dominio.name]: Yup.string().required(dominio.errorMsg),
  }),
];

export default validations;
