/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DataTable from "examples/Tables/DataTable";

//React Hooks and Others
import { Link, useNavigate } from "react-router-dom";
import { CircularProgress, Icon, Tooltip } from "@mui/material";
import Swal from "sweetalert2";
import PropTypes from "prop-types";
import ComponentsPrivatization from "config/component_privatization";
import { style_icon } from "helpers/global_vars";
import { useRedirectForm } from "hooks/useRedirectForm";
import { AUXILIAR_DOCENTE, COORDINADOR_DOCENTE } from "roles";
import { useCallback, useEffect } from "react";
import {
  useDeleteGrupoEscolarMutation,
  useGetCarrerasPorGrupoQuery,
} from "services";
import usePagination from "hooks/usePagination";
import { setCicloActivo } from "../../../../slices";
import { useDispatch } from "react-redux";
import useModalHandler from "hooks/useModalHandler";
import HistorialComponent from "./components/descarga_historial";

function DataTableGrupos({ tableHeader, ciclo_id, cicle_name, activo }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { offset, setOffset, limit, handleNext, handlePrevious } =
    usePagination();

  const { open, handleOpen, handleClose } = useModalHandler();

  useEffect(() => {
    if (activo) dispatch(setCicloActivo(ciclo_id));
  }, [ciclo_id]);

  const {
    data: gruposEscolares_data,
    isError: isErrorG,
    isLoading: isLoadingG,
    error: errorG,
  } = useGetCarrerasPorGrupoQuery(
    {
      filter_params: {
        ordering:
          "ciclo_interno__numero_de_ciclo,grupo__identificador,ciclo_interno__carrera__nombre",
        limit: limit,
        offset: offset,
        ciclo: ciclo_id,
        query:
          "{id,url,grupo{id,identificador},ciclo_interno{numero_de_ciclo,carrera{nombre,plan_de_estudio}}}",
      },
      shape: {
        id: "id",
        url: "url",
        grupo_id: "grupo.id",
        identificador: "grupo.identificador",
        carrera: "ciclo_interno.carrera.nombre",
        semestre: "ciclo_interno.numero_de_ciclo",
        plan_de_estudio: "ciclo_interno.carrera.plan_de_estudio",
      },
    },
    {
      refetchOnReconnect: true,
    }
  );

  //constantes para manejar la paginacion
  const dataRest = gruposEscolares_data?.results
    ? gruposEscolares_data.results
    : gruposEscolares_data;
  const countTotal = gruposEscolares_data?.count;
  const previous = gruposEscolares_data?.previous;
  const next = gruposEscolares_data?.next;

  const [
    deleteGrupoEscolar,
    {
      isSuccess: isSuccessD,
      isLoading: isLoadingD,
      isError: isErrorD,
      error: errorD,
    },
  ] = useDeleteGrupoEscolarMutation();

  const dataTableDistribucion = {
    columns: [
      {
        Header: "Semestre",
        accessor: "semestre",
        width: "10%",
        Cell: ({ value }) => {
          return value ? value : "-------------";
        },
      },
      {
        Header: "Grupo",
        accessor: "identificador",
        Cell: ({ value }) => {
          return value ? value : "-------------";
        },
      },
      {
        Header: "Carrera",
        accessor: "carrera",
        Cell: ({ value, row: { original } }) => {
          return value
            ? value + " " + original.plan_de_estudio
            : "-------------";
        },
      },
      {
        Header: "Acciones",
        accessor: "actions",
        width: "1%",
        Cell: ({ row }) => {
          const { original } = row;
          return (
            <MDBox display="flex">
              <MDBox px={0.2}>
                <Tooltip
                  placement="top"
                  title="Ver los profesores y estudiantes asociados a este grupo (Puede acceder a esta funncionalidad con doble click en cualquier parte de la fila)"
                >
                  <Icon
                    style={style_icon}
                    onClick={() => handleClickShow(original)}
                    fontSize="small"
                  >
                    find_in_page
                  </Icon>
                </Tooltip>
              </MDBox>
              <ComponentsPrivatization
                permitted={[COORDINADOR_DOCENTE, AUXILIAR_DOCENTE]}
              >
                <MDBox px={0.2}>
                  <Tooltip
                    placement="top"
                    title="Distribuir un profesor con su asignatura correspondiente a este Grupo y agregar varios estudiantes al grupo"
                  >
                    <Icon
                      style={style_icon}
                      onClick={() => handleClickAsignar(original.id)}
                      fontSize="small"
                    >
                      group_add
                    </Icon>
                  </Tooltip>
                </MDBox>
                <MDBox px={0.2}>
                  <Tooltip
                    placement="top"
                    title="Editar la información del grupo"
                  >
                    <Icon
                      style={style_icon}
                      onClick={() => handleClickEdit(original)}
                      fontSize="small"
                    >
                      edit
                    </Icon>
                  </Tooltip>
                </MDBox>
                <MDBox px={0.2}>
                  <Tooltip
                    placement="top"
                    title="Eliminar el grupo del listado"
                  >
                    <Icon
                      style={style_icon}
                      onClick={() =>
                        handleClickErase(
                          original.grupo_id,
                          original.identificador
                        )
                      }
                      fontSize="small"
                    >
                      remove_circle
                    </Icon>
                  </Tooltip>
                </MDBox>
              </ComponentsPrivatization>
            </MDBox>
          );
        },
      },
    ],

    rows: dataRest ?? [],
  };

  useRedirectForm(
    isLoadingG,
    null,
    isErrorG,
    errorG,
    "Hubo problemas para cargar la información",
    `/ciclos/${cicle_name}/grupos`
  );

  useRedirectForm(
    isLoadingD,
    isSuccessD,
    isErrorD,
    errorD,
    "Grupo eliminado",
    `/ciclos/${cicle_name}/grupos`
  );

  const handleClickErase = (id, nombre) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: `¡Si eliminas el grupo: "${nombre}", la acción no se podrá revertir! Esto eliminara todas las asociaciones de estudiantes, profesores y notas al mismo, hagalo bajo su propia responsibilidad.`,
      icon: "warning",
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Eliminar",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteGrupoEscolar(id);
      }
    });
  };

  const handleClickEdit = (el) => {
    navigate(`/ciclos/${cicle_name}/grupos/editar/${el.id}`);
  };

  const handleClickAsignar = useCallback(
    (id) => {
      navigate(
        `/ciclos/${cicle_name}/grupos/distribuciones/grupo/${id}/ciclo_id/${ciclo_id}`
      );
    },
    [cicle_name, ciclo_id]
  );

  const handleClickShow = useCallback(
    (el) => {
      navigate(`/ciclos/${cicle_name}/grupos/grupo/${el.id}/ciclo/${ciclo_id}`);
    },
    [cicle_name, ciclo_id]
  );

  return (
    <MDBox pt={6} pb={3}>
      {open && (
        <HistorialComponent
          open={open}
          handleClose={handleClose}
          ciclo_id={ciclo_id}
        />
      )}
      <Card>
        <MDBox
          display="flex"
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={2}
          flexDirection={{ xs: "column", sm: "row" }}
          m={2}
          p={3}
        >
          <MDTypography variant="h5" fontWeight="medium">
            {tableHeader}
          </MDTypography>
          <ComponentsPrivatization
            permitted={[COORDINADOR_DOCENTE, AUXILIAR_DOCENTE]}
          >
            <MDBox display="flex">
              <MDButton
                variant={"gradient"}
                color={"secondary"}
                size="medium"
                onClick={handleOpen}
                circular
                iconOnly
                sx={{ marginRight: "10px" }}
              >
                <Icon>history</Icon>
              </MDButton>
              <Link to={`/ciclos/${cicle_name}/grupos/promover`}>
                <Tooltip
                  placement="top"
                  title="Crear nuevo grupo y asociarlo a un ciclo interno pero con los estudiantes de un grupo anterior"
                >
                  <MDButton
                    variant="gradient"
                    color="dark"
                    size="medium"
                    sx={{ marginRight: "10px" }}
                  >
                    Clonar
                  </MDButton>
                </Tooltip>
              </Link>
              <Link to={`/ciclos/${cicle_name}/grupos/nuevo`}>
                <Tooltip
                  placement="top"
                  title="Crear nuevo grupo y asociarlo a un ciclo interno de una carrera ya existente"
                >
                  <MDButton variant="gradient" color="info" size="medium">
                    Nuevo
                  </MDButton>
                </Tooltip>
              </Link>
            </MDBox>
          </ComponentsPrivatization>
        </MDBox>

        <MDBox display="flex" justifyContent={"center"} alignItems={"center"}>
          {isLoadingG ? (
            <CircularProgress sx={{ marginBottom: "3rem" }} color="inherit" />
          ) : (
            <DataTable
              table={dataTableDistribucion}
              canSearch
              doubleClick={handleClickShow}
              paginationApi
              countTotal={countTotal}
              next={next}
              previous={previous}
              handleNext={handleNext}
              handlePrevious={handlePrevious}
              offset={offset}
              setOffset={setOffset}
            />
          )}
        </MDBox>
      </Card>
    </MDBox>
  );
}

DataTableGrupos.propTypes = {
  tableHeader: PropTypes.string.isRequired,
};

export default DataTableGrupos;
