import MDBox from "components/MDBox";
import PrintButton from "layouts/almacen/pdf/printing/PrintButton";
import PrintRoot from "layouts/almacen/pdf/printing/print";
import { NoPrint } from "layouts/almacen/pdf/printing/print";
import ActaCalificaciones from "../acta_calificacione";

function ActaNotas({ profesor, ordinaria, semestre, cicle_name }) {
  return (
    <div
      style={{
        width: "216mm",
        margin: "auto",
        backgroundColor: "white",
        marginRight: "10px",
      }}
    >
      <PrintRoot>
        <NoPrint>
          <MDBox
            sx={{
              display: "grid",
              justifyItems: "flex-end",
              marginRight: "10px",
            }}
          >
            <PrintButton />
          </MDBox>
        </NoPrint>
        <ActaCalificaciones
          profesor={profesor}
          ordinaria={ordinaria}
          semestre={semestre}
          cicle_name={cicle_name}
        />
      </PrintRoot>
    </div>
  );
}

export default ActaNotas;
