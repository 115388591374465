/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as Yup from "yup";
import checkout from "./form";

const {
  formField: {
    nombre,
    modalidad,
    nombre_corto,
    duracion,
    fecha_inicio,
    fecha_fin,
    expedicion,
    firmante,
    localizacion,
  },
} = checkout;

const validations = [
  Yup.object().shape({
    [nombre.name]: Yup.string().required(nombre.errorMsg),
    [modalidad.name]: Yup.string().required(modalidad.errorMsg),
    [firmante.name]: Yup.string().required(firmante.errorMsg),
    [nombre_corto.name]: Yup.string().required(nombre_corto.errorMsg),
    [expedicion.name]: Yup.date().required(expedicion.errorMsg),
    [fecha_inicio.name]: Yup.date().required(fecha_inicio.errorMsg),
    [fecha_fin.name]: Yup.date().required(fecha_fin.errorMsg),
    [duracion.name]: Yup.string().required(duracion.errorMsg),
    [localizacion.name]: Yup.string().required(localizacion.errorMsg),
  }),
];

export default validations;
