/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// NewUser page components
import FormField from "components/FormField";
import MDTypography from "components/MDTypography";
import MDImage from "components/MDImage/image";
import {useSelector} from "react-redux";
import {Icon, Tooltip} from "@mui/material";
import {admin_state} from "slices";
import {useSnackbar} from "notistack";

const style = {
  width: "100%",
  cursor: "pointer",
  borderRadius: "0.5rem",
  marginTop: "0.5rem",
  marginLeft: "0.5rem",
};

const icon_style = {
  position: "absolute",
  left: "50%",
  top: "50%",
  fontSize: "30px",
  transform: "translate(-50%,-50%)",
};

function AddEntidad({ formData }) {
  const { formField, values, errors, touched, setFieldValue } = formData;
  const { nombre, logo, municipio, localidad, clave_del_centro, dominio } =
    formField;
  const {
    nombre: nombreE,
    logo: logoE,
    municipio: municipioE,
    dominio: dominioE,
    localidad: localidadE,
    clave_del_centro: clave_del_centroE,
  } = values;

  const { edit } = useSelector(admin_state);
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (e, isValid) => {
    if (isValid) setFieldValue(logo.name, e.target.files[0]);
    else {
      enqueueSnackbar("El tamaño de la imagen no debe ser mayor a 1MB", {
        variant: "error",
        autoHideDuration: 4000,
      });
    }
  };

  return (
    <MDBox>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <FormField
            type={nombre.type}
            label={nombre.label}
            name={nombre.name}
            value={nombreE}
            placeholder={nombre.placeholder}
            error={errors.nombre && touched.nombre}
            success={nombreE?.length > 0 && !errors.nombre}
            onInput={(e) =>
              (e.target.value = ("" + e.target.value).toUpperCase())
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormField
            type={clave_del_centro.type}
            label={clave_del_centro.label}
            name={clave_del_centro.name}
            value={clave_del_centroE}
            placeholder={clave_del_centro.placeholder}
            error={errors.clave_del_centro && touched.clave_del_centro}
            success={clave_del_centroE?.length > 0 && !errors.clave_del_centro}
            onInput={(e) =>
              (e.target.value = ("" + e.target.value).toUpperCase())
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <FormField
            type={municipio.type}
            label={municipio.label}
            name={municipio.name}
            value={municipioE}
            placeholder={municipio.placeholder}
            error={errors.municipio && touched.municipio}
            success={municipioE?.length > 0 && !errors.municipio}
            onInput={(e) =>
              (e.target.value = ("" + e.target.value).toUpperCase())
            }
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormField
            type={localidad.type}
            label={localidad.label}
            name={localidad.name}
            value={localidadE}
            placeholder={localidad.placeholder}
            error={errors.localidad && touched.localidad}
            success={localidadE?.length > 0 && !errors.localidad}
            onInput={(e) =>
              (e.target.value = ("" + e.target.value).toUpperCase())
            }
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormField
            type={dominio.type}
            label={dominio.label}
            name={dominio.name}
            value={dominioE}
            placeholder={dominio.placeholder}
            error={errors.dominio && touched.dominio}
            success={dominioE?.length > 0 && !errors.dominio}
            // onInput={(e) =>
            //   (e.target.value = ("" + e.target.value).toUpperCase())
            // }
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} display="flex" justifyContent={"center"}>
        <Grid item xs={12} sm={6} justifyContent="center">
          <MDBox>
            <MDTypography
              variant="h6"
              fontWeight="bold"
              flex
              justifyContent="center"
              textAlign="center"
            >
              Logo:
            </MDTypography>
          </MDBox>
          <MDBox mt={1.625} display="flex" justifyContent="center">
            <Tooltip
              placement="top"
              title={
                !edit
                  ? !logoE
                    ? "Agregar imagen"
                    : "Cambiar Foto"
                  : "Agregar imagen"
              }
            >
              <MDBox
                style={{
                  position: "relative",
                  width: "200px",
                  cursor: "pointer",
                }}
              >
                {!logoE ? (
                  <Icon fontSize="medium" style={icon_style}>
                    image_search
                  </Icon>
                ) : null}

                <MDImage
                  id="profile-image"
                  htmlFor="profile-image"
                  disabled={false}
                  style={style}
                  src={
                    logoE && typeof logoE === "object"
                      ? URL.createObjectURL(logoE)
                      : logoE
                  }
                  imageTypes={logo.accept}
                  handleChange={handleChange}
                />
              </MDBox>
            </Tooltip>
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}

// typechecking props for EntidadInfo
AddEntidad.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default AddEntidad;
