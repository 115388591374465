import { buildFiltersUrl, reshape_response } from "helpers/functions";
import customFetchBase from "../../principal/customFetchBase";
import { createApi } from "@reduxjs/toolkit/query/react";

export const carrerasPorGrupoApi = createApi({
  reducerPath: "carrerasPorGrupoApi",
  baseQuery: customFetchBase,
  tagTypes: ["CarrerasPorGrupo"],
  endpoints: (builder) => ({
    getCarrerasPorGrupo: builder.query({
      query: ({ filter_params }) => ({
        url: buildFiltersUrl(
          `/v1/api/distribuciondecarreraporgrupo/`,
          filter_params
        ),
        method: "GET",
      }),
      providesTags: (result) =>
        Array.isArray(result)
          ? [
              ...result.map(({ id }) => ({
                type: "CarrerasPorGrupo",
                id,
              })),
              { type: "CarrerasPorGrupo", id: "LIST" },
            ]
          : [{ type: "CarrerasPorGrupo", id: "LIST" }],
      transformResponse: (response, meta, args) => {
        if (Array.isArray(response)) {
          return response?.map((el) => reshape_response(el, args.shape));
        } else {
          const resultsTransformed = response?.results?.map((el) =>
            reshape_response(el, args.shape)
          );
          return {
            results: resultsTransformed,
            count: response.count,
            next: response.next,
            previous: response.previous,
          };
        }
      },
    }),
    getCarrerasPorGrupoById: builder.query({
      query: (id) => ({
        url: `/v1/api/distribuciondecarreraporgrupo/${id}/`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "CarrerasPorGrupo", id }],
    }),
    createCarrerasPorGrupo: builder.mutation({
      query: (data) => ({
        url: "/v1/api/distribuciondecarreraporgrupo/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "CarrerasPorGrupo", id: "LIST" }],
    }),
    promoverGrupoEscolar: builder.mutation({
      query: ({ id, identificador }) => ({
        url: `/v1/api/distribuciondecarreraporgrupo/${id}/clone/`,
        method: "POST",
        body: { identificador },
      }),
      invalidatesTags: [{ type: "CarrerasPorGrupo", id: "LIST" }],
    }),
    deleteCarrerasPorGrupo: builder.mutation({
      query: (id) => ({
        url: `/v1/api/distribuciondecarreraporgrupo/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "CarrerasPorGrupo", id: "LIST" }],
    }),
    editCarrerasPorGrupo: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `/v1/api/distribuciondecarreraporgrupo/${id}/`,
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: (result, error, { id }) =>
        result
          ? [
              { type: "CarrerasPorGrupo", id },
              { type: "CarrerasPorGrupo", id: "LIST" },
            ]
          : [{ type: "CarrerasPorGrupo", id: "LIST" }],
    }),
  }),
});

export const {
  useGetCarrerasPorGrupoQuery,
  useLazyGetCarrerasPorGrupoQuery,
  useEditCarrerasPorGrupoMutation,
  usePromoverGrupoEscolarMutation,
  useDeleteCarrerasPorGrupoMutation,
  useLazyGetCarrerasPorGrupoByIdQuery,
  useGetCarrerasPorGrupoByIdQuery,
  useCreateCarrerasPorGrupoMutation,
} = carrerasPorGrupoApi;
