import {userApi} from "../../view/users.service";

export const deleteUser = userApi.injectEndpoints({
  endpoints: (builder) => ({
    deleteUser: builder.mutation({
      query: (id) => ({
        url: `/v3/api/registro/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [{type: "Users", id: "LIST"}],
    }),
  }),
})

export const {
  useDeleteUserMutation,
} = deleteUser;