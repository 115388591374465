import * as React from "react";
import PropTypes from "prop-types";
import Tab from "@mui/material/Tab";
import MDBox from "components/MDBox";
import { Card } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { calificaciones, setTab } from "slices";

export default function TabsCardCalificaciones({ tabs }) {
  const dispatch = useDispatch();
  const { tab } = useSelector(calificaciones);

  const handleChange = (event, newValue) => {
    dispatch(setTab(newValue));
  };

  return (
    <TabContext value={tab}>
      <MDBox
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          borderBottom: 1,
          borderColor: "divider",
          borderRadius: "1rem",
        }}
      >
        <TabList value={tab} onChange={handleChange} aria-label="tabs">
          {tabs.map((el) => (
            <Tab
              key={el.id}
              label={el.label}
              value={el.id}
              disabled={el.disabled}
              sx={{
                px: 1.5,
                fontSize: { xs: "0.75rem", sm: "0.875rem", md: "1rem" },
              }}
            />
          ))}
        </TabList>
      </MDBox>
      {tabs.map((el) => (
        <Card key={el.id} sx={{ width: "100%" }}>
          <TabPanel key={el.id} value={el.id}>
            {el.element}
          </TabPanel>
        </Card>
      ))}
    </TabContext>
  );
}

TabsCardCalificaciones.propTypes = {
  tabs: PropTypes.array.isRequired,
};
