/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// NewUser page components
import FormField from "components/FormField";
import { MenuItem } from "@mui/material";

function AddUnidad({ formData }) {
  const { formField, values, errors, touched } = formData;
  const { nombre, nombre_corto, decimal } = formField;
  const {
    nombre: nombreV,
    nombre_corto: nombre_cortoV,
    decimal: decimalV,
  } = values;

  return (
    <MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              type={nombre.type}
              label={nombre.label}
              name={nombre.name}
              value={nombreV}
              placeholder={nombre.placeholder}
              error={errors.nombre && touched.nombre}
              success={nombreV.length > 0 && !errors.nombre}
              onInput={(e) =>
                (e.target.value = ("" + e.target.value).toUpperCase())
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={nombre_corto.type}
              label={nombre_corto.label}
              name={nombre_corto.name}
              value={nombre_cortoV}
              placeholder={nombre_corto.placeholder}
              error={errors.nombre_corto && touched.nombre_corto}
              success={nombre_cortoV.length > 0 && !errors.nombre_corto}
              onInput={(e) =>
                (e.target.value = ("" + e.target.value).toUpperCase())
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              select
              type={decimal.type}
              label={decimal.label}
              name={decimal.name}
              value={decimalV}
              placeholder={decimal.placeholder}
              error={errors.decimal && touched.decimal}
              success={decimalV.length > 0 && !errors.decimal}
            >
              <MenuItem key="1" value={true}>
                Si
              </MenuItem>
              <MenuItem key="2" value={false}>
                No
              </MenuItem>
            </FormField>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for UserInfo
AddUnidad.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default AddUnidad;
