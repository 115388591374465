// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";

import MDInput from "../../../../components/MDInput";

function BasicInfo() {
  const user_info = JSON.parse(sessionStorage.getItem("user"));

  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Información Básica</MDTypography>
        <Grid container spacing={2} pt={3}>
          <Grid item xs={12} sm={4}>
            <MDInput
              variant={"outlined"}
              label="Nombre(s)"
              name="first_name"
              value={user_info.first_name}
              InputProps={{
                readOnly: true,
              }}
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <MDInput
              variant={"outlined"}
              label="Apellido Paterno"
              name="apellido_paterno"
              placeholder="Perez"
              value={user_info.apellido_paterno}
              InputProps={{
                readOnly: true,
              }}
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <MDInput
              variant={"outlined"}
              label="Apellido Materno"
              placeholder="Perez"
              name="apellido_materno"
              value={user_info.apellido_materno}
              InputProps={{
                readOnly: true,
              }}
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDInput
              variant={"outlined"}
              label="Usuario"
              value={user_info.username.toUpperCase()}
              InputProps={{
                readOnly: true,
              }}
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDInput
              variant={"outlined"}
              label="Email"
              value={user_info.email}
              type="email"
              InputProps={{
                readOnly: true,
              }}
              sx={{ width: "100%" }}
            />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default BasicInfo;
