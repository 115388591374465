import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

export const FiltersComponent = ({
  values,
  handleChange,
  grupos,
  profesores,
}) => {
  return (
    <>
      <FormControl sx={{ m: 1, minWidth: "14rem" }} size="large">
        <InputLabel id="demo-simple-select-label">Grupo</InputLabel>
        <Select
          sx={{ minHeight: "37.25px" }}
          labelId="demo-simple-select-label"
          name="grupo"
          value={values.grupo}
          label="Grupo"
          onChange={handleChange}
        >
          <MenuItem value="">Limpiar</MenuItem>
          {grupos
            ?.filter(
              (obj, index, self) =>
                index ===
                self.findIndex(
                  (t) =>
                    t.grupo_ident === obj.grupo_ident &&
                    t.grupo_id === obj.grupo_id,
                ),
            )
            .map((el) => (
              <MenuItem key={el.grupo_id} value={el.grupo_id}>
                {el.grupo_ident}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: "14rem" }} size="large">
        <InputLabel id="profesores-fitler">Profesor</InputLabel>
        <Select
          sx={{ minHeight: "37.25px" }}
          labelId="profesores-fitler"
          name="profesor"
          value={values.profesor}
          label="Profesor"
          onChange={handleChange}
        >
          <MenuItem value="">Limpiar</MenuItem>
          {profesores
            ?.filter(
              (obj, index, self) =>
                index ===
                self.findIndex((t) => t.profesor_id === obj.profesor_id),
            )
            .map((el) => (
              <MenuItem key={el.profesor_id} value={el.profesor_id}>
                {el.profesor_name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </>
  );
};
