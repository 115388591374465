import * as Yup from "yup";

export const formularioProf = {
  form: {
    formId: "distribucion-Grupo",
    formField: {
      distribucion_grupo: {
        name: "distribucion_grupo",
        label: "Grupo",
        type: "text",
        errorMsg: "El grupo no puede quedar vacio",
      },
      profesor: {
        name: "profesor",
        label: "Profesor",
        type: "text",
        errorMsg: "El Profesor es obligatorio",
      },
      asignatura: {
        name: "asignatura",
        label: "Asignatura",
        type: "text",
        errorMsg: "La Asignatura es obligatoria",
      },
      estudiante: {
        name: "estudiante",
        label: "Estudiante",
        type: "text",
        errorMsg: "EL estudiante es obligatorio",
      },
      estudiantes: {
        name: "estudiantes",
        label: "Estudiantes",
        type: "text",
        errorMsg: "Los estudiantes son obligatorios",
      },
    },
  },
  initialValues: {
    grupo: "",
    profesor: "",
    asignatura: "",
    estudiante: "",
    estudiantes: [],
  },
  validationSchema: Yup.object({}),
};
