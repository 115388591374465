/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useEffect, useState} from "react";
import {useSelector} from "react-redux";

// react-router components
import {useLocation} from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import Swal from "sweetalert2";

// Material Dashboard 2 PRO React examples
import Breadcrumbs from "examples/Breadcrumbs";

// Custom styles for DashboardNavbar
import {
    navbar,
    navbarContainer,
    navbarDesktopMenu,
    navbarIconButton,
    navbarMobileMenu,
    navbarRow,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 PRO React context
import {setMiniSidenav, setOpenConfigurator, setTransparentNavbar, useMaterialUIController,} from "context";

import {auth_state} from "slices";
import MDTypography from "components/MDTypography";
import {LOGIN} from "config/bases_url";
import {useLogoutUserMutation} from "services/principal/auth.service";
import {Tooltip} from "@mui/material";
import {useRedirectForm} from "../../../hooks/useRedirectForm";

function DashboardNavbar({absolute, light, isMini}) {
    const [navbarType, setNavbarType] = useState();
    const [controller, dispatch] = useMaterialUIController();
    const {authenticated} = useSelector(auth_state);
    const {
        miniSidenav,
        transparentNavbar,
        fixedNavbar,
        openConfigurator,
        darkMode,
    } = controller;
    const route = useLocation().pathname.split("/").slice(1);
    const [logoutUser, {isSuccess, isError, isLoading, error}] =
        useLogoutUserMutation();

    useEffect(() => {
        // Setting the navbar type
        if (fixedNavbar) {
            setNavbarType("sticky");
        } else {
            setNavbarType("static");
        }

        // A function that sets the transparent state of the navbar.
        function handleTransparentNavbar() {
            setTransparentNavbar(
                dispatch,
                (fixedNavbar && window.scrollY === 0) || !fixedNavbar
            );
        }

        /**
         The event listener that's calling the handleTransparentNavbar function when
         scrolling the window.
         */
        window.addEventListener("scroll", handleTransparentNavbar);

        // Call the handleTransparentNavbar function to set the state with the initial value.
        handleTransparentNavbar();

        // Remove event listener on cleanup
        return () => window.removeEventListener("scroll", handleTransparentNavbar);
    }, [dispatch, fixedNavbar]);

    const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
    const handleConfiguratorOpen = () =>
        setOpenConfigurator(dispatch, !openConfigurator);

    // Styles for the navbar icons
    const iconsStyle = ({
                            palette: {dark, white, text},
                            functions: {rgba},
                        }) => ({
        color: () => {
            let colorValue = light || darkMode ? white.main : dark.main;

            if (transparentNavbar && !light) {
                colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
            }

            return colorValue;
        },
    });

    useRedirectForm(
        isLoading,
        isSuccess,
        isError,
        error,
        "Sesión cerrada correctamente",
        LOGIN
    );

    const handleClick = () => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¿Estás seguro de querer cerrar la sesión?",
            icon: "question",
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "Cancelar",
            confirmButtonText: "Aceptar",
        }).then((result) => {
            if (result.isConfirmed) {
                logoutUser();
            }
        });
    };

    return (
        <AppBar
            position={absolute ? "absolute" : navbarType}
            color="inherit"
            sx={(theme) =>
                navbar(theme, {transparentNavbar, absolute, light, darkMode})
            }
        >
            <Toolbar sx={(theme) => navbarContainer(theme)}>
                <MDBox
                    color="inherit"
                    mb={{xs: 1, md: 0}}
                    sx={(theme) => navbarRow(theme, {isMini})}
                >
                    <Breadcrumbs
                        icon="home"
                        title={route[route.length - 1]}
                        route={route}
                        light={light}
                    />
                    <Tooltip
                        placement="top"
                        title={miniSidenav ? "Abrir menú" : "Contraer menú"}
                    >
                        <IconButton
                            sx={navbarDesktopMenu}
                            onClick={handleMiniSidenav}
                            size="small"
                            disableRipple
                        >
                            <Icon fontSize="medium" sx={iconsStyle}>
                                {miniSidenav ? "menu_open" : "menu"}
                            </Icon>
                        </IconButton>
                    </Tooltip>
                </MDBox>
                {isMini ? null : (
                    <MDBox sx={(theme) => navbarRow(theme, {isMini})}>
                        <MDBox pr={1}></MDBox>
                        <MDBox color={light ? "white" : "inherit"}>
                            <IconButton sx={navbarIconButton} size="small" disableRipple>
                                <Icon fontSize="large" sx={iconsStyle}>
                                    account_circle
                                </Icon>
                                {authenticated && (
                                    <MDTypography fontWeight="bold" pb={0.01} pl={0.2}>
                                        {JSON.parse(sessionStorage?.getItem("user"))
                                            ?.first_name !== ""
                                            ? JSON.parse(sessionStorage.getItem("user"))
                                                ?.first_name
                                            : null}
                                    </MDTypography>
                                )}
                            </IconButton>
                            <Tooltip placement="top" title="Cerrar sesión">
                                <IconButton
                                    sx={navbarIconButton}
                                    size="small"
                                    onClick={handleClick}
                                    disableRipple
                                >
                                    <Icon sx={iconsStyle}>logout</Icon>
                                </IconButton>
                            </Tooltip>
                            <Tooltip
                                placement="top"
                                title={miniSidenav ? "Abrir menú" : "Contraer menú"}
                            >
                                <IconButton
                                    size="small"
                                    disableRipple
                                    color="inherit"
                                    sx={navbarMobileMenu}
                                    onClick={handleMiniSidenav}
                                >
                                    <Icon sx={iconsStyle} fontSize="medium">
                                        {miniSidenav ? "menu_open" : "menu"}
                                    </Icon>
                                </IconButton>
                            </Tooltip>

                            <Tooltip placement="top" title="Configuración">
                                <IconButton
                                    size="small"
                                    disableRipple
                                    color="inherit"
                                    sx={navbarIconButton}
                                    onClick={handleConfiguratorOpen}
                                >
                                    <Icon sx={iconsStyle}>settings</Icon>
                                </IconButton>
                            </Tooltip>
                        </MDBox>
                    </MDBox>
                )}
            </Toolbar>
        </AppBar>
    );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
    absolute: false,
    light: false,
    isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
    absolute: PropTypes.bool,
    light: PropTypes.bool,
    isMini: PropTypes.bool,
};

export default DashboardNavbar;
