/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DataTable from "examples/Tables/DataTable";
import { CircularProgress, Icon, Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { style_icon } from "helpers/global_vars";
import { useGetEstudiantesPorGrupoQuery } from "services";
import { ESTUDIANTE } from "roles";
import { useNavigate } from "react-router-dom";
import MDTypography from "../../../components/MDTypography";
import { useGetBoletasQuery } from "services";
import { QRCodeCanvas } from "qrcode.react";
import { generarURL } from "helpers/functions";
import { pdf } from "@react-pdf/renderer";
import BoletaFirmada from "layouts/escuela/pdf/reactPdf/boleta";
import BoletaSinFirmar from "layouts/escuela/pdf/reactPdf/boletSinFirmar";

function DataTableDocBoletas({ tableHeader }) {
  const authUser = JSON.parse(sessionStorage.getItem("user"));
  const navigate = useNavigate();

  const {
    data: estPorGrupo,
    isLoading,
    isFetching,
  } = useGetEstudiantesPorGrupoQuery(
    {
      filter_params: {
        estudiante: authUser.perfil.resourcetype.find(
          (e) => e.resourcetype === ESTUDIANTE
        ).id,
        query:
          "{distribucion_grupo{ciclo{nombre,activo},ciclo_interno{numero_de_ciclo,carrera{nombre}},grupo{identificador}},id,firmado,notas,estudiante{user{first_name,apellido_paterno,apellido_materno},numero_de_control}}",
      },
      shape: {
        nombre: "estudiante.user.first_name",
        apellido_materno: "estudiante.user.apellido_materno",
        apellido_paterno: "estudiante.user.apellido_paterno",
        numero_de_control: "estudiante.numero_de_control",
        id: "id",
        carrera: "distribucion_grupo.ciclo_interno.carrera.nombre",
        ciclo: "distribucion_grupo.ciclo.nombre",
        activo: "distribucion_grupo.ciclo.activo",
        semestre: "distribucion_grupo.ciclo_interno.numero_de_ciclo",
        grupo: "distribucion_grupo.grupo.identificador",
        firmado: "firmado",
        notas: "notas",
      },
    },
    {
      refetchOnReconnect: true,
    }
  );

  const { data: getBoleta } = useGetBoletasQuery({
    filter_params: {
      signed_object__estudiante: authUser.perfil.resourcetype.find(
        (e) => e.resourcetype === ESTUDIANTE
      ).id,
      query:
        "{id,firmante{cargo,firma,user{first_name,apellido_paterno,apellido_materno}},fecha_creacion,sello,signed_object{id,distribucion_grupo{ciclo{nombre},ciclo_interno{numero_de_ciclo,carrera{nombre}},grupo{identificador}}}}",
    },
    shape: {
      nombre: "firmante.user.first_name",
      paterno: "firmante.user.apellido_paterno",
      materno: "firmante.user.apellido_materno",
      cargo: "firmante.cargo",
      firma: "firmante.firma",
      fecha: "fecha_creacion",
      sello: "sello",
      id: "id",
      dist_est: "signed_object.id",
    },
  });

  const data = useMemo(() => {
    return {
      columns: [
        {
          Header: "Ciclo",
          accessor: "ciclo",
          Cell: ({ value }) => {
            return value ? value : "-------------";
          },
        },
        {
          Header: "Ciclo Interno",
          accessor: "semestre",
        },
        {
          Header: "Carrera",
          accessor: "carrera",
        },
        {
          Header: "Grupo",
          accessor: "grupo",
        },
        {
          Header: "Acciones",
          accessor: "actions",
          width: "1%",
          Cell: ({ row }) => {
            const { original } = row;
            const { activo, firmado } = original;
            if (activo) {
              return (
                firmado && (
                  <MDBox display="flex">
                    <MDBox px={0.2}>
                      <Tooltip
                        placement="top"
                        title="Ver vista previa de la boleta de notas del estudiante"
                      >
                        <Icon
                          style={style_icon}
                          onClick={() => handleClickShow(original)}
                          fontSize="small"
                        >
                          picture_as_pdf
                        </Icon>
                      </Tooltip>
                    </MDBox>
                  </MDBox>
                )
              );
            } else {
              return (
                <MDBox display="flex">
                  <MDBox px={0.2}>
                    <Tooltip
                      placement="top"
                      title="Ver vista previa de la boleta de notas del estudiante"
                    >
                      <Icon
                        style={style_icon}
                        onClick={() => handleClickShow(original)}
                        fontSize="small"
                      >
                        picture_as_pdf
                      </Icon>
                    </Tooltip>
                  </MDBox>
                </MDBox>
              );
            }
          },
        },
      ],

      rows: estPorGrupo ?? [],
    };
  }, [estPorGrupo]);

  const handleClickShow = (original) => {
    const { firmado } = original;
    if (firmado) {
      let boletaCorrespondiente = getBoleta.find(
        (elem) => elem.dist_est === original.id
      );
      const canvas = document.getElementById(
        `qrCodeCertificado-${boletaCorrespondiente.id}`
      );
      if (canvas) {
        const dataURL = canvas.toDataURL();
        if (dataURL) {
          let obj = pdf(
            <BoletaFirmada
              boleta={original.notas}
              estudiante={original}
              qrImageSrc={dataURL}
              firma={boletaCorrespondiente}
            />
          ).toBlob();
          obj.then(function (blob) {
            let url = URL.createObjectURL(blob);
            window.open(url, "_blank");
          });
        }
      }
    } else {
      let obj = pdf(
        <BoletaSinFirmar boleta={original.notas} estudiante={original} />
      ).toBlob();
      obj.then(function (blob) {
        let url = URL.createObjectURL(blob);
        window.open(url, "_blank");
      });
    }
  };

  return (
    <MDBox pt={6} pb={3}>
      {getBoleta?.map((boleta) => {
        return (
          <QRCodeCanvas
            id={`qrCodeCertificado-${boleta.id}`}
            key={boleta.id}
            style={{
              width: "3.6cm",
              height: "3.6cm",
              display: "none",
            }}
            value={generarURL(boleta.id, false)}
            bgColor={"#fff"}
            size={90}
            level={"H"}
            imageSettings={{ height: 3.6, width: 3.6 }}
          />
        );
      })}
      <Card>
        <MDBox
          display="flex"
          justifyContent={"space-between"}
          alignItems={"center"}
          m={2}
          p={3}
        >
          <MDTypography variant="h5" fontWeight="medium">
            {tableHeader}
          </MDTypography>
        </MDBox>

        <MDBox display="flex" justifyContent={"center"} alignItems={"center"}>
          {isLoading || isFetching ? (
            <CircularProgress sx={{ marginBottom: "3rem" }} color="inherit" />
          ) : (
            <DataTable table={data} canSearch entriesPerPage={false} />
          )}
        </MDBox>
      </Card>
    </MDBox>
  );
}

DataTableDocBoletas.propTypes = {
  tableHeader: PropTypes.string.isRequired,
};

export default DataTableDocBoletas;
