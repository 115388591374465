import { examenApi } from "../view/examen.service";

export const deleteExamen = examenApi.injectEndpoints({
  endpoints: (builder) => ({
    deleteExamen: builder.mutation({
      query: ({ id }) => ({
        url: `/v1/api/examenentidad/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Examenes", id: "LIST" }],
    }),
  }),
});

export const { useDeleteExamenMutation } = deleteExamen;
