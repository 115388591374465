import { Divider } from "@mui/material";
import "./styles/calificaciones.css";
import { useEffect, useState } from "react";

// Images
import logoderecha from "assets/images/tecnoschool/cren_benito/logoderechaMenos.png";
import { useNotasEstudiantesQuery } from "services";
import { useGetAsignaturasPorProfesorByIdQuery } from "services";

const changePalabra = (numb) => {
  switch (numb) {
    case "1":
      return "PRIMERO";
    case "2":
      return "SEGUNDO";
    case "3":
      return "TERCERO";
    case "4":
      return "CUARTO";
    case "5":
      return "QUINTO";
    case "6":
      return "SEXTO";
    case "7":
      return "SÉPTIMO";
    case "8":
      return "OCTAVO";
    case "9":
      return "NOVENO";
    case "10":
      return "DÉCIMO";
    default:
      break;
  }
};
const changeJefeControEscolar = (clave) => {
  switch (clave) {
    case "13DNL0004E":
      return "MTRO. RICARDO CARPIO SAGAHÓN";
    case "13DLN0002G":
      return "LIC. CASANDRA ALHELI ORTEGA RONDERO";
  }
};

const changeDirectorPlantel = (clave) => {
  switch (clave) {
    case "13DNL0004E":
      return "MTRO. JOSÉ CUATEPOTZO COSTEIRA";
    case "13DLN0002G":
      return "MTRA. MARÍA DE JESÚS LEINES SOLARES";
  }
};
const ActaCalificaciones = ({ profesor, ordinaria, semestre, cicle_name }) => {
  const [profesorState, setProfesorState] = useState();
  const [licenciaturaState, setLicenciaturaState] = useState();
  const [semestreState, setSemestreState] = useState();
  const [cursoState, setCursoState] = useState();
  const [grupoState, setGrupoState] = useState();
  const [claveState, setClaveState] = useState();
  const date = new Date();
  const dateString = date.toLocaleDateString("es-ES", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  });

  const user = JSON.parse(sessionStorage.getItem("user"));
  const logo = JSON.parse(sessionStorage.getItem("user"))?.perfil?.entidad
    ?.logo;
  const claveEntidad = JSON.parse(sessionStorage.getItem("user"))?.perfil
    ?.entidad?.clave_del_centro;

  const { data: distProf } = useGetAsignaturasPorProfesorByIdQuery(profesor);

  const { data: evaluaciones } = useNotasEstudiantesQuery(
    {
      id: profesor,
      filter_params: {
        query:
          "{estudiante{id,url,distribucion_grupo{grupo{identificador}}, estudiante{numero_de_control, user{first_name, apellido_paterno, apellido_materno}}}, notas{id,nota_final,aprobado,asistencias,porcentaje_de_asistencia,faltas,examen{nombre}}}",
      },
    },
    {
      refetchOnReconnect: true,
    }
  );

  useEffect(() => {
    if (distProf) {
      setProfesorState(
        `${distProf?.profesor?.user?.first_name} ${distProf?.profesor?.user?.apellido_paterno} ${distProf?.profesor?.user?.apellido_materno}`
      );
      setLicenciaturaState(
        `${distProf?.distribucion_grupo?.ciclo_interno?.carrera?.nombre}`
      );
      setSemestreState(
        changePalabra(
          `${distProf?.distribucion_grupo?.ciclo_interno?.numero_de_ciclo}`
        )
      );
      setCursoState(`${distProf?.asignatura?.nombre}`);
      setGrupoState(`${distProf?.distribucion_grupo?.grupo?.identificador}`);
      setClaveState(`${distProf?.asignatura.clave}`);
    }
  }, [distProf]);

  let i = 1;

  const createTbody = (ordinaria) => {
    switch (ordinaria) {
      case "1":
        return (
          <tbody>
            <tr>
              <th>No.</th>
              <th>MATRÍCULA</th>
              <th>NOMBRE y APELIIDOS</th>
              <th>PROMEDIO FINAL</th>
              <th>TOTAL FALTAS</th>
              <th>TOTAL ASISTENCIAS</th>
              <th>% ASISTENCIAS</th>
              <th>OBSERVACIONES</th>
            </tr>
            {evaluaciones?.map((el) => (
              <tr key={el.id_dist_estu}>
                <td>{i++}</td>
                <td className="text-matricula">
                  {el.numero_de_control ?? "---"}
                </td>
                <td className="text-nombre">{el.nombre ?? "---"}</td>
                <td className="text">{el.ordinario?.nota_final ?? "---"}</td>
                <td className="text">{el.ordinario?.faltas ?? "---"}</td>
                <td className="text">{el.ordinario?.asistencias ?? "---"}</td>
                <td className="text">
                  {el.ordinario?.porcentaje_de_asistencia !== undefined
                    ? Math.trunc(el.ordinario?.porcentaje_de_asistencia)
                    : "---"}
                </td>
                <td className="text">
                  {el.ordinario !== undefined
                    ? el.ordinario?.aprobado === true
                      ? ""
                      : "No Acreditado"
                    : "---"}
                </td>
              </tr>
            ))}
          </tbody>
        );
      case "2":
        return (
          <tbody>
            <tr>
              <th>No.</th>
              <th>MATRÍCULA</th>
              <th>NOMBRE y APELLIDOS</th>
              <th>CALIFICACIÓN PERIODO ORDINARIO</th>
              <th>OPORTUNIDAD 1 de 2</th>
            </tr>
            {evaluaciones
              ?.filter((el) => el.ordinario && !el.ordinario.aprobado)
              .map((el) => (
                <tr key={el.id_dist_estu}>
                  <td>{i++}</td>
                  <td className="text-matricula">{el.numero_de_control}</td>
                  <td className="text-nombre">{el.nombre}</td>
                  <td className="text">{el.ordinario?.nota_final}</td>
                  <td className="text">
                    {el.regularización_1?.nota_final !== undefined
                      ? el.regularización_1?.nota_final === 0
                        ? "NP"
                        : el.regularización_1?.nota_final
                      : ""}
                  </td>
                </tr>
              ))}
          </tbody>
        );
      case "3":
        return (
          <tbody>
            <tr>
              <th>No.</th>
              <th>MATRÍCULA</th>
              <th>NOMBRE y APELLIDOS</th>
              <th>CALIFICACIÓN PERIODO ORDINARIO</th>
              <th>OPORTUNIDAD 1 de 2</th>
              <th>OPORTUNIDAD 2 de 2</th>
            </tr>
            {evaluaciones
              ?.filter(
                (el) =>
                  el.ordinario &&
                  !el.ordinario.aprobado &&
                  !el.regularización_1.aprobado
              )
              .map((el) => (
                <tr key={el.id_dist_estu}>
                  <td>{i++}</td>
                  <td className="text-matricula">{el.numero_de_control}</td>
                  <td className="text-nombre">{el.nombre}</td>
                  <td className="text">{el.ordinario?.nota_final}</td>
                  <td className="text">
                    {el.regularización_1?.nota_final !== undefined
                      ? el.regularización_1?.nota_final === 0
                        ? "NP"
                        : el.regularización_1?.nota_final
                      : ""}
                  </td>
                  <td className="text">
                    {el.regularización_2?.nota_final !== undefined
                      ? el.regularización_2?.nota_final === 0
                        ? "NP"
                        : el.regularización_2?.nota_final
                      : ""}
                  </td>
                </tr>
              ))}
          </tbody>
        );
    }
  };
  return (
    <>
      <div
        style={{
          marginLeft: "10px",
          marginRight: "10px",
          marginTop: "5mm",
          height: "274mm",
        }}
      >
        <header className="title">
          <div className="titulo-contenedor">
            <img
              component="img"
              className="logoizquierda"
              src={logo}
              variant="gradient"
            />
            <div>
              <p className="arial">
                SISTEMA DE ACREDITACIÓN Y CERTIFICACIÓN NORMAL
              </p>
              <p className="arial">
                {ordinaria === "1"
                  ? "REGISTRO DE CALIFICACIONES"
                  : "ACTA DE REGULARIZACIONES"}
              </p>
              <p className="arial">
                CICLO ESCOLAR: {cicle_name} {semestre}
              </p>
              <p className="arial">
                {user?.perfil?.entidad?.nombre}{" "}
                {user?.perfil?.entidad?.clave_del_centro}
              </p>
              <p className="arial">Departamento de Control Escolar</p>
            </div>
            <img
              component="img"
              className="logoderecha"
              src={logoderecha}
              variant="gradient"
            />
          </div>
        </header>

        <section>
          <div className="informacion">
            <table className="informacionTable">
              <tbody>
                <tr>
                  <td className="tdarial">LICENCIATURA:</td>
                  <td className="value">{licenciaturaState}</td>
                </tr>
                <tr>
                  <td className="tdarial">SEMESTRE:</td>
                  <td className="value">{semestreState}</td>
                </tr>
                <tr>
                  <td className="tdarial">PROFESOR:</td>
                  <td className="value">{profesorState}</td>
                </tr>

                <tr>
                  <td className="tdarial">CURSO:</td>
                  <td className="value">{cursoState}</td>
                </tr>
              </tbody>
            </table>
            <table className="informacionTableDerecha">
              <tbody>
                <tr>
                  <td className="tdarial">MODALIDAD:</td>
                  <td className="value">ESCOLARIZADA</td>
                </tr>
                <tr>
                  <td className="tdarial">TURNO:</td>
                  <td className="value">MATUTINO</td>
                </tr>
                <tr>
                  <td className="tdarial">GRUPO:</td>
                  <td className="value">{grupoState}</td>
                </tr>

                <tr>
                  <td className="tdarial">CLAVE:</td>
                  <td className="value">{claveState}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
        <section>
          <table className="calificaciones">{createTbody(ordinaria)}</table>
          <div className="impresion">F. Impresión: {dateString}</div>
        </section>

        <div
          className="footer"
          style={{
            position: "absolute",
            bottom: "0",
            right: "0",
            left: "0",
            margin: "auto",
            width: "205mm",
            marginBottom: "9mm",
          }}
        >
          {ordinaria === "1" ? (
            <div className="botton">
              <div>
                <p className="arial">{profesorState}</p>
                <div className="separador"></div>
                <p className="pie">NOMBRE DEL PROFESOR</p>
              </div>
              <div className="firma">
                <div></div>
                <div className="separador"></div>
                <p className="pie">FIRMA DEL PROFESOR</p>
              </div>
            </div>
          ) : (
            <div>
              <div className="botton">
                <div>
                  <p className="arial">
                    {changeJefeControEscolar(claveEntidad)}
                  </p>
                  <div className="separador-regularizacion"></div>
                  <p className="pie">JEFE CONTROL ESCOLAR</p>
                </div>
                <div>
                  <p className="arial">{profesorState}</p>
                  <div className="separador-regularizacion"></div>
                  <p className="pie">PROFESOR(A)</p>
                </div>
              </div>
              <div className="director">
                <p className="arial">{changeDirectorPlantel(claveEntidad)}</p>
                <div className="separador-director"></div>
                <p className="pie">DIRECTOR DEL PLANTEL</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ActaCalificaciones;
export { changePalabra, changeJefeControEscolar };
