import { CircularProgress, Grid, Icon, Tooltip } from "@mui/material";
import MyTimePicker from "components/DateTimePicker/dateTimePicker";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import ComponentsPrivatization from "config/component_privatization";
import { Form, Formik } from "formik";
import { getModifiedFields } from "helpers/functions";
import { CANCEL_NAVIGATION } from "hooks/useRedirectForm";
import { useRedirectForm } from "hooks/useRedirectForm";
import { useSnackbar } from "notistack";
import React from "react";
import { COORDINADOR_DOCENTE } from "roles";
import { useLazyGetExamenesQuery } from "services";
import dayjs from "dayjs";
import { useCreateFechaEvaluacionEntityMutation } from "services/escuela/fecha_evaluacion/useCases/createFechaEntity";
import { convertirDivision } from "./reportes";

export default function FechaDeEvaluaciones({
  fechas,
  examen,
  division,
  ciclo_url,
}) {
  const [editar, setEditar] = React.useState(false);
  const [fechas_reducidas, setFechasR] = React.useState({
    inicio: dayjs.utc(),
    fin: dayjs.utc(),
  });
  const { enqueueSnackbar } = useSnackbar();
  const authuser = JSON.parse(sessionStorage.getItem("user"));

  const [
    createFechaEval,
    {
      isSuccess: isSuccessC,
      isLoading: isLoadingC,
      isError: isErrorC,
      error: errorC,
    },
  ] = useCreateFechaEvaluacionEntityMutation();

  function obtenerFechasFrecuentes(arr) {
    const inicial = {
      inicio: { value: null, count: 0 },
      fin: { value: null, count: 0 },
      contadores_inicio: {},
      contadores_fin: {},
    };

    const resultado = arr.reduce((acc, obj) => {
      // Contar la fecha de inicio
      if (acc.contadores_inicio[obj.inicio]) {
        acc.contadores_inicio[obj.inicio]++;
      } else {
        acc.contadores_inicio[obj.inicio] = 1;
      }
      if (acc.contadores_inicio[obj.inicio] > acc.inicio.count) {
        acc.inicio = {
          value: obj.inicio,
          count: acc.contadores_inicio[obj.inicio],
        };
      }

      // Contar la fecha de fin
      if (acc.contadores_fin[obj.fin]) {
        acc.contadores_fin[obj.fin]++;
      } else {
        acc.contadores_fin[obj.fin] = 1;
      }
      if (acc.contadores_fin[obj.fin] > acc.fin.count) {
        acc.fin = {
          value: obj.fin,
          count: acc.contadores_fin[obj.fin],
        };
      }

      return acc;
    }, inicial);

    return {
      inicio: resultado.inicio.value,
      fin: resultado.fin.value,
    };
  }

  const [examenes] = useLazyGetExamenesQuery(
    {
      filter_params: {
        query: "{id,examen{nombre}},borrable,compartido}",
      },
      shape: {
        id: "id",
        url: "url",
        nombre: "examen.nombre",
        borrable: "borrable",
        compartido: "compartido",
      },
    },
    {
      refetch_on_reconnect: true,
    }
  );

  const [state_examenes, setExamenes] = React.useState();
  const submitForm = async (values, actions) => {
    try {
      const modifiedFields = getModifiedFields(fechas_reducidas, values);
      if (Object.keys(modifiedFields).length !== 0) {
        const examen_value = state_examenes.find((e) => e.nombre === examen);
        const newValues = {
          inicio: modifiedFields.inicio
            ? modifiedFields.inicio.format("YYYY-MM-DDTHH:mm:ss:Z")
            : values.inicio,
          fin: modifiedFields.fin
            ? modifiedFields.fin.format("YYYY-MM-DDTHH:mm:ss:Z")
            : values.fin,
        };
        const response = {
          ciclo: ciclo_url,
          division: convertirDivision(division),
          examen: examen_value.url,
          inicio: newValues.inicio,
          fin: newValues.fin,
        };
        await createFechaEval(response);
        if (isSuccessC) {
          actions.setSubmitting(false);
          actions.resetForm();
        }
        setEditar(!editar);
      } else {
        setEditar(!editar);
      }
    } catch (error) {
      console.error(error);
      actions.setSubmitting(true);
    }
  };
  const handleSubmit = async (values, actions) => {
    if (editar) {
      submitForm(values, actions);
    } else {
      await examenes()
        .unwrap()
        .then((e) => setExamenes(e));

      setEditar(!editar);
    }
  };

  const cancelEdit = (setFieldValue) => {
    setEditar(false);
    setFieldValue("inicio", fechas_reducidas.inicio);
    setFieldValue("fin", fechas_reducidas.fin);
  };

  useRedirectForm(
    isLoadingC,
    isSuccessC,
    isErrorC,
    errorC,
    `Fecha de evaluación asignada correctamente al examen: ${examen}.`,
    CANCEL_NAVIGATION
  );
  return (
    <MDBox>
      <Formik initialValues={fechas_reducidas} onSubmit={handleSubmit}>
        {({ values, setFieldValue }) => {
          React.useEffect(() => {
            if (
              fechas.length === 0 &&
              authuser.groups.some((e) => e.name === COORDINADOR_DOCENTE)
            ) {
              enqueueSnackbar(
                "Debe establecer una fecha de inicio y fin para las evaluaciones ordinarias",
                {
                  variant: "warning",
                  autoHideDuration: 8000,
                }
              );
            } else if (fechas.length !== 0) {
              setFechasR(obtenerFechasFrecuentes(fechas));
            }
          }, [fechas]);

          React.useEffect(() => {
            if (fechas_reducidas) {
              setFieldValue("inicio", fechas_reducidas.inicio);
              setFieldValue("fin", fechas_reducidas.fin);
            }
          }, [fechas_reducidas]);

          return (
            <Form>
              <MDBox
                display="flex"
                flexDirection="row"
                width="50%"
                alignItems="center"
                justifyContent="flex-start"
              >
                <MDBox mr={2} display={"flex"} gap={2}>
                  <MDBox width="100%">
                    <MyTimePicker
                      disabled={!editar}
                      label={"Inician capturas"}
                      name={"inicio"}
                      value={dayjs(values.inicio)}
                      onChange={(name, value) => setFieldValue(name, value)}
                    />
                  </MDBox>
                  <MDBox width="100%">
                    <MyTimePicker
                      disabled={!editar}
                      label={"Cierran capturas"}
                      name={"fin"}
                      value={dayjs(values.fin)}
                      onChange={(name, value) => setFieldValue(name, value)}
                    />
                  </MDBox>
                </MDBox>

                <MDBox
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <ComponentsPrivatization permitted={[COORDINADOR_DOCENTE]}>
                    <MDButton
                      variant="gradient"
                      color={editar ? "success" : "light"}
                      size="medium"
                      type="submit"
                      circular
                      iconOnly
                    >
                      <Tooltip
                        placement="top"
                        title={
                          editar
                            ? `Hacer click en el botón le permitirá cambiar el rango de fecha para la evaluación: ${examen}, solo si se realizó algún cambio con respecto a la fecha que se mostraba inicialmente`
                            : "Hacer click en el botón le permitirá editar los campos de fechas que se muestran, mientras no esté activo la edición estará deshabilitada"
                        }
                      >
                        <Icon>edit</Icon>
                      </Tooltip>
                    </MDButton>

                    <MDButton
                      variant="gradient"
                      color="error"
                      size="medium"
                      onClick={() => cancelEdit(setFieldValue)}
                      circular
                      iconOnly
                      style={{
                        visibility: `${editar ? "" : "hidden"}`,
                        marginLeft: "5px",
                      }}
                    >
                      <Tooltip
                        placement="top"
                        title={
                          "Hacer click en el botón cerrará nuevamente la edición de los campos de fecha y pondrá nuevamente los valores iniciales"
                        }
                      >
                        <Icon>cancel</Icon>
                      </Tooltip>
                    </MDButton>

                    {isLoadingC && (
                      <CircularProgress size={30} color="inherit" />
                    )}
                  </ComponentsPrivatization>
                </MDBox>
              </MDBox>
            </Form>
          );
        }}
      </Formik>
    </MDBox>
  );
}
