import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React from "react";
import { useSelector } from "react-redux";
import { activo_state } from "slices";
import FormField from "components/FormField";
import PropTypes from "prop-types";

const FormModal = ({ formData, activo }) => {
  const {
    values,
    errors,
    touched,
    formField,
    setFieldValue,
    handleBlur,
    handleChange,
  } = formData;
  const { fecha, cantidad } = formField;
  const { fecha: fechaV, cantidad: cantidadV } = values;

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5" color="dark">
          Dar de Baja:
        </MDTypography>
      </MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              key={"0"}
              label={"Fecha"}
              type={fecha.type}
              name={fecha.name}
              error={errors.fecha && touched.fecha}
              success={fechaV.length > 0 && !errors.fecha}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              key={"1"}
              disabled={activo.resourcetype === "Tangible" ? true : false}
              type={cantidad.type}
              label={cantidad.label}
              name={cantidad.name}
              error={errors.cantidad && touched.cantidad}
              success={cantidadV > 0 && !errors.cantidad}
              onChange={(e) => {
                e.preventDefault();
                const { value } = e.target;
                const regex =
                  /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                if (
                  (regex.test(value.toString()) || value === "") &&
                  value <= baja.cantidad
                ) {
                  setFieldValue(cantidad.name, Number(value));
                }
              }}
              onBlur={handleBlur}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
};

FormModal.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default FormModal;
