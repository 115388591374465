import {administrativoApi} from "../view/administrativo.service";


export const getAdministrativoById = administrativoApi.injectEndpoints({
    endpoints: (builder) => ({
        getAdministrativoById: builder.query({
            query: (id) => ({
                url: `/v1/api/no_docente/${id}/`,
                method: "GET",
            }),
            providesTags: (result, error, id) => [{type: "Administrativo", id}],
        }),
    }),
})

export const {
    useGetAdministrativoByIdQuery,
    useLazyGetAdministrativoByIdQuery
} = getAdministrativoById;