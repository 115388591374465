import {useSelector} from "react-redux";
import {Navigate, Outlet} from "react-router-dom";
import {auth_state} from "slices";
import {LOGIN} from "./bases_url";
import {useAuthRedirect} from "../hooks/useAuthRedirect.";

const PrivateRoutes = () => {
    const {authenticated} = useSelector(auth_state);

    useAuthRedirect()

    return authenticated ? <Outlet/> : <Navigate to={LOGIN} replace/>;
};

export default PrivateRoutes;
