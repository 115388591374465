/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

const form = {
  formId: "new-grupo-escolar",
  formField: {
    identificador: {
      name: "identificador",
      label: "Identificador",
      type: "text",
      errorMsg: "Debe introducir un identificador",
    },
    clonar: {
      name: "clonar",
      type: "text",
    },
    ciclo_id: {
      name: "ciclo_id",
      type: "text",
    },
    grupo_id_clonar: {
      name: "grupo_id_clonar",
      type: "text",
      errorMsg: "EL grupo a clonar es obligatorio",
    },
  },
};

export default form;
