import Swal from "sweetalert2";

export function handleToast({
  title,
  text,
  dispatcher,
  icon = "warning",
  confirmButtonText = "Eliminar",
  cancelButtonText = "Cancelar",
}) {
  Swal.fire({
    title,
    text,
    icon,
    reverseButtons: true,
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    cancelButtonText,
    confirmButtonText,
  }).then((result) => dispatcher(result));
}
