/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// formik components
import { Form, Formik } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// NewExamenForm page components
import AddAsignatura from "./components/asignatura.info";

// NewExamenForm layout schemas for form and form feilds
import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import MDTypography from "components/MDTypography";
import { ASIGNATURAS_LIST_URL } from "config/bases_url";
import { useRedirectForm } from "hooks/useRedirectForm";
import {
  useCreateAsignaturaMutation,
  useEditAsignaturaMutation,
  useLazyGetAsignaturaByIdQuery,
} from "services";
import FormErrorHandler from "../../../../handlers/FormErrorHandler/form-error.handler";
import { ErrorBundary } from "../../../../config/ErrorBundary/error-bundary";
import { getModifiedFields } from "helpers/functions";

function NewAsignatura() {
  const [
    createAsignatura,
    {
      isSuccess: isSuccessC,
      isLoading: isLoadingC,
      isError: isErrorC,
      error: errorC,
    },
  ] = useCreateAsignaturaMutation();
  const [
    editAsignatura,
    {
      isSuccess: isSuccessE,
      isLoading: isLoadingE,
      isError: isErrorE,
      error: errorE,
    },
  ] = useEditAsignaturaMutation();

  const { id } = useParams();
  const { formId, formField } = form;
  const currentValidation = validations[0];
  const navigate = useNavigate();

  const [getAsignaturasById, { data: asignatura }] =
    useLazyGetAsignaturaByIdQuery();

  useRedirectForm(
    isLoadingC,
    isSuccessC,
    isErrorC,
    errorC,
    "Asignatura creada",
    ASIGNATURAS_LIST_URL,
  );

  useRedirectForm(
    isLoadingE,
    isSuccessE,
    isErrorE,
    errorE,
    "Asignatura editada",
    ASIGNATURAS_LIST_URL,
  );

  const submitForm = async (values, actions) => {
    try {
      if (!id) {
        await createAsignatura(values);
      } else {
        const modifiedFields = getModifiedFields(asignatura, values);
        if (Object.keys(modifiedFields).length !== 0) {
          await editAsignatura({ id, ...modifiedFields });
        }
      }
      if (isSuccessC || isSuccessE) {
        actions.setSubmitting(false);
        actions.resetForm();
      }
    } catch (error) {
      console.error(error);
      actions.setSubmitting(true);
    }
  };

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
  };

  return (
    <MDBox mt={5} mb={20}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} lg={8}>
          <MDBox mt={6} mb={8} textAlign="center">
            <MDBox mb={1}>
              <MDTypography variant="h3" fontWeight="bold">
                Asignaturas
              </MDTypography>
            </MDBox>
            <MDTypography variant="h5" fontWeight="regular" color="secondary">
              {!id
                ? "Introduzca la información relacionada al Asignatura por agregar"
                : `Edite la información relacionada a la Asignatura: ${asignatura?.nombre}`}
            </MDTypography>
          </MDBox>
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, setFieldValue }) => {
              useEffect(() => {
                if (id) {
                  getAsignaturasById(id)
                    .unwrap()
                    .then((res) => {
                      setFieldValue(formField.nombre.name, res?.nombre, true);
                      setFieldValue(formField.clave.name, res?.clave, true);
                      setFieldValue(
                        formField.identificador.name,
                        res?.identificador,
                        true,
                      );
                      setFieldValue(
                        formField.asistencia_requerida.name,
                        res?.asistencia_requerida,
                        true,
                      );
                    });
                }
              }, [id]);

              return (
                <ErrorBundary renderOnError={() => <FormErrorHandler />}>
                  <Form id={formId} autoComplete="off">
                    <Card sx={{ height: "100%" }}>
                      <MDBox p={3}>
                        <MDBox>
                          <AddAsignatura
                            formData={{
                              values,
                              touched,
                              formField,
                              errors,
                            }}
                          />
                          <MDBox
                            mt={2}
                            width="100%"
                            display="flex"
                            justifyContent="space-between"
                          >
                            <MDButton
                              onClick={(e) => {
                                navigate(ASIGNATURAS_LIST_URL);
                              }}
                              type="button"
                              variant="gradient"
                              color="primary"
                            >
                              cancelar
                            </MDButton>
                            <MDButton
                              type="submit"
                              variant="gradient"
                              color="dark"
                            >
                              aceptar
                            </MDButton>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </Card>
                  </Form>
                </ErrorBundary>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default NewAsignatura;
