import { userApi } from "../../view/users.service";
import {
  appendFormData,
  transformTelefonos,
} from "../../../../../helpers/functions";

export const editUser = userApi.injectEndpoints({
  endpoints: (builder) => ({
    editUser: builder.mutation({
      query: (body) => {
        let { id, ...rest } = body;

        if (rest.telefonos) rest.telefonos = transformTelefonos(rest.telefonos);

        const formData = new FormData();
        Object.entries(rest).forEach(([key, value]) => {
          appendFormData(formData, value, key);
        });

        return {
          url: `/v3/api/registro/${id}/`,
          method: "PATCH",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          body: formData,
        };
      },
      invalidatesTags: (result, error, { id }) =>
        result
          ? [
              { type: "Users", id },
              { type: "Users", id: "LIST" },
            ]
          : [{ type: "Users", id: "LIST" }],
    }),
  }),
});

export const { useEditUserMutation } = editUser;
