import MDButton from "components/MDButton";
import * as React from "react";
import { PrintContext } from "./print";

function PrintButton() {
  return (
    <PrintContext.Consumer>
      {({ print, printing }) => (
        <MDButton
          type="button"
          variant="gradient"
          color="dark"
          onClick={print}
          disabled={printing}
        >
          imprimir
        </MDButton>
      )}
    </PrintContext.Consumer>
  );
}

export default PrintButton;
