import ErrorHandling from "config/error_handling";
import { ErrorTrue } from "config/error_handling";
import { useSnackbar } from "notistack";
import { useEffect } from "react";

/**
 * Hook para el manejo de errores dentro de la app, así como el manejo de cierre de odals una vez realizada
 * la query designada.
 *
 * @param {boolean} isLoading Variable de control de eventos de RTK Query.
 * @param {boolean} isSuccess Variable de control de eventos de RTK Query.
 * @param {boolean} isError Variable de control de eventos de RTK Query.
 * @param {object} error Objeto de error en caso de existir (este se manda el de RTK Query).
 * @param {string} successMessage Dato tipo string el cual se va a mostrar en la notificación una vez resuelta la petición.
 * @param {Function} handleClose Función para controlar los modals. Es requerida si `redirect` es false.
 */

export const useCloseModal = (
  isLoading,
  isSuccess,
  isError,
  error,
  successMessage,
  handleClose
) => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (isSuccess) {
      handleClose?.();

      successMessage &&
        enqueueSnackbar(successMessage, {
          variant: "success",
          autoHideDuration: 5000,
        });
    }

    if (isError) {
      if (Array.isArray(error.data)) {
        error.data.map((el) =>
          typeof el === "object"
            ? Object.entries(el).map(([k, v]) =>
                enqueueSnackbar(
                  `${k.charAt(0).toUpperCase() + k.slice(1)}: ${v[0]}`,
                  {
                    variant: "error",
                    autoHideDuration: 1500,
                  }
                )
              )
            : enqueueSnackbar(el, { variant: "error", autoHideDuration: 1500 })
        );
      } else if (typeof error.data === "object") {
        Object.entries(error.data).map(([key, value]) =>
          enqueueSnackbar(
            `${key.charAt(0).toUpperCase() + key.slice(1)}: ${
              typeof value === "string"
                ? value
                : value.map((el) =>
                    typeof el === "string"
                      ? el
                      : Object.values(el).map((v) => v)
                  )
            }`,
            {
              variant: "error",
              autoHideDuration: 5000,
            }
          )
        );
      } else if (ErrorTrue(error.status)) {
        enqueueSnackbar(ErrorHandling(error.status), { variant: "error" });
      }
    }
  }, [isLoading]);
};
