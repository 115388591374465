/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DataTable from "examples/Tables/DataTable";
import PropTypes from "prop-types";

import ModalActivoShow from "components/ModalShow/modal_activo.show";
import { useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";
import {
  ACTIVO_RESGUARDAR_URL,
  ACTIVOS_RESGUARDOS_LIST_URL,
} from "config/bases_url";
import ModalDevolucion from "components/ModalDevolucion/modal.devolucion";
import { useSnackbar } from "notistack";
import MDTypography from "components/MDTypography";
import { style_icon } from "helpers/global_vars";
import {
  useGetActivosQuery,
  useLazyGetActivoByIdQuery,
  useLazyGetHistoryQuery,
} from "services";

import { CircularProgress, Tooltip } from "@mui/material";
import { useRedirectForm } from "hooks/useRedirectForm";
import useModalHandler from "../../../../hooks/useModalHandler";
import usePagination from "hooks/usePagination";

function OrderListActivosResguardo({ tableHeader }) {
  const navigate = useNavigate();
  const { open, handleOpen, handleClose } = useModalHandler();
  const {
    open: openShow,
    handleOpen: handleOpenShow,
    handleClose: handleCloseShow,
  } = useModalHandler();
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: almacen,
    isError: isErrorA,
    isLoading: isLoadingA,
    error: errorA,
  } = useGetActivosQuery(
    {
      filter_params: {
        disposicion: "Almacén",
        type: "Tangibles",
        query: "{id}",
      },
      shape: {
        id: "id",
      },
    },
    {
      refetchOnReconnect: true,
    },
  );

  const {
    offset,
    setOffset,
    limit,
    busqueda,
    setBusqueda,
    handleNext,
    handlePrevious,
  } = usePagination();

  const {
    data: resguardo,
    isError: isErrorG,
    isLoading: isLoadingG,
    error: errorG,
  } = useGetActivosQuery(
    {
      filter_params: {
        buscar: busqueda,
        offset: offset,
        limit: limit,
        disposicion: "Resguardo",
        type: "Tangibles",
        query:
          "{id,numero_de_inventario,descripcion,numero_de_serie,estado{nombre}}",
      },
      shape: {
        id: "id",
        numero_de_inventario: "numero_de_inventario",
        descripcion: "descripcion",
        numero_de_serie: "numero_de_serie",
        estado: "estado.nombre",
      },
    },
    {
      refetchOnReconnect: true,
    },
  );

  //constantes para manejar la paginacion
  const dataResguardo = resguardo?.results ? resguardo.results : resguardo;
  const countTotal = resguardo?.count;
  const previous = resguardo?.previous;
  const next = resguardo?.next;

  const [
    getActivoById,
    {
      data: activo,
      isLoading: isLoadingAid,
      isError: isErrorAid,
      error: errorAid,
    },
  ] = useLazyGetActivoByIdQuery();

  const [getHistory, { data: history }] = useLazyGetHistoryQuery();

  const dataTable = {
    columns: [
      {
        Header: "No. inventario",
        accessor: "numero_de_inventario",
        Cell: ({ value }) => {
          return value ? value : "-------------";
        },
      },
      {
        Header: "Descripción",
        accessor: "descripcion",
      },
      {
        Header: "Estado",
        accessor: "estado",
      },

      {
        Header: "No. serie",
        accessor: "numero_de_serie",
        Cell: ({ value }) => {
          return value ? value : "-------------";
        },
      },
      {
        Header: "Acciones",
        accessor: "acciones",
        Cell: ({ row }) => {
          const { original } = row;

          return (
            <MDBox display="flex">
              <MDBox px={0.3}>
                <Tooltip placement="top" title="Información">
                  <Icon
                    style={style_icon}
                    onClick={() => handleClickShow(original)}
                    fontSize="small"
                  >
                    find_in_page
                  </Icon>
                </Tooltip>
              </MDBox>
              {original.resourcetype !== "Consumible" && (
                <MDBox px={0.3}>
                  <Tooltip placement="top" title="Hacer devolución">
                    <Icon
                      style={style_icon}
                      onClick={() => handleClickReturn(original.id)}
                      fontSize="small"
                    >
                      keyboard_return
                    </Icon>
                  </Tooltip>
                </MDBox>
              )}
            </MDBox>
          );
        },
      },
    ],
    rows: dataResguardo ?? [],
  };

  useRedirectForm(
    isLoadingG,
    null,
    isErrorG,
    errorG,
    "Hubo problemas para cargar la información",
    ACTIVOS_RESGUARDOS_LIST_URL,
  );

  useRedirectForm(
    isLoadingA,
    null,
    isErrorA,
    errorA,
    "Hubo problemas para cargar la información",
    ACTIVOS_RESGUARDOS_LIST_URL,
  );

  useRedirectForm(
    isLoadingAid,
    null,
    isErrorAid,
    errorAid,
    "Hubo problemas para cargar la información",
    ACTIVOS_RESGUARDOS_LIST_URL,
  );

  const handleClickShow = async (el) => {
    await getActivoById(el.id)
      .then(() => getHistory(el.id))
      .then(() => handleOpenShow());
    if (history?.length === 0) {
      getHistory(el.id).then(() => handleOpenShow());
    } else {
      handleOpenShow();
    }
  };

  const handleClickReturn = async (id) => {
    await getActivoById(id)
      .unwrap()
      .then(() => handleOpen());
  };

  const handleClickResguardar = () => {
    if (almacen?.length > 0) {
      navigate(ACTIVO_RESGUARDAR_URL);
    } else {
      enqueueSnackbar("Debe existir antes al menos un activo en el almacén .", {
        variant: "warning",
      });
    }
  };

  return (
    <MDBox my={3}>
      {activo ? (
        <>
          <ModalDevolucion
            open={open}
            handleClose={handleClose}
            activo={activo}
          />
          <ModalActivoShow
            open={openShow}
            handleClose={handleCloseShow}
            activo={activo}
          />
        </>
      ) : null}
      <Card>
        <MDBox
          display="flex"
          justifyContent={"space-between"}
          alignItems={"center"}
          m={2}
          p={3}
        >
          <MDTypography variant="h5" fontWeight="medium">
            {tableHeader}
          </MDTypography>

          <MDButton
            variant="gradient"
            color="info"
            size="medium"
            onClick={handleClickResguardar}
          >
            nuevo
          </MDButton>
        </MDBox>
        <MDBox display="flex" justifyContent={"center"} alignItems={"center"}>
          {isLoadingG ? (
            <CircularProgress sx={{ marginBottom: "3rem" }} color="inherit" />
          ) : (
            <DataTable
              table={dataTable}
              canSearch
              doubleClick={handleClickShow}
              paginationApi
              countTotal={countTotal}
              next={next}
              previous={previous}
              handleNext={handleNext}
              handlePrevious={handlePrevious}
              offset={offset}
              setOffset={setOffset}
              setBusqueda={setBusqueda}
            />
          )}
        </MDBox>
      </Card>
    </MDBox>
  );
}

OrderListActivosResguardo.propTypes = {
  tableHeader: PropTypes.string.isRequired,
};

export default OrderListActivosResguardo;
