import PrintButton from "./printing/PrintButton";
import "./styles/resguardo.css";

const ResguardoPdf = ({ lote }) => {
  const isoDate = lote.creacion;
  const date = new Date(isoDate);
  const dateString = date.toLocaleDateString("es-ES", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  let i = 1;
  return (
    <>
      <div
        style={{
          marginLeft: "10px",
          marginRight: "10px",
          marginTop: "18mm",
          height: "210mm",
        }}
      >
        <header className="title">
          <h1>
            Dirección de Almacenes, Inventarios, Bienes Muebles e Inmuebles
          </h1>
          <h1>Vale de resguardo de bienes muebles</h1>
        </header>

        <section className="personal">
          <table className="personalTable">
            <tbody>
              <tr>
                <td>Adscripción</td>
                <td className="value">
                  {`${lote?.operaciones[0].personal.user.first_name} 
                  ${lote?.operaciones[0].personal.user.last_name}`}
                </td>
                <td>C.C.T</td>
                <td className="value">
                  {lote?.operaciones[0].personal.entidad.clave_del_centro}
                </td>
              </tr>
              <tr>
                <td>Sub-area</td>
                <td className="value">
                  {lote?.operaciones[0].personal.area.nombre}
                </td>
                <td>Fecha</td>
                <td className="value">{dateString}</td>
              </tr>
            </tbody>
          </table>
        </section>
        <section>
          <table className="customers">
            <tbody>
              <tr>
                <th>No.</th>
                <th>Clave Bien</th>
                <th>Numero de Serie</th>
                <th>Descripcion</th>
                <th>Numero de Inventario</th>
              </tr>
              {lote?.operaciones.map((el) => (
                <tr key={el.id}>
                  <td>{i++}</td>
                  <td className="text">{el.activo.llave}</td>
                  <td className="text">{el.activo.numero_de_serie}</td>
                  <td className="text">{el.activo.descripcion}</td>
                  <td className="text">{el.activo.numero_de_inventario}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>

        <div
          className="footer"
          style={{
            position: "absolute",
            bottom: "0",
            right: "0",
            left: "0",
            margin: "auto",
            width: "205mm",
            marginBottom: "9mm",
          }}
        >
          <table className="observation">
            <thead>
              <tr>
                <th>Obeservaciones: Resguardo a departamento it</th>
                <th>
                  ME COMPROMETO A RESGUARDAR Y DAR BUEN USO A LOS BIENES QUE SE
                  DESCRIBEN Y ENTREGARLOS CUANDO SE ME REQUIERAN, CONFORME A LO
                  DISPUESTO EN EL REGLAMENTO DE CONDICIONES GENERALES DE TRABAJO
                  Y EN EL ACUERDO QUE CONTIENEN LAS NORMAS GENERALES SOBRE LOS
                  BIENES MUEBLES PROPIEDAD DEL INSTITUTO HIDALGUENSE DE
                  EDUCACIÓN, CAPITULO IV, NORMA 11, INCISO II.
                </th>
              </tr>
            </thead>
          </table>
          <table className="sign">
            <tbody>
              <tr>
                <th>nombre del usuario:</th>
                <th>{`${lote?.operaciones[0].personal.user.first_name} 
                  ${lote?.operaciones[0].personal.user.last_name}`}</th>
                <th>nombre del jefe inmediato:</th>
                <th>Ing. Ariel Pérez Nájera</th>
              </tr>
              <tr>
                <td>RFC:</td>
                <td>{lote?.operaciones[0].personal.rfc}</td>
                <td>RFC:</td>
                <td></td>
              </tr>
              <tr>
                <td>Cargo:</td>
                <td></td>
                <td>Cargo:</td>
                <td>Encargado de almacén</td>
              </tr>
              <tr>
                <td>Firma:</td>
                <td></td>
                <td>Firma y Sello</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ResguardoPdf;
