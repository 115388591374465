import * as React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import ResguardoReceipt from "layouts/almacen/pdf/printing/pdfLoteAsignacion";
import ModalBase from "../ModalBase/modal_base";

const ModalLoteShow = ({ open, handleClose, lote }) => {
  return (
    <ModalBase open={open} handleClose={handleClose}>
      <Grid container sx={{ width: "205mm" }}>
        <ResguardoReceipt lote={lote} />
      </Grid>
    </ModalBase>
  );
};

ModalLoteShow.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ModalLoteShow;
