// formik components
import { Form, Formik } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// NewUser layout schemas for form and form feilds
import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import MDTypography from "components/MDTypography";
import { useRedirectForm } from "hooks/useRedirectForm";
import {
  useCerrarCicloMutation,
  useEditCicloMutation,
  useLazyGetCicloByIdQuery,
} from "services";
import CicloEdit from "./components/Ciclo/ciclo.edit";
import Swal from "sweetalert2";
import { HOME_URL } from "../../../../config/bases_url";

function EditCiclo({ id }) {
  const [
    editCiclo,
    {
      isSuccess: isSuccessE,
      isLoading: isLoadingE,
      isError: isErrorE,
      error: errorE,
    },
  ] = useEditCicloMutation();

  const [
    cerrarCiclo,
    {
      isSuccess: isSuccessCC,
      isLoading: isLoadingCC,
      isError: isErrorCC,
      error: errorCC,
    },
  ] = useCerrarCicloMutation();

  const { formId, formField } = form;
  const currentValidation = validations[0];

  const navigate = useNavigate();

  const [getCicloById] = useLazyGetCicloByIdQuery();

  useRedirectForm(
    isLoadingE,
    isSuccessE,
    isErrorE,
    errorE,
    "Ciclo editado",
    "/ciclos/ciclos-list"
  );

  useRedirectForm(
    isLoadingCC,
    isSuccessCC,
    isErrorCC,
    errorCC,
    "Ciclo cerrado exitosamente",
    HOME_URL
  );

  const submitForm = async (values, actions) => {
    await editCiclo(values);

    if (isSuccessE) {
      actions.setSubmitting(false);
      actions.resetForm();
    }
  };

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
  };

  const handleCerrarCiclo = () => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: `¡Al aceptar esta accion se cerrará por completo el ciclo actual y no sera reversible!`,
      icon: "warning",
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Terminar",
    }).then((result) => {
      if (result.isConfirmed) {
        cerrarCiclo();
      }
    });
  };

  return (
    <MDBox mt={5} mb={20}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} lg={8}>
          <MDBox mt={6} mb={8} textAlign="center">
            <MDBox mb={1}>
              <MDTypography variant="h3" fontWeight="bold">
                Editar Año Escolar
              </MDTypography>
            </MDBox>
            <MDTypography variant="h5" fontWeight="regular" color="secondary">
              Introduzca la información relacionada al año en cuestión
            </MDTypography>
          </MDBox>
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, setFieldValue }) => {
              useEffect(() => {
                if (id) {
                  getCicloById(id)
                    .unwrap()
                    .then((res) => {
                      setFieldValue(formField.nombre.name, res?.nombre, true);
                      setFieldValue(
                        formField.fecha_fin.name,
                        res?.fecha_fin,
                        true
                      );
                      setFieldValue(
                        formField.fecha_inicio.name,
                        res?.fecha_inicio,
                        true
                      );
                    });
                }
              }, []);

              return (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox p={3}>
                      <MDBox>
                        <CicloEdit
                          formData={{
                            values,
                            touched,
                            errors,
                            formField,
                          }}
                        />
                        <MDBox
                          mt={2}
                          width="100%"
                          display="flex"
                          justifyContent="center"
                        >
                          <MDButton
                            onClick={handleCerrarCiclo}
                            variant="gradient"
                            size={"large"}
                            color="warning"
                          >
                            cerrar ciclo actual
                          </MDButton>
                        </MDBox>
                        <MDBox
                          mt={2}
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                        >
                          <MDButton
                            onClick={() => navigate(`/ciclos/ciclos-list`)}
                            variant="gradient"
                            color="primary"
                          >
                            cancelar
                          </MDButton>
                          <MDButton
                            type="submit"
                            variant="gradient"
                            color="dark"
                          >
                            aceptar
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default EditCiclo;
