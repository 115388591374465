/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {RESET_PASSWORD_CONFIRM} from "config/bases_url";
import checkout from "./form";

const {
  formField: {
    nombre,
    apellido_materno,
    apellido_paterno,
    correo,
    telefonos,
    groups,
    foto,
    url_cambio_password,
  },
} = checkout;

const initialValues = {
  [nombre.name]: "",
  [apellido_materno.name]: "",
  [apellido_paterno.name]: "",
  [correo.name]: "",
  [telefonos.name]: [],
  [groups.name]: [],
  [foto.name]: "",
  [url_cambio_password.name]: `${window.location.origin}${RESET_PASSWORD_CONFIRM}`,
};

export default initialValues;
