import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "services/principal/customFetchBase";
import { buildFiltersUrl } from "helpers/functions";
import { reshape_response } from "helpers/functions";

export const estadoApi = createApi({
  reducerPath: "estadoApi",
  baseQuery: customFetchBase,
  tagTypes: ["Estado"],
  endpoints: (builder) => ({
    getEstados: builder.query({
      query: ({ filter_params }) => ({
        url: buildFiltersUrl("/v1/api/estado-activo/", filter_params),
        method: "GET",
      }),
      providesTags: (result) =>
        Array.isArray(result)
          ? [
              ...result.map(({ id }) => ({
                type: "Estado",
                id,
              })),
              { type: "Estado", id: "LIST" },
            ]
          : [{ type: "Estado", id: "LIST" }],
      transformResponse: (response, meta, args) => {
        if (Array.isArray(response)) {
          return response?.map((el) => reshape_response(el, args?.shape));
        } else {
          const resultsTransformed = response?.results?.map((el) =>
            reshape_response(el, args?.shape)
          );

          // Devolvemos los resultados transformados junto con las propiedades de paginación
          return {
            results: resultsTransformed,
            count: response.count,
            next: response.next,
            previous: response.previous,
          };
        }
      },
    }),
    getEstadoById: builder.query({
      query: (id) => ({
        url: `/v1/api/estado-activo/${id}/`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "Estado", id }],
    }),

    createEstado: builder.mutation({
      query: (adq) => ({
        url: "/v1/api/estado-activo/",
        method: "POST",
        body: adq,
      }),
      invalidatesTags: [{ type: "Estado", id: "LIST" }],
    }),

    deleteEstado: builder.mutation({
      query: (id) => ({
        url: `/v1/api/estado-activo/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Estado", id: "LIST" }],
    }),

    editEstado: builder.mutation({
      query: (body) => ({
        url: `/v1/api/estado-activo/${body.id}/`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, { id }) =>
        result
          ? [
              { type: "Estado", id },
              { type: "Estado", id: "LIST" },
            ]
          : [{ type: "Estado", id: "LIST" }],
    }),
  }),
});

export const {
  useGetEstadosQuery,
  useCreateEstadoMutation,
  useEditEstadoMutation,
  useDeleteEstadoMutation,
  useLazyGetEstadoByIdQuery,
} = estadoApi;
