/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import React from "react";
// NewUser page components
import FormField from "components/FormField";
import {Icon, Tooltip} from "@mui/material";
import MDImage from "components/MDImage/image";
import MDTypography from "components/MDTypography";
import {EMAIL_REGEX} from "helpers/regexs";
import MDDropzone from "components/MDDropzone";
import MDButton from "components/MDButton";
import cer from "assets/images/tecnoschool/cer.png";
import {useSnackbar} from "notistack";

const style = {
  width: "100%",
  cursor: "pointer",
  borderRadius: "0.5rem",
  marginTop: "0.5rem",
  marginLeft: "0.5rem",
};

const icon_style = {
  position: "absolute",
  left: "50%",
  top: "50%",
  fontSize: "30px",
  transform: "translate(-50%,-50%)",
};

function FirmanteInfo({ formData }) {
  const { formField, values, errors, touched, setFieldValue } = formData;
  const {
    nombre,
    apellido_materno,
    apellido_paterno,
    correo,
    cargo,
    certificado,
    firma,
  } = formField;
  const {
    nombre: nombreV,
    apellido_materno: apellido_maternoV,
    apellido_paterno: apellido_paternoV,
    correo: correoV,
    cargo: cargoV,
    firma: firmaV,
    certificado: certificadoV,
  } = values;
  const [file, setFile] = React.useState(certificadoV);
  const [errorUpload, setErrorUpload] = React.useState(null);
  const [fileUploaded, setFileUploaded] = React.useState(false);

  React.useEffect(() => {
    if (certificadoV) {
      setFileUploaded(true);
    }
  }, [certificadoV]);

  React.useEffect(() => {
    if (file && file instanceof File) {
      if (file.name.split(".").pop() !== "cer") {
        setErrorUpload("Solo se aceptan archivos .cer");
        setFileUploaded(false);
      } else {
        setFieldValue(certificado.name, file);
        setErrorUpload(null);
        setFileUploaded(true);
      }
    }
  }, [file]);

  const { enqueueSnackbar } = useSnackbar();
  const handleChangeFoto = (e, isValid) => {
    if (isValid) setFieldValue(firma.name, e.target.files[0]);
    else {
      enqueueSnackbar("El tamaño de la imagen no debe ser mayor a 1MB", {
        variant: "error",
        autoHideDuration: 4000,
      });
    }
  };

  const dropzoneOptions = {
    maxFilesize: 1,
    acceptedFiles: ".cer",
    dictDefaultMessage: "Suba su archivo .cer aqui",
    autoProcessQueue: false,
    init: function () {
      this.on("addedfile", (file) => {
        setFile(file);
      });
    },
  };

  return (
    <MDBox>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={3}>
          <FormField
            type={nombre.type}
            label={nombre.label}
            name={nombre.name}
            value={nombreV}
            placeholder={nombre.placeholder}
            error={errors.nombre && touched.nombre}
            success={nombreV?.length > 0 && !errors.nombre}
            onInput={(e) =>
              (e.target.value = ("" + e.target.value).toUpperCase())
            }
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <FormField
            type={apellido_paterno.type}
            label={apellido_paterno.label}
            name={apellido_paterno.name}
            value={apellido_paternoV}
            placeholder={apellido_paterno.placeholder}
            error={errors.apellido_paterno && touched.apellido_paterno}
            success={apellido_paternoV?.length > 0 && !errors.apellido_paterno}
            onInput={(e) =>
              (e.target.value = ("" + e.target.value).toUpperCase())
            }
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormField
            type={apellido_materno.type}
            label={apellido_materno.label}
            name={apellido_materno.name}
            value={apellido_maternoV}
            placeholder={apellido_materno.placeholder}
            error={errors.apellido_materno && touched.apellido_materno}
            success={apellido_maternoV?.length > 0 && !errors.apellido_materno}
            onInput={(e) =>
              (e.target.value = ("" + e.target.value).toUpperCase())
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <FormField
            type={correo.type}
            label={correo.label}
            name={correo.name}
            value={correoV}
            placeholder={correo.placeholder}
            error={errors.correo && touched.correo}
            success={
              correoV?.match(EMAIL_REGEX) &&
              !errors.correo &&
              correoV?.length > 0
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormField
            type={cargo.type}
            label={cargo.label}
            name={cargo.name}
            value={cargoV}
            placeholder={cargo.placeholder}
            error={errors.cargo && touched.cargo}
            onInput={(e) =>
              (e.target.value = ("" + e.target.value).toUpperCase())
            }
            success={cargoV?.length > 0 && !errors.cargo}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} display="flex" justifyContent={"center"}>
        <Grid item xs={12} sm={12}>
          <MDBox mt={1.625} display="flex" justifyContent="space-around">
            <Tooltip
              placement="top"
              title={!firmaV ? "Agregar imagen" : "Cambiar Foto"}
            >
              <MDBox
                style={{
                  position: "relative",
                  width: "200px",
                  cursor: "pointer",
                }}
              >
                <MDBox>
                  <MDTypography
                    variant="h6"
                    fontWeight="bold"
                    flex
                    justifyContent="center"
                    textAlign="center"
                  >
                    {firma.label}
                  </MDTypography>
                </MDBox>
                {!firmaV ? (
                  <Icon fontSize="medium" style={icon_style}>
                    image_search
                  </Icon>
                ) : null}

                <MDImage
                  id="profile-image"
                  htmlFor="profile-image"
                  disabled={false}
                  style={style}
                  src={
                    firmaV && typeof firmaV === "object"
                      ? URL.createObjectURL(firmaV)
                      : firmaV
                  }
                  imageTypes={firma.accept}
                  handleChange={handleChangeFoto}
                />
              </MDBox>
            </Tooltip>
            <MDBox display="flex" flexDirection="column">
              <MDBox mt={2} width="100%" display="flex" alignItems="center">
                {!fileUploaded && (
                  <MDDropzone
                    options={dropzoneOptions}
                    file={fileUploaded}
                    style={{ minHeight: "80px" }}
                    id="certificado"
                  />
                )}
                {fileUploaded && (
                  <MDBox
                    component="img"
                    src={cer}
                    variant="gradient"
                    bgColor="dark"
                    style={style}
                  />
                )}
                <Tooltip placement="top" title={"Quitar archivo"}>
                  <MDBox ml={1}>
                    <MDButton
                      type="submit"
                      variant="gradient"
                      color="dark"
                      onClick={() => {
                        setFieldValue(certificado.name, null),
                          setFileUploaded(false);
                      }}
                      circular
                      iconOnly
                    >
                      <Icon> delete</Icon>
                    </MDButton>
                  </MDBox>
                </Tooltip>
              </MDBox>

              {errorUpload && (
                <MDTypography
                  color="error"
                  variant="h6"
                  fontWeight="bold"
                  display="flex"
                  justifyContent="center"
                  textAlign="center"
                >
                  {errorUpload}
                </MDTypography>
              )}
            </MDBox>
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}

// typechecking props for UserInfo
FirmanteInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default FirmanteInfo;
