import {administrativoApi} from "../view/administrativo.service";
import {userApi} from "../../../administracion/users/view/users.service";
import {enqueueSnackbar} from "notistack";
import {showErrorMessages} from "../../../../hooks/useRedirectForm";
import {appendFormData, transformTelefonos} from "../../../../helpers/functions";


export const createAdministrativo = administrativoApi.injectEndpoints({
  endpoints: (builder) => ({
    createAdministrativo: builder.mutation({
      query: (body) => {

        if (body.telefonos)
          body.telefonos = transformTelefonos((body.telefonos))

        const formData = new FormData()
        Object.entries(body).forEach(([key, value]) => {
          appendFormData(formData, value, key);
        });

        return {
          url: "/v1/api/no_docente/",
          method: "POST",
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          body: formData,
        };
      },
      invalidatesTags: [{type: "Administrativo", id: "LIST"}],
      async onQueryStarted(arg, {dispatch, queryFulfilled}) {
        try {
          await queryFulfilled;
          dispatch(
            userApi.util.invalidateTags([{type: "Users", id: "LIST"}])
          );
          enqueueSnackbar("Se creo correctamente el profesor", {
            variant: "success",
          });
        } catch (error) {
          showErrorMessages(error.error, enqueueSnackbar);
        }
      },
    }),
  }),
})

export const {
  useCreateAdministrativoMutation,
} = createAdministrativo;