import { Navigate } from "react-router-dom";
import { HOME_URL } from "./bases_url";
import { checkPerm } from "helpers/functions";

export const removeColumns = (dataTable, toEvaluate, column_name) => {
  try {
    const { columns, rows } = dataTable;

    if (toEvaluate()) {
      column_name.forEach((columnName) => {
        const columnIndex = columns.findIndex(
          (column) => column.accessor === columnName
        );
        if (columnIndex > -1) {
          columns.splice(columnIndex, 1);
        }
      });
    }
    return { columns, rows };
  } catch (err) {
    console.log(err);
  }
};

const ComponentsPrivatization = ({ permitted, redirect, children }) => {
  const user = JSON.parse(sessionStorage.getItem("user"));

  return checkPerm(permitted) || user.is_staff ? (
    <>{children}</>
  ) : redirect === false ? null : (
    <Navigate to={HOME_URL} replace />
  );
};

ComponentsPrivatization.defaultProps = {
  redirect: false,
  permitted: null,
};

export default ComponentsPrivatization;
