/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React, { useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DataTable from "examples/Tables/DataTable";

//React Components
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import PropTypes from "prop-types";

import Swal from "sweetalert2";
import ModalForm from "components/Modal/modal";
import ModalActivoShow from "components/ModalShow/modal_activo.show";
import {
  ACTIVO_CLONE_URL,
  ACTIVO_EDIT_URL,
  ACTIVO_NEW_URL,
  ACTIVOS_LIST_URL,
} from "config/bases_url";
import ModalAsig from "components/ModalAsig/modal.asig";
import MDTypography from "components/MDTypography";
import { useGetActivosQuery, useLazyGetActivoByIdQuery } from "services";
import { admin_state, setClone, setEditAdmin, setNoHereAdmin } from "slices";
import { CircularProgress, Tooltip } from "@mui/material";
import ComponentsPrivatization from "config/component_privatization";
import { COORDINADOR_ALMACEN } from "roles";
import EtiquetaPDF from "../../pdf/etiqueta_pdf";
import { style_icon } from "helpers/global_vars";
import { useRedirectForm } from "hooks/useRedirectForm";
import useModalHandler from "../../../../hooks/useModalHandler";
import usePagination from "hooks/usePagination";

function OrderListActivos({ tableHeader }) {
  //State del Modal de las bajas
  const { open, handleOpen, handleClose } = useModalHandler();

  //State del Modal para asiganción
  const {
    open: openAsig,
    handleOpen: handleOpenAsig,
    handleClose: handleCloseAsig,
  } = useModalHandler();

  //State control over show
  const {
    open: openShow,
    handleOpen: handleOpenShow,
    handleClose: handleCloseShow,
  } = useModalHandler();

  //State del Modal de los Qr
  const {
    open: openTag,
    handleOpen: handleOpenTag,
    handleClose: handleCloseTag,
  } = useModalHandler();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    offset,
    setOffset,
    limit,
    busqueda,
    setBusqueda,
    handleNext,
    handlePrevious,
  } = usePagination();

  const {
    data: activos,
    isError: isErrorG,
    isLoading: isLoadingG,
    error: errorG,
  } = useGetActivosQuery(
    {
      filter_params: {
        buscar: busqueda,
        offset: offset,
        limit: limit,
        disposicion: "Almacén",
        type: "Tangibles",
        query:
          "{id,llave,numero_de_inventario,descripcion,numero_de_serie,estado{nombre}}",
      },
      shape: {
        id: "id",
        nombre: "nombre",
        llave: "llave",
        numero_de_inventario: "numero_de_inventario",
        descripcion: "descripcion",
        numero_de_serie: "numero_de_serie",
        estado: "estado.nombre",
      },
    },
    {
      refetchOnReconnect: true,
    },
  );

  //constantes para manejar la paginacion
  const dataActivos = activos?.results ? activos.results : activos;
  const countTotal = activos?.count;
  const previous = activos?.previous;
  const next = activos?.next;

  const [
    getActivoById,
    { isLoading: isLoadingS, isError: isErrorS, data: activo, error: errorS },
  ] = useLazyGetActivoByIdQuery();

  const dataTable = {
    columns: [
      {
        Header: "Clave de Bien",
        accessor: "llave",
        Cell: ({ value }) => {
          return value ? value : "-------------";
        },
      },
      {
        Header: "No. inventario",
        accessor: "numero_de_inventario",
        Cell: ({ value }) => {
          return value ? value : "-------------";
        },
      },
      {
        Header: "Descripción",
        accessor: "descripcion",
      },
      {
        Header: "No. serie",
        accessor: "numero_de_serie",
        Cell: ({ value }) => {
          return value ? value : "S/N";
        },
      },
      {
        Header: "Estado",
        accessor: "estado",
      },
      {
        Header: "Acciones",
        accessor: "acciones",
        Cell: ({ row }) => {
          const { original } = row;

          return (
            <MDBox display="flex">
              {original.resourcetype !== "Consumible" ? (
                <>
                  <ComponentsPrivatization permitted={COORDINADOR_ALMACEN}>
                    <MDBox px={0.3}>
                      <Tooltip placement="top" title="Clonar">
                        <Icon
                          style={style_icon}
                          onClick={() => handleClickClone(original.id)}
                          fontSize="small"
                        >
                          copy
                        </Icon>
                      </Tooltip>
                    </MDBox>
                  </ComponentsPrivatization>
                  <MDBox px={0.3}>
                    <Tooltip placement="top" title="Obtener QR">
                      <Icon
                        style={style_icon}
                        onClick={() => viewTag(original.id)}
                        fontSize="small"
                      >
                        qr_code
                      </Icon>
                    </Tooltip>
                  </MDBox>
                </>
              ) : (
                <MDBox px={0.3}>
                  <Tooltip placement="top" title="Asignar activo">
                    <Icon
                      style={style_icon}
                      onClick={() => handleClickAsignar(original.id)}
                      fontSize="small"
                    >
                      compare_arrows
                    </Icon>
                  </Tooltip>
                </MDBox>
              )}
              <MDBox px={0.3}>
                <Tooltip placement="top" title="Ver detalles">
                  <Icon
                    style={style_icon}
                    onClick={(e) => handleClickShow(original)}
                    fontSize="small"
                  >
                    find_in_page
                  </Icon>
                </Tooltip>
              </MDBox>

              <ComponentsPrivatization permitted={COORDINADOR_ALMACEN}>
                <MDBox px={0.3}>
                  <Tooltip placement="top" title="Editar">
                    <Icon
                      style={style_icon}
                      onClick={() => handleClickEdit(original.id)}
                      fontSize="small"
                    >
                      edit
                    </Icon>
                  </Tooltip>
                </MDBox>
                {original.resourcetype !== "Consumible" && (
                  <MDBox px={0.3}>
                    <Tooltip placement="top" title="Dar de baja">
                      <Icon
                        style={style_icon}
                        onClick={() =>
                          handleClickDarBaja(original.id, original.descripcion)
                        }
                        fontSize="small"
                      >
                        remove_circle
                      </Icon>
                    </Tooltip>
                  </MDBox>
                )}
              </ComponentsPrivatization>
            </MDBox>
          );
        },
      },
    ],
    rows: dataActivos ?? [],
  };

  useRedirectForm(
    isLoadingG,
    null,
    isErrorG,
    errorG,
    "Hubo problemas para cargar la información",
    ACTIVOS_LIST_URL,
  );

  useRedirectForm(
    isLoadingS,
    null,
    isErrorS,
    errorS,
    "Hubo problemas para cargar la información",
    ACTIVOS_LIST_URL,
  );

  const { edit, clone } = useSelector(admin_state);

  //Limpia las variables clone y edit en el slice de admin
  useEffect(() => {
    if (clone) {
      dispatch(setClone(null));
    }
    if (edit) {
      dispatch(setEditAdmin(null));
    }
  }, []);

  const handleClickDarBaja = (id, descripcion) => {
    Swal.fire({
      title: "¿Dar de baja al activo?",
      text: `¡Si das de baja al activo: "${descripcion}", no podrás revertir la acción!`,
      icon: "warning",
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Dar de baja",
    }).then((result) => {
      if (result.isConfirmed) {
        getActivoById(id)
          .unwrap()
          .then(() => {
            handleOpen();
          });
      }
    });
  };

  const viewTag = async (id) => {
    await getActivoById(id)
      .unwrap()
      .then(() => {
        handleOpenTag();
      });
  };

  const handleClickAsignar = (id) => {
    getActivoById(id)
      .unwrap()
      .then(() => {
        handleOpenAsig();
      });
  };

  const handleClickEdit = (id) => {
    dispatch(setNoHereAdmin(false));
    navigate(ACTIVO_EDIT_URL(id));
    getActivoById(id)
      .unwrap()
      .then((res) => dispatch(setEditAdmin(res)));
  };

  const handleClickClone = (id) => {
    dispatch(setNoHereAdmin(false));
    navigate(ACTIVO_CLONE_URL(id));
    getActivoById(id)
      .unwrap()
      .then((res) => dispatch(setClone(res)));
  };

  const handleClickShow = (el) => {
    getActivoById(el.id)
      .unwrap()
      .then(() => {
        handleOpenShow();
      });
  };

  const handleClickCreate = () => {
    dispatch(setNoHereAdmin(false));
    navigate(ACTIVO_NEW_URL);
    dispatch(setClone(null));
    dispatch(setEditAdmin(null));
  };

  return (
    <MDBox my={3}>
      {activo ? (
        <>
          <ComponentsPrivatization permitted={COORDINADOR_ALMACEN}>
            <ModalForm open={open} handleClose={handleClose} activo={activo} />
          </ComponentsPrivatization>
          <EtiquetaPDF
            open={openTag}
            handleClose={handleCloseTag}
            activo={activo}
          />
          <ModalAsig
            open={openAsig}
            handleClose={handleCloseAsig}
            activo={activo}
          />
          <ModalActivoShow
            open={openShow}
            handleClose={handleCloseShow}
            activo={activo}
          />
        </>
      ) : null}
      <Card>
        <MDBox
          display="flex"
          justifyContent={"space-between"}
          alignItems={"center"}
          m={2}
          p={3}
        >
          <MDTypography variant="h5" fontWeight="medium">
            {tableHeader}
          </MDTypography>
          <ComponentsPrivatization permitted={COORDINADOR_ALMACEN}>
            <MDButton
              variant="gradient"
              color="info"
              size="medium"
              onClick={handleClickCreate}
            >
              nuevo
            </MDButton>
          </ComponentsPrivatization>
        </MDBox>
        <MDBox display="flex" justifyContent={"center"} alignItems={"center"}>
          {isLoadingG ? (
            <CircularProgress sx={{ marginBottom: "3rem" }} color="inherit" />
          ) : (
            <DataTable
              table={dataTable}
              canSearch
              doubleClick={handleClickShow}
              paginationApi
              countTotal={countTotal}
              next={next}
              previous={previous}
              handleNext={handleNext}
              handlePrevious={handlePrevious}
              offset={offset}
              setOffset={setOffset}
              setBusqueda={setBusqueda}
            />
          )}
        </MDBox>
      </Card>
    </MDBox>
  );
}

OrderListActivos.propTypes = {
  tableHeader: PropTypes.string.isRequired,
};

export default OrderListActivos;
