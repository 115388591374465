import MDBox from "components/MDBox";
import BajaPdf from "../baja";
import PrintRoot, { NoPrint } from "./print";
import PrintButton from "./PrintButton";

function BajaReceipt({ lote }) {
  return (
    <div style={{ width: "210mm", margin: "auto", backgroundColor: "white" }}>
      <PrintRoot>
        <NoPrint>
          <MDBox sx={{ display: "grid", justifyItems: "flex-end" }}>
            <PrintButton />
          </MDBox>
        </NoPrint>
        <BajaPdf lote={lote} />
      </PrintRoot>
    </div>
  );
}

export default BajaReceipt;
