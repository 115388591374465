import * as React from "react";
import MDBox from "components/MDBox";
import { Card, Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import { formatoAEquivalenteHoras } from "helpers/functions";
import PageLayout from "examples/LayoutContainers/PageLayout";
import fondo from "assets/images/fondos/fondoPizarra.jpg";
import { useLocation } from "react-router-dom";

const CursoDetallesQr = () => {
  const location = useLocation();

  // Extrae la cadena de consulta de la URL
  const base64Query = new URLSearchParams(location.search).get("query");

  const decodificadoBase64 = atob(base64Query);
  const objeto = JSON.parse(decodificadoBase64);

  return (
    <PageLayout>
      <MDBox
        position="absolute"
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) =>
            fondo &&
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${fondo})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MDBox px={1} width="100%" height="100vh" mx="auto">
        <Grid
          container
          spacing={1}
          px={0.5}
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Grid item xs={12} lg={8}>
            <MDBox minHeight="200px">
              <Card>
                <MDBox px={3} py={2}>
                  <Grid container>
                    <MDBox mt={1} mb={2}>
                      <MDTypography variant="h5" fontWeight="bold" color="dark">
                        Datos generales del estudiante:
                      </MDTypography>
                    </MDBox>
                    <Grid item xs={12} sm={12} mb={1}>
                      <MDBox display="flex" gap={1} alignItems="baseline">
                        <MDTypography
                          variant="h6"
                          fontWeight="bold"
                          color="dark"
                        >
                          Nombre:
                        </MDTypography>
                        <MDTypography
                          variant="h6"
                          fontWeight="regular"
                          color="secondary"
                        >
                          {objeto.nombre_estudiante}
                        </MDTypography>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} sm={6} mb={1}>
                      <MDBox display="flex" gap={1} alignItems="baseline">
                        <MDTypography
                          variant="h6"
                          fontWeight="bold"
                          color="dark"
                        >
                          Curp:
                        </MDTypography>
                        <MDTypography
                          variant="h6"
                          fontWeight="regular"
                          color="secondary"
                        >
                          {objeto.curp}
                        </MDTypography>
                      </MDBox>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <MDBox mt={1} mb={2}>
                      <MDTypography variant="h5" fontWeight="bold" color="dark">
                        Datos generales del curso:
                      </MDTypography>
                    </MDBox>
                    <Grid item xs={12} sm={12} mb={1}>
                      <MDBox display="flex" gap={1} alignItems="baseline">
                        <MDTypography
                          variant="h6"
                          fontWeight="bold"
                          color="dark"
                        >
                          Nombre:
                        </MDTypography>
                        <MDTypography
                          variant="h6"
                          fontWeight="regular"
                          color="secondary"
                        >
                          {objeto.curso_nombre}
                        </MDTypography>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} sm={4} mb={1}>
                      <MDBox display="flex" gap={1} alignItems="baseline">
                        <MDTypography
                          variant="h6"
                          fontWeight="bold"
                          color="dark"
                        >
                          Duracion:
                        </MDTypography>
                        <MDTypography
                          variant="h6"
                          fontWeight="regular"
                          color="secondary"
                        >
                          {formatoAEquivalenteHoras(objeto.duracion)}
                        </MDTypography>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} sm={4} mb={1}>
                      <MDBox display="flex" gap={1} alignItems="baseline">
                        <MDTypography
                          variant="h6"
                          fontWeight="bold"
                          color="dark"
                        >
                          Modalidad:
                        </MDTypography>
                        <MDTypography
                          variant="h6"
                          fontWeight="regular"
                          color="secondary"
                        >
                          {objeto.modalidad}
                        </MDTypography>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} sm={4} mb={1}>
                      <MDBox display="flex" gap={1} alignItems="baseline">
                        <MDTypography
                          variant="h6"
                          fontWeight="bold"
                          color="dark"
                        >
                          Fecha inicio:
                        </MDTypography>
                        <MDTypography
                          variant="h6"
                          fontWeight="regular"
                          color="secondary"
                        >
                          {objeto.fecha_inicio}
                        </MDTypography>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} sm={4} mb={1}>
                      <MDBox display="flex" gap={1} alignItems="baseline">
                        <MDTypography
                          variant="h6"
                          fontWeight="bold"
                          color="dark"
                        >
                          Fecha fin:
                        </MDTypography>
                        <MDTypography
                          variant="h6"
                          fontWeight="regular"
                          color="secondary"
                        >
                          {objeto.fecha_fin}
                        </MDTypography>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} sm={4} mb={1}>
                      <MDBox display="flex" gap={1} alignItems="baseline">
                        <MDTypography
                          variant="h6"
                          fontWeight="bold"
                          color="dark"
                        >
                          Expedición:
                        </MDTypography>
                        <MDTypography
                          variant="h6"
                          fontWeight="regular"
                          color="secondary"
                        >
                          {objeto.fecha_expedicion}
                        </MDTypography>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} sm={12} mb={1}>
                      <MDBox display="flex" gap={1} alignItems="baseline">
                        <MDTypography
                          variant="h6"
                          fontWeight="bold"
                          color="dark"
                        >
                          Firmante:
                        </MDTypography>
                        <MDTypography
                          variant="h6"
                          fontWeight="regular"
                          color="secondary"
                        >
                          {objeto.firmante_nombre}
                        </MDTypography>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </PageLayout>
  );
};

export default CursoDetallesQr;
