import { enqueueSnackbar } from "notistack";
import React from "react";
import PropTypes from "prop-types";

export class ErrorBundary extends React.Component {
  state = { hasError: false, error: undefined };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error);
    this.setState({ error });
  }

  render() {
    if (this.state.hasError && !this.props.renderOnError)
      enqueueSnackbar("Ocurrió un error", { variant: "error" });
    else if (this.state.hasError && this.props.renderOnError)
      return this.props.renderOnError({
        error: this.state.error,
        hasError: this.state.hasError,
      });
    else return this.props.children;
  }
}

ErrorBundary.propTypes = {
  renderOnError: PropTypes.func,
};
