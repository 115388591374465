/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================
 
 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)
 
 Coded by www.creative-tim.com
 
 =========================================================
 
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DataTable from "examples/Tables/DataTable";

//React Hooks and Others
import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import {
  CircularProgress,
  FormControl,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import PropTypes from "prop-types";

import ComponentsPrivatization from "config/component_privatization";
import { style_icon } from "helpers/global_vars";
import {
  ESTUDIANTES_EDIT_URL,
  ESTUDIANTES_LIST_URL,
  ESTUDIANTES_NEW_URL,
} from "config/bases_url";
import { useRedirectForm } from "hooks/useRedirectForm";
import {
  useDeleteEstudianteMutation,
  useGetCarrerasQuery,
  useGetEstudiantesQuery,
  useGetGrupoEscolarQuery,
} from "services";
import { AUXILIAR_DOCENTE, COORDINADOR_DOCENTE } from "roles";
import usePagination from "hooks/usePagination";
import ExcelModal from "../../../../components/ModalExcel/excel.modal";
import EstudianteShow from "./estudianteShow";
import useModalHandler from "../../../../hooks/useModalHandler";
import { handleToast } from "../../../../helpers/handleToast";
import excelFile from "assets/templates/Importar_estudiantes.xlsx";

export const FiltersStudentComponent = ({
  values,
  handleChange,
  grupos,
  carreras,
}) => {
  return (
    <>
      <FormControl sx={{ m: 1, minWidth: "10rem" }} size="large">
        <InputLabel id="estados-label">Estado</InputLabel>
        <Select
          sx={{ minHeight: "37.25px" }}
          labelId="estados-label"
          name="estado"
          value={values.estado}
          label="Grupo"
          onChange={handleChange}
        >
          <MenuItem value="">Limpiar</MenuItem>
          <MenuItem value={"Activo"}>Activos</MenuItem>
          <MenuItem value={"Baja Temporal"}>Baja Temporal</MenuItem>
          <MenuItem value={"Baja Definitiva"}>Baja Definitiva</MenuItem>
          <MenuItem value={"Graduado"}>Graduados</MenuItem>
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: "10rem" }} size="large">
        <InputLabel id="profesores-fitler">Carrera</InputLabel>
        <Select
          sx={{ minHeight: "37.25px" }}
          labelId="profesores-fitler"
          name="carrera"
          value={values.carrera}
          label="Carrera"
          onChange={handleChange}
        >
          <MenuItem value="">Limpiar</MenuItem>
          {carreras
            ?.filter(
              (obj, index, self) =>
                index === self.findIndex((t) => t.id === obj.id),
            )
            .map((el) => (
              <MenuItem key={el.id} value={el.id}>
                {`${el.nombre} ${el.plan_de_estudio}`}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: "10rem" }} size="large">
        <InputLabel id="demo-simple-select-label">Grupo</InputLabel>
        <Select
          sx={{ minHeight: "37.25px" }}
          labelId="demo-simple-select-label"
          name="grupo"
          value={values.grupo}
          label="Grupo"
          onChange={handleChange}
        >
          <MenuItem value="">Limpiar</MenuItem>
          {grupos
            ?.filter(
              (obj, index, self) =>
                index === self.findIndex((t) => t.id === obj.id),
            )
            .map((el) => (
              <MenuItem key={el.id} value={el.id}>
                {el.identificador}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </>
  );
};

function DataTableEstudiantes({ tableHeader }) {
  const { open, handleOpen, handleClose } = useModalHandler();

  const [values, setValues] = useState({
    carrera: "",
    grupo: "",
    estado: "",
  });

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const navigate = useNavigate();

  const {
    offset,
    setOffset,
    limit,
    busqueda,
    setBusqueda,
    handleNext,
    handlePrevious,
  } = usePagination();

  const { data: carrerasOrdenadas } = useGetCarrerasQuery(
    {
      filter_params: {
        estudiantes: true,
        ordering: "nombre,plan_de_estudio",
        query: "{plan_de_estudio, nombre,id,}",
      },
      shape: {
        id: "id",
        nombre: "nombre",
        plan_de_estudio: "plan_de_estudio",
      },
    },
    { refetchOnReconnect: true },
  );

  const { data: gruposOrdenados } = useGetGrupoEscolarQuery({
    filter_params: {
      estudiantes: true,
      carrera: values.carrera,
      ordering: "identificador",
      query: "{id,identificador}",
    },
    shape: {
      id: "id",
      identificador: "identificador",
    },
  });

  const {
    data: estudiantes_data,
    isError: isErrorG,
    isLoading: isLoadingG,
    error: errorG,
    isFetching,
  } = useGetEstudiantesQuery(
    {
      filter_params: {
        ...(values.estado !== "" ? { estado: values.estado } : {}),
        ...(values.grupo !== "" ? { grupo: values.grupo } : {}),
        buscar: busqueda,
        ordering:
          "user__apellido_paterno,user__apellido_materno,user__first_name,numero_de_control",
        limit: limit,
        offset: offset,
        preasignacion_de_carrera: values.carrera,
        grupo: values.grupo,
        query:
          "{user{first_name,apellido_materno,apellido_paterno,foto,email},numero_de_control,url,id,curp,genero{genero},tipo_sangre,telefonos}",
      },
      shape: {
        id: "id",
        nombre: "user.first_name",
        numero_de_control: "numero_de_control",
        apellido_materno: "user.apellido_materno",
        apellido_paterno: "user.apellido_paterno",
        preasignacion_de_carrera: "preasignacion_de_carrera",
        foto: "user.foto",
        curp: "curp",
        genero: "genero.genero",
        tipo_sangre: "tipo_sangre",
        telefonos: "telefonos",
        email: "user.email",
        grupo_actual: "grupo_actual",
      },
    },
    {
      refetchOnReconnect: true,
      refetchOnMountOrArgChange: true,
    },
  );

  //constantes para manejar la paginacion
  const est = estudiantes_data?.results
    ? estudiantes_data.results
    : estudiantes_data;
  const countTotal = estudiantes_data?.count;
  const previous = estudiantes_data?.previous;
  const next = estudiantes_data?.next;

  const [
    deleteEstudiante,
    {
      isSuccess: isSuccessD,
      isLoading: isLoadingD,
      isError: isErrorD,
      error: errorD,
    },
  ] = useDeleteEstudianteMutation();

  const dataTableEstudiante = {
    columns: [
      {
        Header: "Matrícula",
        accessor: "numero_de_control",
        Cell: ({ value }) => {
          return value ? value : "-------------";
        },
      },
      {
        Header: "Apellido Paterno",
        accessor: "apellido_paterno",
        Cell: ({ value }) => {
          return value ? value : "-------------";
        },
      },
      {
        Header: "Apellido Materno",
        accessor: "apellido_materno",
        Cell: ({ value }) => {
          return value ? value : "-------------";
        },
      },
      {
        Header: "Nombre(s)",
        accessor: "nombre",
        width: "20%",
        Cell: ({ value }) => {
          return value ? value : "-------------";
        },
      },
      {
        Header: "Acciones",
        accessor: "actions",
        width: "1%",
        Cell: ({ row }) => {
          const { original } = row;

          return (
            <MDBox display="flex">
              <ComponentsPrivatization
                permitted={[AUXILIAR_DOCENTE, COORDINADOR_DOCENTE]}
              >
                <MDBox px={0.2}>
                  <Tooltip
                    placement="top"
                    title="Editar la información del estudiante"
                  >
                    <Icon
                      style={style_icon}
                      onClick={() => handleClickEdit(original)}
                      fontSize="small"
                    >
                      edit
                    </Icon>
                  </Tooltip>
                </MDBox>
              </ComponentsPrivatization>
              <ComponentsPrivatization permitted={[COORDINADOR_DOCENTE]}>
                <MDBox px={0.2}>
                  <Tooltip
                    placement="top"
                    title="Eliminar el estudiante del listado"
                  >
                    <Icon
                      style={style_icon}
                      onClick={() =>
                        handleToast({
                          title: "¿Estás seguro?",
                          text: `¡Si eliminas a: "${original.nombre}", la acción no se podrá revertir!`,
                          dispatcher: (result) => {
                            if (result.isConfirmed) {
                              deleteEstudiante(original.id);
                            }
                          },
                        })
                      }
                      fontSize="small"
                    >
                      remove_circle
                    </Icon>
                  </Tooltip>
                </MDBox>
              </ComponentsPrivatization>
            </MDBox>
          );
        },
      },
    ],

    rows: est ?? [],
  };

  useRedirectForm(
    isLoadingG,
    null,
    isErrorG,
    errorG,
    "Hubo problemas para cargar la información",
    ESTUDIANTES_LIST_URL,
  );

  useRedirectForm(
    isLoadingD,
    isSuccessD,
    isErrorD,
    errorD,
    "Estudiante eliminado",
    ESTUDIANTES_LIST_URL,
  );

  const handleClickEdit = ({ id }) => {
    navigate(ESTUDIANTES_EDIT_URL(id));
  };

  return (
    <MDBox pt={6} pb={3}>
      <Card>
        <MDBox
          display="flex"
          gap={2}
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent={"space-between"}
          alignItems={"center"}
          m={2}
          p={3}
        >
          <MDTypography variant="h5" fontWeight="medium">
            {tableHeader}
          </MDTypography>
          <ExcelModal open={open} handleClose={handleClose} />

          <ComponentsPrivatization
            permitted={[COORDINADOR_DOCENTE, AUXILIAR_DOCENTE]}
          >
            <MDBox display={"flex"} gap={2} alignItems={"center"}>
              <Tooltip title={"Descargar plantilla"} placement={"top"}>
                <a
                  href={excelFile}
                  download="Descargar plantilla excel"
                  target="_blank"
                  rel="noreferrer"
                >
                  <MDButton
                    variant="gradient"
                    color="dark"
                    size="medium"
                    circular
                    iconOnly
                  >
                    <Icon fontSize={"large"}>cloud_download</Icon>
                  </MDButton>
                </a>
              </Tooltip>
              <Tooltip title={"Importar fichero excel"} placement={"top"}>
                <MDButton
                  variant="gradient"
                  color="dark"
                  size="medium"
                  circular
                  iconOnly
                  onClick={handleOpen}
                >
                  <Icon fontSize={"large"}>cloud_upload</Icon>
                </MDButton>
              </Tooltip>
              <Link to={ESTUDIANTES_NEW_URL}>
                <Tooltip
                  placement="top"
                  title="Crear nuevo estudiante con toda su información requerida"
                >
                  <MDButton variant="gradient" color="info" size="medium">
                    nuevo
                  </MDButton>
                </Tooltip>
              </Link>
            </MDBox>
          </ComponentsPrivatization>
        </MDBox>
        <MDBox display="flex" justifyContent={"center"} alignItems={"center"}>
          {isLoadingG || isFetching ? (
            <CircularProgress sx={{ marginBottom: "3rem" }} color="inherit" />
          ) : (
            <DataTable
              table={dataTableEstudiante}
              canSearch
              paginationApi
              countTotal={countTotal}
              next={next}
              previous={previous}
              handleNext={handleNext}
              handlePrevious={handlePrevious}
              setBusqueda={setBusqueda}
              offset={offset}
              setOffset={setOffset}
              filtersComponent={
                <FiltersStudentComponent
                  grupos={gruposOrdenados}
                  carreras={carrerasOrdenadas}
                  values={values}
                  handleChange={handleChange}
                />
              }
              collapse
              detailsComponent={(rowData) => (
                <EstudianteShow rowData={rowData} />
              )}
            />
          )}
        </MDBox>
      </Card>
    </MDBox>
  );
}

DataTableEstudiantes.propTypes = {
  tableHeader: PropTypes.string.isRequired,
};

export default DataTableEstudiantes;
