import {cursosApi} from "../view/cursos.service";

export const createCurso = cursosApi.injectEndpoints({
    endpoints: (builder) => ({
        createCurso: builder.mutation({
            query: (data) => {
                data.nombre = data.nombre.trim();

                return {
                    url: "/v1/api/curso/",
                    method: "POST",
                    body: data,
                };
            },
            invalidatesTags: [{type: "Cursos", id: "LIST"}],
        }),
    }),
})

export const {
    useCreateCursoMutation,
} = createCurso;