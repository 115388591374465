/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

const form = {
  formId: "new-grupo-escolar",
  formField: {
    nombre: {
      name: "nombre",
      label: "Nombre",
      type: "text",
      errorMsg: "El nombre es requerido.",
    },
    descripcion: {
      name: "descripcion",
      label: "Descripción",
      type: "text",
    },
    asistencia: {
      name: "asistencia",
      label: "% asistencia",
      type: "tel",
      errorMsg: "La asistencia es requerida.",
    },
    anterior: {
      name: "anterior",
      label: "Examen anterior",
      type: "text",
    },
  },
};

export default form;
