import * as React from "react";
import PropTypes from "prop-types";
import { CircularProgress, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import { dataTableActivo } from "./data/dataTable";

import { useGetHistoryQuery } from "services";
import ModalBase from "components/ModalBase/modal_base";
import { useRedirectForm } from "hooks/useRedirectForm";
import { ACTIVOS_CONSUMIBLES_RESGUARDOS_LIST_URL } from "config/bases_url";

const formatFieldName = (fieldName) => {
  let formattedName = fieldName.replace(/_/g, " ");
  const replacements = {
    adquisicion: "adquisición",
    metodo: "método",
    numero: "número",
    facturacion: "facturación",
    resourcetype: "tipo",
    llave: "clave de bien",
  };
  Object.entries(replacements).forEach(([from, to]) => {
    formattedName = formattedName.replace(from, to);
  });
  return formattedName.charAt(0).toUpperCase() + formattedName.slice(1);
};

const getFieldText = (field, obj) => {
  const specialFields = {
    estado: (obj) => obj[field].nombre,
    metodo_de_adquisicion: (obj) => obj[field].nombre,
    tipo: (obj) => `${obj[field].base} / ${obj[field].nombre}`,
  };

  const formatter = specialFields[field] || ((obj) => obj[field]);
  return formatter(obj);
};

const ModalActivoShow = ({ open, handleClose, activo }) => {
  const {
    data: history,
    isLoading,
    isError,
    error,
  } = useGetHistoryQuery(activo.id);

  useRedirectForm(
    isLoading,
    null,
    isError,
    error,
    "Hubo problemas para cargar la información",
    ACTIVOS_CONSUMIBLES_RESGUARDOS_LIST_URL
  );

  const table = {
    columns: dataTableActivo.columns,
    rows:
      history
        ?.map((el) => el)
        .sort((a, b) => new Date(a.fecha) - new Date(b.fecha))
        .reverse() ?? [],
  };

  const fieldsActivo = [
    "estado",
    "metodo_de_adquisicion",
    "marca",
    "modelo",
    "fecha_de_facturacion",
    "fecha_cedula",
    "proveedor",
    "numero_de_factura",
    "tipo",
    "numero_de_serie",
    "llave",
    "numero_de_inventario",
  ];

  const createFieldString = (field, obj) => {
    const fieldName = formatFieldName(field);
    const fieldValue = getFieldText(field, obj);

    return (
      <React.Fragment key={field}>
        <b>{`${fieldName}: `}</b>
        {fieldValue || "**NO POSEE**"}
      </React.Fragment>
    );
  };

  function formatearNumero(numero) {
    const numeroStr = numero.toString();

    let [parteEntera, parteDecimal] = numeroStr.split(".");
    parteDecimal = parteDecimal || "00";
    if (parteDecimal.length === 1) {
      parteDecimal += "0";
    } else if (parteDecimal.length > 2) {
      parteDecimal = parteDecimal.slice(0, 2);
    }

    let grupos = [];
    while (parteEntera.length > 0) {
      grupos.unshift(parteEntera.slice(-3));
      parteEntera = parteEntera.slice(0, -3);
    }

    const numeroFormateado = grupos.join(",") + "." + parteDecimal;

    return numeroFormateado;
  }

  return (
    <ModalBase open={open} handleClose={handleClose}>
      <Grid container>
        <Grid item xs={12} sx={{ mx: "auto" }}>
          <MDBox>
            <MDBox mb={1}>
              <Grid container display={"flex"} justifyContent="space-between">
                <Grid
                  container
                  mt={0.2}
                  display="flex"
                  justifyContent={"space-between"}
                >
                  <Grid item xs={12} lg={12}>
                    <MDTypography variant="h4" fontWeight="bold" mb={2}>
                      Descripción:
                    </MDTypography>
                    <MDTypography
                      variant="h6"
                      fontWeight="bold"
                      style={{ textAlign: "justify" }}
                    >
                      {activo?.descripcion}
                    </MDTypography>
                  </Grid>
                </Grid>
                {activo.foto && (
                  <MDBox
                    item
                    xs={12}
                    lg={12}
                    sx={{
                      display: "flex",
                      position: "relative",
                      width: "400px",
                    }}
                  >
                    <img
                      src={activo.foto}
                      alt={"Imagen del activo"}
                      style={{
                        width: "100%",
                        borderRadius: "1rem",
                        marginTop: "0.5rem",
                      }}
                    />
                  </MDBox>
                )}
              </Grid>
            </MDBox>
            <MDBox mt={1} ml={1}>
              <MDTypography variant="h6" fontWeight="medium">
                Precio:
              </MDTypography>
            </MDBox>
            <MDBox mb={1} ml={2}>
              <MDTypography variant="h5" fontWeight="medium">
                {"$"}
                {formatearNumero(activo.valor)}
                {" MX"}
              </MDTypography>
            </MDBox>
            <MDBox mt={3} mb={1} ml={0.5}>
              <MDTypography
                variant="contained"
                fontWeight="bold"
                color="text"
                ml={1}
              >
                Detalles:
              </MDTypography>
            </MDBox>
            <MDBox component="ul" m={0} pl={4} mb={2} ml={1.5}>
              {fieldsActivo.map((field, idx) => (
                <MDBox
                  component="li"
                  color="text"
                  fontSize="1.25rem"
                  lineHeight={1}
                  key={idx}
                >
                  <MDTypography
                    variant="body2"
                    color="text"
                    fontWeight="regular"
                    verticalAlign="middle"
                  >
                    {createFieldString(field, activo)}
                  </MDTypography>
                </MDBox>
              ))}
            </MDBox>
          </MDBox>
        </Grid>
      </Grid>
      {history?.length > 0 ? (
        <Grid container display="flex" ml={1}>
          <Grid item xs={12} sx={{ mx: "auto" }}>
            <MDTypography variant="h3" fontWeight="bold">
              Historial:
            </MDTypography>
          </Grid>
          <MDBox display="flex" justifyContent={"center"} alignItems={"center"}>
            {isLoading ? (
              <CircularProgress sx={{ marginBottom: "3rem" }} color="inherit" />
            ) : (
              <Grid item xs={12} sx={{ mx: "auto" }}>
                <DataTable
                  canSearch
                  entriesPerPage={{
                    defaultValue: 5,
                    entries: [5, 10, 15],
                  }}
                  table={table}
                />
              </Grid>
            )}
          </MDBox>
        </Grid>
      ) : null}
    </ModalBase>
  );
};

ModalActivoShow.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ModalActivoShow;
