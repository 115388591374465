import * as React from "react";
import MDBox from "components/MDBox";
import { Card, Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import PageLayout from "examples/LayoutContainers/PageLayout";
import fondo from "assets/images/fondos/fondoPizarra.jpg";
import { useLocation } from "react-router-dom";
import MDInput from "components/MDInput";
import no_foto from "assets/images/not_found_image.jpg";

const TrabajadoresDetallesQr = () => {
  const location = useLocation();

  // Extrae la cadena de consulta de la URL
  const base64Query = new URLSearchParams(location.search).get("query");

  const decodificadoBase64 = atob(base64Query);
  const objeto = JSON.parse(decodificadoBase64);
  return (
    <PageLayout>
      <MDBox
        position="absolute"
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) =>
            fondo &&
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${fondo})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MDBox px={1} width="100%" height="100vh" mx="auto">
        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <MDBox pt={6} pb={3} ml={10} mr={10} minHeight="200px">
            <Card>
              <MDBox
                mt={-4}
                borderRadius="xxl"
                variant="gradient"
                sx={{ boxShadow: "0rem 0rem 0.625rem 0.3rem rgb(0 0 0 / 40%)" }}
                width={"95%"}
                ml={"2.5%"}
                display="flex"
                alignItems={"center"}
              >
                <MDBox
                  component="img"
                  src={objeto.foto ?? no_foto}
                  borderRadius="section"
                  mx={2}
                  p={2}
                  maxWidth={"160px"}
                />

                <MDTypography variant="h5" fontWeight="regular">
                  {objeto.nombre ?? "Juan Perez Perez"}
                </MDTypography>
              </MDBox>
              <MDBox
                display="flex"
                justifyContent={"center"}
                alignItems={"flex-start"}
                m={2}
                p={3}
              >
                <MDBox>
                  <Grid container spacing={2}>
                    <Grid item xs={12} mb={1} sm={12}>
                      <MDInput
                        variant="outlined"
                        id="entidad-read-only"
                        label="Entidad:"
                        defaultValue={objeto.entidad}
                        placeholder="Ejemplo de Entidad"
                        InputProps={{
                          readOnly: true,
                        }}
                        style={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} mb={1} sm={9}>
                      <MDInput
                        variant="outlined"
                        id="correo-read-only"
                        label="Correo:"
                        defaultValue={objeto.correo}
                        placeholder="Ej: ejemplo@ejemplo.com"
                        InputProps={{
                          readOnly: true,
                        }}
                        style={{ width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
            </Card>
          </MDBox>
        </Grid>
      </MDBox>
    </PageLayout>
  );
};

export default TrabajadoresDetallesQr;
