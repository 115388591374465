/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

const form = {
  formId: "new-grupo-escolar",
  formField: {
    nombre: {
      name: "nombre",
      label: "Nombre",
      type: "text",
      errorMsg: "El nombre es requerido.",
    },
    modalidad: {
      name: "modalidad",
      label: "Modalidad",
      type: "text",
      errorMsg: "La modalidad es requerida.",
    },
    rvoe: {
      name: "rvoe",
      label: "RVOE",
      type: "text",
      errorMsg: "El campo es requerido.",
    },
    nivel: {
      name: "nivel",
      label: "Nivel",
      type: "text",
      errorMsg: "El campo es requerido.",
    },
    plan_de_estudio: {
      name: "plan_de_estudio",
      label: "Plan de estudio",
      type: "text",
      errorMsg: "El plan de estudio es requerido.",
    },
    duracion: {
      name: "duracion",
      label: "Duración",
      type: "text",
      errorMsg: "El campo es requerido.",
    },
    calificacion_minima: {
      name: "calificacion_minima",
      label: "Calificación mínima",
      type: "tel",
      errorMsg: "El campo es requerido.",
    },
    calificacion_maxima: {
      name: "calificacion_maxima",
      label: "Calificación máxima",
      type: "tel",
      errorMsg: "El campo es requerido.",
    },
    calificacion_aprobatoria: {
      name: "calificacion_aprobatoria",
      label: "Calificación aprobatoria",
      type: "tel",
      errorMsg: "El campo es requerido.",
    },
    fecha_expedicion: {
      name: "fecha_expedicion",
      label: "Fecha expedición",
      type: "date",
      errorMsg: "El campo es requerido.",
    },
    identificador: {
      name: "identificador",
      label: "Identificador",
      type: "text",
      errorMsg: "El campo es requerido.",
    },
  },
};

export default form;
