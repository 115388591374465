import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  status: "idle",
  edit: null,
  clone: null,
  noHere: true,
  isToAddChild: false,
  flag: null,
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setEditAdmin: (state, action) => {
      state.edit = action.payload;
    },
    setClone: (state, action) => {
      state.clone = action.payload;
    },
    setNoHereAdmin: (state, action) => {
      state.noHere = action.payload;
    },
    setIsChildAdd: (state, action) => {
      state.isToAddChild = action.payload;
    },
    setFlag: (state, action) => {
      switch (action.payload) {
        case "EDIT":
          state.flag = "EDIT";
          break;
        case "CREATE":
          state.flag = "CREATE";
          break;
        default:
          break;
      }
    },
  },
});

export const {
  setEditAdmin,
  setNoHereAdmin,
  setIsChildAdd,
  setClone,
  setFlag,
} = adminSlice.actions;

export const admin_state = (state) => state.admin;

export default adminSlice.reducer;
