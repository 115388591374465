/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// formik components
import {Form, Formik} from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// NewUser page components
import AddEstadoActivo from "./components/AddEstadoActivo/add.form";

// NewUser layout schemas for form and form feilds
import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {admin_state, setEditAdmin, setNoHereAdmin} from "slices";
import MDTypography from "components/MDTypography";
import {ESTADO_ACTIVOS_URL} from "config/bases_url";
import {useCreateEstadoMutation, useEditEstadoMutation,} from "services/catalogo/estado_activo.service";
import {useSnackbar} from "notistack";
import {useRedirectForm} from "hooks/useRedirectForm";
import FormErrorHandler from "../../../../handlers/FormErrorHandler/form-error.handler";
import {ErrorBundary} from "../../../../config/ErrorBundary/error-bundary";

function NewEstadoActivo() {
  const { formId, formField } = form;
  const currentValidation = validations[0];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [
    createEstado,
    {
      isSuccess: isSuccessC,
      isLoading: isLoadingC,
      isError: isErrorC,
      error: errorC,
    },
  ] = useCreateEstadoMutation();
  const [
    editEstado,
    {
      isSuccess: isSuccessE,
      isLoading: isLoadingE,
      isError: isErrorE,
      error: errorE,
    },
  ] = useEditEstadoMutation();

  const { edit, noHere } = useSelector(admin_state);

  useEffect(() => {
    if (noHere) {
      navigate(ESTADO_ACTIVOS_URL);
    }
  }, []);

  useRedirectForm(
    isLoadingC,
    isSuccessC,
    isErrorC,
    errorC,
    "Estado de activo creado",
    ESTADO_ACTIVOS_URL
  );

  useRedirectForm(
    isLoadingE,
    isSuccessE,
    isErrorE,
    errorE,
    "Estado de activo editado",
    ESTADO_ACTIVOS_URL
  );

  const submitForm = async (values, actions) => {
    if (edit === null) {
      await createEstado(values);
      dispatch(setNoHereAdmin(true));
    } else if (
      Object.keys(
        Object.fromEntries(
          Object.entries(values).filter(([k, v]) => edit[k] !== v)
        )
      ).length !== 0
    ) {
      await editEstado({ id: edit.id, ...values });
      dispatch(setNoHereAdmin(true));
    }

    if (isSuccessC || isSuccessE) {
      actions.setSubmitting(false);
      actions.resetForm();
    }

    dispatch(setEditAdmin(null));
    navigate(ESTADO_ACTIVOS_URL);
  };

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
  };

  return (
    <MDBox mt={5} mb={20}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} lg={8}>
          <MDBox mt={6} mb={8} textAlign="center">
            <MDBox mb={1}>
              <MDTypography variant="h3" fontWeight="bold">
                Estado de Activos
              </MDTypography>
            </MDBox>
            <MDTypography variant="h5" fontWeight="regular" color="secondary">
              {edit === null
                ? "Introduzca la información relacionada al Estado del Activo por agregar"
                : `Edite la información relacionada al estado de activo: ${edit.nombre}`}
            </MDTypography>
          </MDBox>
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, setFieldValue }) => {
              useEffect(() => {
                if (edit !== null) {
                  const fields = ["nombre"];

                  fields.forEach((field) =>
                    setFieldValue(formField[field].name, edit[field], true)
                  );
                }
              }, [edit]);

              return (
                <ErrorBundary renderOnError={() => <FormErrorHandler />}>
                  <Form id={formId} autoComplete="off">
                    <Card sx={{ height: "100%" }}>
                      <MDBox p={3}>
                        <MDBox>
                          <AddEstadoActivo
                            formData={{
                              values,
                              touched,
                              formField,
                              errors,
                            }}
                          />
                          <MDBox
                            mt={2}
                            width="100%"
                            display="flex"
                            justifyContent="space-between"
                          >
                            <MDButton
                              onClick={(e) => {
                                navigate(ESTADO_ACTIVOS_URL);
                                dispatch(setEditAdmin(null));
                              }}
                              variant="gradient"
                              color="primary"
                            >
                              cancelar
                            </MDButton>
                            <MDButton
                              type="submit"
                              variant="gradient"
                              color="dark"
                            >
                              aceptar
                            </MDButton>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </Card>
                  </Form>
                </ErrorBundary>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default NewEstadoActivo;
