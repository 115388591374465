/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// formik components
import { Form, Formik } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// NewEntity page components
import AddEntidad from "./components/entidad.add";

// NewEntity layout schemas for form and form
import validations from "./schemas/validations";
import form from "./schemas/form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import MDTypography from "components/MDTypography";
import {
  useCreateEntidadMutation,
  useEditEntidadMutation,
} from "services/administracion/entidades.service";
import { ENTIDADES_URL } from "config/bases_url";
import { admin_state, setEditAdmin, setNoHereAdmin } from "slices";
import initialValues from "./schemas/initialValues";
import { useRedirectForm } from "hooks/useRedirectForm";
import FormErrorHandler from "../../../../handlers/FormErrorHandler/form-error.handler";
import { ErrorBundary } from "../../../../config/ErrorBundary/error-bundary";
import { getModifiedFields } from "helpers/functions";

function NewEntity() {
  const [
    createEntidad,
    {
      isSuccess: isSuccessC,
      isLoading: isLoadingC,
      isError: isErrorC,
      error: errorC,
    },
  ] = useCreateEntidadMutation();
  const [
    editEntidad,
    {
      isSuccess: isSuccessE,
      isLoading: isLoadingE,
      isError: isErrorE,
      error: errorE,
    },
  ] = useEditEntidadMutation();

  const { formId, formField } = form;
  const currentValidation = validations[0];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { edit, noHere } = useSelector(admin_state);

  useEffect(() => {
    if (noHere) {
      navigate(ENTIDADES_URL);
    }
  }, []);

  useRedirectForm(
    isLoadingC,
    isSuccessC,
    isErrorC,
    errorC,
    "Entidad creada",
    ENTIDADES_URL
  );

  useRedirectForm(
    isLoadingE,
    isSuccessE,
    isErrorE,
    errorE,
    "Entidad editada",
    ENTIDADES_URL
  );

  const submitForm = async (values, actions) => {
    if (edit === null) {
      await createEntidad(values);
      dispatch(setNoHereAdmin(true));
      dispatch(setEditAdmin(null));
    } else if (
      Object.keys(
        Object.fromEntries(
          Object.entries(values).filter(([k, v]) => edit[k] !== v)
        )
      ).length !== 0
    ) {
      const modifyFields = getModifiedFields(edit, values);
      await editEntidad({ id: edit.id, ...modifyFields });
      dispatch(setNoHereAdmin(true));
      dispatch(setEditAdmin(null));
    }

    actions.setSubmitting(false);
    actions.resetForm();
    navigate(ENTIDADES_URL);
    dispatch(setNoHereAdmin(true));
  };

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
  };

  return (
    <MDBox mt={5} mb={20}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} lg={8}>
          <MDBox mt={6} mb={8} textAlign="center">
            <MDBox mb={1}>
              <MDTypography variant="h3" fontWeight="bold">
                {edit === null ? "Agregar Entidad" : "Editar Entidad"}
              </MDTypography>
            </MDBox>
            <MDTypography variant="h5" fontWeight="regular" color="secondary">
              {edit === null
                ? "Entre la información relacionada a la entidad a agregar"
                : `Edite la información relacionada a la entidad: ${edit.nombre}`}
            </MDTypography>
          </MDBox>
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isValid, setFieldValue }) => {
              useEffect(() => {
                if (edit !== null) {
                  setFieldValue(formField.nombre.name, edit.nombre, true);
                  setFieldValue(
                    formField.logo.name,
                    edit.logo !== null ? edit.logo : ""
                  );
                  setFieldValue(formField.municipio.name, edit.municipio, true);
                  setFieldValue(formField.localidad.name, edit.localidad, true);
                  setFieldValue(
                    formField.clave_del_centro.name,
                    edit.clave_del_centro,
                    true
                  );
                  setFieldValue(formField.dominio.name, edit.dominio, true);
                }
              }, []);

              return (
                <ErrorBundary renderOnError={() => <FormErrorHandler />}>
                  <Form id={formId} autoComplete="off">
                    <Card sx={{ height: "100%" }}>
                      <MDBox p={3}>
                        <MDBox>
                          <AddEntidad
                            formData={{
                              values,
                              touched,
                              formField,
                              errors,
                              setFieldValue,
                            }}
                          />
                          <MDBox
                            mt={2}
                            width="100%"
                            display="flex"
                            justifyContent="space-between"
                          >
                            <MDButton
                              onClick={(e) => {
                                dispatch(setEditAdmin(null));
                                navigate(ENTIDADES_URL);
                              }}
                              variant="gradient"
                              color="primary"
                            >
                              cancelar
                            </MDButton>
                            <MDButton
                              disabled={!isValid}
                              type="submit"
                              variant="gradient"
                              color="dark"
                            >
                              aceptar
                            </MDButton>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </Card>
                  </Form>
                </ErrorBundary>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default NewEntity;
