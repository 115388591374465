/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React, {useState} from "react";

// react-router-dom components
import {useLocation} from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import {HOME_URL} from "config/bases_url";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import FormField from "components/FormField";
import {useResetPasswordConfirmMutation} from "services/principal/auth.service";
import Sinfondo from "assets/images/tecnoschool/Sinfondo.png";
import {Icon, InputAdornment} from "@mui/material";
import {useRedirectForm} from "hooks/useRedirectForm";
import {style_icon} from "helpers/global_vars";
import {useAuthRedirect} from "../../../hooks/useAuthRedirect.";

function useQuery() {
    const {search} = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function NewPassword() {
    const query = useQuery();
    const [resetPasswordConfirm, {isLoading, isSuccess, error, isError}] =
        useResetPasswordConfirmMutation();

    const [shown1, setShown1] = useState(false);
    const [shown2, setShown2] = useState(false);

    const handleSubmit = async (values, actions) => {
        actions.setSubmitting(false);
        actions.resetForm();

        await resetPasswordConfirm({
            new_password1: values.new_pass,
            new_password2: values.re_pass,
            uid: query.get("uid"),
            token: query.get("token"),
        });
    };

    useAuthRedirect()

    useRedirectForm(
        isLoading,
        isSuccess,
        isError,
        error,
        "Credenciales cambiadas correctamente",
        HOME_URL
    );

    const handleChangeShown1 = () => {
        setShown1(!shown1);
    };

    const handleChangeShown2 = () => {
        setShown2(!shown2);
    };

    return (
        <BasicLayout image={bgImage}>
            <Card>
                <MDBox
                    component="img"
                    src={Sinfondo}
                    variant="gradient"
                    bgColor="dark"
                    coloredShadow="dark"
                    borderRadius="xxl"
                    mx={2}
                    mt={-10}
                    p={2}
                    mb={1}
                />

                <MDBox pt={3} pb={3} px={3}>
                    <Formik
                        initialValues={{new_pass: "", re_pass: ""}}
                        validationSchema={Yup.object({
                            new_pass: Yup.string()
                                .min(8, "La contraseña debe tener al menos 8 caracteres")
                                .matches(
                                    /[A-Z]/,
                                    "La contraseña debe contener al menos una letra mayúscula"
                                )
                                .matches(
                                    /[0-9]/,
                                    "La contraseña debe contener al menos un número"
                                )
                                .matches(
                                    /[!@#$%^&*(),.?":{}|<>]/,
                                    "La contraseña debe contener al menos un carácter especial"
                                )
                                .required("Debe proveer una contraseña"),
                            re_pass: Yup.string()
                                .oneOf(
                                    [Yup.ref("new_pass"), null],
                                    "Las contraseñas deben coincidir"
                                )
                                .required("Debe repetir la contraseña"),
                        })}
                        onSubmit={handleSubmit}
                    >
                        {({values, isValid, handleChange, handleBlur}) => {
                            return (
                                <Form id={"reset_password_confirm"} autoComplete="off">
                                    <MDBox mb={2}>
                                        <MDBox mb={2}>
                                            <FormField
                                                type={shown1 ? "text" : "password"}
                                                label="Contraseña"
                                                name="new_pass"
                                                value={values.new_pass}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                autocomplete="new-password"
                                                placeholder="Escriba una contraseña"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {values.new_pass.length !== 0 ? (
                                                                <Icon
                                                                    style={style_icon}
                                                                    onClick={handleChangeShown1}
                                                                    fontSize="small"
                                                                    variant="outlined"
                                                                >
                                                                    {shown1 ? "visibility_off" : "visibility"}
                                                                </Icon>
                                                            ) : null}
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                fullWidth
                                            />
                                        </MDBox>
                                        <MDBox mb={2}>
                                            <FormField
                                                type={shown2 ? "text" : "password"}
                                                label="Confirmar Contraseña"
                                                name="re_pass"
                                                value={values.re_pass}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                autocomplete="new-password"
                                                placeholder="Repita la contraseña"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {values.new_pass.length !== 0 ? (
                                                                <Icon
                                                                    style={style_icon}
                                                                    onClick={handleChangeShown2}
                                                                    fontSize="small"
                                                                    variant="outlined"
                                                                >
                                                                    {shown2 ? "visibility_off" : "visibility"}
                                                                </Icon>
                                                            ) : null}
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                fullWidth
                                            />
                                        </MDBox>
                                    </MDBox>

                                    <MDBox mt={5} mb={1}>
                                        <MDButton
                                            disabled={!isValid}
                                            variant="gradient"
                                            color="info"
                                            type="submit"
                                            fullWidth
                                        >
                                            confirmar
                                        </MDButton>
                                    </MDBox>
                                </Form>
                            );
                        }}
                    </Formik>
                </MDBox>
            </Card>
        </BasicLayout>
    );
}

export default NewPassword;
