import checkout from "./form";

const {
  formField: {
    estado,
    descripcion,
    metodo_de_adquisicion,
    marca,
    modelo,
    fecha_de_facturacion,
    fecha_cedula,
    proveedor,
    numero_de_factura,
    valor,
    resourcetype,
    tipo,
    cantidad,
    foto,
    unidad_de_medida,
  },
} = checkout;

const initialValues = {
  [estado.name]: "",
  [resourcetype.name]: "Consumible",
  [descripcion.name]: "",
  [metodo_de_adquisicion.name]: "",
  [marca.name]: "",
  [modelo.name]: "",
  [fecha_de_facturacion.name]: new Date().toISOString().slice(0, 10),
  [fecha_cedula.name]: new Date().toISOString().slice(0, 10),
  [proveedor.name]: "",
  [numero_de_factura.name]: "",
  [valor.name]: "",
  [tipo.name]: "",
  [cantidad.name]: "",
  [foto.name]: null,
  [unidad_de_medida.name]: "",
};

export default initialValues;
