/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DataTable from "examples/Tables/DataTable";
import { CircularProgress, Icon, Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import { useGetEstudiantesPorCursoQuery } from "services";
import React, { useMemo } from "react";
import { style_icon } from "helpers/global_vars";
import { ESTUDIANTE } from "roles";
import MyPDF from "layouts/escuela/pdf/reactPdf/pdf-plantilla";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { generarURL } from "helpers/functions";
import { QRCodeCanvas } from "qrcode.react";
import { useGetFirmaCursosQuery } from "services";

function DataTableCursosPerfilEstudiante() {
  const estudiante_id = JSON.parse(
    sessionStorage.getItem("user")
  ).perfil.resourcetype.find((e) => e.resourcetype === ESTUDIANTE).id;

  const {
    data: estPorCurso,
    isLoading,
    isFetching,
  } = useGetEstudiantesPorCursoQuery(
    {
      filter_params: {
        estudiante: estudiante_id,
        firmado: true,
        query:
          "{id,estudiante{user{first_name,apellido_paterno,apellido_materno}},firmado,curso{id,nombre,nombre_corto,duracion,expedicion,localizacion,fecha_inicio,fecha_fin,firmante{firma,cargo,user{first_name,apellido_paterno,apellido_materno}},modalidad{nombre}}}",
      },
      shape: {
        id: "id",
        estudiante: "estudiante",
        firmado: "firmado",
        nombreCurso: "curso.nombre",
        nombreCorto: "curso.nombre_corto",
        duracionCurso: "curso.duracion",
        expedicion: "curso.expedicion",
        localizacion: "curso.localizacion",
        fecha_inicio: "curso.fecha_inicio",
        fecha_fin: "curso.fecha_fin",
        modalidad: "curso.modalidad.nombre",
      },
    },
    {
      refetchOnReconnect: true,
    }
  );

  const { data: getFirmaCurso } = useGetFirmaCursosQuery({
    filter_params: {
      signed_object__estudiante: estudiante_id,
      query:
        "{id,firmante{cargo,firma,user{first_name,apellido_paterno,apellido_materno}},fecha_creacion,sello,signed_object{id}}",
    },
    shape: {
      nombre: "firmante.user.first_name firmante.user.apellido_paterno firmante.user.apellido_materno",
      cargo: "firmante.cargo",
      firma: "firmante.firma",
      fecha: "fecha_creacion",
      sello: "sello",
      id: "id",
      dist_est: "signed_object.id",
    },
  });

  const data = useMemo(() => {
    return {
      columns: [
        {
          Header: "Nombre",
          accessor: "nombreCurso",
          Cell: ({ value }) => {
            return value ? value : "-------------";
          },
        },
        {
          Header: "Nombre Corto",
          accessor: "nombreCorto",
        },
        {
          Header: "Modalidad",
          accessor: "modalidad",
        },
        {
          Header: "Acciones",
          accessor: "actions",
          width: "1%",
          Cell: ({ row }) => {
            const { original } = row;
            const { estudiante, firmado, id, ...rest } = original;
            const curso = rest;
            const obj_estudiante = {estudiante: estudiante}
            return (
              <MDBox display="flex">
                <MDBox px={0.2}>
                  <Tooltip
                    placement="top"
                    title="Ver certificado de constancia extracurricular firmado digitalmente"
                  >
                    <Icon
                      style={style_icon}
                      fontSize="small"
                      onClick={() => printPDF(curso, obj_estudiante, id)}
                    >
                      picture_as_pdf
                    </Icon>
                  </Tooltip>
                </MDBox>
              </MDBox>
            );
          },
        },
      ],

      rows: estPorCurso ?? [],
    };
  }, [estPorCurso]);

  const printPDF = (curso, obj_estudiante, id) => {
    const firmaCorrespondiente = getFirmaCurso.find(
      (elem) => elem.dist_est === id
    );
    const canvas = document.getElementById(
      `qrCodeCertificado-${firmaCorrespondiente.dist_est}`
    );
    if (canvas) {
      const dataURL = canvas.toDataURL();
      if (dataURL) {
        let obj = pdf(
          <MyPDF
            curso={curso}
            estudiante={obj_estudiante}
            qrImageSrc={dataURL}
            firma={firmaCorrespondiente}
          />
        ).toBlob();
        obj.then(function (blob) {
          let url = URL.createObjectURL(blob);
          window.open(url, "_blank");
        });
      }
    }
  };

  return (
    <MDBox pt={6} pb={3}>
      {getFirmaCurso?.map((estudiante) => {
        return (
          <QRCodeCanvas
            key={estudiante.dist_est}
            id={`qrCodeCertificado-${estudiante.dist_est}`}
            style={{
              width: "3.6cm",
              height: "3.6cm",
              display: "none",
            }}
            value={`${generarURL(estudiante.dist_est, true)}`}
            bgColor={"#fff"}
            size={90}
            level={"H"}
            imageSettings={{ height: 3.6, width: 3.6 }}
          />
        );
      })}
      <Card>
        <MDBox
          display="flex"
          justifyContent={"space-between"}
          alignItems={"center"}
          m={2}
          p={3}
        >
          <MDTypography variant="h5" fontWeight="medium">
            {"Cursos Extracurriculares"}
          </MDTypography>
        </MDBox>
        <MDBox display="flex" justifyContent={"center"} alignItems={"center"}>
          {isLoading || isFetching ? (
            <CircularProgress sx={{ marginBottom: "3rem" }} color="inherit" />
          ) : (
            <DataTable table={data} canSearch entriesPerPage={false} />
          )}
        </MDBox>
      </Card>
    </MDBox>
  );
}

DataTableCursosPerfilEstudiante.propTypes = {
  tableHeader: PropTypes.string.isRequired,
};

export default DataTableCursosPerfilEstudiante;
