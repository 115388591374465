import * as React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import ModalBase from "../ModalBase/modal_base";
import ActaNotas from "layouts/escuela/pdf/printing/pdfCalificaciones";

const ModalNotasPdf = ({
  open,
  handleClose,
  profesor,
  ordinaria,
  semestre,
  cicle_name,
}) => {
  return (
    <ModalBase open={open} handleClose={handleClose} style={{ with: "80%" }}>
      <Grid container sx={{ width: "205mm" }}>
        <ActaNotas
          profesor={profesor}
          ordinaria={ordinaria}
          semestre={semestre}
          cicle_name={cicle_name}
        />
      </Grid>
    </ModalBase>
  );
};

ModalNotasPdf.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ModalNotasPdf;
