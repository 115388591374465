import {CircularProgress, Grid} from "@mui/material";
import TransferList from "components/TransferList/transfer_list.index";
import React, {useEffect, useRef, useState} from "react";
import {useGetEstudiantesQuery} from "services";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

const StudentsAdd = ({formData, edit}) => {
	const {formField, setFieldValue, values} = formData;
	const {estudiantes: estudiantesV} = values;
	
	//state para manejar los datos del formulario
	const [submittingData, setSubmittingData] = useState([]);
	
	//state para manejar la paginacion
	const [offset, setOffset] = React.useState(0);
	
	const [busqueda, setBusqueda] = React.useState("");
	const [offsetBusqueda, setOffsetBusqueda] = React.useState(0);
	
	//state para manejar los acumulados de la paginacion
	const [accumulatedData, setAccumulatedData] = React.useState([]);
	const [accumulatedDataBusqueda, setAccumulatedDataBusqueda] = React.useState(
		[]
	);
	
	// Search input value state
	const [searchApi, setSearchApi] = useState("");
	
	const {
		data: estudiantes_data,
		isSuccess,
		isLoading,
	} = useGetEstudiantesQuery(
		{
			filter_params: {
				buscar: busqueda,
				limit: "50",
				offset: busqueda !== "" ? offsetBusqueda : offset,
				query:
					"{user{first_name,apellido_materno,apellido_paterno},numero_de_control,url,id}",
			},
			shape: {
				id: "id",
				url: "url",
				nombre: "user.first_name",
				numero_de_control: "numero_de_control",
				apellido_materno: "user.apellido_materno",
				apellido_paterno: "user.apellido_paterno",
			},
		},
		{
			refetchOnReconnect: true,
		}
	);
	
	const prevEstudiantesDataRef = useRef();
	
	useEffect(() => {
		if (estudiantes_data?.results && busqueda === "") {
			const newOptions = estudiantes_data.results.map((e) => ({
				nombre: `${e.apellido_paterno} ${e.apellido_materno} ${e.nombre}, ${e.numero_de_control}`,
				url: e.url,
				id: e.id,
			}));
			
			// Si el offset es 0, significa que es una nueva búsqueda o la primera carga, por lo que debes reemplazar los datos acumulados
			if (offset === 0) {
				setAccumulatedData(newOptions);
			} else if (
				prevEstudiantesDataRef.current !== estudiantes_data &&
				busqueda === ""
			) {
				setAccumulatedData((prevData) => {
					// Verificar si newOptions es subconjunto de prevData
					const isSubset = newOptions.every((option) =>
						prevData.some((prevOption) => prevOption.id === option.id)
					);
					
					// Si no es subconjunto, entonces acumular los datos
					return isSubset ? prevData : [...prevData, ...newOptions];
				});
			}
			prevEstudiantesDataRef.current = estudiantes_data;
		}
	}, [offset, estudiantes_data]);
	
	useEffect(() => {
		if (estudiantes_data?.results && busqueda !== "") {
			const newOptions = estudiantes_data.results.map((e) => ({
				nombre: `${e.apellido_paterno} ${e.apellido_materno} ${e.nombre}, ${e.numero_de_control}`,
				url: e.url,
				id: e.id,
			}));
			
			// Si el offset es 0, significa que es una nueva búsqueda o la primera carga, por lo que debes reemplazar los datos acumulados
			if (offsetBusqueda === 0) {
				setAccumulatedDataBusqueda(newOptions);
			} else if (
				prevEstudiantesDataRef.current !== estudiantes_data &&
				busqueda !== ""
			) {
				setAccumulatedDataBusqueda((prevData) => [...prevData, ...newOptions]);
			}
			prevEstudiantesDataRef.current = estudiantes_data;
		}
	}, [offsetBusqueda, busqueda, estudiantes_data]);
	
	useEffect(() => {
		if (submittingData.length !== 0) {
			setFieldValue(formField.estudiantes.name, submittingData);
		}
	}, [submittingData]);
	
	const handleKeyDown = (event) => {
		if (event.key === "Enter") {
			setBusqueda(searchApi);
		}
	};
	
	const loadMore = () => {
		setOffset((prevOffset) => prevOffset + 50);
		if (busqueda !== "") {
			setOffsetBusqueda((prevOffsetBusqueda) => prevOffsetBusqueda + 50);
		}
	};
	
	return (
		<>
			{isLoading && (
				<CircularProgress
					sx={{marginBottom: "2rem", marginTop: "2rem"}}
					color="inherit"
				/>
			)}
			{isSuccess && (
				<MDBox
					display="flex"
					justifyContent="space-between"
					alignItems="center"
				>
					<Grid container>
						<MDBox>
							<MDTypography variant="h5" fontWeight="bold" mb={2}>
								Inscribir Estudiantes:
							</MDTypography>
							<MDInput
								placeholder="Buscar en la base de datos"
								type="text"
								value={searchApi}
								size="small"
								fullWidth
								style={{minWidth: 330}}
								onChange={(e) => setSearchApi(e.target.value)}
								onKeyDown={handleKeyDown}
							/>
						</MDBox>
						<Grid container spacing={3}>
							<Grid item xs={12} sm={12}>
								<TransferList
									data={busqueda !== "" ? accumulatedDataBusqueda : accumulatedData}
									noTitle={true}
									generalTitle={"Inscribir Estudiantes"}
									setSubmittingData={setSubmittingData}
									rightData={estudiantesV}
									pagination={{
										showLoadMoreButton: !!estudiantes_data?.next,
										loadMore,
									}}
									titleRight={"Inscritos"}
								/>
							</Grid>
						</Grid>
					
					</Grid>
				</MDBox>
			)}
		</>
	);
};

export default StudentsAdd;
