import { createApi } from "@reduxjs/toolkit/query/react";
import {
  buildFiltersUrl,
  reshape_response,
  toIsoDate,
} from "helpers/functions";
import customFetchBase from "./customFetchBase";

const responseTransformed = (el) => {
  if (el.resourcetype === "Consumible") {
    const {
      cantidad,
      estado: { nombre: nombreEstado },
      unidad_de_medida: { nombre_corto },
      metodo_de_adquisicion: { nombre },
      tipo: { nombre: nombreTipo, base },
      ...rest
    } = el;

    return {
      estado: nombreEstado,
      cantidad: ` ${cantidad} ${nombre_corto}`,
      metodo_de_adquisicion: nombre,
      tipo: `${nombreTipo}/${base}`,
      ...rest,
    };
  } else {
    const {
      estado: { nombre: nombreEstado },
      metodo_de_adquisicion: { nombre },
      tipo: { nombre: nombreTipo, base },
      ...rest
    } = el;

    return {
      estado: nombreEstado,
      metodo_de_adquisicion: nombre,
      tipo: `${nombreTipo}/${base}`,
      ...rest,
    };
  }
};

export const activosAPI = createApi({
  reducerPath: "activosApi",
  baseQuery: customFetchBase,
  tagTypes: [
    "activos",
    "activos_baja",
    "activos_almacén",
    "activos_resguardos",
  ],
  endpoints: (builder) => ({
    getActivos: builder.query({
      query: ({ filter_params }) => ({
        url: buildFiltersUrl("/v1/api/activo/", filter_params),
        method: "GET",
      }),
      providesTags: (result, _, arg) =>
        Array.isArray(result)
          ? [
              ...result.map(({ id }) => ({
                type: `activos_${arg.filter_params.disposicion.toLowerCase()}`,
                id,
              })),
              `activos_${arg.filter_params.disposicion.toLowerCase()}`,
            ]
          : [`activos_${arg.filter_params.disposicion.toLowerCase()}`],
      transformResponse: (response, meta, args) => {
        if (Array.isArray(response)) {
          return response?.map((el) => reshape_response(el, args?.shape));
        } else {
          const resultsTransformed = response?.results?.map((el) =>
            reshape_response(el, args?.shape)
          );

          // Devolvemos los resultados transformados junto con las propiedades de paginación
          return {
            results: resultsTransformed,
            count: response.count,
            next: response.next,
            previous: response.previous,
          };
        }
      },
    }),
    getActivoById: builder.query({
      query: (id) => ({
        url: `/v1/api/activo/${id}/`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "activos", id: "LIST" }],
    }),
    asignarActivos: builder.mutation({
      query: (data) => {
        const { activo, ...rest } = data;
        const arr = {
          operaciones: [
            ...activo.map((el) =>
              el.resourcetype === "Tangible"
                ? {
                    activo: el.url,
                    fecha: rest.fecha,
                    cantidad: 1,
                    personal: rest.personal,
                  }
                : {
                    activo: el.url,
                    fecha: rest.fecha,
                    cantidad: rest.cantidad,
                    personal: rest.personal,
                  }
            ),
          ],
          resourcetype: "LoteAsignacion",
        };

        return { url: "/v1/api/lotes/", method: "POST", body: arr };
      },
      invalidatesTags: [
        { type: "activos_resguardos", id: "LIST" },
        { type: "activos_almacén", id: "LIST" },
      ],
    }),
    devolverActivos: builder.mutation({
      query: (data) => {
        const { activos, motivo, fecha } = data;

        const arr = {
          operaciones: [
            ...activos.map((el) => ({
              activo: el.url,
              fecha,
              cantidad: 1,
              motivo,
            })),
          ],
          resourcetype: "LoteDevolucion",
        };

        return { url: "/v1/api/lotes/", method: "POST", body: arr };
      },
      invalidatesTags: [
        { type: "activos_resguardos", id: "LIST" },
        { type: "activos_almacén", id: "LIST" },
      ],
    }),
    createActivo: builder.mutation({
      query: (data) => {
        const formData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
          formData.append(key, value);
        });
        return {
          url: "/v1/api/activo/",
          method: "POST",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          body: formData,
        };
      },
      invalidatesTags: [{ type: "activos_almacén", id: "LIST" }],
    }),
    deleteActivo: builder.mutation({
      query: (id) => ({
        url: `/v1/api/activo/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [
        { type: "activos_almacén", id: "LIST" },
        { type: "activos_baja", id: "LIST" },
      ],
    }),
    editActivo: builder.mutation({
      query: (data) => {
        const formData = new FormData();
        Object.entries(data).map(([key, value]) => {
          formData.append(key, value);
        });
        return {
          url: `/v1/api/activo/${data.id}/`,
          headers: {
            "Content-Type": "multipart/form-data",
          },
          method: "PATCH",
          body: formData,
        };
      },
      invalidatesTags: (result, error, { id }) =>
        result
          ? [
              { type: "activos_almacén", id },
              { type: "activos_almacén", id: "LIST" },
            ]
          : [{ type: "activos_almacén", id: "LIST" }],
    }),
    darBaja: builder.mutation({
      query: (data) => {
        const { activo, fecha, cantidad } = data;
        if (activo.resourcetype === "Tangible") {
          return {
            url: `/v1/api/lotes/`,
            method: "POST",
            body: {
              operaciones: [{ activo: activo.url, fecha: fecha, cantidad: 1 }],
              resourcetype: "LoteBaja",
            },
          };
        } else {
          return {
            url: `/v1/api/lotes/`,
            method: "POST",
            body: {
              operaciones: [
                {
                  activo: activo.url,
                  fecha: fecha,
                  cantidad: cantidad,
                },
              ],
              resourcetype: "LoteBaja",
            },
          };
        }
      },
      invalidatesTags: [
        { type: "activos_baja", id: "LIST" },
        { type: "activos_almacén", id: "LIST" },
      ],
    }),
    clonarActivo: builder.mutation({
      query: (data) => {
        const { id, ...rest } = data;
        return {
          url: `/v1/api/activo/${id}/clonar/`,
          method: "PATCH",
          body: rest,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
      },
      invalidatesTags: [{ type: "activos_almacén", id: "LIST" }],
    }),
    getHistory: builder.query({
      query: (id) => ({
        url: `/v1/api/operaciones/historial/activo/${id}/`,
        method: "GET",
      }),
    }),
    getLotes: builder.query({
      query: ({ filter_params }) => ({
        url: buildFiltersUrl(`/v1/api/lotes/`, filter_params),
        method: "GET",
      }),
      providesTags: (result, _, arg) =>
        Array.isArray(result)
          ? [
              ...result.map(({ id }) => ({
                type: `activos_${arg.filter_params.type.toLowerCase()}`,
                id,
              })),
              {
                type: `activos_${arg.filter_params.type.toLowerCase()}`,
                id: "LIST",
              },
            ]
          : [
              {
                type: `activos_${arg.filter_params.type.toLowerCase()}`,
                id: "LIST",
              },
            ],
      transformResponse: (response, meta, args) => {
        if (Array.isArray(response)) {
          return response?.map((el) => reshape_response(el, args.shape));
        } else {
          const resultsTransformed =
            response?.results
              ?.sort((a, b) => new Date(a.creacion) - new Date(b.creacion)) // Ordena por fecha de creación
              .reverse() // Invierte el orden para tener los más recientes primero
              .map((el) => reshape_response(el, args.shape)) ?? // Aplica la transformación de forma
            [];

          // Devolvemos los resultados transformados junto con las propiedades de paginación
          return {
            results: resultsTransformed,
            count: response.count,
            next: response.next,
            previous: response.previous,
          };
        }
      },
    }),
    getLoteById: builder.query({
      query: (id) => ({
        url: `/v1/api/lotes/${id}/`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetActivosQuery,
  useGetActivoByIdQuery,
  useGetHistoryQuery,

  useGetLotesQuery,
  useLazyGetLoteByIdQuery,

  useAsignarActivosMutation,
  useDevolverActivosMutation,
  useDarBajaMutation,

  useCreateActivoMutation,
  useDeleteActivoMutation,
  useEditActivoMutation,
  useClonarActivoMutation,

  useLazyGetActivoByIdQuery,
  useLazyGetHistoryQuery,
} = activosAPI;
