import {administrativoApi} from "../view/administrativo.service";


export const deleteAdministrativo = administrativoApi.injectEndpoints({
    endpoints: (builder) => ({
        deleteAdministrativo: builder.mutation({
            query: (id) => ({
                url: `/v1/api/no_docente/${id}/`,
                method: "DELETE",
            }),
            invalidatesTags: [{type: "Administrativo", id: "LIST"}],
        }),
    }),
})

export const {
    useDeleteAdministrativoMutation
} = deleteAdministrativo;