import { createApi } from "@reduxjs/toolkit/query/react";
import { buildFiltersUrl, reshape_response } from "helpers/functions";
import customFetchBase from "services/principal/customFetchBase";

export const unidadesApi = createApi({
  reducerPath: "unidadesApi",
  baseQuery: customFetchBase,
  tagTypes: ["Unidades"],
  endpoints: (builder) => ({
    getUnidades: builder.query({
      query: ({ filter_params }) => ({
        url: buildFiltersUrl("/v1/api/medidas/", filter_params),
        method: "GET",
      }),
      providesTags: (result) =>
        Array.isArray(result)
          ? [
              ...result.map(({ id }) => ({
                type: "Unidades",
                id,
              })),
              { type: "Unidades", id: "LIST" },
            ]
          : [{ type: "Unidades", id: "LIST" }],
      transformResponse: (response, meta, args) => {
        if (Array.isArray(response)) {
          return response?.map((el) => reshape_response(el, args?.shape));
        } else {
          const resultsTransformed = response?.results?.map((el) =>
            reshape_response(el, args?.shape)
          );

          // Devolvemos los resultados transformados junto con las propiedades de paginación
          return {
            results: resultsTransformed,
            count: response.count,
            next: response.next,
            previous: response.previous,
          };
        }
      },
    }),

    getUnidadById: builder.query({
      query: (id) => ({
        url: `/v1/api/medidas/${id}/`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "Unidades", id }],
    }),

    createUnidad: builder.mutation({
      query: (body) => ({
        url: "/v1/api/medidas/",
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "Unidades", id: "LIST" }],
    }),

    deleteUnidad: builder.mutation({
      query: (id) => ({
        url: `/v1/api/medidas/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Unidades", id: "LIST" }],
    }),

    editUnidad: builder.mutation({
      query: (body) => ({
        url: `/v1/api/medidas/${body.id}/`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: (result, error, { id }) =>
        result
          ? [
              { type: "Unidades", id },
              { type: "Unidades", id: "LIST" },
            ]
          : [{ type: "Unidades", id: "LIST" }],
    }),
  }),
});

export const {
  useGetUnidadesQuery,
  useCreateUnidadMutation,
  useEditUnidadMutation,
  useDeleteUnidadMutation,
  useLazyGetUnidadByIdQuery,
} = unidadesApi;
