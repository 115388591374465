import form from "./form";

const {
  formField: { fecha, cantidad, personal },
} = form;

const initialValues = {
  [fecha.name]: new Date().toISOString().slice(0, 10),
  [cantidad.name]: 1,
  [personal.name]: "",
};

export default initialValues;
