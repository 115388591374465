/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as Yup from "yup";
import checkout from "layouts/almacen/pages/new-adq/schemas/form";
import { NAME_CATALOGO_REGEX } from "helpers/regexs";

const {
  formField: { adqName },
} = checkout;

const validations = [
  Yup.object().shape({
    [adqName.name]: Yup.string()
      .min(2, "Debe tener 2 o más caracteres")
      .max(50, "No se permiten más de 50 caracteres")
      .matches(
        NAME_CATALOGO_REGEX,
        "El nombre no puede contener caracteres extraños, excepto el punto"
      )
      .required(adqName.errorMsg),
  }),
];

export default validations;
