/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import checkout from "./form";

const {
  formField: {
    nombre,
    modalidad,
    plan_de_estudio,
    rvoe,
    nivel,
    duracion,
    calificacion_minima,
    calificacion_maxima,
    calificacion_aprobatoria,
    fecha_expedicion,
    identificador,
  },
} = checkout;

const initialValues = {
  [nombre.name]: "",
  [modalidad.name]: "",
  [plan_de_estudio.name]: "",
  [fecha_expedicion.name]: new Date().toISOString().slice(0, 10),
  [duracion.name]: "",
  [calificacion_minima.name]: 0,
  [calificacion_maxima.name]: 0,
  [calificacion_aprobatoria.name]: 0,
  [rvoe.name]: "",
  [identificador.name]: "",
  [nivel.name]: "",
};

export default initialValues;
