/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

const form = {
  formId: "new-grupo-escolar",
  formField: {
    fecha_inicio: {
      name: "fecha_inicio",
      label: "Fecha de Inicio",
      type: "date",
      errorMsg: "Debe entrar una fecha de inicio.",
    },
    fecha_fin: {
      name: "fecha_fin",
      label: "Fecha de Fin",
      type: "date",
      errorMsg: "Debe entrar una fecha de fin.",
    },
    nombre: {
      name: "nombre",
      label: "Nombre",
      type: "text",
      errorMsg: "El nombre es requerido",
      invalidMsg: "El nombre no puede tener mas de 15 caracteres",
    },
  },
};

export default form;
