import customFetchBase from "./customFetchBase";
import { createApi } from "@reduxjs/toolkit/query/react";

export const areaApi = createApi({
  reducerPath: "areaApi",
  baseQuery: customFetchBase,
  tagTypes: ["Area"],
  endpoints: (builder) => ({
    getAreas: builder.query({
      query: () => ({
        url: "/v1/api/area/",
        method: "GET",
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "Area",
                id,
              })),
              { type: "Area", id: "LIST" },
            ]
          : [{ type: "Area", id: "LIST" }],
    }),
    getAreaById: builder.query({
      query: (id) => ({
        url: `/v1/api/area/${id}/`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "Area", id }],
    }),
    getAreaRawById: builder.query({
      query: (id) => ({
        url: `/v1/api/area/raw/${id}/`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "Area", id }],
    }),
    createArea: builder.mutation({
      query: (area) => ({
        url: "/v1/api/area/",
        method: "POST",
        body: area,
      }),
      invalidatesTags: [{ type: "Area", id: "LIST" }],
    }),
    deleteArea: builder.mutation({
      query: (id) => ({
        url: `/v1/api/area/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Area", id: "LIST" }],
    }),
    editArea: builder.mutation({
      query: (area) => {
        const { id, ...rest } = area;

        return {
          url: `/v1/api/area/${area.id}/`,
          method: "PUT",
          body: Object.fromEntries(
            Object.entries(rest).filter(
              ([k, v]) => !(k === "area" && v === "Entidad")
            )
          ),
        };
      },
      invalidatesTags: (result, error, { id }) =>
        result
          ? [
              { type: "Area", id },
              { type: "Area", id: "LIST" },
            ]
          : [{ type: "Area", id: "LIST" }],
    }),
  }),
});

export const {
  useGetAreasQuery,
  useLazyGetAreasQuery,
  useLazyGetAreaRawByIdQuery,
  useCreateAreaMutation,
  useEditAreaMutation,
  useDeleteAreaMutation,
  useLazyGetAreaByIdQuery,
} = areaApi;
