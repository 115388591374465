import {asignaturasApi} from "../view/asignaturas.service";


export const editAsignatura = asignaturasApi.injectEndpoints({
    endpoints: (builder) => ({
        editAsignatura: builder.mutation({
            query: ({id, ...rest}) => {
                rest.nombre = rest.nombre?.trim();

                return {
                    url: `/v1/api/asignaturas/${id}/`,
                    method: "PATCH",
                    body: rest,
                };
            },
            invalidatesTags: (result, error, {id}) =>
                result
                    ? [
                        {type: "Asignaturas", id},
                        {type: "Asignaturas", id: "LIST"},
                    ]
                    : [{type: "Asignaturas", id: "LIST"}],
        }),
    }),
})

export const {
    useEditAsignaturaMutation
} = editAsignatura;