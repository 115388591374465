import { CircularProgress, Grid } from "@mui/material";
import TransferList from "components/TransferList/transfer_list.index";
import React, { useEffect, useRef, useState } from "react";
import {
  useGetEstudiantesPorGrupoQuery,
  useGetEstudiantesQuery,
} from "services";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

const EstudiantesAdd = ({ formData, edit, group }) => {
  const { setFieldValue, values } = formData;
  const { estudiantes: estudiantesV } = values;

  //state para manejar la paginacion
  const [offset, setOffset] = React.useState(0);

  const [busqueda, setBusqueda] = React.useState("");
  const [offsetBusqueda, setOffsetBusqueda] = React.useState(0);

  //state para manejar los acumulados de la paginacion
  const [accumulatedData, setAccumulatedData] = React.useState([]);
  const [accumulatedDataBusqueda, setAccumulatedDataBusqueda] = React.useState(
    []
  );

  // Search input value state
  const [searchApi, setSearchApi] = useState("");

  const {
    data: estudiantes_data,
    isSuccess,
    isLoading,
  } = useGetEstudiantesQuery(
    {
      filter_params: {
        preasignacion_de_carrera: group?.ciclo_interno?.carrera.id,
        ordering:
          "user__apellido_paterno,user__apellido_materno,user__first_name,numero_de_control",
        asignado: "false",
        buscar: busqueda,
        estado: "Activo",
        limit: 50,
        offset: busqueda !== "" ? offsetBusqueda : offset,
        query:
          "{user{first_name,apellido_materno,apellido_paterno},numero_de_control,url,id}",
      },
      shape: {
        id: "id",
        url: "url",
        nombre:
          "user.apellido_paterno user.apellido_materno user.first_name numero_de_control",
      },
    },
    {
      refetchOnReconnect: true,
    }
  );

  const {
    data: estXGrupo,
    isLoading: isLoadingExG,
    isSuccess: isSuccessExG,
  } = useGetEstudiantesPorGrupoQuery(
    {
      filter_params: {
        distribucion_grupo__ciclo_interno__carrera:
          group?.ciclo_interno?.carrera.id,
        distribucion_grupo__grupo: group?.grupo.id,
        ordering:
          "estudiante__user__apellido_paterno,estudiante__user__apellido_materno,estudiante__user__first_name",
        query:
          "{estudiante{id,url,numero_de_control,user{apellido_paterno,apellido_materno,first_name}}}",
      },
      shape: {
        id: "estudiante.id",
        url: "estudiante.url",
        nombre:
          "estudiante.user.apellido_paterno estudiante.user.apellido_materno estudiante.user.first_name estudiante.numero_de_control",
      },
    },
    { refetchOnReconnect: true }
  );

  const prevEstudiantesDataRef = useRef();

  useEffect(() => {
    if (estudiantes_data?.results && busqueda === "") {
      // Si el offset es 0, significa que es una nueva búsqueda o la primera carga, por lo que debes reemplazar los datos acumulados
      if (offset === 0) {
        setAccumulatedData(estudiantes_data.results);
      } else if (
        prevEstudiantesDataRef.current !== estudiantes_data.results &&
        busqueda === ""
      ) {
        setAccumulatedData((prevData) => {
          const isSubset = estudiantes_data.results.every((option) =>
            prevData.some((prevOption) => prevOption.id === option.id)
          );

          // Si no es subconjunto, entonces acumular los datos
          return isSubset
            ? prevData
            : [...prevData, ...estudiantes_data.results];
        });
      }
      prevEstudiantesDataRef.current = estudiantes_data.results;
    }
  }, [offset, estudiantes_data]);

  useEffect(() => {
    if (estudiantes_data?.results && busqueda !== "") {
      // Si el offset es 0, significa que es una nueva búsqueda o la primera carga, por lo que debes reemplazar los datos acumulados
      if (offsetBusqueda === 0) {
        setAccumulatedDataBusqueda(estudiantes_data.results);
      } else if (
        prevEstudiantesDataRef.current !== estudiantes_data.results &&
        busqueda !== ""
      ) {
        setAccumulatedDataBusqueda((prevData) => [
          ...prevData,
          ...estudiantes_data,
        ]);
      }
      prevEstudiantesDataRef.current = estudiantes_data.results;
    }
  }, [offsetBusqueda, busqueda, estudiantes_data]);

  useEffect(() => {
    if (isSuccessExG) {
      setFieldValue("estudiantes", estXGrupo);
    }
  }, [isLoadingExG, estXGrupo]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setBusqueda(searchApi);
    }
  };

  const loadMore = () => {
    setOffset((prevOffset) => prevOffset + 50);
    if (busqueda !== "") {
      setOffsetBusqueda((prevOffsetBusqueda) => prevOffsetBusqueda + 50);
    }
  };

  return (
    <>
      {isLoading && (
        <CircularProgress
          sx={{ marginBottom: "2rem", marginTop: "2rem" }}
          color="inherit"
        />
      )}
      {isSuccess && (
        <MDBox
          display="flex"
          flexDirection={"column"}
          justifyContent="space-between"
          alignItems="center"
          p={3}
        >
          <MDBox width="12rem" ml="auto">
            <MDInput
              placeholder="Buscar en la base de datos"
              type="text"
              value={searchApi}
              size="small"
              fullWidth
              onChange={(e) => setSearchApi(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <TransferList
                data={
                  busqueda !== "" ? accumulatedDataBusqueda : accumulatedData
                }
                generalTitle={"Distribuir Estudiantes"}
                setSubmittingData={(right) =>
                  setFieldValue("estudiantes", right)
                }
                rightData={estudiantesV}
                edit={edit ? true : false}
                pagination={{
                  showLoadMoreButton: !!estudiantes_data?.next,
                  loadMore,
                }}
              />
            </Grid>
          </Grid>
        </MDBox>
      )}
    </>
  );
};

export default EstudiantesAdd;
