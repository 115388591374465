import {carrerasApi} from "../../view/carreras.service";

export const createCarrera = carrerasApi.injectEndpoints({
  endpoints: (builder) => ({
    createCarrera: builder.mutation({
      query: (data) => {
        return {
          url: "/v1/api/carreras/",
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: [{type: "Carreras", id: "LIST"}],
    }),
  }),
})

export const {
  useCreateCarreraMutation
} = createCarrera;