import { createApi } from "@reduxjs/toolkit/query/react";
import { buildFiltersUrl, reshape_response } from "helpers/functions";
import customFetchBase from "services/principal/customFetchBase";

export const cicloInternoApi = createApi({
  reducerPath: "cicloInternoApi",
  baseQuery: customFetchBase,
  tagTypes: ["CicloInterno"],
  endpoints: (builder) => ({
    getCiclosInternos: builder.query({
      query: ({ filters, shape }) => ({
        url: buildFiltersUrl("/v1/api/ciclointernodecarrera/", filters),
        method: "GET",
      }),
      providesTags: (result) =>
        Array.isArray(result)
          ? [
              ...result.map(({ id }) => ({
                type: "CicloInterno",
                id,
              })),
              { type: "CicloInterno", id: "LIST" },
            ]
          : [{ type: "CicloInterno", id: "LIST" }],
      transformResponse: (response, meta, args) => {
        if (Array.isArray(response)) {
          return response
            ?.map((el) => reshape_response(el, args.shape))
            .sort((a, b) => a.numero_de_ciclo - b.numero_de_ciclo === -1);
        } else {
          const resultsTransformed = response?.results?.map((el) =>
            reshape_response(el, args.shape)
          );

          // Devolvemos los resultados transformados junto con las propiedades de paginación
          return {
            results: resultsTransformed,
            count: response.count,
            next: response.next,
            previous: response.previous,
          };
        }
      },
    }),
    getCicloInternoById: builder.query({
      query: (id) => ({
        url: `/v1/api/ciclointernodecarrera/${id}/`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "CicloInterno", id }],
    }),

    createCicloInterno: builder.mutation({
      query: (data) => {
        if (data.asignaturas)
          data.asignaturas = data.asignaturas.map((el) => el.url);

        return {
          url: "/v1/api/ciclointernodecarrera/",
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: [{ type: "CicloInterno", id: "LIST" }],
    }),

    deleteCicloInterno: builder.mutation({
      query: (id) => ({
        url: `/v1/api/ciclointernodecarrera/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "CicloInterno", id: "LIST" }],
    }),

    editCicloInterno: builder.mutation({
      query: ({ id, ...rest }) => {
        rest.asignaturas = rest.asignaturas.map((el) => el.url);

        return {
          url: `/v1/api/ciclointernodecarrera/${id}/`,
          method: "PATCH",
          body: rest,
        };
      },
      invalidatesTags: (result, error, { id }) =>
        result
          ? [
              { type: "CicloInterno", id },
              { type: "CicloInterno", id: "LIST" },
            ]
          : [{ type: "CicloInterno", id: "LIST" }],
    }),
  }),
});

export const {
  useGetCiclosInternosQuery,
  useLazyGetCiclosInternosQuery,
  useGetCicloInternoByIdQuery,
  useLazyGetCicloInternoByIdQuery,
  useCreateCicloInternoMutation,
  useDeleteCicloInternoMutation,
  useEditCicloInternoMutation,
} = cicloInternoApi;
