import {carrerasApi} from "../../view/carreras.service";

export const getDuraciones = carrerasApi.injectEndpoints({
  endpoints: (builder) => ({
    getDuraciones: builder.query({
      query: () => ({
        url: `/v1/api/duracion/`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{type: "Duracion", id}],
    }),
  })
})

export const {
  useGetDuracionesQuery,
} = getDuraciones;