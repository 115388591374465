/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "../../components/MDBox";

// Settings page components
import Sidenav from "./components/Sidenav/side_nav";
import BasicInfo from "./components/BasicInfo/basic_info";
import ChangePassword from "./components/ChangePassword";
import Generales from "../estudiantes/perfil/estudiantes_perfil";
import { ESTUDIANTE, FIRMANTE } from "../../roles";
import EntidadInfo from "./components/Entidad Info/entidad_info";
import FirmanteInfo from "./components/Firmante Info/firmante_Info";

function Home() {
  const user = JSON.parse(sessionStorage.getItem("user"));
  return (
    <MDBox mt={4}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={3}>
          <Sidenav />
        </Grid>
        <Grid item xs={12} lg={9}>
          <MDBox mb={3}>
            <Grid container spacing={3}>
              {!user.is_staff && user.groups[0]?.name !== ESTUDIANTE && (
                <Grid item xs={12}>
                  <EntidadInfo />
                </Grid>
              )}
              <Grid item xs={12}>
                {user.groups[0]?.name !== ESTUDIANTE ||
                user.groups[0].isStaff ? (
                  <BasicInfo id="basic-info" />
                ) : (
                  <Generales id="basic-estudiante-info" />
                )}
              </Grid>
              {user.perfil.resourcetype.some(
                (e) => e.resourcetype === FIRMANTE
              ) && (
                <Grid item xs={12}>
                  <FirmanteInfo />
                </Grid>
              )}
              <Grid item xs={12}>
                <ChangePassword />
              </Grid>
            </Grid>
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default Home;
