/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "new-curso",
  formField: {
    nombre: {
      name: "nombre",
      label: "Nombre",
      type: "text",
      errorMsg: "El nombre es requerido.",
    },
    nombre_corto: {
      name: "nombre_corto",
      label: "Nombre corto",
      type: "text",
      errorMsg: "El nombre corto es requerido.",
    },
    firmante: {
      name: "firmante",
      label: "Firmante",
      type: "text",
      errorMsg: "El firmante es requerido.",
    },
    modalidad: {
      name: "modalidad",
      label: "Modalidad",
      type: "text",
      errorMsg: "La modalidad es requerida.",
    },
    fecha_inicio: {
      name: "fecha_inicio",
      label: "Fecha de inicio",
      type: "date",
      errorMsg: "Debe proveer fecha de inicioa.",
    },
    fecha_fin: {
      name: "fecha_fin",
      label: "Fecha de fin",
      type: "date",
      errorMsg: "Debe proveer fecha de fin.",
    },
    duracion: {
      name: "duracion",
      label: "Duración",
      type: "number",
      errorMsg: "Debe proveer el timpo de duración del curso.",
    },
    expedicion: {
      name: "expedicion",
      label: "Expedición",
      type: "date",
      errorMsg: "La fecha de expedición es requerida.",
    },
    localizacion: {
      name: "localizacion",
      label: "Lugar de expedición",
      type: "text",
      errorMsg: "El lugar de expedición es requerido.",
    },
    estudiantes: {
      name: "estudiantes",
      label: "Estudiantes",
      type: "text",
    },
  },
};

export default form;
