import MDTypography from "../../components/MDTypography";
import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";
import { useNavigate } from "react-router-dom";
import Icon from "@mui/material/Icon";

export default function FormErrorHandler({ message }) {
  const navigate = useNavigate();
  return (
    <MDBox
      sx={{
        p: 3,
        gap: 2,
        flexDirection: "column",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <MDBox display={"flex"} gap={1} alignItems={"center"}>
        <Icon fontSize={"medium"} color={"primary"}>
          error
        </Icon>
        <MDTypography color={"primary"} fontWeight={"regular"} variant="h6">
          {message}
        </MDTypography>
      </MDBox>
      <MDButton
        variant={"outlined"}
        color={"dark"}
        onClick={() => navigate(-1)}
      >
        Regresar
      </MDButton>
    </MDBox>
  );
}

FormErrorHandler.defaultProps = {
  message:
    "Lo sentimos no podemos mostrar el contenido actualmente dado un error",
};
