import checkout from "./form";

const {
  formField: {
    estado,
    descripcion,
    metodo_de_adquisicion,
    marca,
    modelo,
    fecha_de_facturacion,
    fecha_cedula,
    proveedor,
    numero_de_factura,
    numero_de_serie,
    valor,
    numero_de_inventario,
    resourcetype,
    tipo,
    llave,
    foto,
  },
} = checkout;

const initialValues = {
  [estado.name]: "",
  [resourcetype.name]: "Tangible",
  [descripcion.name]: "",
  [metodo_de_adquisicion.name]: "",
  [marca.name]: "",
  [modelo.name]: "",
  [fecha_de_facturacion.name]: new Date().toISOString().slice(0, 10),
  [fecha_cedula.name]: new Date().toISOString().slice(0, 10),
  [proveedor.name]: "",
  [numero_de_factura.name]: "",
  [numero_de_serie.name]: "",
  [valor.name]: "",
  [numero_de_inventario.name]: "",
  [tipo.name]: "",
  [llave.name]: "",
  [foto.name]: null,
};

export default initialValues;
