/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// NewUser page components
import FormField from "components/FormField";
import { useGetCiclosInternosQuery } from "services";
import { Autocomplete } from "@mui/material";

function AddGrupoEscolar({ formData }) {
  const { formField, values, errors, touched, setFieldValue } = formData;
  const { identificador } = formField;
  const { identificador: identificadorV, ciclo_interno: ciclo_internoV } =
    values;

  const { data: ciclos_internos } = useGetCiclosInternosQuery(
    {
      filters: {
        query:
          "{id,url,numero_de_ciclo,carrera{nombre,plan_de_estudio,duracion{identificador}}}",
        ordering: "carrera__nombre,carrera__plan_de_estudio,numero_de_ciclo",
      },
      shape: {
        id: "id",
        url: "url",
        numero_de_ciclo: "numero_de_ciclo",
        nombre_carrera: "carrera.nombre",
        duracion: "carrera.duracion.identificador",
        plan_de_estudio: "carrera.plan_de_estudio",
      },
    },
    {
      refetchOnReconnect: true,
    }
  );

  const opcionesInt = ciclos_internos?.map((e) => {
    return {
      nombre_carrera: e.nombre_carrera,
      numero_de_ciclo: e.numero_de_ciclo,
      plan_de_estudio: e.plan_de_estudio,
      duracion: e.duracion,
      url: e.url,
      key: e.id,
    };
  });

  return (
    <MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              type={identificador.type}
              label={identificador.label}
              name={identificador.name}
              value={identificadorV}
              placeholder={identificador.placeholder}
              error={errors.identificador && touched.identificador}
              success={identificadorV.length > 0 && !errors.identificador}
              onInput={(e) =>
                (e.target.value = ("" + e.target.value).toUpperCase())
              }
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Autocomplete
              id="distribuir-CicloInterno"
              options={opcionesInt ?? []}
              onChange={(e, value) => {
                setFieldValue("ciclo_interno", value ? value.url : null);
              }}
              value={
                opcionesInt?.find((opcion) => opcion.url === ciclo_internoV) ||
                null
              }
              isOptionEqualToValue={(option, value) => {
                return `${option.nombre_carrera}` === `${value.nombre_carrera}`;
              }}
              getOptionLabel={(option) =>
                `${option.nombre_carrera} ${option.plan_de_estudio} - ${option.duracion} ${option.numero_de_ciclo}`
              }
              renderInput={(params) => (
                <FormField
                  id="distribucionCI"
                  name={"distribucionCI"}
                  label="Distribuir Ciclo Interno"
                  {...params}
                />
              )}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for UserInfo
AddGrupoEscolar.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default AddGrupoEscolar;
