import { fechaEvaluacionApi } from "../view/fecha_evaluacion.service";

export const editFechaEvaluacion = fechaEvaluacionApi.injectEndpoints({
  endpoints: (builder) => ({
    editFechaEvaluacion: builder.mutation({
      query: (body) => {
        let { id, ...rest } = body;

        return {
          url: `/v1/api/permisoevaluacion/${id}/`,
          method: "PATCH",
          body: rest,
        };
      },
      invalidatesTags: (result, error, { id }) =>
        result
          ? [
              { type: "Fecha Evaluacion", id },
              { type: "Fecha Evaluacion", id: "LIST" },
            ]
          : [{ type: "Fecha Evaluacion", id: "LIST" }],
    }),
  }),
});

export const { useEditFechaEvaluacionMutation } = editFechaEvaluacion;
