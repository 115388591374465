import {cursosApi} from "../view/cursos.service";

export const getModalidadById = cursosApi.injectEndpoints({
    endpoints: (builder) => ({
        getModalidadById: builder.query({
            query: (id) => ({
                url: `/v1/api/modalidaddecurso/${id}/`,
                method: "GET",
            }),
            providesTags: (result, error, id) => [{type: "Modalidades", id}],
        }),
    }),
})

export const {
    useGetModalidadByIdQuery,
    useLazyGetModalidadByIdQuery
} = getModalidadById;