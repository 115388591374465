import {useEffect} from "react";
import {isRefreshNeeded} from "../config/axios";
import {HOME_URL, LOGIN} from "../config/bases_url";
import {useNavigate} from "react-router-dom";
import {auth_state} from "../slices";
import {useSelector} from "react-redux";

export function useAuthRedirect() {
    const navigate = useNavigate()
    const {authenticated} = useSelector(auth_state);

    useEffect(() => {
        if (isRefreshNeeded(sessionStorage.getItem("token")).valid && authenticated) {
            navigate(authenticated ? HOME_URL : LOGIN);
        }
    }, [authenticated]);
}