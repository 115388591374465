import { firmantesApi } from "../view/firmante.service";
import {
  appendFormData,
  transformTelefonos,
} from "../../../../helpers/functions";
import { enqueueSnackbar } from "notistack";
import { showErrorMessages } from "../../../../hooks/useRedirectForm";

export const editFirmante = firmantesApi.injectEndpoints({
  endpoints: (builder) => ({
    editFirmante: builder.mutation({
      query: (body) => {
        let { id, ...rest } = body;

        if (rest.telefonos) rest.telefonos = transformTelefonos(rest.telefonos);

        const formData = new FormData();
        Object.entries(rest).forEach(([key, value]) => {
          appendFormData(formData, value, key);
        });

        return {
          url: `/v1/api/firmante/${id}/`,
          method: "PATCH",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          body: formData,
        };
      },
      invalidatesTags: (result, error, { id }) =>
        result
          ? [
              { type: "Firmantes", id },
              { type: "Firmantes", id: "LIST" },
            ]
          : [{ type: "Firmantes", id: "LIST" }],
      async onQueryStarted({ id, ...rest }, { queryFulfilled, getCacheEntry }) {
        try {
          await queryFulfilled;
          let user = JSON.parse(sessionStorage.getItem("user"));
          const {
            data: {
              cargo,
              firma,
              certificado,
              user: { email },
            },
          } = getCacheEntry();

          if (email === user.correo) {
            user.perfil = {
              ...user.perfil,
              cargo,
              firma,
              certificado,
            };
            sessionStorage.setItem("user", JSON.stringify(user));
          }
          enqueueSnackbar("Datos subidos correctamente", {
            variant: "success",
          });
        } catch (e) {
          showErrorMessages(e.error, enqueueSnackbar);
        }
      },
    }),
  }),
});

export const { useEditFirmanteMutation } = editFirmante;
