import * as React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import root from "window-or-global";

export const PrintContext = React.createContext({
  print: () => root.print(),
  printing: false,
});

export const noPrint = css`
  @media print {
    display: none !important;
  }
`;

export const NoPrint = styled.div`
  @media print {
    display: none !important;
  }
`;

const printStop = css`
  display: none;
`;

export const PrintOnly = styled.div`
  display: none;
  @media print {
    display: initial;
  }
`;

const PrintFrame = styled.iframe`
  visibility: hidden;
  position: absolute;
  border: none;
`;

const siblingsBetween = (startNode, stopNode) =>
  startNode.nextSibling && startNode.nextSibling !== stopNode
    ? [
        startNode.nextSibling,
        ...siblingsBetween(startNode.nextSibling, stopNode),
      ]
    : [];

const createPrint = (iframeDocument, iframe, last, bodyClassName) => {
  const nodes = siblingsBetween(iframe, last).map((node) =>
    node.cloneNode(true)
  );
  const styles = [
    ...root.document.head.querySelectorAll(
      'link[rel="stylesheet"],style[type="text/css"]:not([data-emotion])'
    ),
  ];
  const emotionStyles = iframeDocument.createElement("style");
  emotionStyles.type = "text/css";
  emotionStyles.appendChild(
    iframeDocument.createTextNode(
      [...root.document.querySelectorAll("style")]
        .reduce(
          (acc, { sheet }) => [
            ...acc,
            ...[...sheet.cssRules].map((rules) => rules.cssText),
          ],
          []
        )
        .join("\n")
    )
  );
  const { head } = iframeDocument;
  if (head !== null) {
    styles.forEach((node) => head.appendChild(node.cloneNode(true)));
    head.appendChild(emotionStyles);
  }

  const { body } = iframeDocument;
  if (body !== null) {
    nodes.forEach((node) => {
      const nClone = new DOMParser().parseFromString(
        node.outerHTML,
        "text/html"
      ).body.firstChild;

      body.appendChild(nClone);
    });
    body.className = bodyClassName || "";
  }
};

const unmountPrint = (iframeWindow, onAfter) => {
  if (iframeWindow.matchMedia) {
    iframeWindow
      .matchMedia("print")
      .addListener((mql) => !mql.matches && onAfter());
  }
};

function PrintRoot(props) {
  const { children, bodyClassName } = props;
  const printFrame = React.useRef(null);
  const printLimiter = React.useRef(null);

  const [compKey, setCompKey] = React.useState(1);

  const [printing, setPrintingState] = React.useState(false);

  const printMessageHandler = (event) => {
    if (event.data === "PRINT_CLOSED") {
      setPrintingState(false);
      setCompKey(compKey + 1);
    }
  };

  React.useEffect(() => {
    window.addEventListener("message", printMessageHandler);
    return () => {
      window.removeEventListener("message", printMessageHandler);
    };
  }, [compKey]);

  const printIframe = React.useCallback(() => {
    const iframe = printFrame.current;
    const last = printLimiter.current;

    if (!iframe || !last) {
      root.print();
      setPrintingState(false);
      setCompKey(compKey + 1);
      return;
    }
    if (iframe) {
      iframe.src = "about:blank";
      iframe.onload = () => {
        const { contentWindow: iframeWindow, contentDocument: iframeDocument } =
          iframe;
        const onAfterHandler = () => {
          parent.postMessage("PRINT_CLOSED", "*");
        };

        iframeWindow?.addEventListener("afterprint", () => {
          onAfterHandler();
        });

        if (iframeWindow && iframeDocument) {
          unmountPrint(iframeWindow, onAfterHandler);
          createPrint(iframeDocument, iframe, last, bodyClassName);
          iframeWindow.setTimeout(() => {
            setTimeout(() => {
              iframeWindow.print();
              setTimeout(() => iframeWindow.close());
            });
          }, 500);
        }
      };
    }
  }, [bodyClassName, compKey]);

  const print = React.useCallback(() => {
    if (!printing) {
      setPrintingState(true);

      setTimeout(() => {
        printIframe();
      }, 0);
      // In Safari, some times you get an extra dialog, that blocks the CLOSE flow of the printing, so it nevers get stoped
      if (
        navigator.userAgent.indexOf("Safari") !== -1 &&
        navigator.userAgent.indexOf("Chrome") === -1
      ) {
        setTimeout(() => {
          setPrintingState(false);
        }, 2000);
      }
    }
  }, [printIframe, printing]);

  const valueMemo = React.useMemo(
    () => ({ print, printing }),
    [print, printing]
  );

  return (
    <PrintContext.Provider key={compKey.toString()} value={valueMemo}>
      {printing && <PrintFrame ref={printFrame} />}
      {children}
      {printing && <div css={printStop} ref={printLimiter} />}
    </PrintContext.Provider>
  );
}

export default PrintRoot;
