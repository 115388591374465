/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================
 
 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)
 
 Coded by www.creative-tim.com
 
 =========================================================
 
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import DataTable from "examples/Tables/DataTable";

//React Hooks and Others
import {Link, useNavigate} from "react-router-dom";
import Swal from "sweetalert2";

import PropTypes from "prop-types";

import {CircularProgress, FormControl, Icon, InputLabel, MenuItem, Select, Tooltip,} from "@mui/material";
import {REGISTRO_EDIT_URL, REGISTRO_NEW_URL, REGISTROS_URL,} from "config/bases_url";
import {style_icon} from "helpers/global_vars";
import {useRedirectForm} from "hooks/useRedirectForm";
import {COORDINADOR_DOCENTE, RECURSOS_HUMANOS} from "roles";
import ComponentsPrivatization, {removeColumns,} from "config/component_privatization";
import {useState} from "react";
import usePagination from "hooks/usePagination";
import RegistroShow from "./registroShow";
import {useDeleteUserMutation, useGetPlantillaQuery} from "services";

const FiltersComponentContrato = ({ values, handleChange }) => {
  let contrato = [
    { id: "1", profesores: "false", name: "Administrativo" },
    { id: "2", profesores: "true", name: "Docente" },
  ];
  return (
    <FormControl sx={{ m: 1, minWidth: "14rem" }} size="large">
      <InputLabel id="demo-simple-select-label">Contrato</InputLabel>
      <Select
        sx={{ minHeight: "37.25px" }}
        labelId="demo-simple-select-label"
        name="Contrato"
        value={values}
        label="Contrato"
        onChange={handleChange}
      >
        <MenuItem value="">Limpiar</MenuItem>
        {contrato?.map((el) => (
          <MenuItem key={el.id} value={el.profesores}>
            {el.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

function DataTableRegistros({ tableHeader }) {
  const navigate = useNavigate();
  const [contratoState, setContrato] = useState("");
  const handleChangeContrato = (e) => {
    setContrato(e.target.value);
  };

  const {
    offset,
    setOffset,
    busqueda,
    setBusqueda,
    limit,
    handleNext,
    handlePrevious,
  } = usePagination();

  // const {
  //   open: openAsignarRol,
  //   handleOpen: handleOpenAsignarRol,
  //   handleClose: handleCloseAsignarRol,
  // } = useModalHandler();

  const [idConvertir, setId] = useState("");
  const [groups, setGroups] = useState([]);

  const entidad = JSON.parse(sessionStorage.getItem("user")).perfil.entidad.id;

  const {
    data: registros,
    isLoading: isLoadingG,
    isError: isErrorG,
    error: errorG,
  } = useGetPlantillaQuery(
    {
      filter_params: {
        limit: limit,
        offset: offset,
        buscar: busqueda,
        entidad: entidad,
        estudiantes: "false",
        profesores: contratoState,
        query:
          "{id,url,email,first_name,apellido_paterno,apellido_materno,perfil,foto,groups{id,name,url}}",
      },
      shape: {
        id: "id",
        url: "url",
        nombre: "first_name",
        apellido_paterno: "apellido_paterno",
        apellido_materno: "apellido_materno",
        email: "email",
        groups: "groups[].id",
        roles: "groups[].name",
        resourcetype: "perfil.resourcetype[].resourcetype",
        resourcetypeFull: "perfil.resourcetype",
        telefonos: "perfil.telefonos",
        foto: "foto",
      },
    },
    {
      refetchOnReconnect: true,
    }
  );

  //constantes para manejar la paginacion
  const dataProf = registros?.results ? registros.results : registros;
  const countTotal = registros?.count;
  const previous = registros?.previous;
  const next = registros?.next;

  const [
    deleteProfesor,
    {
      isSuccess: isSuccessD,
      isLoading: isLoadingD,
      isError: isErrorD,
      error: errorD,
    },
  ] = useDeleteUserMutation();

  const dataTable = {
    columns: [
      {
        Header: "Apellido Paterno",
        accessor: "apellido_paterno",
        Cell: ({ value }) => {
          return value ? value : "-------------";
        },
      },
      {
        Header: "Apellido Materno",
        accessor: "apellido_materno",
        Cell: ({ value }) => {
          return value ? value : "-------------";
        },
      },
      {
        Header: "Nombre",
        accessor: "nombre",
        Cell: ({ value }) => {
          return value ? value : "-------------";
        },
      },
      {
        Header: "Correo",
        accessor: "email",
        Cell: ({ value }) => {
          return value ? value : "-------------";
        },
      },
      {
        Header: "Rol",
        accessor: "roles",
        Cell: ({ value }) => {
          return value.length !== 0 ? value.join(", ") : "**NO POSEE**";
        },
      },
      {
        Header: "Contrato",
        accessor: "resourcetype",
        Cell: ({ value }) => value.join(", "),
      },
      {
        Header: "Acciones",
        accessor: "actions",
        width: "1%",
        Cell: ({ row: { original } }) => {
          return (
            <MDBox display="flex">
              <ComponentsPrivatization permitted={COORDINADOR_DOCENTE}>
                <MDBox px={0.2}>
                  <Tooltip
                    placement="top"
                    title="Editar la información del usuario"
                  >
                    <Icon
                      style={style_icon}
                      onClick={() =>
                        handleClickEdit(
                          original.resourcetypeFull.find(
                            (el) => el.resourcetype === "Registro"
                          ).id
                        )
                      }
                      fontSize="small"
                    >
                      edit
                    </Icon>
                  </Tooltip>
                </MDBox>
                {/*<MDBox px={0.2}>*/}
                {/*  <Tooltip*/}
                {/*    placement="top"*/}
                {/*    title="Cambiar rol/es dentro del sistema usuario en cuestión"*/}
                {/*  >*/}
                {/*    <Icon*/}
                {/*      style={style_icon}*/}
                {/*      onClick={() => handleAsignarRol(original)}*/}
                {/*      fontSize="small"*/}
                {/*    >*/}
                {/*      badge*/}
                {/*    </Icon>*/}
                {/*  </Tooltip>*/}
                {/*</MDBox>*/}
                <MDBox px={0.2}>
                  <Tooltip
                    placement="top"
                    title="Eliminar el registro del usuario"
                  >
                    <Icon
                      style={style_icon}
                      onClick={() => handleClickErase(original)}
                      fontSize="small"
                    >
                      remove_circle
                    </Icon>
                  </Tooltip>
                </MDBox>
              </ComponentsPrivatization>
            </MDBox>
          );
        },
      },
    ],
    rows: dataProf ?? [],
  };
  useRedirectForm(
    isLoadingG,
    null,
    isErrorG,
    errorG,
    "Hubo problemas para cargar la información",
    REGISTROS_URL
  );

  useRedirectForm(
    isLoadingD,
    isSuccessD,
    isErrorD,
    errorD,
    "Profesor eliminado",
    REGISTROS_URL
  );

  const handleClickErase = (original) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: `¡Si eliminas el usuario de la plantilla: "${original.nombre} ${original.apellido_materno} ${original.apellido_paterno}", esta acción no se podrá revertir!`,
      icon: "warning",
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Eliminar",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteProfesor(
          original.resourcetypeFull.find((el) => el.resourcetype === "Registro")
            .id
        );
      }
    });
  };

  // const handleAsignarRol = (e) => {
  //   setId(e.resourcetypeFull.find((el) => el.resourcetype === "Registro").id);
  //   setGroups(e.groups);
  //   handleOpenAsignarRol();
  // };

  const handleClickEdit = (id) => {
    navigate(REGISTRO_EDIT_URL(id));
  };

  return (
    <MDBox pt={6} pb={3}>
      <Card>
        <MDBox
          display="flex"
          justifyContent={"space-between"}
          alignItems={"center"}
          m={2}
          p={3}
        >
          <MDTypography variant="h5" fontWeight="medium">
            {tableHeader}
          </MDTypography>

          {/*<AsignarRol*/}
          {/*  open={openAsignarRol}*/}
          {/*  handleClose={handleCloseAsignarRol}*/}
          {/*  id={idConvertir}*/}
          {/*  rol={groups}*/}
          {/*/>*/}

          <ComponentsPrivatization
            permitted={[COORDINADOR_DOCENTE, RECURSOS_HUMANOS]}
          >
            <Link to={REGISTRO_NEW_URL}>
              <Tooltip
                placement="top"
                title="Crear nuevo profesor con toda su información requerida"
              >
                <MDButton variant="gradient" color="info" size="medium">
                  nuevo
                </MDButton>
              </Tooltip>
            </Link>
          </ComponentsPrivatization>
        </MDBox>

        <MDBox display="flex" justifyContent={"center"} alignItems={"center"}>
          {isLoadingG ? (
            <CircularProgress sx={{ marginBottom: "3rem" }} color="inherit" />
          ) : (
            <DataTable
              table={removeColumns(
                dataTable,
                () =>
                  !JSON.parse(sessionStorage.getItem("user")).groups.some(
                    (role) => role.name === COORDINADOR_DOCENTE
                  ),
                ["actions"]
              )}
              canSearch
              paginationApi
              countTotal={countTotal}
              next={next}
              previous={previous}
              handleNext={handleNext}
              handlePrevious={handlePrevious}
              offset={offset}
              setOffset={setOffset}
              setBusqueda={setBusqueda}
              filtersComponent={
                <FiltersComponentContrato
                  values={contratoState}
                  handleChange={handleChangeContrato}
                />
              }
              collapse
              detailsComponent={(rowData) => <RegistroShow rowData={rowData} />}
            />
          )}
        </MDBox>
      </Card>
    </MDBox>
  );
}

DataTableRegistros.propTypes = {
  tableHeader: PropTypes.string.isRequired,
};

export default DataTableRegistros;
