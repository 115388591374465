/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// formik components
import { Form, Formik } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// NewExamenForm page components
import CursoInfo from "./components/cursos.info";

// NewExamenForm layout schemas for form and form feilds
import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import MDTypography from "components/MDTypography";
import { CURSOS_LIST_URL } from "config/bases_url";
import { useRedirectForm } from "hooks/useRedirectForm";
import {
  useCreateCursoMutation,
  useEditCursoMutation,
  useLazyGetCursoByIdQuery,
} from "services";
import StudentsAdd from "./components/students.add";
import { Step, StepLabel, Stepper } from "@mui/material";
import {
  formatoAEquivalenteHoras,
  getModifiedFields,
  reemplazarUrl,
} from "helpers/functions";
import FormErrorHandler from "../../../../handlers/FormErrorHandler/form-error.handler";
import { ErrorBundary } from "../../../../config/ErrorBundary/error-bundary";

function getSteps() {
  return ["Información del Curso", "Añadir Estudiantes"];
}

function getStepContent(stepIndex, formData, id) {
  switch (stepIndex) {
    case 0:
      return <CursoInfo formData={formData} />;
    case 1:
      return <StudentsAdd formData={formData} edit={id} />;
    default:
      return null;
  }
}

function NewCurso() {
  const [
    createCurso,
    {
      isSuccess: isSuccessC,
      isLoading: isLoadingC,
      isError: isErrorC,
      error: errorC,
    },
  ] = useCreateCursoMutation();
  const [
    editCurso,
    {
      isSuccess: isSuccessE,
      isLoading: isLoadingE,
      isError: isErrorE,
      error: errorE,
    },
  ] = useEditCursoMutation();

  const { id } = useParams();
  const { formId, formField } = form;
  const currentValidation = validations[0];
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;
  const handleBack = () => setActiveStep(activeStep - 1);
  const [oldValues, setOldValues] = useState();
  const navigate = useNavigate();

  const [getCursoById, { data: curso }] = useLazyGetCursoByIdQuery();

  useRedirectForm(
    isLoadingC,
    isSuccessC,
    isErrorC,
    errorC,
    "Curso creado",
    CURSOS_LIST_URL,
  );

  useRedirectForm(
    isLoadingE,
    isSuccessE,
    isErrorE,
    errorE,
    "Curso editado",
    CURSOS_LIST_URL,
  );

  function horasAFormatoEsperado(horas) {
    const dias = Math.floor(horas / 24);
    const horasRestantes = horas % 24;
    return `${dias} ${String(horasRestantes).padStart(2, "0")}:00:00`;
  }

  const submitForm = async (values, actions) => {
    try {
      if (!id) {
        values.duracion = horasAFormatoEsperado(values.duracion);
        if (values.estudiantes.length > 0) {
          values.estudiantes = values.estudiantes.map((e) => e.url);
          values.estudiantes = values.estudiantes.map((e) =>
            reemplazarUrl(e, 2),
          );
        }
        await createCurso(values);
      } else {
        const modifiedFields = getModifiedFields(oldValues, values);
        if (Object.keys(modifiedFields).length !== 0) {
          if (modifiedFields.duracion) {
            modifiedFields.duracion = horasAFormatoEsperado(
              modifiedFields.duracion,
            );
          }
          if (modifiedFields.estudiantes.length > 0) {
            modifiedFields.estudiantes = modifiedFields.estudiantes.map(
              (e) => e.url,
            );
            modifiedFields.estudiantes = modifiedFields.estudiantes.map((e) =>
              reemplazarUrl(e, 2),
            );
          }
          await editCurso({ id: id, ...modifiedFields });
        }
      }
      if (isSuccessC || isSuccessE) {
        actions.setSubmitting(false);
        actions.resetForm();
      }
    } catch (error) {
      console.error(error);
      actions.setSubmitting(true);
    }
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  return (
    <MDBox mt={5} mb={20}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} lg={8}>
          <MDBox mt={6} mb={8} textAlign="center">
            <MDBox mb={1}>
              <MDTypography variant="h3" fontWeight="bold">
                {!id
                  ? "Nuevo Curso Extracurricular"
                  : "Editar Curso Extracurricular"}
              </MDTypography>
            </MDBox>
            <MDTypography variant="h5" fontWeight="regular" color="secondary">
              {!id
                ? "Introduzca la información relacionada al curso por agregar"
                : `Edite la información relacionada al curso: ${curso?.nombre}`}
            </MDTypography>
          </MDBox>
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, setFieldValue }) => {
              useEffect(() => {
                if (id) {
                  getCursoById({
                    id,
                    filter_params: {
                      query:
                        "{nombre_corto,localizacion,fecha_inicio,fecha_fin,modalidad,nombre,duracion,expedicion,firmante{url,user{apellido_paterno,apellido_materno,first_name}},estudiantes{numero_de_control,user{first_name,apellido_paterno,apellido_materno},url,id}}",
                    },
                  })
                    .unwrap()
                    .then((res) => {
                      const reduceEstudiante = (e) => {
                        return {
                          nombre: `${e?.user.apellido_paterno} ${e?.user.apellido_materno} ${e?.user.first_name}, ${e?.numero_de_control}`,
                          url: e?.url,
                          id: e?.id,
                        };
                      };
                      setOldValues({
                        nombre: res?.nombre,
                        nombre_corto: res?.nombre_corto,
                        modalidad: res?.modalidad.url,
                        firmante: res?.firmante.url,
                        duracion: res?.duracion,
                        expedicion: res?.expedicion,
                        localizacion: res?.localizacion,
                        fecha_inicio: res?.fecha_inicio,
                        fecha_fin: res?.fecha_fin,
                        estudiantes: res?.estudiantes?.map((e) =>
                          reduceEstudiante(e),
                        ),
                      });
                      setFieldValue(formField.nombre.name, res?.nombre, true);
                      setFieldValue(
                        formField.nombre_corto.name,
                        res?.nombre_corto,
                        true,
                      );
                      setFieldValue(
                        formField.modalidad.name,
                        res?.modalidad.url,
                        true,
                      );
                      setFieldValue(
                        formField.firmante.name,
                        res?.firmante.url,
                        true,
                      );
                      setFieldValue(
                        formField.duracion.name,
                        formatoAEquivalenteHoras(res?.duracion),
                        true,
                      );
                      setFieldValue(
                        formField.expedicion.name,
                        res?.expedicion,
                        true,
                      );
                      setFieldValue(
                        formField.localizacion.name,
                        res?.localizacion,
                        true,
                      );
                      setFieldValue(
                        formField.fecha_inicio.name,
                        res?.fecha_inicio,
                        true,
                      );
                      setFieldValue(
                        formField.fecha_fin.name,
                        res?.fecha_fin,
                        true,
                      );

                      setFieldValue(
                        formField.estudiantes.name,
                        res?.estudiantes?.map((e) => reduceEstudiante(e)),
                        true,
                      );
                    });
                }
              }, [id]);

              return (
                <ErrorBundary renderOnError={() => <FormErrorHandler />}>
                  <Form id={formId} autoComplete="off">
                    <Card sx={{ height: "100%" }}>
                      <MDBox mx={2} mt={-3}>
                        <Stepper activeStep={activeStep} alternativeLabel>
                          {steps.map((label) => (
                            <Step key={label}>
                              <StepLabel>{label}</StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                      </MDBox>
                      <MDBox p={3}>
                        <MDBox>
                          {getStepContent(
                            activeStep,
                            {
                              values,
                              touched,
                              formField,
                              errors,
                              setFieldValue,
                            },
                            id,
                          )}
                          <MDBox
                            mt={2}
                            width="100%"
                            display="flex"
                            justifyContent="space-between"
                          >
                            <MDButton
                              onClick={(e) =>
                                activeStep === 0
                                  ? navigate(CURSOS_LIST_URL)
                                  : handleBack()
                              }
                              type="button"
                              variant="gradient"
                              color={activeStep === 0 ? "primary" : "dark"}
                            >
                              {activeStep === 0 ? "cancelar" : "atrás"}
                            </MDButton>
                            {activeStep !== 0 && (
                              <MDButton
                                onClick={(e) => navigate(CURSOS_LIST_URL)}
                                type="button"
                                variant="gradient"
                                color="primary"
                              >
                                cancelar
                              </MDButton>
                            )}
                            <MDButton
                              type="submit"
                              variant="gradient"
                              color="dark"
                            >
                              {isLastStep ? "aceptar" : "siguiente"}
                            </MDButton>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </Card>
                  </Form>
                </ErrorBundary>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default NewCurso;
