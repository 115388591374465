import {profesorApi} from "../view/profesor.service";


export const getProfesorById = profesorApi.injectEndpoints({
    endpoints: (builder) => ({
        getProfesorById: builder.query({
            query: (id) => ({
                url: `/v1/api/docente/${id}/`,
                method: "GET",
            }),
            providesTags: (result, error, id) => [{type: "Profesor", id}],
        }),
    }),
})

export const {
    useGetProfesorByIdQuery,
    useLazyGetProfesorByIdQuery
} = getProfesorById;