import { Grid, Icon, InputAdornment, Tooltip } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";
import FormField from "../FormField";
import MDButton from "../MDButton";
import ModalBase from "../ModalBase/modal_base";
import * as Yup from "yup";
import MDDropzone from "../MDDropzone";
import cer from "../../assets/images/tecnoschool/cer.png";
import { style_icon } from "helpers/global_vars";
import { decryptPrivateKeyFromFile } from "helpers/cryptography";
import { signData } from "helpers/cryptography";
import { useCloseModal } from "hooks/useCloseModal";
import { useSnackbar } from "notistack";
import { useFirmarBoletaMutation, useFirmarCursosMutation } from "services";

const style = {
  cursor: "pointer",
  borderRadius: "0.5rem",
};

export default function ModalFirmar({ open, handleClose, selected, curso }) {
  const [errorUpload, setErrorUpload] = React.useState(null);
  const [fileUploaded, setFileUploaded] = React.useState(false);
  const [shown, setShown] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const firmanteUrl = JSON.parse(
    sessionStorage.getItem("user")
  ).perfil.resourcetype.find((e) => e.resourcetype === "Firmante").url;

  const [
    firmarCursos,
    {
      isSuccess: isSuccessE,
      isError: isErrorE,
      isLoading: isLoadingE,
      error: errorE,
    },
  ] = useFirmarCursosMutation();

  const [
    firmarBoleta,
    {
      isSuccess: isSuccessES,
      isError: isErrorES,
      isLoading: isLoadingES,
      error: errorES,
    },
  ] = useFirmarBoletaMutation();

  const handleSubmit = async (values) => {
    const { key, password } = values;
    try {
      if (!key) {
        throw new Error("No se seleccionó ningún archivo");
      } else {
        if (curso) {
          const decryptedPrivateKey = await decryptPrivateKeyFromFile(
            key,
            password
          );
          selected?.map((e) => {
            firmarCursos({
              signed_object: e.url,
              sello: signData(decryptedPrivateKey, e.cadena_unica),
              firmante: firmanteUrl,
              fecha_creacion: values.fecha,
            });
          });
        } else {
          const decryptedPrivateKey = await decryptPrivateKeyFromFile(
            key,
            password
          );
          selected?.map((e) => {
            firmarBoleta({
              signed_object: e.url,
              sello: signData(decryptedPrivateKey, e.cadena_unica),
              firmante: firmanteUrl,
              fecha_creacion: values.fecha,
            });
          });
        }
      }
    } catch (error) {
      enqueueSnackbar("Contraseña incorrecta o formato de llave inválido.", {
        variant: "error",
        autoHideDuration: 5000,
      });
      console.error(error);
    }
  };

  useCloseModal(
    isLoadingE,
    isSuccessE,
    isErrorE,
    errorE,
    "Certificado firmado con éxito",
    handleClose
  );

  useCloseModal(
    isLoadingES,
    isSuccessES,
    isErrorES,
    errorES,
    "Boleta firmada con éxito",
    handleClose
  );

  const handleChangeShown = () => {
    setShown(!shown);
  };

  return (
    <ModalBase open={open} handleClose={handleClose}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} lg={12}>
          <Formik
            initialValues={{
              key: "",
              password: "",
              fecha: new Date().toISOString().slice(0, 10),
            }}
            onSubmit={handleSubmit}
            validationSchema={Yup.object().shape({
              key: Yup.string().required("Debe proporcionar su llave privada"),
              password: Yup.string().required(
                "Debe proporcionar su contraseña"
              ),
            })}
          >
            {({
              values,
              errors,
              touched,
              isValid,
              handleBlur,
              setFieldValue,
            }) => {
              return (
                <Form key={"firmar"} id={"firmar_certificados"}>
                  <Grid
                    container
                    spacing={3}
                    display={"flex"}
                    justifyContent={"center"}
                  >
                    <Grid item xs={12} sm={10}>
                      <FormField
                        type={shown ? "text" : "password"}
                        label={"Contraseña"}
                        onBlur={handleBlur}
                        name={"password"}
                        value={values.password}
                        error={errors.password && touched.password}
                        success={values.password.length > 0 && !errors.password}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {values.password.length !== 0 ? (
                                <Icon
                                  style={style_icon}
                                  onClick={handleChangeShown}
                                  fontSize="small"
                                  variant="outlined"
                                >
                                  {shown ? "visibility_off" : "visibility"}
                                </Icon>
                              ) : null}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={10}>
                      <FormField
                        type="date"
                        label={"Fecha de la firma"}
                        onBlur={handleBlur}
                        name={"fecha"}
                        value={values.fecha}
                      />
                    </Grid>
                    <Grid item xs={12} sm={10}>
                      <MDBox display="flex" flexDirection="column">
                        <MDBox
                          mt={2}
                          width="100%"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          {!fileUploaded && (
                            <MDDropzone
                              options={{
                                maxFilesize: 1,
                                acceptedFiles: ".key",
                                dictDefaultMessage: "Suba su archivo .key aqui",
                                autoProcessQueue: false,
                                init: function () {
                                  this.on("addedfile", (file) => {
                                    const validExts = [".key"];
                                    let fileExt = file.name;
                                    fileExt = fileExt.substring(
                                      fileExt.lastIndexOf(".")
                                    );
                                    if (!validExts.includes(fileExt)) {
                                      setErrorUpload(
                                        "Solo se aceptan archivos .key"
                                      );
                                      setFileUploaded(false);
                                    } else {
                                      setFieldValue("key", file, true);
                                      setErrorUpload(null);
                                      setFileUploaded(true);
                                    }
                                  });
                                },
                              }}
                              file={fileUploaded}
                              style={{ minHeight: "80px", width: "100%" }}
                              id="key"
                            />
                          )}
                          {fileUploaded && (
                            <MDBox
                              component="img"
                              src={cer}
                              variant="gradient"
                              bgColor="dark"
                              style={style}
                            />
                          )}
                          <Tooltip placement="top" title={"Quitar archivo"}>
                            <MDBox ml={1}>
                              <MDButton
                                type="submit"
                                variant="gradient"
                                color="dark"
                                onClick={() => {
                                  setFieldValue("key", null),
                                    setFileUploaded(false);
                                }}
                                circular
                                iconOnly
                              >
                                <Icon> delete</Icon>
                              </MDButton>
                            </MDBox>
                          </Tooltip>
                        </MDBox>

                        {errorUpload && (
                          <MDTypography
                            color="error"
                            variant="h6"
                            fontWeight="bold"
                            display="flex"
                            justifyContent="center"
                            textAlign="center"
                          >
                            {errorUpload}
                          </MDTypography>
                        )}
                      </MDBox>
                    </Grid>
                  </Grid>
                  <MDBox
                    mt={2}
                    width="100%"
                    display="flex"
                    justifyContent="end"
                  >
                    <MDButton
                      disabled={!isValid}
                      type="submit"
                      variant="gradient"
                      color="dark"
                    >
                      aceptar
                    </MDButton>
                  </MDBox>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </ModalBase>
  );
}
