/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { asistencia_requerida, nombre, identificador, clave },
} = checkout;

const validations = [
  Yup.object().shape({
    [nombre.name]: Yup.string().required(nombre.errorMsg),
    [identificador.name]: Yup.string().required(identificador.errorMsg),
    [asistencia_requerida.name]: Yup.number("Debe entrar un valor numérico")
      .min(0, "El minimo valor aceptado es 0")
      .max(100, "El mayor valor aceptado es 100")
      .required("Debe introducir un porciento de asistencia"),
    [clave.name]: Yup.string().required(clave.errorMsg),
  }),
];

export default validations;
