import * as React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import BajaReceipt from "layouts/almacen/pdf/printing/pdfBaja";
import ModalBase from "../ModalBase/modal_base";

const ModalBajaShow = ({ open, handleClose, lote }) => {
  return (
    <ModalBase open={open} handleClose={handleClose}>
      <Grid container>
        <BajaReceipt lote={lote} />
      </Grid>
    </ModalBase>
  );
};

ModalBajaShow.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ModalBajaShow;
