/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================
 
 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)
 
 Coded by www.creative-tim.com
 
 =========================================================
 
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// NewUser page components
import React from "react";
import FormField from "components/FormField";
import {Autocomplete, Icon, Tooltip} from "@mui/material";
import {useGetGruposQuery} from "services/administracion/grupos.service";
import {useSelector} from "react-redux";
import {admin_state} from "slices";
import MDImage from "components/MDImage/image";
import MDTypography from "components/MDTypography";
import {EMAIL_REGEX} from "helpers/regexs";
import {useSnackbar} from "notistack";

const style = {
  width: "100%",
  cursor: "pointer",
  borderRadius: "0.5rem",
  marginTop: "0.5rem",
  marginLeft: "0.5rem",
};

const icon_style = {
  position: "absolute",
  left: "50%",
  top: "50%",
  fontSize: "30px",
  transform: "translate(-50%,-50%)",
};

function RegisterForm({ formData }) {
  const { formField, values, errors, touched, setFieldValue } = formData;
  const {
    nombre,
    apellido_materno,
    apellido_paterno,
    correo,
    foto,
    groups,
  } = formField;
  const {
    nombre: nombreV,
    apellido_materno: apellido_maternoV,
    apellido_paterno: apellido_paternoV,
    correo: correoV,
    foto: fotoV,
    groups: groupsV,
  } = values;
  
  const { data: grupos } = useGetGruposQuery({
    filter_params: {
      query: "{name, url, id}"
    },
    shape: {
      id: "id",
      name: "name",
      url: "url",
    }
  }, {
    refetchOnReconnect: true,
  });
  
  const { edit } = useSelector(admin_state);
  
  const { enqueueSnackbar } = useSnackbar();
  const handleChangeFoto = (e, isValid) => {
    if (isValid) setFieldValue(foto.name, e.target.files[0]);
    else {
      enqueueSnackbar("El tamaño de la imagen no debe ser mayor a 1MB", {
        variant: "error",
        autoHideDuration: 4000,
      });
    }
  };
  
  const handleChange = (event, values) => {
    setFieldValue(groups.name, values?.map((option) => option.id));
  };
  
  return (
    <MDBox>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={3}>
          <FormField
            type={nombre.type}
            label={nombre.label}
            name={nombre.name}
            value={nombreV}
            placeholder={nombre.placeholder}
            error={errors.nombre && touched.nombre}
            success={nombreV?.length > 0 && !errors.nombre}
            onInput={(e) =>
              (e.target.value = ("" + e.target.value).toUpperCase())
            }
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <FormField
            type={apellido_paterno.type}
            label={apellido_paterno.label}
            name={apellido_paterno.name}
            value={apellido_paternoV}
            placeholder={apellido_paterno.placeholder}
            error={errors.apellido_paterno && touched.apellido_paterno}
            success={apellido_paternoV?.length > 0 && !errors.apellido_paterno}
            onInput={(e) =>
              (e.target.value = ("" + e.target.value).toUpperCase())
            }
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormField
            type={apellido_materno.type}
            label={apellido_materno.label}
            name={apellido_materno.name}
            value={apellido_maternoV}
            placeholder={apellido_materno.placeholder}
            error={errors.apellido_materno && touched.apellido_materno}
            success={apellido_maternoV?.length > 0 && !errors.apellido_materno}
            onInput={(e) =>
              (e.target.value = ("" + e.target.value).toUpperCase())
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <FormField
            type={correo.type}
            label={correo.label}
            name={correo.name}
            value={correoV}
            placeholder={correo.placeholder}
            error={errors.correo && touched.correo}
            success={!!correoV?.match(EMAIL_REGEX) && !errors.correo}
          />
        </Grid>
      
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Autocomplete
            multiple
            id="distribuir-rol-plantilla"
            options={grupos ?? []}
            onChange={handleChange}
            value={
              grupos?.filter((option) => groupsV?.includes(option.id)) || []
            }
            isOptionEqualToValue={(option, value) => {
              return `${option.name}` === `${value.name}`;
            }}
            getOptionLabel={(option) => `${option.name}`}
            renderInput={(params) => (
              <FormField
                id="distribucionRol"
                name={"distribucionRol"}
                label="Asignar rol/roles"
                {...params}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} display="flex" justifyContent={"center"}>
        <Grid item xs={12} sm={6}>
          <MDBox>
            <MDTypography
              variant="h6"
              fontWeight="bold"
              flex
              justifyContent="center"
              textAlign="center"
            >
              {foto.label}
            </MDTypography>
          </MDBox>
          <MDBox mt={1.625} display="flex" justifyContent="center">
            <Tooltip
              placement="top"
              title={
                !edit
                  ? !fotoV
                    ? "Agregar imagen"
                    : "Cambiar Foto"
                  : "Agregar imagen"
              }
            >
              <MDBox
                style={{
                  position: "relative",
                  width: "200px",
                  cursor: "pointer",
                }}
              >
                {!fotoV ? (
                  <Icon fontSize="medium" style={icon_style}>
                    image_search
                  </Icon>
                ) : null}
                
                <MDImage
                  id="profile-image"
                  htmlFor="profile-image"
                  disabled={false}
                  style={style}
                  src={
                    fotoV && typeof fotoV === "object"
                      ? URL.createObjectURL(fotoV)
                      : fotoV
                  }
                  imageTypes={foto.accept}
                  handleChange={handleChangeFoto}
                />
              </MDBox>
            </Tooltip>
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}

// typechecking props for UserInfo
RegisterForm.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default RegisterForm;
