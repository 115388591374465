import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// NewUser page components
import FormField from "components/FormField";
import { MenuItem } from "@mui/material";
import { useGetDuracionesQuery, useGetNivelesDeEstudiosQuery } from "services";

function AddCarrera({ formData, isDisabled }) {
  const { formField, values, errors, touched } = formData;
  const {
    nombre,
    modalidad,
    plan_de_estudio,
    rvoe,
    identificador,
    nivel,
    calificacion_minima,
    calificacion_maxima,
    calificacion_aprobatoria,
    fecha_expedicion,
    duracion,
  } = formField;

  const {
    calificacion_minima: calificacion_minimaV,
    calificacion_maxima: calificacion_maximaV,
    calificacion_aprobatoria: calificacion_aprobatoriaV,
    fecha_expedicion: fecha_expedicionV,
    duracion: duracionV,
    identificador: identificadorV,
    nivel: nivelV,
    rvoe: rvoeV,
    nombre: nombreV,
    modalidad: modalidadV,
    plan_de_estudio: plan_de_estudioV,
  } = values;

  const { data: duraciones } = useGetDuracionesQuery(undefined, {
    refetchOnReconnect: true,
  });

  const { data: niveles } = useGetNivelesDeEstudiosQuery(undefined, {
    refetchOnReconnect: true,
  });

  const modalidades = [
    { id: 1, nombre: "Presencial" },
    { id: 2, nombre: "Virtual" },
    { id: 3, nombre: "Mixta" },
  ];

  return (
    <MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              disabled={isDisabled}
              type={nombre.type}
              label={nombre.label}
              name={nombre.name}
              value={nombreV}
              placeholder={nombre.placeholder}
              error={errors.nombre && touched.nombre}
              success={nombreV.length > 0 && !errors.nombre}
              onInput={(e) =>
                (e.target.value = ("" + e.target.value).toUpperCase())
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <FormField
              disabled={isDisabled}
              type={rvoe.type}
              label={rvoe.label}
              name={rvoe.name}
              value={rvoeV}
              placeholder={rvoe.placeholder}
              error={errors.rvoe && touched.rvoe}
              success={rvoeV?.length > 0 && !errors.rvoe}
              onInput={(e) =>
                (e.target.value = ("" + e.target.value).toUpperCase())
              }
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              disabled={isDisabled}
              type={plan_de_estudio.type}
              label={plan_de_estudio.label}
              name={plan_de_estudio.name}
              value={plan_de_estudioV}
              placeholder={plan_de_estudio.placeholder}
              error={errors.plan_de_estudio && touched.plan_de_estudio}
              success={plan_de_estudioV?.length > 0 && !errors.plan_de_estudio}
              onInput={(e) =>
                (e.target.value = ("" + e.target.value).toUpperCase())
              }
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              disabled={isDisabled}
              type={identificador.type}
              label={identificador.label}
              name={identificador.name}
              value={identificadorV}
              placeholder={identificador.placeholder}
              error={errors.identificador && touched.identificador}
              success={identificadorV?.length > 0 && !errors.identificador}
              onInput={(e) =>
                (e.target.value = ("" + e.target.value).toUpperCase())
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              select
              disabled={isDisabled}
              label={nivel.label}
              name={nivel.name}
              value={nivelV}
              placeholder={nivel.placeholder}
              error={errors.nivel && touched.nivel}
              success={nivelV.length > 0 && !errors.nivel}
            >
              <MenuItem key="0" disabled value={""}>
                No seleccionada
              </MenuItem>
              {niveles?.map((el) => (
                <MenuItem key={el.id} value={el.url}>
                  {el.descripcion}
                </MenuItem>
              ))}
            </FormField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              select
              disabled={isDisabled}
              label={modalidad.label}
              name={modalidad.name}
              value={modalidadV}
              placeholder={modalidad.placeholder}
              error={errors.modalidad && touched.modalidad}
              success={modalidadV.length > 0 && !errors.modalidad}
            >
              <MenuItem key="0" disabled value={""}>
                No seleccionada
              </MenuItem>
              {!modalidades.some(
                (el) =>
                  el.nombre.toLocaleLowerCase() ===
                  modalidadV.toLocaleLowerCase()
              ) && (
                <MenuItem key={0} value={modalidadV}>
                  {modalidadV}
                </MenuItem>
              )}
              {modalidades?.map((el) => (
                <MenuItem key={el.id} value={el.nombre}>
                  {el.nombre}
                </MenuItem>
              ))}
            </FormField>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <FormField
              disabled={isDisabled}
              type={calificacion_minima.type}
              label={calificacion_minima.label}
              name={calificacion_minima.name}
              value={calificacion_minimaV}
              error={errors.calificacion_minima && touched.calificacion_minima}
              success={calificacion_minimaV > 0 && !errors.calificacion_minima}
              onInput={(e) => {
                let value = ("" + e.target.value).replace(/[^(\d|\.)]/g, "");
                e.target.value = isNaN(Number(value)) ? 0 : Number(value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              disabled={isDisabled}
              type={calificacion_maxima.type}
              label={calificacion_maxima.label}
              name={calificacion_maxima.name}
              value={calificacion_maximaV}
              error={errors.calificacion_maxima && touched.calificacion_maxima}
              success={calificacion_maximaV > 0 && !errors.calificacion_maxima}
              onInput={(e) => {
                let value = ("" + e.target.value).replace(/[^(\d|\.)]/g, "");
                e.target.value = isNaN(Number(value)) ? 0 : Number(value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              disabled={isDisabled}
              type={calificacion_aprobatoria.type}
              label={calificacion_aprobatoria.label}
              name={calificacion_aprobatoria.name}
              value={calificacion_aprobatoriaV}
              error={
                errors.calificacion_aprobatoria &&
                touched.calificacion_aprobatoria
              }
              success={
                calificacion_aprobatoriaV > 0 && !errors.calificacion_minima
              }
              onInput={(e) => {
                let value = ("" + e.target.value).replace(/[^(\d|\.)]/g, "");
                e.target.value = isNaN(Number(value)) ? 0 : Number(value);
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              select
              disabled={isDisabled}
              label={duracion.label}
              name={duracion.name}
              value={duracionV}
              error={
                errors.duracion && touched.duracion && values.duracion === ""
              }
              success={duracionV !== "" && !errors.duracion}
            >
              <MenuItem key="0" disabled value={""}>
                No seleccionada
              </MenuItem>
              {duraciones?.map((el) => (
                <MenuItem key={el.id} value={el.url}>
                  {el.identificador}
                </MenuItem>
              ))}
            </FormField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              disabled={isDisabled}
              label={fecha_expedicion.label}
              type={fecha_expedicion.type}
              name={fecha_expedicion.name}
              value={fecha_expedicionV}
              error={errors.fecha_expedicion && touched.fecha_expedicion}
              success={fecha_expedicionV && !errors.fecha_expedicion}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for UserInfo
AddCarrera.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default AddCarrera;
