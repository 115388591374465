/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

const form = {
  formId: "nueva-entidad",
  formField: {
    nombre: {
      name: "nombre",
      label: "Nombre(s)",
      type: "text",
      placeholder: "Nombre(s)",
      errorMsg: "El nombre es requerido.",
    },
    logo: {
      name: "logo",
      label: "Logo",
      type: "file",
      accept: "image/jpeg, image/png, image/gif",
    },
    municipio: {
      name: "municipio",
      label: "Municipio",
      type: "text",
      errorMsg: "El Municipio es requerido.",
      placeholder: "Municipio",
    },
    localidad: {
      name: "localidad",
      label: "Localidad",
      type: "text",
      placeholder: "Localidad",
      errorMsg: "La localidad es requerida.",
    },
    clave_del_centro: {
      name: "clave_del_centro",
      label: "Clave del centro de trabajo",
      type: "text",
      errorMsg: "La clave del centro es requerida.",
      placeholder: "Clave",
    },
    dominio: {
      name: "dominio",
      label: "Dominio",
      type: "text",
      errorMsg: "El dominio es requerido",
      placeholder: "Dominio",
    },
  },
};

export default form;
