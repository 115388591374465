import { ACTIVOS_REGEX } from "helpers/regexs";
import { NAME_AREA } from "helpers/regexs";
import * as Yup from "yup";
import checkout from "./form";

const {
  formField: {
    fecha_de_facturacion,
    fecha_cedula,
    estado,
    descripcion,
    metodo_de_adquisicion,
    proveedor,
    numero_de_factura,
    valor,
    tipo,
    cantidad,
    marca,
    modelo,
    unidad_de_medida,
  },
} = checkout;

const validations = [
  Yup.object().shape({
    [metodo_de_adquisicion.name]: Yup.string().required(
      metodo_de_adquisicion.errorMsg
    ),
    [tipo.name]: Yup.string().required(tipo.errorMsg),
    [numero_de_factura.name]: Yup.string()
      .required(numero_de_factura.errorMsg)
      .matches(
        ACTIVOS_REGEX,
        "No puede contener caracteres extraños, a parte de (-)(_)(/)"
      ),
    [valor.name]: Yup.string().required(valor.errorMsg),
    [proveedor.name]: Yup.string()
      .required(proveedor.errorMsg)
      .matches(
        NAME_AREA,
        "El nombre del proveedor no puede contener números ni caracteres extraños"
      ),

    [cantidad.name]: Yup.number()
      .min(1, "La cantidad mínima es 1")
      .max(9999, "La cantidad no puede exceder 9999")
      .required(cantidad.errorMsg),
    [fecha_cedula.name]: Yup.date()
      .max(new Date(), "La fecha proporcionada no es válida")
      .required(),
    [fecha_de_facturacion.name]: Yup.date()
      .max(new Date(), "La fecha proporcionada no es válida")
      .required(),
    [unidad_de_medida.name]: Yup.string().required(unidad_de_medida.errorMsg),
  }),
  Yup.object().shape({
    [estado.name]: Yup.string().required(estado.errorMsg),
    [descripcion.name]: Yup.string().required(descripcion.errorMsg),
    [marca.name]: Yup.string().matches(
      NAME_AREA,
      "No puede contener caracteres extraños"
    ),
    [modelo.name]: Yup.string().matches(
      NAME_AREA,
      "No puede contener caracteres extraños"
    ),
  }),
];

export default validations;
