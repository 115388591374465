import {firmantesApi} from "../view/firmante.service";


export const deleteFirmante = firmantesApi.injectEndpoints({
    endpoints: (builder) => ({
        deleteFirmante: builder.mutation({
            query: (id) => ({
                url: `/v1/api/firmante/${id}/`,
                method: "DELETE",
            }),
            invalidatesTags: [{type: "Firmantes", id: "LIST"}],
        }),
    }),
})

export const {
    useDeleteFirmanteMutation
} = deleteFirmante;