import { createApi } from "@reduxjs/toolkit/query/react";
import { buildFiltersUrl, reshape_response } from "helpers/functions";
import customFetchBase from "services/principal/customFetchBase";

export const tiposApi = createApi({
  reducerPath: "tiposApi",
  baseQuery: customFetchBase,
  tagTypes: ["Tipos"],
  endpoints: (builder) => ({
    getTipos: builder.query({
      query: ({ filter_params }) => ({
        url: buildFiltersUrl("/v1/api/tipo-activo/", filter_params),
        method: "GET",
      }),
      providesTags: (result) =>
        Array.isArray(result)
          ? [
              ...result.map(({ id }) => ({
                type: "Tipos",
                id,
              })),
              { type: "Tipos", id: "LIST" },
            ]
          : [{ type: "Tipos", id: "LIST" }],
      transformResponse: (response, meta, args) => {
        if (Array.isArray(response)) {
          return response?.map((el) => reshape_response(el, args?.shape));
        } else {
          const resultsTransformed = response?.results?.map((el) =>
            reshape_response(el, args?.shape)
          );

          // Devolvemos los resultados transformados junto con las propiedades de paginación
          return {
            results: resultsTransformed,
            count: response.count,
            next: response.next,
            previous: response.previous,
          };
        }
      },
    }),

    getTipoById: builder.query({
      query: (id) => ({
        url: `/v1/api/tipo-activo/${id}/`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "Tipos", id }],
    }),

    createTipo: builder.mutation({
      query: (adq) => ({
        url: "/v1/api/tipo-activo/",
        method: "POST",
        body: adq,
      }),
      invalidatesTags: [{ type: "Tipos", id: "LIST" }],
    }),

    deleteTipo: builder.mutation({
      query: (id) => ({
        url: `/v1/api/tipo-activo/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["Tipos"],
    }),

    editTipo: builder.mutation({
      query: (body) => ({
        url: `/v1/api/tipo-activo/${body.id}/`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, { id }) =>
        result
          ? [
              { type: "Tipos", id },
              { type: "Tipos", id: "LIST" },
            ]
          : [{ type: "Tipos", id: "LIST" }],
    }),
  }),
});

export const {
  useGetTiposQuery,
  useCreateTipoMutation,
  useEditTipoMutation,
  useDeleteTipoMutation,
  useLazyGetTipoByIdQuery,
} = tiposApi;
