import React from "react";
import PropTypes from "prop-types";
import NotFound from "assets/images/not_found_image.jpg";

const MDImage = ({
  imageFail,
  id,
  htmlFor,
  src,
  disabled,
  imageTypes,
  handleChange,
  style,
  ...props
}) => {
  const handleFileChange = (event) => {
    const MAX_FILE_SIZE = 1024; // 1MB
    let isValid;

    if (event.target.files.length > 0) {
      let selectedFile = event.target.files[0];
      const fileSizeKiloBytes = selectedFile.size / 1024;

      if (fileSizeKiloBytes > MAX_FILE_SIZE) isValid = false;
      else isValid = true;

      handleChange(event, isValid);
    }
  };

  return (
    <>
      <input
        disabled={disabled}
        style={{
          visibility: "hidden",
          position: "absolute",
          left: "-1000px",
        }}
        accept={imageTypes}
        id={id}
        type="file"
        onChange={(e) => handleFileChange(e)}
      />

      <label htmlFor={htmlFor}>
        {!src && (
          <>
            <img src={imageFail.image} alt={imageFail.alt} style={style} />
          </>
        )}
        <img src={src} alt="" style={style} />
      </label>
    </>
  );
};

MDImage.propTypes = {
  src: PropTypes.string,
  id: PropTypes.string.isRequired,
  htmlFor: PropTypes.string.isRequired,
  imageTypes: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.object.isRequired,
  imageFail: PropTypes.object,
};

MDImage.defaultProps = {
  imageFail: { image: NotFound, alt: "No encontrada" },
  src: null,
  imageTypes: "image/jpeg, image/png, image/gif",
  disabled: false,
};

export default MDImage;
