import { useState } from "react";

export default function usePagination() {
  const [offset, setOffset] = useState(0);
  const limit = 20;
  const [busqueda, setBusqueda] = useState("");

  const handleNext = async () => {
    setOffset(offset + limit);
  };

  const handlePrevious = async () => {
    setOffset(offset - limit);
  };

  return {
    offset,
    setOffset,
    limit,
    busqueda,
    setBusqueda,
    handleNext,
    handlePrevious,
  };
}
