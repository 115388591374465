import { CircularProgress } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useSnackbar } from "notistack";
import { useState } from "react";
import {
  useMakeRepRegUnoMutation,
  useMakeRepRegDosMutation,
  useLazyGetTaskQuery,
} from "services";

export function convertirDivision(div, reg) {
  let numero = div.division;
  let tipoCicloInterno = div.type;
  if (reg === "reg2" && numero === 1) {
    ++numero;
  } else if (
    reg === "reg2" &&
    numero !== 1 &&
    tipoCicloInterno === "Semestre"
  ) {
    --numero;
  }
  return numero;
}

const ReportesComponent = ({ rep, ciclo, division }) => {
  const [reporteIDUno] = useMakeRepRegUnoMutation();
  const [reporteIDDos] = useMakeRepRegDosMutation();
  const [triggerTarea] = useLazyGetTaskQuery();
  const [cargando1, setCargando1] = useState(false);
  const [cargando2, setCargando2] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleReporte = async (reg) => {
    const endpoint = reg === "reg1" ? reporteIDUno : reporteIDDos;
    const resp = await endpoint({
      filter_params: {
        query: "{id}",
      },
      body: {
        ciclo: ciclo,
        numero_division: convertirDivision(division, reg),
      },
      skip: false,
    }).unwrap();
    if (resp && resp.id) {
      peticionTarea(resp.id, reg);
    }
  };

  const peticionTarea = async (id, reg) => {
    triggerTarea(id)
      .unwrap()
      .then((res) => {
        if (res.estado === "COMPLETED") {
          descargarCSV(res.resultado, reg);
          enqueueSnackbar("Reporte generado correctamente", {
            variant: "success",
            autoHideDuration: 8000,
          });
        } else if (res.estado === "ERROR") {
          reg === "reg1" ? setCargando1(false) : setCargando2(false);
          enqueueSnackbar("Ocurrió un problema al generar el reporte", {
            variant: "error",
            autoHideDuration: 8000,
          });
          return;
        } else {
          setTimeout(() => {
            peticionTarea(id, reg);
          }, [10000]);
        }
      });
  };

  const descargarCSV = (resultado, reg) => {
    reg === "reg1" ? setCargando1(false) : setCargando2(false);
    const csvContent = "\uFEFF" + resultado;
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `${reg}, ${division.division_verbose}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <MDBox>
      <MDBox>
        {rep === 1 ? (
          <MDButton
            disabled={cargando1}
            variant={"gradient"}
            color={"secondary"}
            size="medium"
            onClick={() => {
              handleReporte("reg1"), setCargando1(true);
            }}
          >
            Reporte Reg 1
            {cargando1 && (
              <CircularProgress
                sx={{ marginLeft: "4px" }}
                size={20}
                color="inherit"
              />
            )}
          </MDButton>
        ) : (
          <MDButton
            disabled={cargando2}
            variant={"gradient"}
            color={"secondary"}
            size="medium"
            onClick={() => {
              handleReporte("reg2"), setCargando2(true);
            }}
            sx={{}}
          >
            Reporte Reg 2
            {cargando2 && (
              <CircularProgress
                sx={{ marginLeft: "4px" }}
                size={20}
                color="inherit"
              />
            )}
          </MDButton>
        )}
      </MDBox>
    </MDBox>
  );
};

export default ReportesComponent;
