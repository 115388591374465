/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// NewProduct page components
import FormField from "components/FormField";
import { useSelector } from "react-redux";
import { MenuItem } from "@mui/material";
import { admin_state } from "slices";
import { useGetEstadosQuery } from "services";

function Inventario({ formData }) {
  const { formField, values, errors, touched, handleChange } = formData;

  const { llave, numero_de_inventario, marca, modelo, descripcion, estado } =
    formField;

  const { data: estados_activos } = useGetEstadosQuery(
    {
      filter_params: {
        query: "{id,nombre,url}",
      },
      shape: {
        id: "id",
        nombre: "nombre",
        url: "url",
      },
    },
    {
      refetchOnReconnect: true,
    },
  );

  const {
    llave: llaveV,
    numero_de_inventario: numero_de_inventarioV,
    marca: marcaV,
    modelo: modeloV,
    descripcion: descripcionV,
    estado: estadoV,
  } = values;

  const { clone } = useSelector(admin_state);

  return (
    <MDBox>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormField
            disabled={clone ? true : false}
            type={descripcion.type}
            name={descripcion.name}
            label={descripcion.label}
            value={descripcionV}
            error={errors.descripcion && touched.descripcion}
            success={descripcionV.length > 0 && !errors.descripcion}
            multiline={true}
            rows={3}
            wrap="soft"
            sx={{
              textAlign: "justify",
              marginRight: "35px",
            }}
            onInput={(e) =>
              (e.target.value = ("" + e.target.value).toUpperCase())
            }
          />
        </Grid>
      </Grid>

      <MDBox mt={1}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <FormField
              select
              disabled={clone ? true : false}
              id="estado"
              value={estadoV}
              label={estado.label}
              name={estado.name}
              error={errors.estado && touched.estado}
              success={estadoV.length > 0 && !errors.estado}
              onChange={handleChange}
            >
              <MenuItem key={""} value={""}>
                No Seleccionado
              </MenuItem>
              {estados_activos?.map((estado) => (
                <MenuItem key={estado.id} value={estado.url}>
                  {estado.nombre}
                </MenuItem>
              ))}
            </FormField>
          </Grid>
          <Grid item xs={12} sm={4.5}>
            <FormField
              type={llave.type}
              name={llave.name}
              label={llave.label}
              value={llaveV}
              error={errors.llave && touched.llave}
              success={llaveV.length > 0 && !errors.llave}
              onInput={(e) =>
                (e.target.value = ("" + e.target.value).toUpperCase())
              }
              disabled={clone ? true : false}
            />
          </Grid>
          <Grid item xs={12} sm={4.5}>
            <FormField
              type={numero_de_inventario.type}
              name={numero_de_inventario.name}
              value={numero_de_inventarioV}
              label={numero_de_inventario.label}
              validate={(value) =>
                clone && value === clone?.numero_de_inventario
                  ? "El número de inventario no puede ser el mismo"
                  : ""
              }
              error={
                errors.numero_de_inventario && touched.numero_de_inventario
              }
              success={
                numero_de_inventarioV.length > 0 && !errors.numero_de_inventario
              }
              onInput={(e) =>
                (e.target.value = ("" + e.target.value).toUpperCase())
              }
              disabled={values.resourcetype === "Consumible" ? true : false}
            />
          </Grid>
        </Grid>
      </MDBox>

      <MDBox mt={1}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormField
              disabled={clone ? true : false}
              type={marca.type}
              name={marca.name}
              value={marcaV}
              label={marca.label}
              error={errors.marca && touched.marca}
              success={marcaV.length > 0 && !errors.marcas}
              onInput={(e) =>
                (e.target.value = ("" + e.target.value).toUpperCase())
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              disabled={clone ? true : false}
              type={modelo.type}
              label={modelo.label}
              name={modelo.name}
              value={modeloV}
              error={errors.modelo && touched.modelo}
              success={modeloV.length > 0 && !errors.modelo}
              onInput={(e) =>
                (e.target.value = ("" + e.target.value).toUpperCase())
              }
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default Inventario;
