import {createApi} from "@reduxjs/toolkit/query/react";
import customFetchBase from "services/principal/customFetchBase";
import {buildFiltersUrl, reshape_response} from "../../helpers/functions";

export const gruposAPI = createApi({
  reducerPath: "gruposAPI",
  baseQuery: customFetchBase,
  tagTypes: ["Grupos"],
  endpoints: (builder) => ({
    getGrupos: builder.query({
      query: ({ filter_params }) => ({
        url: buildFiltersUrl("/v1/api/management/grupos/", filter_params),
        method: "GET",
      }),
      providesTags: (result) =>
        result
          ? [
            ...result.map(({ id }) => ({
              type: "Grupos",
              id,
            })),
            { type: "Grupos", id: "LIST" },
          ]
          : [{ type: "Grupos", id: "LIST" }],
      transformResponse: (response, meta, args) => {
        if (Array.isArray(response)) {
          return response?.map((el) => reshape_response(el, args?.shape));
        } else {
          const resultsTransformed = response?.results?.map((el) =>
            reshape_response(el, args?.shape)
          );
          
          // Devolvemos los resultados transformados junto con las propiedades de paginación
          return {
            results: resultsTransformed,
            count: response.count,
            next: response.next,
            previous: response.previous,
          };
        }
      },
    }),
    getGrupoById: builder.query({
      query: (id) => ({
        url: `/v1/api/management/grupos/${id}/`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "Grupos", id }],
    }),
    
    createGrupo: builder.mutation({
      query: (body) => ({
        url: "/v1/api/management/grupos/",
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "Grupos", id: "LIST" }],
    }),
    
    deleteGrupo: builder.mutation({
      query: (id) => ({
        url: `/v1/api/management/grupos/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Grupos", id: "LIST" }],
    }),
    
    editGrupo: builder.mutation({
      query: (body) => ({
        url: `/v1/api/management/grupos/${body.id}/`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, { id }) =>
        result
          ? [
            { type: "Grupos", id },
            { type: "Grupos", id: "LIST" },
          ]
          : [{ type: "Grupos", id: "LIST" }],
    }),
    
    getPermisos: builder.query({
      query: () => ({
        url: "/v1/api/management/permisos/",
        method: "GET",
      }),
      providesTags: (result) =>
        result
          ? [
            ...result.map(({ id }) => ({
              type: "Permisos",
              id,
            })),
            { type: "Permisos", id: "LIST" },
          ]
          : [{ type: "Permisos", id: "LIST" }],
    }),
    
    getPermisoById: builder.query({
      query: (id) => ({
        url: `/v1/api/management/permisos/${id}/`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "Permisos", id }],
    }),
  }),
});

export const {
  useGetGruposQuery,
  useGetPermisosQuery,
  
  useCreateGrupoMutation,
  useEditGrupoMutation,
  useDeleteGrupoMutation,
  
  useLazyGetGrupoByIdQuery,
  useLazyGetPermisoByIdQuery,
} = gruposAPI;
