import { Autocomplete, Grid } from "@mui/material";
import FormField from "components/FormField";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React from "react";
import { useGetAsignaturasQuery } from "../../../../../services";
import { useRedirectForm } from "../../../../../hooks/useRedirectForm";

export default function AsignaturaForm({ carreraId, formData }) {
  const { values, errors, touched, setFieldValue } = formData;

  const handleChange = (event, values) => {
    setFieldValue("dependencias", values);
  };

  const {
    data: asignaturas,
    isError: isErrorG,
    isLoading: isLoadingG,
    error: errorG,
  } = useGetAsignaturasQuery(
    {
      filter_params: {
        carrera: carreraId,
        query:
          "{id,url,nombre, identificador, clave, dependencias{id, url,nombre,identificador, clave}}",
      },
      shape: {
        id: "id",
        url: "url",
        nombre: "nombre",
        identificador: "identificador",
        clave: "clave",
      },
    },
    {
      refetchOnReconnect: true,
    },
  );

  useRedirectForm(isLoadingG, null, isErrorG, errorG);

  return (
    <MDBox>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <MDTypography variant="h6" textAlign="center">
            Asignaturas
          </MDTypography>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <FormField
            type={"text"}
            label={"Nombre"}
            name={"nombre"}
            value={values.nombre}
            error={errors.nombre && touched.nombre}
            success={values.nombre.length > 0 && !errors.nombre}
            onInput={(e) =>
              (e.target.value = ("" + e.target.value).toUpperCase())
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <FormField
            type={"tel"}
            label={"Identificador"}
            name={"identificador"}
            value={values.identificador}
            error={
              errors.identificador &&
              touched.identificador &&
              values.identificador < 0
            }
            success={values.identificador > 0 && !errors.identificador}
            onInput={(e) => {
              let value = ("" + e.target.value).replace(/[^(\d|\.)]/g, "");
              e.target.value = isNaN(Number(value)) ? 0 : Number(value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormField
            type="text"
            label={"Clave"}
            name={"clave"}
            value={values.clave}
            error={errors.clave && touched.clave && values.clave < 0}
            success={values.clave?.length > 0 && !errors.clave}
            onInput={(e) =>
              (e.target.value = ("" + e.target.value).toUpperCase())
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <FormField
            type={"tel"}
            label={"% aprobatorio de asistencia"}
            name={"asistencia_requerida"}
            value={values.asistencia_requerida}
            error={errors.asistencia_requerida && touched.asistencia_requerida}
            success={
              values.asistencia_requerida >= 0 &&
              values.asistencia_requerida <= 100 &&
              !errors.asistencia_requerida
            }
            onInput={(e) => {
              let value = ("" + e.target.value).replace(/[^(\d|\.)]/g, "");
              e.target.value = isNaN(Number(value)) ? 0 : Number(value);
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Autocomplete
            disabled={asignaturas?.length === 0}
            multiple
            id="asignaturas-seriadas"
            options={
              asignaturas?.filter(
                (el) =>
                  values.identificador !== el.identificador &&
                  values.clave !== el.clave,
              ) ?? []
            }
            onChange={handleChange}
            value={
              asignaturas?.filter((option) =>
                values.dependencias.some((el) => el.url === option.url),
              ) || []
            }
            isOptionEqualToValue={(option, value) => {
              return `${option.nombre}` === `${value.nombre}`;
            }}
            getOptionLabel={(option) => `${option.nombre}`}
            renderInput={(params) => (
              <FormField
                id="dependencias"
                name={"dependencias"}
                label="Asignar dependencias"
                {...params}
              />
            )}
          />
        </Grid>
      </Grid>
    </MDBox>
  );
}
