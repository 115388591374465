import { firmaCursosApi } from "../view/firmaDeCursos.jsx";
import { estudiantesPorCursoApi } from "services";

export const deleteFirmaCurso = firmaCursosApi.injectEndpoints({
  endpoints: (builder) => ({
    deleteFirmaCurso: builder.mutation({
      query: (id) => {
        return {
          url: `/v1/api/firmaconstanciacurso/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "FirmaCursos", id: "LIST" }],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          queryFulfilled.then(() => {
            dispatch(
              estudiantesPorCursoApi.util.invalidateTags([
                { type: "EstudiantesPorCurso", id: "LIST" },
              ])
            );
          });
        } catch (error) {
          console.log(error);
        }
      },
    }),
  }),
});

export const { useDeleteFirmaCursoMutation } = deleteFirmaCurso;
