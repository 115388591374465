import customFetchBase from "../principal/customFetchBase";
import {createApi} from "@reduxjs/toolkit/query/react";
import {
  appendFormData,
  buildFiltersUrl,
  reemplazarUrl,
  reshape_response,
  transformTelefonos,
} from "../../helpers/functions";
import {estadoPorEstudianteApi} from "./distribuciones/distribucionDeEstadoPorEstudiante.service";
import {enqueueSnackbar} from "notistack";
import {showErrorMessages} from "../../hooks/useRedirectForm";
import {ESTUDIANTE} from "../../roles";
import _ from "lodash";

export const estudianteApi = createApi({
  reducerPath: "estudianteApi",
  baseQuery: customFetchBase,
  tagTypes: ["Estudiante", "Genero"],
  endpoints: (builder) => ({
    getEstudiantes: builder.query({
      query: ({ filter_params }) => ({
        url: buildFiltersUrl("/v2/api/estudiante/", filter_params),
        method: "GET",
      }),
      providesTags: (result) =>
        Array.isArray(result)
          ? [
            ...result.map(({ id }) => ({
              type: "Estudiante",
              id,
            })),
            { type: "Estudiante", id: "LIST" },
          ]
          : [{ type: "Estudiante", id: "LIST" }],
      transformResponse: (response, meta, args) => {
        if (Array.isArray(response)) {
          return response?.map((el) => reshape_response(el, args.shape));
        } else {
          const resultsTransformed = response?.results?.map((el) =>
            reshape_response(el, args.shape)
          );
          
          // Devolvemos los resultados transformados junto con las propiedades de paginación
          return {
            results: resultsTransformed,
            count: response.count,
            next: response.next,
            previous: response.previous,
          };
        }
      },
    }),
    getEstudianteById: builder.query({
      query: ({ id, filter_params }) => ({
        url: buildFiltersUrl(`/v2/api/estudiante/${id}/`, filter_params),
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "Estudiante", id }],
    }),
    createEstudiante: builder.mutation({
      query: (body) => {
        let bodyMutated = _.omit(body, ["numero_de_control", "genero", "curp", "password_wifi", "usuario_wifi", "estado", "ciclo", "tipo_sangre", "preasignacion_de_carrera"])
        
        if (bodyMutated.telefonos) bodyMutated.telefonos = transformTelefonos(body.telefonos);
        
        const extraFields = {
          correo: body.numero_de_control + JSON.parse(sessionStorage.getItem("user"))?.perfil.entidad.dominio_de_correo,
          username: body.numero_de_control,
        };
        
        const formData = new FormData();
        Object.entries({ ...bodyMutated, ...extraFields }).forEach(([key, value]) => {
          appendFormData(formData, value, key);
        });
        
        return {
          url: "/v3/api/registro/",
          method: "POST",
          body: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
      },
      invalidatesTags: [{ type: "Estudiante", id: "LIST" }],
      async onQueryStarted({ ciclo, estado, ...rest }, { dispatch, queryFulfilled, getCacheEntry }) {
        const mutatedArgs = _.omit(rest, ["nombre", "telefonos", "apellido_materno", "apellido_paterno", "groups", "foto", "url_cambio_password"])
        try {
          await queryFulfilled;
          
          const { data: { perfil: { resourcetype } } } = getCacheEntry()
          
          const student = resourcetype.find(el => el.resourcetype === ESTUDIANTE)
          
          if (student) {
            await dispatch(estudianteApi.endpoints.editEstudiante.initiate({ id: student.id, ...mutatedArgs }))
            
            if (ciclo !== "")
              await dispatch(
                estadoPorEstudianteApi.endpoints.createEstadoPorEstudiante.initiate(
                  {
                    ciclo,
                    estado: estado ?? "Activo",
                    estudiante: reemplazarUrl(student.url, 1),
                  }
                )
              );
            enqueueSnackbar("Nuevo estudiante creado", {
              variant: "success",
            });
          }
        } catch (error) {
          showErrorMessages(error.error, enqueueSnackbar);
        }
      },
    }),
    bulkCreate: builder.mutation({
      query: (data) => ({
        url: "/v2/api/estudiante/bulk_create/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "Estudiante", id: "LIST" }],
    }),
    deleteEstudiante: builder.mutation({
      query: (id) => ({
        url: `/v2/api/estudiante/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Estudiante", id: "LIST" }],
    }),
    editEstudiante: builder.mutation({
      query: (body) => {
        let { id, ...rest } = body;
        
        if (rest.telefonos) rest.telefonos = transformTelefonos(rest.telefonos);
        
        const formData = new FormData();
        Object.entries(rest).forEach(([key, value]) => {
          appendFormData(formData, value, key);
        });
        
        return {
          url: `/v2/api/estudiante/${id}/`,
          method: "PATCH",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          body: formData,
        };
      },
      invalidatesTags: (result, error, { id }) =>
        result
          ? [
            { type: "Estudiante", id },
            { type: "Estudiante", id: "LIST" },
          ]
          : [{ type: "Estudiante", id: "LIST" }],
    }),
    getGenero: builder.query({
      query: () => ({
        url: "/v1/api/genero/",
        method: "GET",
      }),
      providesTags: (result) =>
        result
          ? [
            ...result.map(({ id }) => ({
              type: "Genero",
              id,
            })),
            { type: "Genero", id: "LIST" },
          ]
          : [{ type: "Genero", id: "LIST" }],
    }),
    getGeneroById: builder.query({
      query: (id) => ({
        url: `/v1/api/genero/${id}/`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "Genero", id }],
    }),
  }),
});

export const {
  useGetEstudiantesQuery,
  useLazyGetEstudiantesQuery,
  useCreateEstudianteMutation,
  useEditEstudianteMutation,
  useDeleteEstudianteMutation,
  useLazyGetEstudianteByIdQuery,
  useBulkCreateMutation,
  useGetGeneroQuery,
  useLazyGetGeneroByIdQuery,
} = estudianteApi;
