/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React from "react";
import { createRoot } from "react-dom/client";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import store from "app/store";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";

// Material Dashboard 2 PRO React Context Provider
import { MaterialUIControllerProvider } from "context";

const root = createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <MaterialUIControllerProvider>
        <SnackbarProvider
          preventDuplicate
          dense
          maxSnack={2}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          autoHideDuration={2500}
        >
          <App />
        </SnackbarProvider>
      </MaterialUIControllerProvider>
    </BrowserRouter>
  </Provider>
);
