//React components hooks and others
import { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// NewActivo page components
import ProductInfo from "./components/ProductInfo/product_info";
import Inventario from "./components/Inventario/inventario";

//Schemas de datos
import form from "./schemas/form";
import validations from "./schemas/validations";
import initialValues from "./schemas/initialValues";

import Media from "./components/Multimedia/media";

import { admin_state, setNoHereAdmin } from "slices";
import { useCreateActivoMutation, useEditActivoMutation } from "services";
import { ACTIVOS_CONSUMIBLES_URL } from "config/bases_url";
import { useRedirectForm } from "hooks/useRedirectForm";
import FormErrorHandler from "../../../../handlers/FormErrorHandler/form-error.handler";
import { ErrorBundary } from "../../../../config/ErrorBundary/error-bundary";

function getSteps() {
  return ["1. Artículo", "2. Inventario", "3. Multimedia"];
}

const decimal = (amount) => {
  let formattedMoney = parseFloat(amount).toFixed(2);
  if (!formattedMoney.includes(".")) {
    formattedMoney += ".00";
  } else if (formattedMoney.split(".")[1].length === 1) {
    formattedMoney += "0";
  } else if (formattedMoney.split(".")[1].length > 2) {
    formattedMoney = parseFloat(amount).toFixed(2);
  }
  return formattedMoney;
};

const filterPhotoData = (data) => {
  return Object.fromEntries(
    Object.entries(data).filter(
      ([k, v]) => !(k === "foto" && (typeof v === "string" || v === null)),
    ),
  );
};

const filterUnchangedData = (data, editData) => {
  return Object.fromEntries(
    Object.entries(data).filter(
      ([k, v]) => k === "resourcetype" || editData[k] !== v,
    ),
  );
};

function getStepContent(stepIndex, formData, selected, setSelected) {
  switch (stepIndex) {
    case 0:
      return <ProductInfo formData={formData} />;
    case 1:
      return <Inventario formData={formData} />;
    case 2:
      return <Media formData={formData} />;
    default:
      return null;
  }
}

function NewConsumible() {
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];

  const [
    createActivo,
    {
      isSuccess: isSuccessC,
      isLoading: isLoadingC,
      isError: isErrorC,
      error: errorC,
    },
  ] = useCreateActivoMutation();
  const [
    editActivo,
    {
      isSuccess: isSuccessE,
      isLoading: isLoadingE,
      isError: isErrorE,
      error: errorE,
    },
  ] = useEditActivoMutation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { edit, noHere } = useSelector(admin_state);

  const handleBack = () => setActiveStep(activeStep - 1);

  useEffect(() => {
    if (noHere) {
      navigate(ACTIVOS_CONSUMIBLES_URL);
    }
  }, [edit]);

  useRedirectForm(
    isLoadingC,
    isSuccessC,
    isErrorC,
    errorC,
    "Activo creado",
    ACTIVOS_CONSUMIBLES_URL,
  );

  useRedirectForm(
    isLoadingE,
    isSuccessE,
    isErrorE,
    errorE,
    "Activo editado",
    ACTIVOS_CONSUMIBLES_URL,
  );

  const submitForm = async (values, actions) => {
    try {
      // Crear o editar activo
      if (edit === null) {
        await createActivo(filterPhotoData(values));
        dispatch(setNoHereAdmin(true));
      } else {
        const editedData = filterPhotoData(filterUnchangedData(values, edit));
        await editActivo({ id: edit.id, ...editedData });
        dispatch(setNoHereAdmin(true));
      }

      // Limpiar formulario y manejar redirección
      actions.setSubmitting(false);
      actions.resetForm();
      if (isLastStep) {
        navigate(ACTIVOS_CONSUMIBLES_URL);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const fillValues = (data, setFieldValue) => {
    function unWantedKeyAndValue([key, value]) {
      const unWantedKeys = [
        "foto",
        "valor",
        "tipo",
        "estado",
        "unidad_de_medida",
        "metodo_de_adquisicion",
      ];
      return !unWantedKeys.includes(key) && typeof value !== "object";
    }

    const filteredData = Object.fromEntries(
      Object.entries(data).filter(unWantedKeyAndValue),
    );

    Object.entries(filteredData).forEach(([k, v]) => {
      setFieldValue(formField[k]?.name, v, true);
    });

    setFieldValue(
      formField["metodo_de_adquisicion"].name,
      data.metodo_de_adquisicion.url,
      true,
    );
    setFieldValue(formField["tipo"].name, data.tipo.url, true);
    setFieldValue(
      formField["unidad_de_medida"].name,
      data.unidad_de_medida.url,
      true,
    );
    setFieldValue(formField["foto"].name, data.foto);
    setFieldValue(formField["estado"].name, data.estado.url, true);
    setFieldValue(formField["valor"]?.name, decimal(data.valor), true);
  };

  return (
    <MDBox mt={5} mb={9}>
      <Grid container justifyContent="center">
        <Grid item xs={12} lg={8}>
          <MDBox mt={6} mb={8} textAlign="center">
            <MDBox mb={1}>
              <MDTypography variant="h3" fontWeight="bold">
                {!edit ? "Agregar consumible" : "Editar consumible"}
              </MDTypography>
            </MDBox>
          </MDBox>
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              isSubmitting,
              setFieldValue,
              handleChange,
            }) => {
              useEffect(() => {
                if (edit !== null) {
                  fillValues(edit, setFieldValue);
                }
              }, [edit]);

              return (
                <ErrorBundary renderOnError={() => <FormErrorHandler />}>
                  <Form id={formId} autoComplete="off">
                    <Card sx={{ height: "100%" }}>
                      <MDBox mt={-3} mb={3} mx={2}>
                        <Stepper activeStep={activeStep} alternativeLabel>
                          {steps.map((label) => (
                            <Step key={label}>
                              <StepLabel>{label}</StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                      </MDBox>
                      <MDBox p={2}>
                        <MDBox>
                          {getStepContent(activeStep, {
                            values,
                            touched,
                            formField,
                            errors,
                            setFieldValue,
                            handleChange,
                          })}
                          <MDBox
                            mt={3}
                            width="100%"
                            display="flex"
                            justifyContent="space-between"
                          >
                            <MDButton
                              onClick={(e) =>
                                activeStep === 0
                                  ? navigate(ACTIVOS_CONSUMIBLES_URL)
                                  : handleBack()
                              }
                              type="button"
                              variant="gradient"
                              color={activeStep === 0 ? "primary" : "dark"}
                            >
                              {activeStep === 0 ? "cancelar" : "atrás"}
                            </MDButton>
                            {activeStep !== 0 && (
                              <MDButton
                                onClick={(e) =>
                                  navigate(ACTIVOS_CONSUMIBLES_URL)
                                }
                                type="button"
                                variant="gradient"
                                color="primary"
                              >
                                cancelar
                              </MDButton>
                            )}
                            <MDButton
                              type="submit"
                              variant="gradient"
                              color="dark"
                            >
                              {isLastStep ? "aceptar" : "siguiente"}
                            </MDButton>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </Card>
                  </Form>
                </ErrorBundary>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default NewConsumible;
