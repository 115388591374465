import * as React from "react";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import FormModal from "./components/Form/form_modal";
import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import validations from "./schemas/validations";
import { useAsignarActivosMutation } from "services";
import ModalBase from "components/ModalBase/modal_base";
import { useCloseModal } from "hooks/useCloseModal";

const ModalAsig = ({ open, handleClose, activo }) => {
  const currentValidation = validations[0];
  const { formId, formField } = form;
  const [asignarActivos, { isSuccess, isError, isLoading, error }] =
    useAsignarActivosMutation();

  const submitForm = async (values, actions) => {
    await asignarActivos({
      activo: [activo],
      fecha: values.fecha,
      cantidad: values.cantidad,
      personal: values.personal,
    })
      .unwrap()
      .then(() => {
        actions.setSubmitting(false);
        actions.resetForm();
      });
  };
  useCloseModal(
    isLoading,
    isSuccess,
    isError,
    error,
    "Activo asignado correctamente",
    handleClose
  );

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
  };

  return (
    <ModalBase open={open} handleClose={handleClose}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} lg={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              isValid,
              setFieldValue,
              handleBlur,
              handleChange,
            }) => (
              <Form key={formId} id={formId} autoComplete="off">
                <Card key={formId} sx={{ height: "100%" }}>
                  <MDBox p={3}>
                    <MDBox>
                      <FormModal
                        formData={{
                          values,
                          touched,
                          errors,
                          setFieldValue,
                          handleBlur,
                          handleChange,
                          formField,
                        }}
                      />
                      <MDBox
                        mt={2}
                        width="100%"
                        display="flex"
                        justifyContent="flex-end"
                      >
                        <MDButton
                          disabled={!isValid}
                          type="submit"
                          variant="gradient"
                          color="dark"
                        >
                          aceptar
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </Card>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </ModalBase>
  );
};

ModalAsig.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ModalAsig;
