import {profesorApi} from "../view/profesor.service";
import {appendFormData, transformTelefonos} from "../../../../helpers/functions";


export const editProfesor = profesorApi.injectEndpoints({
  endpoints: (builder) => ({
    editProfesor: builder.mutation({
      query: (body) => {
        let {id, ...rest} = body;

        if (rest.telefonos)
          rest.telefonos = transformTelefonos((rest.telefonos))

        const formData = new FormData()
        Object.entries(rest).forEach(([key, value]) => {
          appendFormData(formData, value, key);
        });

        return {
          url: `/v1/api/docente/${id}/`,
          method: "PATCH",
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          body: formData,
        };
      },
      invalidatesTags: (result, error, {id}) =>
        result
          ? [
            {type: "Profesor", id},
            {type: "Profesor", id: "LIST"},
          ]
          : [{type: "Profesor", id: "LIST"}],
    }),
  }),
})

export const {
  useEditProfesorMutation
} = editProfesor;