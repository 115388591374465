import { firmaBoletaApi } from "../view/firmaDeBoletaDeNotas.jsx";
import { estudiantesPorGrupoApi } from "../../../distribuciones/distrubucionDeEstudiantesPorGrupo.service.jsx";

export const deleteBoleta = firmaBoletaApi.injectEndpoints({
  endpoints: (builder) => ({
    deleteBoleta: builder.mutation({
      query: (id) => {
        return {
          url: `/v1/api/firmadeboletadenota/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "Boletas", id: "LIST" }],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          queryFulfilled.then(() => {
            dispatch(
              estudiantesPorGrupoApi.util.invalidateTags([
                { type: "EstudiantesPorGrupo", id: "LIST" },
              ])
            );
          });
        } catch (error) {
          console.log(error);
        }
      },
    }),
  }),
});

export const { useDeleteBoletaMutation } = deleteBoleta;
