// @mui material components
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewProduct page components
import {useSelector} from "react-redux";
import ImgComponent from "components/MDImage/image";
import PropTypes from "prop-types";
import image404 from "assets/images/404.jpg";
import {admin_state} from "slices";
import {Tooltip} from "@mui/material";
import {useSnackbar} from "notistack";

function Media({ formData }) {
  const { formField, values, setFieldValue } = formData;

  const { foto } = formField;

  const { foto: fotoV } = values;

  const { edit, clone } = useSelector(admin_state);

  const style = {
    width: "100%",
    height: "auto",
    cursor: !clone ? "pointer" : "default",
    borderRadius: "0.5rem",
    marginTop: "0.5rem",
    marginLeft: "0.5rem",
  };

  const imageFail = {
    image: image404,
    alt: "No existe",
  };

  const { enqueueSnackbar } = useSnackbar();
  const handleChange = (e, isValid) => {
    if (isValid) setFieldValue(foto.name, e.target.files[0]);
    else {
      enqueueSnackbar("El tamaño de la imagen no debe ser mayor a 1MB", {
        variant: "error",
        autoHideDuration: 4000,
      });
    }
  };

  const existPicture = () => {
    if (edit) {
      return edit?.foto;
    } else if (clone) {
      return clone?.foto;
    }
  };

  return (
    <MDBox>
      <MDTypography
        variant="h5"
        fontWeight="bold"
        flex
        justifyContent="center"
        textAlign="center"
      >
        Haga click para agregar una imagen
      </MDTypography>
      <MDBox mt={1.625} display="flex" justifyContent="center">
        <Tooltip
          placement="top"
          title={
            !clone
              ? edit
                ? fotoV
                  ? "Cambiar Foto"
                  : "Agregar imagen"
                : "Agregar imagen"
              : null
          }
        >
          <MDBox
            style={{
              position: "relative",
              width: "200px",
              cursor: clone === null ? "default" : "pointer",
            }}
          >
            <ImgComponent
              imageFail={imageFail}
              id="activo-image"
              htmlFor="activo-image"
              disabled={clone ? true : false}
              style={style}
              src={
                fotoV && typeof fotoV === "object"
                  ? URL.createObjectURL(fotoV)
                  : existPicture()
              }
              imageTypes={foto.accept}
              handleChange={handleChange}
            />
          </MDBox>
        </Tooltip>
      </MDBox>
    </MDBox>
  );
}

Media.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
};

export default Media;
