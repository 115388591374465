import { useState } from "react";

import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 PRO React contexts
import { setMiniSidenav, useMaterialUIController } from "context";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Outlet } from "react-router-dom";

import ModalQr from "components/ModalQR/modal.qr";
import useModalHandler from "../hooks/useModalHandler";

function MainLayout({ routes }) {
  const {
    open: openQr,
    handleOpen: handleOpenQr,
    handleClose: handleCloseQr,
  } = useModalHandler();

  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const qrScanButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleOpenQr}
    >
      <Icon fontSize="small" color="inherit">
        qr_code_scanner
      </Icon>
    </MDBox>
  );

  return (
    <>
      <Sidenav
        color={sidenavColor}
        brandName={"Tecnoschool ™"}
        routes={routes}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      />
      <Configurator />
      {window.screen.width <= 768 && (
        <>
          {qrScanButton}
          <ModalQr open={openQr} handleClose={handleCloseQr} />
        </>
      )}
      <DashboardLayout>
        <DashboardNavbar />
        <Outlet />
      </DashboardLayout>
    </>
  );
}

export default MainLayout;
