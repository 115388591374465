import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tab: "ordinaria",
  carreraSelect:{},
  cicloActivo: "",
};

export const calificacionesSlice = createSlice({
  name: "calificaciones",
  initialState,
  reducers: {
    setTab: (state, action) => {
      state.tab = action.payload;
    },
    setCarreraSelect: (state, action) => {
      state.carreraSelect = action.payload;
    },
    setCicloActivo: (state, action) => {
      state.cicloActivo = action.payload;
    },
  },
});

export const { setTab, setCarreraSelect, setCicloActivo } =
  calificacionesSlice.actions;

export const calificaciones = (state) => state.calificaciones;

export default calificacionesSlice.reducer;
