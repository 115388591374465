import {profesorApi} from "../view/profesor.service";


export const deleteProfesor = profesorApi.injectEndpoints({
    endpoints: (builder) => ({
        deleteProfesor: builder.mutation({
            query: (id) => ({
                url: `/v1/api/docente/${id}/`,
                method: "DELETE",
            }),
            invalidatesTags: [{type: "Profesor", id: "LIST"}],
        }),
    }),
})

export const {
    useDeleteProfesorMutation
} = deleteProfesor;