import {cursosApi} from "../view/cursos.service";

export const getModalidades = cursosApi.injectEndpoints({
    endpoints: (builder) => ({
        getModalidades: builder.query({
            query: () => ({
                url: `/v1/api/modalidaddecurso/`,
                method: "GET",
            }),
            providesTags: (result) =>
                Array.isArray(result)
                    ? [
                        ...result.map(({id}) => ({
                            type: "Modalidades",
                            id,
                        })),
                        {type: "Modalidades", id: "LIST"},
                    ]
                    : [{type: "Modalidades", id: "LIST"}],
        }),
    }),
})

export const {
    useGetModalidadesQuery,
} = getModalidades;