/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// NewUser page components
import FormField from "components/FormField";

function AddAsignatura({ formData }) {
  const { formField, values, errors, touched } = formData;
  const { nombre, identificador, clave, asistencia_requerida } = formField;
  const {
    asistencia_requerida: asistencia_requeridaV,
    nombre: nombreV,
    clave: claveV,
    identificador: identificadorV,
  } = values;

  return (
    <MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              type={nombre.type}
              label={nombre.label}
              name={nombre.name}
              value={nombreV}
              placeholder={nombre.placeholder}
              error={errors.nombre && touched.nombre}
              success={nombreV?.length > 0 && !errors.nombre}
              onInput={(e) =>
                (e.target.value = ("" + e.target.value).toUpperCase())
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={identificador.type}
              label={identificador.label}
              name={identificador.name}
              value={identificadorV}
              placeholder={identificador.placeholder}
              error={errors.identificador && touched.identificador}
              success={identificadorV?.length > 0 && !errors.identificador}
              onInput={(e) =>
                (e.target.value = ("" + e.target.value).toUpperCase())
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={clave.type}
              label={clave.label}
              name={clave.name}
              value={claveV}
              placeholder={clave.placeholder}
              error={errors.clave && touched.clave}
              success={claveV?.length > 0 && !errors.clave}
              onInput={(e) =>
                (e.target.value = ("" + e.target.value).toUpperCase())
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              type={asistencia_requerida.type}
              label={asistencia_requerida.label}
              name={asistencia_requerida.name}
              value={asistencia_requeridaV}
              error={
                errors.asistencia_requerida && touched.asistencia_requerida
              }
              success={
                asistencia_requeridaV >= 0 &&
                asistencia_requeridaV <= 100 &&
                !errors.asistencia_requerida
              }
              onInput={(e) => {
                let value = ("" + e.target.value).replace(/[^(\d|\.)]/g, "");
                e.target.value = isNaN(Number(value)) ? 0 : Number(value);
              }}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for UserInfo
AddAsignatura.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default AddAsignatura;
