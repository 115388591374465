import forge from "node-forge";

// Función para descifrar la llave privada con la contraseña
export async function decryptPrivateKeyFromFile(file, password) {
  try {
    const binaryKey = await readFileAsArrayBuffer(file);
    const pemKey = convertBinaryToPem(binaryKey);
    const privateKey = decryptPrivateKey(pemKey, password);
    if (privateKey === null) {
      throw new Error("Contraseña incorrecta o formato de llave inválido.");
    }
    return privateKey;
  } catch (error) {
    throw error;
  }
}

function convertBinaryToPem(binaryKey) {
  // Convertir el ArrayBuffer a una cadena Base64
  const base64Key = btoa(
    String.fromCharCode.apply(null, new Uint8Array(binaryKey))
  );

  // Dividir la cadena Base64 en líneas de 64 caracteres
  const base64Lines = base64Key.match(/.{1,64}/g).join("\n");

  // Crear la cadena PEM
  const pemKey =
    "-----BEGIN ENCRYPTED PRIVATE KEY-----\n" +
    base64Lines +
    "\n-----END ENCRYPTED PRIVATE KEY-----";

  return pemKey;
}

function decryptPrivateKey(pemKey, password) {
  // Descifrar la llave privada usando Forge
  const privateKey = forge.pki.decryptRsaPrivateKey(pemKey, password);

  return privateKey;
}

function readFileAsArrayBuffer(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      resolve(event.target.result);
    };

    reader.onerror = (error) => {
      reject(new Error("Error leyendo el archivo: ", error));
    };

    reader.readAsArrayBuffer(file);
  });
}

export function signData(privateKey, data) {
  const md = forge.md.sha256.create();
  md.update(data, "utf8");

  const signature = privateKey.sign(md);
  return forge.util.encode64(signature);
}

// Esta parte es para la verificacion de la firma
// export function signData(privateKey, data, publicKey) {
//   const md = forge.md.sha256.create();
//   md.update(data, "utf8");

//   const signature = privateKey.sign(md);
//   const verified = publicKey.verify(md.digest().bytes(), signature);
//   if (verified) {
//     return forge.util.encode64(signature);
//   } else {
//     throw new Error("La firma no pudo ser verificada");
//   }
// }

// Función para cargar y decodificar la clave pública desde un archivo
// export async function loadPublicKeyFromFile(file) {
//   try {
//     const publicKeyDer = await readFileAsArrayBuffer(file);
//     const publicKeyAsn1 = forge.pkcs12.toPkcs12Asn1(publicKeyDer, {
//       algorithm: "3des",
//     });
//     const pkcs12 = forge.pkcs12.pkcs12FromAsn1(publicKeyAsn1);
//     const publicKey = pkcs12.safeContents[0].safeBags[1].cert;
//     return publicKey;
//   } catch (err) {
//     throw err;
//   }
// }
