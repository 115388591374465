import * as React from "react";
import { Card, CircularProgress, Grid, Icon } from "@mui/material";
import { ErrorMessage, Form, Formik } from "formik";
import cer from "../../../../assets/images/tecnoschool/cer.png";
import MDImage from "../../../../components/MDImage/image";
import FormField from "../../../../components/FormField";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import MDDropzone from "../../../../components/MDDropzone";
import { useEditFirmanteMutation } from "../../../../services";
import MDButton from "../../../../components/MDButton";
import ComponentsPrivatization from "../../../../config/component_privatization";
import { FIRMANTE } from "../../../../roles";
import * as Yup from "yup";
import { useRedirectForm } from "../../../../hooks/useRedirectForm";
import { HOME_URL } from "../../../../config/bases_url";
import no_foto from "../../../../assets/images/404.jpg";
import { useSnackbar } from "notistack";
import { getModifiedFields } from "helpers/functions";

const style = {
  maxWidth: "200px",
  cursor: "pointer",
  borderRadius: "0.5rem",
  marginTop: "0.5rem",
  alignItems: "center",
};

export default function FirmanteInfo() {
  const [convertir, { isSuccess, isLoading, isError, error }] =
    useEditFirmanteMutation();

  useRedirectForm(
    isLoading,
    isSuccess,
    isError,
    error,
    "Informacion de firmante subida con éxito",
    HOME_URL
  );

  const user_info = JSON.parse(sessionStorage.getItem("user"));
  const firmaFoto = user_info.perfil.firma;
  const [editar, setEditar] = React.useState(false);
  const originalValues = {
    firma: user_info.perfil.firma,
    certificado: user_info.perfil.certificado,
    cargo: user_info.perfil.cargo,
  };

  const { enqueueSnackbar } = useSnackbar();

  function filtrarPropiedadesObjeto(obj) {
    const objFiltrado = {};
    for (const propiedad in obj) {
      const valorPropiedad = obj[propiedad];
      if (
        typeof valorPropiedad === "string" &&
        valorPropiedad.startsWith("https://bucketeer")
      ) {
        return;
      } else {
        objFiltrado[propiedad] = valorPropiedad;
      }
    }
    return objFiltrado;
  }

  const submitForm = async (values, actions) => {
    try {
      const modifiedFields = getModifiedFields(originalValues, values);
      const noBucketeer = filtrarPropiedadesObjeto(modifiedFields);
      if (Object.keys(noBucketeer).length !== 0) {
        await convertir({
          id: user_info.perfil.resourcetype.find(
            (el) => el.resourcetype === FIRMANTE
          ).id,
          ...noBucketeer,
        });

        if (isSuccess) {
          actions.setSubmitting(false);
          actions.resetForm();
        }
        setEditar(!editar);
      } else {
        setEditar(!editar);
      }
    } catch (error) {
      console.error(error);
      actions.setSubmitting(true);
    }
  };
  const handleSubmit = (values, actions) => {
    if (editar) {
      submitForm(values, actions);
    } else {
      setEditar(!editar);
    }
  };
  return (
    <Card id="firmante-info" sx={{ overflow: "visible" }}>
      <MDBox
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
        p={3}
      >
        <Formik
          initialValues={{
            certificado: user_info.perfil.certificado,
            firma: user_info.perfil.firma,
            cargo: user_info.perfil.cargo ?? "",
          }}
          validationSchema={
            editar
              ? Yup.object().shape({
                  certificado: Yup.mixed().required(
                    "Debe subir un archivo .cer"
                  ),
                  firma: Yup.mixed().required("Una firma es requerida"),
                  cargo: Yup.string()
                    .required("El campo es requerido")
                    .max(
                      50,
                      "Asegúrese de que este campo no tenga más de 50 caracteres."
                    ),
                })
              : null
          }
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            setFieldValue,
            setFieldError,
            isValid,
            touched,
          }) => {
            const dropzoneOptions = {
              maxFilesize: 1,
              acceptedFiles: ".cer",
              dictDefaultMessage: "Suba su archivo .cer aqui",
              autoProcessQueue: false,
              init: function () {
                this.on("addedfile", (file) => {
                  const validExts = [".cer"];
                  let fileExt = file.name;
                  fileExt = fileExt.substring(fileExt.lastIndexOf("."));
                  if (!validExts.includes(fileExt)) {
                    setFieldError("file", "Solo se aceptan archivos .cer");
                  } else {
                    setFieldValue("certificado", file, true);
                  }
                });
              },
            };
            return (
              <Form
                style={{
                  width: "100%",
                }}
              >
                <MDBox display="flex" flexDirection="column">
                  <Grid item xs={12} sm={12}>
                    <FormField
                      disabled={!editar}
                      type={"text"}
                      label={"Cargo de la persona"}
                      name={"cargo"}
                      value={values.cargo}
                      error={errors.cargo && touched.cargo}
                      success={!errors.cargo && values.cargo.length > 0}
                      onInput={(e) =>
                        (e.target.value = ("" + e.target.value).toUpperCase())
                      }
                    />
                  </Grid>
                  <MDBox
                    mt={1.625}
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                  >
                    <MDBox
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      style={{
                        position: "relative",
                        width: "200px",
                        cursor: "pointer",
                        marginRight: "20px",
                      }}
                    >
                      <MDBox>
                        <MDTypography
                          variant="h6"
                          fontWeight="bold"
                          flex
                          justifyContent="center"
                          textAlign="center"
                        >
                          Agregar imagen de firma
                        </MDTypography>
                      </MDBox>

                      <MDImage
                        id="profile-image"
                        htmlFor="profile-image"
                        disabled={!editar}
                        style={style}
                        src={
                          values.firma && typeof values.firma === "object"
                            ? URL.createObjectURL(values.firma)
                            : firmaFoto
                        }
                        imageTypes={"file"}
                        handleChange={(e, isValid) => {
                          if (isValid)
                            setFieldValue("firma", e.target.files[0]);
                          else {
                            enqueueSnackbar(
                              "El tamaño de la imagen no debe ser mayor a 1MB",
                              {
                                variant: "error",
                                autoHideDuration: 4000,
                              }
                            );
                          }
                        }}
                      />
                      <MDTypography
                        fontWeight="regular"
                        variant={"caption"}
                        color={"error"}
                      >
                        <ErrorMessage name={"firma"} />
                      </MDTypography>
                    </MDBox>

                    {editar ? (
                      values.certificado === undefined ||
                      values.certificado === null ? (
                        <MDBox
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "4px",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <MDDropzone
                            options={dropzoneOptions}
                            file={!!values.certificado}
                            id="certificado"
                          />
                          <MDTypography
                            fontWeight="regular"
                            variant={"caption"}
                            color={"error"}
                          >
                            <ErrorMessage name={"certificado"} />
                          </MDTypography>
                        </MDBox>
                      ) : (
                        <MDBox
                          component="img"
                          src={cer}
                          variant="gradient"
                          bgColor="dark"
                          style={style}
                        />
                      )
                    ) : values.certificado === undefined ||
                      values.certificado === null ? (
                      <MDBox
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        style={{
                          position: "relative",
                          width: "200px",
                          cursor: "pointer",
                          marginRight: "20px",
                        }}
                      >
                        <MDBox>
                          <MDTypography
                            variant="h6"
                            fontWeight="bold"
                            flex
                            justifyContent="center"
                            textAlign="center"
                          >
                            Certificado digital no encontrado
                          </MDTypography>
                        </MDBox>
                        <MDImage
                          id="logo-image"
                          htmlFor="logo-image"
                          disabled={!editar}
                          style={style}
                          src={no_foto}
                        />
                      </MDBox>
                    ) : (
                      <MDBox
                        component="img"
                        src={cer}
                        variant="gradient"
                        bgColor="dark"
                        style={style}
                      />
                    )}

                    {isLoading && (
                      <CircularProgress
                        sx={{ marginBottom: "2rem", marginTop: "2rem" }}
                        color="inherit"
                      />
                    )}

                    {errors.file && (
                      <MDTypography
                        color="error"
                        variant="h6"
                        fontWeight="bold"
                        display="flex"
                        justifyContent="center"
                        textAlign="center"
                      >
                        <ErrorMessage name="file" />
                      </MDTypography>
                    )}
                  </MDBox>
                </MDBox>
                <ComponentsPrivatization permitted={[FIRMANTE]}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    mt={3}
                    display="flex"
                    justifyContent={editar ? "space-between" : "flex-end"}
                  >
                    {editar && (
                      <MDButton
                        type="submit"
                        variant="gradient"
                        color="error"
                        style={{
                          display: "flex",
                        }}
                        onClick={() => setFieldValue("certificado", null)}
                      >
                        <Icon fontSize={"medium"}>delete</Icon>&nbsp; Archivo
                      </MDButton>
                    )}
                    {editar && (
                      <MDButton
                        onClick={(e) => setEditar(!editar)}
                        variant="gradient"
                        color="primary"
                      >
                        cancelar
                      </MDButton>
                    )}
                    <MDButton
                      type="submit"
                      variant="gradient"
                      disabled={!isValid && editar}
                      color="dark"
                    >
                      {editar ? "subir" : "editar"}
                    </MDButton>
                  </Grid>
                </ComponentsPrivatization>
              </Form>
            );
          }}
        </Formik>
      </MDBox>
    </Card>
  );
}
