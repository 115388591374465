const form = {
  formId: "new-activo-form",
  formField: {
    resourcetype: {
      name: "resourcetype",
      label: "Tipo de Activo*",
      type: "text",
      errorMsg: "El tipo de activo es requerido.",
    },
    tipo: {
      name: "tipo",
      label: "Tipo de Catálogo*",
      type: "text",
      errorMsg: "El tipo de catálogo es requerido.",
    },
    descripcion: {
      name: "descripcion",
      label: "Descripción*",
      type: "text",
      errorMsg: "La descripción es obligatoria",
    },
    metodo_de_adquisicion: {
      name: "metodo_de_adquisicion",
      label: "Método de Adquisición*",
      type: "text",
      errorMsg: "Debe introducir un método de adquisición.",
    },
    marca: {
      name: "marca",
      label: "Marca",
      type: "text",
    },
    modelo: {
      name: "modelo",
      label: "Modelo",
      type: "text",
    },
    fecha_de_facturacion: {
      name: "fecha_de_facturacion",
      label: "Fecha de Facturación",
      type: "date",
    },
    fecha_cedula: {
      name: "fecha_cedula",
      label: "Fecha de Cédula",
      type: "date",
    },
    proveedor: {
      name: "proveedor",
      label: "Proveedor*",
      type: "text",
      errorMsg: "Debe proporcionar un proveedor",
    },
    numero_de_factura: {
      name: "numero_de_factura",
      label: "No. de Factura*",
      type: "text",
      errorMsg: "Debe introducir un número de factura.",
    },
    numero_de_serie: {
      name: "numero_de_serie",
      label: "No. de Serie*",
      type: "text",
      errorMsg:
        "El número de serie es obligatorio. Ingrese 'S/N' si desea dejarlo vacío.",
    },
    valor: {
      name: "valor",
      label: "Precio*",
      type: "tel",
      errorMsg: "El valor del producto es requerido",
      invalidMsg: "El valor no puede ser menor a 0",
    },
    numero_de_inventario: {
      name: "numero_de_inventario",
      label: "No. de Inventario*",
      type: "text",
      errorMsg: "Debe introducir un número de inventario.",
    },
    llave: {
      name: "llave",
      label: "Clave de bien*",
      type: "text",
      errorMsg: "Debe introducir una clave de bien.",
    },
    estado: {
      name: "estado",
      label: "Estado*",
      type: "text",
      errorMsg: "El estado del activo es obligatorio.",
    },
    foto: {
      name: "foto",
      label: "Foto",
      type: "file",
      accept: "image/jpeg, image/png",
    },
  },
};

export default form;
