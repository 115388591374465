/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { Fragment, useEffect, useMemo, useRef, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-table components
import {
  useAsyncDebounce,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";

import { styled } from "@mui/material/styles";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";

// Material Dashboard 2 PRO React examples
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import { Grid, Tooltip } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import MDButton from "components/MDButton";
import { v4 as uuid } from "uuid";

const StyledTableRow = styled((props) => <TableRow {...props} />)(
  ({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.light.main,
    },
  })
);

function DataTable({
  entriesPerPage,
  canSearch,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  noEndBorder,
  tableHeader,
  doubleClick, // funcion para manejar el evento de double click
  paginationApi, //bool para saber si usar paginacion de la tabla o paginacion a traves del api, si paginacionApi es true los siguientes props son obligados
  countTotal, // cantidad total de objetos devuelta por el api
  next, // url para pedir la siguiente pag
  previous, // url para pedir la pag anterior
  handleNext, // funcion encargada de repitir la peticion api para la siguiente pag
  handlePrevious, // funcion encargada de repitir la peticion api para la pag anterior
  setBusqueda, // funcion encargada de llenar el estado de la busqueda
  offset, // saber en que pagina se encuentra segun el api
  setOffset, // poner el offset de la paginacion en 0 cuando hay busqueda
  indice, //pone el indice en la tabla
  filtersComponent,
  collapse,
  detailsComponent,
}) {
  const defaultValue = entriesPerPage.defaultValue
    ? entriesPerPage.defaultValue
    : 20;
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries.map((el) => el.toString())
    : ["5", "10", "15", "20", "25"];

  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const handleDoubleClick = (row) => {
    doubleClick(row.original);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 20), [defaultValue]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value) => setPageSize(value);

  // Render the paginations
  const renderPagination = pageOptions.map((option) => (
    <MDPagination
      item
      key={option}
      onClick={() => gotoPage(Number(option))}
      active={pageIndex === option}
    >
      {option + 1}
    </MDPagination>
  ));

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0
      ? gotoPage(0)
      : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) =>
    gotoPage(Number(value.value - 1));

  const totalPages = totalCount(countTotal);

  const customizedPageOptionsApi = Array.from(
    { length: totalPages },
    (_, i) => i + 1
  );

  // Render the paginations for the api
  const setPageApi = (i) => {
    setOffset(20 * i);
  };
  const renderPaginationApi = Array.from({ length: totalPages }).map((_, i) => (
    <MDPagination
      item
      key={i}
      onClick={() => setPageApi(i)}
      active={calculate(offset) - 1 === i}
    >
      {i + 1}
    </MDPagination>
  ));

  const handleInputPaginationApi = ({ target: { value } }) =>
    value > totalPages || value < 0
      ? setOffset(0)
      : setOffset(20 * Number(value));

  const handleInputPaginationValueApi = ({ target: value }) => {
    if (value.value <= totalPages) {
      setOffset(20 * Number(value.value - 1));
    }
  };

  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  // Setting the entries starting point
  const entriesStart =
    pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  const inputRef = useRef(null);
  const refCallback = (element) => {
    if (element) {
      inputRef.current = element.querySelector("input");
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && inputRef.current) {
      setOffset(0);
      setBusqueda(inputRef.current.value);
    }
  };

  function calculate(offset) {
    return offset / 20 + 1;
  }

  function totalCount(countTotal) {
    return Math.ceil(countTotal / 20);
  }
  const [openRows, setOpenRows] = useState({});
  useEffect(() => {
    const newOpenRows = { ...openRows };

    // Iterar sobre las filas de la página actual
    page.forEach((row) => {
      if (newOpenRows[row.id] === undefined) {
        // Si una fila no está en openRows, inicialízala como cerrada
        newOpenRows[row.id] = false;
      }
    });

    setOpenRows(newOpenRows);
  }, [page]);

  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      {entriesPerPage || canSearch ? (
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={3}
        >
          {tableHeader && (
            <MDBox lineHeight={1} display="flex" justifyContent="space-between">
              <MDTypography variant="h5" fontWeight="medium">
                {tableHeader}
              </MDTypography>
            </MDBox>
          )}
          {entriesPerPage && !paginationApi && (
            <Grid container>
              <MDBox display="flex" flexDirection="row" alignItems="center">
                <Autocomplete
                  id="page-size"
                  disableClearable
                  value={pageSize.toString()}
                  options={entries}
                  onChange={(event, newValue) => {
                    setEntriesPerPage(parseInt(newValue, 10));
                  }}
                  size="small"
                  sx={{ width: "5rem" }}
                  renderInput={(params) => <MDInput {...params} />}
                />
                <MDTypography variant="caption" color="secondary" ml={1}>
                  cantidad por página
                </MDTypography>
              </MDBox>
            </Grid>
          )}

          {filtersComponent}

          {canSearch && (
            <MDBox
              width="14rem"
              ml="auto"
              gap={"0.5rem"}
              display={"flex"}
              alignItems={"center"}
            >
              <MDInput
                placeholder="Buscar..."
                value={search}
                size="small"
                fullWidth
                ref={refCallback}
                onChange={
                  paginationApi && setBusqueda
                    ? ({ currentTarget }) => {
                        setSearch(search);
                        onSearchChange(currentTarget.value);
                        setTimeout(() => {
                          setOffset(0);
                          setBusqueda(currentTarget.value);
                        }, 3000);
                      }
                    : ({ currentTarget }) => {
                        setSearch(search);
                        onSearchChange(currentTarget.value);
                      }
                }
                onBlur={
                  paginationApi && setBusqueda
                    ? ({ currentTarget }) => {
                        setOffset(0);
                        setBusqueda(currentTarget.value);
                      }
                    : null
                }
                onKeyDown={paginationApi && setBusqueda ? handleKeyDown : null}
              />
              <Tooltip
                placement="top"
                title="Busca en la tabla al escribir y en la base de datos del servidor al presionar 'Enter' o después de 3 segundos de inactividad."
              >
                <Icon fontSize="small" sx={{ cursor: "pointer" }}>
                  help_outline
                </Icon>
              </Tooltip>
            </MDBox>
          )}
        </MDBox>
      ) : null}
      <Table {...getTableProps()}>
        <MDBox component="thead">
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {collapse && <th width={"1%"} />}
              {indice && (
                <DataTableHeadCell sorted="asce" width={0.02}>
                  No.
                </DataTableHeadCell>
              )}
              {headerGroup.headers.map((column) => (
                <DataTableHeadCell
                  {...column.getHeaderProps(
                    isSorted && column.getSortByToggleProps()
                  )}
                  width={column.width ? column.width : "auto"}
                  align={column.align ? column.align : "left"}
                  sorted={setSortedValue(column)}
                >
                  {column.render("Header")}
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </MDBox>
        <TableBody {...getTableBodyProps()}>
          {page.map((row, key) => {
            prepareRow(row);
            return (
              <Fragment key={`fragment-${uuid()}`}>
                <StyledTableRow
                  {...row.getRowProps()}
                  onDoubleClick={
                    doubleClick ? () => handleDoubleClick(row) : null
                  }
                >
                  {collapse && (
                    <td>
                      <MDButton
                        variant="contained"
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                          setOpenRows((prevOpenRows) => ({
                            ...prevOpenRows,
                            [row.id]: !prevOpenRows[row.id],
                          }));
                        }}
                      >
                        <Icon fontSize="medium" color="black">
                          {openRows[row.id]
                            ? "keyboard_arrow_up"
                            : "keyboard_arrow_down"}
                        </Icon>
                      </MDButton>
                    </td>
                  )}
                  {indice && (
                    <DataTableBodyCell>{Number(row.id) + 1}</DataTableBodyCell>
                  )}
                  {row.cells.map((cell) => (
                    <DataTableBodyCell
                      noBorder={noEndBorder && rows.length - 1 === key}
                      align={cell.column.align ? cell.column.align : "left"}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </DataTableBodyCell>
                  ))}
                </StyledTableRow>
                {collapse && (
                  <StyledTableRow>
                    <td
                      colSpan={row.cells.length + 1}
                      key={`collapse-${row.original.id}`}
                    >
                      <Collapse
                        in={openRows[row.id]}
                        timeout="auto"
                        unmountOnExit
                      >
                        {detailsComponent(row.original)}
                      </Collapse>
                    </td>
                  </StyledTableRow>
                )}
              </Fragment>
            );
          })}
        </TableBody>
      </Table>
      <MDBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
      >
        {showTotalEntries && (
          <MDBox mb={{ xs: 3, sm: 0 }}>
            <MDTypography
              variant="button"
              color="secondary"
              fontWeight="regular"
            >
              {!countTotal
                ? rows.length > entriesEnd
                  ? `Mostrando ${entriesStart} a ${entriesEnd} de un total de ${rows.length} elemento(s).`
                  : `Mostrando ${rows.length} elemento(s) de un total de ${rows.length} elemento(s).`
                : `Mostrando la página ${calculate(
                    offset
                  )} de un total de ${totalCount(countTotal)} páginas.`}
            </MDTypography>
          </MDBox>
        )}
        {(pageOptions.length > 1 || paginationApi) && (
          <MDPagination
            variant={pagination.variant ? pagination.variant : "gradient"}
            color={pagination.color ? pagination.color : "info"}
          >
            {(canPreviousPage || previous) && (
              <MDPagination
                item
                onClick={
                  !previous ? () => previousPage() : () => handlePrevious()
                }
              >
                <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
              </MDPagination>
            )}
            {paginationApi ? (
              totalCount(countTotal) > 6 ? (
                <MDBox width="5rem" mx={1}>
                  <MDInput
                    inputProps={{
                      type: "number",
                      min: 1,
                      max: totalCount(countTotal),
                    }}
                    value={customizedPageOptionsApi[offset / 20]}
                    onChange={
                      (handleInputPaginationApi, handleInputPaginationValueApi)
                    }
                  />
                </MDBox>
              ) : (
                renderPaginationApi
              )
            ) : renderPagination.length > 6 ? (
              <MDBox width="5rem" mx={1}>
                <MDInput
                  inputProps={{
                    type: "number",
                    min: 1,
                    max: customizedPageOptions.length,
                  }}
                  value={customizedPageOptions[pageIndex]}
                  onChange={(handleInputPagination, handleInputPaginationValue)}
                />
              </MDBox>
            ) : (
              renderPagination
            )}

            {(canNextPage || next) && (
              <MDPagination
                item
                onClick={!next ? () => nextPage() : () => handleNext()}
              >
                <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
              </MDPagination>
            )}
          </MDPagination>
        )}
      </MDBox>
    </TableContainer>
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  indice: false,
  collapse: false,
  entriesPerPage: { defaultValue: 20, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
};

export default DataTable;
