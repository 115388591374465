/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { CURP_REGEX } from "helpers/regexs";
import { NAME_REGEX } from "helpers/regexs";
import * as Yup from "yup";
import checkout from "./form";

const {
  formField: {
    nombre,
    apellido_paterno,
    apellido_materno,
    correo,
    entidad,
    groups,
  },
} = checkout;

const validations = [
  Yup.object().shape({
    [nombre.name]: Yup.string()
      .required(nombre.errorMsg)
      .matches(NAME_REGEX, "El nombre no puede contener caracteres extraños"),
    [apellido_materno.name]: Yup.string()
      .required(apellido_materno.errorMsg)
      .matches(NAME_REGEX, "El nombre no puede contener caracteres extraños"),
    [apellido_paterno.name]: Yup.string()
      .required(apellido_paterno.errorMsg)
      .matches(NAME_REGEX, "El nombre no puede contener caracteres extraños"),
    [correo.name]: Yup.string()
      .email(correo.invalidMsg)
      .required(correo.errorMsg),
    [entidad.name]: Yup.string().required(entidad.errorMsg),
    [groups.name]: Yup.array().required(groups.errorMsg),
  }),
];

export default validations;
