/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DataTable from "examples/Tables/DataTable";

// Data
import Swal from "sweetalert2";
import ModalActivoShow from "components/ModalShow/modal_activo.show";
import PropTypes from "prop-types";
import MDTypography from "components/MDTypography";
import {
  useDeleteActivoMutation,
  useGetLotesQuery,
  useLazyGetActivoByIdQuery,
  useLazyGetLoteByIdQuery,
} from "services";
import { CircularProgress, Tooltip } from "@mui/material";
import ComponentsPrivatization from "config/component_privatization";
import { COORDINADOR_ALMACEN } from "roles";
import ModalBajaShow from "components/PDF/modal_baja";
import { style_icon } from "helpers/global_vars";
import { ACTIVOS_BAJAS_LIST_URL } from "config/bases_url";
import { useRedirectForm } from "hooks/useRedirectForm";
import useModalHandler from "../../../../hooks/useModalHandler";

function OrderListActivosBaja({ tableHeader }) {
  const {
    open: openShow,
    handleOpen: handleOpenShow,
    handleClose: handleCloseShow,
  } = useModalHandler();

  const {
    open: openPdf,
    handleOpen: handleOpenPdf,
    handleClose: handleClosePdf,
  } = useModalHandler();

  const {
    data: activos_bajas,
    isError: isErrorG,
    isLoading: isLoadingG,
    error: errorG,
  } = useGetLotesQuery(
    {
      filter_params: {
        type: "Baja",
        query: "{id,operaciones}",
      },
      shape: {
        idLote: "id",
        id: "operaciones[].activo.id",
        llave: "operaciones[].activo.llave",
        numero_de_inventario: "operaciones[].activo.numero_de_inventario",
        descripcion: "operaciones[].activo.descripcion",
        borrable: "operaciones[].activo.borrable",
        numero_de_serie: "operaciones[].activo.numero_de_serie",
        estado: "operaciones[].activo.estado.nombre",
      },
    },
    {
      refetchOnReconnect: true,
    },
  );

  const [
    getActivoById,
    { isLoading: isLoadingS, isError: isErrorS, data: activo, error: errorS },
  ] = useLazyGetActivoByIdQuery();
  const [
    deleteActivo,
    {
      isSuccess: isSuccessD,
      isLoading: isLoadingD,
      isError: isErrorD,
      error: errorD,
    },
  ] = useDeleteActivoMutation();

  const [getLoteById, { data: lote }] = useLazyGetLoteByIdQuery();

  const dataTable = {
    columns: [
      {
        Header: "Descripción",
        accessor: "descripcion",
      },
      {
        Header: "Estado",
        accessor: "estado",
      },
      {
        Header: "No. inventario",
        accessor: "numero_de_inventario",
        Cell: ({ value }) => {
          return value ? value : "-------------";
        },
      },
      {
        Header: "No. serie",
        accessor: "numero_de_serie",
        Cell: ({ value }) => {
          return value ? value : "-------------";
        },
      },
      {
        Header: "Acciones",
        accessor: "acciones",
        Cell: ({ row }) => {
          const { original } = row;

          return (
            <MDBox display="flex">
              <MDBox px={0.3}>
                <Tooltip placement="top" title="Ver detalles">
                  <Icon
                    style={style_icon}
                    fontSize="small"
                    onClick={(e) => handleClickShow(original)}
                  >
                    find_in_page
                  </Icon>
                </Tooltip>
              </MDBox>
              <ComponentsPrivatization permitted={COORDINADOR_ALMACEN}>
                {original.borrable && (
                  <MDBox px={0.3}>
                    <Tooltip placement="top" title="Eliminar">
                      <Icon
                        style={style_icon}
                        fontSize="small"
                        onClick={(e) =>
                          handleClickDelete(original.id, original.descripcion)
                        }
                      >
                        delete
                      </Icon>
                    </Tooltip>
                  </MDBox>
                )}
              </ComponentsPrivatization>
              <MDBox>
                <MDBox px={0.3}>
                  <Tooltip placement="top" title="Mostrar PDF">
                    <Icon
                      style={style_icon}
                      onClick={() => handleClickShowPdf(original.idLote)}
                      fontSize="small"
                    >
                      picture_as_pdf
                    </Icon>
                  </Tooltip>
                </MDBox>
              </MDBox>
            </MDBox>
          );
        },
      },
    ],
    rows: activos_bajas ? activos_bajas : [],
  };

  useRedirectForm(
    isLoadingG,
    null,
    isErrorG,
    errorG,
    "Hubo problemas para cargar la información",
    ACTIVOS_BAJAS_LIST_URL,
  );

  useRedirectForm(
    isLoadingS,
    null,
    isErrorS,
    errorS,
    "Hubo problemas para cargar la información",
    ACTIVOS_BAJAS_LIST_URL,
  );

  useRedirectForm(
    isLoadingD,
    isSuccessD,
    isErrorD,
    errorD,
    "Activo eliminado correctamente",
    ACTIVOS_BAJAS_LIST_URL,
  );

  const handleClickShow = async (el) => {
    await getActivoById(el.id)
      .unwrap()
      .then(() => {
        handleOpenShow();
      });
  };

  const handleClickDelete = (id, descripcion) => {
    Swal.fire({
      title: "¿Eliminar activo?",
      text: `¡Si eliminas el activo: "${descripcion}", no se podrá revertir la acción!`,
      icon: "warning",
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Eliminar",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteActivo(id);
      }
    });
  };

  const handleClickShowPdf = (id) => {
    getLoteById(id);
    handleOpenPdf();
  };

  return (
    <MDBox my={3}>
      {activo && (
        <ModalActivoShow
          open={openShow}
          handleClose={handleCloseShow}
          activo={activo}
        />
      )}
      {lote && (
        <ModalBajaShow
          open={openPdf}
          handleClose={handleClosePdf}
          lote={lote}
        />
      )}
      <Card>
        <MDBox
          display="flex"
          justifyContent={"space-between"}
          alignItems={"center"}
          m={2}
          p={3}
        >
          <MDTypography variant="h5" fontWeight="medium">
            {tableHeader}
          </MDTypography>
        </MDBox>
        <MDBox display="flex" justifyContent={"center"} alignItems={"center"}>
          {isLoadingG ? (
            <CircularProgress sx={{ marginBottom: "3rem" }} color="inherit" />
          ) : (
            <DataTable
              table={dataTable}
              canSearch
              doubleClick={handleClickShow}
            />
          )}
        </MDBox>
      </Card>
    </MDBox>
  );
}

OrderListActivosBaja.propTypes = {
  tableHeader: PropTypes.string.isRequired,
};

export default OrderListActivosBaja;
