import customFetchBase from "../principal/customFetchBase";
import { createApi } from "@reduxjs/toolkit/query/react";
import { carrerasPorGrupoApi } from "./distribuciones/distribucionDeCarrerasPorGrupo.service";
import { buildFiltersUrl, reshape_response } from "helpers/functions";
import { enqueueSnackbar } from "notistack";
import { showErrorMessages } from "../../hooks/useRedirectForm";

export const grupoEscolarApi = createApi({
  reducerPath: "grupoEscolarApi",
  baseQuery: customFetchBase,
  tagTypes: ["GrupoEscolar"],
  endpoints: (builder) => ({
    getGrupoEscolar: builder.query({
      query: ({ filter_params }) => ({
        url: buildFiltersUrl("/v1/api/grupo/", filter_params),
        method: "GET",
      }),
      providesTags: (result) =>
        Array.isArray(result)
          ? [
              ...result.map(({ id }) => ({
                type: "GrupoEscolar",
                id,
              })),
              { type: "GrupoEscolar", id: "LIST" },
            ]
          : [{ type: "GrupoEscolar", id: "LIST" }],
      transformResponse: (response, meta, args) => {
        if (Array.isArray(response)) {
          return response?.map((el) => reshape_response(el, args.shape));
        } else {
          const resultsTransformed = response?.results?.map((el) =>
            reshape_response(el, args.shape)
          );
          return {
            results: resultsTransformed,
            count: response.count,
            next: response.next,
            previous: response.previous,
          };
        }
      },
    }),
    getGrupoEscolarById: builder.query({
      query: (id) => ({
        url: `/v1/api/grupo/${id}/`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "GrupoEscolar", id }],
    }),
    createGrupoEscolar: builder.mutation({
      query: (data) => {
        const { ciclo, ciclo_interno, ...rest } = data;
        return {
          url: "/v1/api/grupo/",
          method: "POST",
          body: rest,
        };
      },
      invalidatesTags: [{ type: "GrupoEscolar", id: "LIST" }],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const { ciclo, ciclo_interno } = args;
        try {
          const { data } = await queryFulfilled;

          if (ciclo !== "" && ciclo_interno !== "")
            await dispatch(
              carrerasPorGrupoApi.endpoints.createCarrerasPorGrupo.initiate({
                ciclo,
                ciclo_interno,
                grupo: data.url,
              })
            );
        } catch (error) {
          showErrorMessages(error.error, enqueueSnackbar);
        }
      },
    }),
    deleteGrupoEscolar: builder.mutation({
      query: (id) => ({
        url: `/v1/api/grupo/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "GrupoEscolar", id: "LIST" }],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          dispatch(
            carrerasPorGrupoApi.util.invalidateTags([
              { type: "CarrerasPorGrupo", id: "LIST" },
            ])
          );
        } catch (error) {
          showErrorMessages(error.error, enqueueSnackbar);
        }
      },
    }),
    editGrupoEscolar: builder.mutation({
      query: ({ id, ...rest }) => {
        return {
          url: `/v1/api/grupo/${id}/`,
          method: "PATCH",
          body: rest,
        };
      },
      invalidatesTags: (result, error, { id }) =>
        result
          ? [
              { type: "GrupoEscolar", id },
              { type: "GrupoEscolar", id: "LIST" },
            ]
          : [{ type: "GrupoEscolar", id: "LIST" }],

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          dispatch(
            carrerasPorGrupoApi.util.invalidateTags([
              { type: "CarrerasPorGrupo", id: "LIST" },
            ])
          );
          enqueueSnackbar("Se editó correctamente el grupo", {
            variant: "success",
          });
        } catch (error) {
          showErrorMessages(error.error, enqueueSnackbar);
        }
      },
    }),
  }),
});

export const {
  useGetGrupoEscolarQuery,
  useLazyGetGrupoEscolarQuery,
  useCreateGrupoEscolarMutation,
  useEditGrupoEscolarMutation,
  useDeleteGrupoEscolarMutation,
  useLazyGetGrupoEscolarByIdQuery,
} = grupoEscolarApi;
