import { fechaEvaluacionApi } from "../view/fecha_evaluacion.service";

export const createAllFechaEvaluacion = fechaEvaluacionApi.injectEndpoints({
  endpoints: (builder) => ({
    createAllFechaEvaluacion: builder.mutation({
      query: (body) => ({
        url: "/v1/api/permisoevaluacion/bulk_create/",
        method: "POST",
        body: body,
      }),
      providesTags: (result) =>
        Array.isArray(result)
          ? [
              ...result.map(({ id }) => ({
                type: "Fecha Evaluacion",
                id,
              })),
              { type: "Fecha Evaluacion", id: "LIST" },
            ]
          : [{ type: "Fecha Evaluacion", id: "LIST" }],
    }),
  }),
});

export const { useCreateAllFechaEvaluacionMutation } = createAllFechaEvaluacion;
