/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

const form = {
    formId: "new-student-form",
    formField: {
        nombre: {
            name: "nombre",
            label: "Nombre(s)",
            type: "text",
            errorMsg: "El nombre es requerido.",
        },
        apellido_materno: {
            name: "apellido_materno",
            label: "Apellido Materno",
            type: "text",
            errorMsg: "Se debe entrar el apellido materno",
        },
        apellido_paterno: {
            name: "apellido_paterno",
            label: "Apellido Paterno",
            type: "text",
            errorMsg: "Se debe entrar el apellido paterno",
        },
        numero_de_control: {
            name: "numero_de_control",
            label: "Número de control",
            type: "text",
            errorMsg: "Debe entrar el número de control",
            invalidMsg: "Número de control no válido",
        },
        telefonos: {
            name: "telefonos",
            label: "Número de teléfono",
            type: "tel",
            invalidMsg: "Número de teléfono no valido",
        },
        genero: {
            name: "genero",
            label: "Género",
            type: "text",
            errorMsg: "El género debe ser especificado",
        },
        foto: {
            name: "foto",
            label: "Foto",
            type: "file",
            accept: "image/jpeg, image/png, image/gif",
        },
        tipo_sangre: {
            name: "tipo_sangre",
            label: "Tipo de sangre",
            type: "text",
        },
        curp: {
            name: "curp",
            label: "CURP",
            type: "text",
            errorMsg: "Debe entrar el CURP",
            invalidMsg: "CURP no valido",
        },
        ciclo: {
            name: "ciclo",
            label: "Ciclo",
            type: "text",
        },
        preasignacion_de_carrera: {
            name: "preasignacion_de_carrera",
            label: "Carrera",
            type: "text",
            errorMsg: "Debe seleccionar una carrera",
        },
        estado: {
            name: "estado",
            label: "Estado",
            type: "text",
        },
        groups: {
            name: "groups",
            label: "Rol",
            type: "text",
        },
        url_cambio_password: {
            name: "url_cambio_password",
            label: "url_cambio_password",
            type: "text",
        },
        usuario_wifi: {
            name: "usuario_wifi",
            label: "Usuario Wifi",
            type: "text",
        },
        password_wifi: {
            name: "password_wifi",
            label: "Password Wifi",
            type: "text",
        },
    },
};

export default form;
