import {firmantesApi} from "../view/firmante.service";
import {appendFormData, transformTelefonos} from "../../../../helpers/functions";


export const createFirmante = firmantesApi.injectEndpoints({
  endpoints: (builder) => ({
    createFirmante: builder.mutation({
      query: (body) => {
        if (body.telefonos)
          body.telefonos = transformTelefonos((body.telefonos))

        const formData = new FormData()
        Object.entries(body).forEach(([key, value]) => {
          appendFormData(formData, value, key);
        });

        return {
          url: "/v1/api/firmante/",
          method: "POST",
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          body: formData,
        };
      },
      invalidatesTags: [{type: "Firmantes", id: "LIST"}],
    }),
  }),
})

export const {
  useCreateFirmanteMutation,
} = createFirmante;