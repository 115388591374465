import * as React from "react";
import {Form, Formik} from "formik";
import FormField from "components/FormField";
import {Autocomplete, Card, Grid} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import {formularioProf} from "./schemas/form";
import {
    useCreateAsignaturasPorProfesorMutation,
    useCreateEstudiantesPorGrupoMutation,
    useEditAsignaturasPorProfesorMutation,
    useEditEstudiantesPorGrupoMutation,
    useGetAsignaturasPorProfesorQuery,
    useGetCarrerasPorGrupoByIdQuery,
    useLazyGetAsignaturasPorProfesorByIdQuery,
    useLazyGetEstudiantesPorGrupoByIdQuery,
} from "services";
import {GRUPOS_LIST_URL} from "config/bases_url";
import {useRedirectForm} from "hooks/useRedirectForm";
import {useNavigate, useParams} from "react-router-dom";
import EstudiantesAdd from "./components/students/add_estudiantes";
import {getModifiedFields, reemplazarUrl} from "helpers/functions";
import FormErrorHandler from "../../../../handlers/FormErrorHandler/form-error.handler";
import {ErrorBundary} from "../../../../config/ErrorBundary/error-bundary";
import {useGetProfesoresQuery} from "../../../../services/escuela/profesor";

const Distribucion = () => {
    const {formId, initialValues, validation} = formularioProf;

    const {id, idAsig, idEst, cicle_name} = useParams();

    const navigate = useNavigate();

    const [opcionesAsigAll, setOpcionesAsigAll] = React.useState();

    const {data: grupoById} = useGetCarrerasPorGrupoByIdQuery(id);

    const {data: asig_prof} = useGetAsignaturasPorProfesorQuery(
        {
            filter_params: {
                distribucion_grupo: id,
                query: "{asignatura{id}}",
            },
            shape: {
                id: "asignatura.id",
            },
        },
        {
            refetchOnReconnect: true,
            skip: idEst ? true : false,
        }
    );

    const [getAsigXProf] = useLazyGetAsignaturasPorProfesorByIdQuery();

    const [getEstXGrupo] = useLazyGetEstudiantesPorGrupoByIdQuery();

    const [oldValues, setOldValues] = React.useState();

    const {data: profesores} = useGetProfesoresQuery(
        {
            filter_params: {
                ordering:
                    "user__apellido_paterno,user__apellido_materno,user__first_name",
                query:
                    "{id,url,user{first_name,apellido_paterno,apellido_materno,email},entidad{clave_del_centro}}",
            },
            shape: {
                id: "id",
                url: "url",
                nombre: "user.first_name",
                apellido_paterno: "user.apellido_paterno",
                apellido_materno: "user.apellido_materno",
                email: "user.email",
                entidad: "entidad.clave_del_centro",
            },
        },
        {
            refetchOnReconnect: true,
            skip: idEst ? true : false,
        }
    );

    const [
        createEstudiantesPorGrupo,
        {
            isLoading: isLoadingCE,
            isSuccess: isSuccessCE,
            isError: isErrorCE,
            error: errorCE,
        },
    ] = useCreateEstudiantesPorGrupoMutation();

    const [
        createProfesorPorAsignatura,
        {
            isLoading: isLoadingPA,
            isSuccess: isSuccessPA,
            isError: isErrorPA,
            error: errorPA,
        },
    ] = useCreateAsignaturasPorProfesorMutation();

    const [
        editProfesorPorAsignatura,
        {
            isLoading: isLoadingPAedit,
            isSuccess: isSuccessPAedit,
            isError: isErrorPAedit,
            error: errorPAedit,
        },
    ] = useEditAsignaturasPorProfesorMutation();

    const [
        editEstudiantePorGrupo,
        {
            isLoading: isLoadingEGedit,
            isSuccess: isSuccessEGedit,
            isError: isErrorEGedit,
            error: errorEGedit,
        },
    ] = useEditEstudiantesPorGrupoMutation();

    useRedirectForm(
        isLoadingEGedit,
        isSuccessEGedit,
        isErrorEGedit,
        errorEGedit,
        "Editada asignación de estudiante con éxito",
        `${GRUPOS_LIST_URL}/${cicle_name}/grupos`
    );
    useRedirectForm(
        isLoadingPAedit,
        isSuccessPAedit,
        isErrorPAedit,
        errorPAedit,
        "Editada asignación de profesor y asignatura con éxito",
        `${GRUPOS_LIST_URL}/${cicle_name}/grupos`
    );

    useRedirectForm(
        isLoadingCE,
        isSuccessCE,
        isErrorCE,
        errorCE,
        "Estudiante asignado al grupo con éxito",
        `${GRUPOS_LIST_URL}/${cicle_name}/grupos`
    );
    useRedirectForm(
        isLoadingPA,
        isSuccessPA,
        isErrorPA,
        errorPA,
        "Asignada asignatura a un profesor con éxito",
        `${GRUPOS_LIST_URL}/${cicle_name}/grupos`
    );

    const asignaturasProfSet = new Set(asig_prof?.map((e) => e.id));

    const opcionesAsig = grupoById?.ciclo_interno?.asignaturas
        ?.filter((e) => !asignaturasProfSet.has(e.id))
        .map((e) => ({
            nombre: e.nombre,
            url: e.url,
            key: e.id,
        }));

    const opcionesProf = profesores?.map((e) => {
        return {
            nombre: `${e.apellido_paterno} ${e.apellido_materno} ${e.nombre}`,
            url: e.url,
            key: e.id,
        };
    });

    const submitForm = async (values, actions) => {
        const {
            distribucion_grupo,
            profesor,
            asignatura,
            estudiantes,
            estudiante,
        } = values;
        try {
            if (idAsig) {
                const field = {profesor, asignatura};
                const modifiedFields = getModifiedFields(oldValues, field);
                if (Object.keys(modifiedFields).length !== 0) {
                    await editProfesorPorAsignatura({
                        id: idAsig,
                        ...modifiedFields,
                    });
                }
            } else if (idEst) {
                const field = {estudiante};
                const modifiedFields = getModifiedFields(oldValues, field);
                const onlyUrl = modifiedFields?.[0].url;
                if (Object.keys(modifiedFields).length !== 0) {
                    await editEstudiantePorGrupo({
                        id: idEst,
                        ...onlyUrl,
                    });
                }
            } else {
                if (estudiantes.length !== 0) {
                    const onlyUrl = estudiantes.map((e) => {
                        return {estudiante: e.url};
                    });
                    createEstudiantesPorGrupo({
                        distribucion_grupo: distribucion_grupo,
                        estudiantes: onlyUrl,
                    });
                }
                if (profesor && asignatura) {
                    createProfesorPorAsignatura({
                        distribucion_grupo: distribucion_grupo,
                        profesor: profesor,
                        asignatura: asignatura,
                    });
                }
            }
        } catch (error) {
            console.error(error);
            actions.setSubmitting(false);
        }
    };

    const handleSubmit = (values, actions) => {
        submitForm(values, actions);
    };

    return (
        <MDBox mt={2} mb={20}>
            <Grid container justifyContent="center" alignItems="center" spacing={2}>
                <Grid item xs={12} sm={12}>
                    <MDBox textAlign="center" ml={3}>
                        <MDBox display="flex" alignItems="baseline" justifyContent="center">
                            <MDTypography variant="h4" fontWeight="bold">
                                Ciclo Actual:
                            </MDTypography>
                            {cicle_name && (
                                <MDTypography variant="h5" ml={2}>
                                    {cicle_name}
                                </MDTypography>
                            )}
                        </MDBox>

                        <MDBox display="flex" alignItems="baseline" justifyContent="center">
                            <MDTypography variant="h4" fontWeight="bold">
                                Grupo:
                            </MDTypography>
                            {grupoById && (
                                <MDTypography variant="h5" ml={2}>
                                    {grupoById?.grupo?.identificador}
                                </MDTypography>
                            )}
                        </MDBox>
                    </MDBox>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validation}
                        onSubmit={handleSubmit}
                    >
                        {({setFieldValue, values, errors, touched}) => {
                            React.useEffect(() => {
                                if (grupoById) {
                                    setFieldValue("distribucion_grupo", grupoById.url);
                                }
                            }, [grupoById]);
                            React.useEffect(() => {
                                if (idAsig) {
                                    getAsigXProf(idAsig)
                                        .unwrap()
                                        .then((res) => {
                                            const asignatura = res?.asignatura?.url;
                                            const profesor = reemplazarUrl(res?.profesor?.url, 2);
                                            setOldValues({
                                                asignatura: asignatura,
                                                profesor: profesor,
                                            });
                                            setFieldValue("asignatura", asignatura);
                                            setFieldValue("profesor", profesor);
                                            const opcionesAsigSinFiltrar =
                                                res.distribucion_grupo?.ciclo_interno?.asignaturas?.map(
                                                    (e) => ({
                                                        nombre: e.nombre,
                                                        url: e.url,
                                                        key: e.id,
                                                    })
                                                );
                                            setOpcionesAsigAll(opcionesAsigSinFiltrar);
                                        });
                                }
                            }, [idAsig]);
                            React.useEffect(() => {
                                if (idEst) {
                                    getEstXGrupo(idEst)
                                        .unwrap()
                                        .then((res) => {
                                            const estudiante = res?.estudiante;
                                            const reduceEstudiante = (e) => {
                                                return [
                                                    {
                                                        nombre: `${e.user.apellido_paterno} ${e.user.apellido_materno} ${e.user.first_name}, ${e.numero_de_control}`,
                                                        url: e.url,
                                                        id: e.id,
                                                    },
                                                ];
                                            };

                                            setOldValues({
                                                nombre: `${estudiante?.user?.apellido_paterno} ${estudiante?.user?.apellido_materno} ${estudiante?.user?.first_name}`,
                                                url: estudiante?.url,
                                                id: estudiante?.id,
                                            });
                                            setFieldValue(
                                                "estudiantes",
                                                reduceEstudiante(estudiante)
                                            );
                                        });
                                }
                            }, [idEst]);

                            return (
                                <ErrorBundary renderOnError={() => <FormErrorHandler/>}>
                                    <Form key={formId} id={formId} autoComplete="off">
                                        <Card sx={{height: "100%"}}>
                                            <MDBox p={3}>
                                                <MDBox mt={1.625}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12} sm={12}>
                                                            <Autocomplete
                                                                id="distribuir-asignatura"
                                                                disabled={idEst ? true : false}
                                                                options={
                                                                    (idAsig ? opcionesAsigAll : opcionesAsig) ??
                                                                    []
                                                                }
                                                                onChange={(e, value) => {
                                                                    setFieldValue(
                                                                        "asignatura",
                                                                        value ? value.url : null
                                                                    );
                                                                }}
                                                                value={
                                                                    (idAsig
                                                                            ? opcionesAsigAll
                                                                            : opcionesAsig
                                                                    )?.find(
                                                                        (opcion) => opcion.url === values.asignatura
                                                                    ) || null
                                                                }
                                                                isOptionEqualToValue={(option, value) => {
                                                                    return `${option.url}` === `${value.url}`;
                                                                }}
                                                                getOptionLabel={(option) =>
                                                                    `${option.nombre} ${option.key}`
                                                                }
                                                                renderInput={(params) => (
                                                                    <FormField
                                                                        id="distribucionA"
                                                                        name={"distribucionA"}
                                                                        label="Distribuir una Asignatura"
                                                                        {...params}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={12}>
                                                            <Autocomplete
                                                                disabled={idEst ? true : false}
                                                                id="distribuirB-profesor"
                                                                options={opcionesProf ?? []}
                                                                onChange={(e, value) => {
                                                                    setFieldValue(
                                                                        "profesor",
                                                                        value ? value.url : null
                                                                    );
                                                                }}
                                                                value={
                                                                    opcionesProf?.find(
                                                                        (opcion) => opcion.url === values.profesor
                                                                    ) || null
                                                                }
                                                                isOptionEqualToValue={(option, value) => {
                                                                    return `${option.url}` === `${value.url}`;
                                                                }}
                                                                getOptionLabel={(option) => `${option.nombre}`}
                                                                renderInput={(params) => (
                                                                    <FormField
                                                                        id="distribucionP"
                                                                        name={"distribucionP"}
                                                                        label="Distribuir un Profesor"
                                                                        {...params}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={12}>
                                                            {!idAsig && grupoById && (
                                                                <EstudiantesAdd
                                                                    group={grupoById}
                                                                    formData={{
                                                                        values,
                                                                        touched,
                                                                        errors,
                                                                        setFieldValue,
                                                                    }}
                                                                    edit={idEst}
                                                                />
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </MDBox>
                                                <MDBox
                                                    mt={2}
                                                    width="100%"
                                                    display="flex"
                                                    justifyContent="space-between"
                                                >
                                                    <MDButton
                                                        onClick={(e) => {
                                                            navigate(
                                                                `${GRUPOS_LIST_URL}/${cicle_name}/grupos`
                                                            );
                                                        }}
                                                        variant="gradient"
                                                        color="primary"
                                                    >
                                                        cancelar
                                                    </MDButton>
                                                    <MDButton
                                                        type="submit"
                                                        variant="gradient"
                                                        color="dark"
                                                    >
                                                        aceptar
                                                    </MDButton>
                                                </MDBox>
                                            </MDBox>
                                        </Card>
                                    </Form>
                                </ErrorBundary>
                            );
                        }}
                    </Formik>
                </Grid>
            </Grid>
        </MDBox>
    );
};

export default Distribucion;
