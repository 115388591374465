/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================
 
 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)
 
 Coded by www.creative-tim.com
 
 =========================================================
 
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DataTable from "examples/Tables/DataTable";

//React Hooks and Others
import { useNavigate } from "react-router-dom";
import {
  CircularProgress,
  FormControl,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import Swal from "sweetalert2";
import PropTypes from "prop-types";

import ComponentsPrivatization, {
  removeColumns,
} from "config/component_privatization";
import { style_icon } from "helpers/global_vars";
import { ASIGNATURAS_EDIT_URL, ASIGNATURAS_LIST_URL } from "config/bases_url";
import { useRedirectForm } from "hooks/useRedirectForm";
import {
  useDeleteAsignaturaMutation,
  useGetAsignaturasQuery,
  useGetCarrerasQuery,
} from "services";
import { COORDINADOR_DOCENTE } from "roles";
import usePagination from "hooks/usePagination";
import React, { useState } from "react";

export const FiltersCareerComponent = ({ values, handleChange, carreras }) => {
  return (
    <FormControl sx={{ m: 1, minWidth: "14rem" }} size="large">
      <InputLabel id="profesores-fitler">Carrera</InputLabel>
      <Select
        sx={{ minHeight: "37.25px" }}
        labelId="profesores-fitler"
        name="carrera"
        value={values.carrera}
        label="Carrera"
        onChange={handleChange}
      >
        <MenuItem value="">Limpiar</MenuItem>
        {carreras
          ?.filter(
            (obj, index, self) =>
              index === self.findIndex((t) => t.id === obj.id),
          )
          .map((el) => (
            <MenuItem key={el.id} value={el.id}>
              {`${el.nombre} ${el.plan_de_estudio}`}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

function DataTableAsignaturas({ tableHeader }) {
  const navigate = useNavigate();

  const { offset, setOffset, limit, handleNext, handlePrevious } =
    usePagination();

  const { data: carreras } = useGetCarrerasQuery({
    filter_params: {
      query: "{id, nombre, plan_de_estudio}",
      ordering: "nombre,plan_de_estudio",
    },
    shape: {
      id: "id",
      nombre: "nombre",
      plan_de_estudio: "plan_de_estudio",
    },
  });

  const [values, setValues] = useState({
    carrera: "",
  });

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const {
    data: asignaturas,
    isError: isErrorG,
    isLoading: isLoadingG,
    error: errorG,
    isFetching,
  } = useGetAsignaturasQuery(
    {
      filter_params: {
        limit: limit,
        offset: offset,
        carrera: values.carrera,
        query: "{id,url,nombre,clave,ciclo_interno,asistencia_requerida}",
        ordering: "ciclo_interno,nombre,identificador,clave",
      },
      shape: {
        id: "id",
        url: "url",
        clave: "clave",
        nombre: "nombre",
        ciclo_interno: "ciclo_interno",
        asistencia_requerida: "asistencia_requerida",
      },
    },
    {
      refetchOnReconnect: true,
    },
  );

  //constantes para manejar la paginacion
  const dataAsig = asignaturas?.results ? asignaturas.results : asignaturas;
  const countTotal = asignaturas?.count;
  const previous = asignaturas?.previous;
  const next = asignaturas?.next;

  const [
    deleteAsignaturas,
    {
      isSuccess: isSuccessD,
      isLoading: isLoadingD,
      isError: isErrorD,
      error: errorD,
    },
  ] = useDeleteAsignaturaMutation();

  const dataTable = {
    columns: [
      {
        Header: "Período",
        accessor: "ciclo_interno",
        width: "5%",
      },
      {
        Header: "Nombre",
        accessor: "nombre",
        Cell: ({ value }) => {
          return value ? value : "-------------";
        },
      },
      {
        Header: "Clave",
        accessor: "clave",
      },
      {
        Header: "% de Asistencia",
        accessor: "asistencia_requerida",
      },
      {
        Header: "Acciones",
        accessor: "actions",
        width: "1%",
        Cell: ({ row }) => {
          const { original } = row;

          return (
            <MDBox display="flex">
              <ComponentsPrivatization permitted={COORDINADOR_DOCENTE}>
                <MDBox px={0.2}>
                  <Tooltip
                    placement="top"
                    title="Editar la información asociada a la asignatura"
                  >
                    <Icon
                      style={style_icon}
                      onClick={() => handleClickEdit(original)}
                      fontSize="small"
                    >
                      edit
                    </Icon>
                  </Tooltip>
                </MDBox>
                <MDBox px={0.2}>
                  <Tooltip
                    placement="top"
                    title="Eliminar la asignatura del listado"
                  >
                    <Icon
                      style={style_icon}
                      onClick={() =>
                        handleClickErase(original.id, original.nombre)
                      }
                      fontSize="small"
                    >
                      remove_circle
                    </Icon>
                  </Tooltip>
                </MDBox>
              </ComponentsPrivatization>
            </MDBox>
          );
        },
      },
    ],

    rows: dataAsig ?? [],
  };

  useRedirectForm(
    isLoadingG,
    null,
    isErrorG,
    errorG,
    "Hubo problemas para cargar la información",
    ASIGNATURAS_LIST_URL,
  );

  useRedirectForm(
    isLoadingD,
    isSuccessD,
    isErrorD,
    errorD,
    "Asignatura eliminada",
    ASIGNATURAS_LIST_URL,
  );

  const handleClickErase = (id, nombre) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: `¡Si eliminas "${nombre}", la acción no se podrá revertir!`,
      icon: "warning",
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Eliminar",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteAsignaturas({ id });
      }
    });
  };

  const handleClickEdit = (el) => {
    navigate(ASIGNATURAS_EDIT_URL(el.id));
  };

  return (
    <MDBox pt={6} pb={3}>
      <Card>
        <MDBox
          display="flex"
          justifyContent={"space-between"}
          alignItems={"center"}
          m={2}
          p={3}
        >
          <MDTypography variant="h5" fontWeight="medium">
            {tableHeader}
          </MDTypography>
        </MDBox>

        <MDBox display="flex" justifyContent={"center"} alignItems={"center"}>
          {isLoadingG || isFetching ? (
            <CircularProgress sx={{ marginBottom: "3rem" }} color="inherit" />
          ) : (
            <DataTable
              table={removeColumns(
                dataTable,
                () =>
                  !JSON.parse(sessionStorage.getItem("user")).groups.some(
                    (role) => role.name === COORDINADOR_DOCENTE,
                  ),
                ["actions"],
              )}
              canSearch
              paginationApi
              countTotal={countTotal}
              next={next}
              previous={previous}
              handleNext={handleNext}
              handlePrevious={handlePrevious}
              offset={offset}
              setOffset={setOffset}
              filtersComponent={
                <FiltersCareerComponent
                  values={values}
                  handleChange={handleChange}
                  carreras={carreras}
                />
              }
            />
          )}
        </MDBox>
      </Card>
    </MDBox>
  );
}

DataTableAsignaturas.propTypes = {
  tableHeader: PropTypes.string.isRequired,
};

export default DataTableAsignaturas;
