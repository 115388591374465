import {
  buildFiltersUrl,
  reemplazarUrl,
  reshape_response,
} from "helpers/functions";
import customFetchBase from "../../principal/customFetchBase";
import { createApi } from "@reduxjs/toolkit/query/react";
import { estudianteApi } from "../estudiantes.service";

export const estudiantesPorGrupoApi = createApi({
  reducerPath: "estudiantesPorGrupoApi",
  baseQuery: customFetchBase,
  tagTypes: ["EstudiantesPorGrupo"],
  endpoints: (builder) => ({
    getEstudiantesPorGrupo: builder.query({
      query: ({ filter_params }) => ({
        url: buildFiltersUrl(
          "/v1/api/distribuciondeestudianteporgrupo/",
          filter_params
        ),
        method: "GET",
      }),
      providesTags: (result) =>
        Array.isArray(result)
          ? [
              ...result.map(({ id }) => ({
                type: "EstudiantesPorGrupo",
                id,
              })),
              { type: "EstudiantesPorGrupo", id: "LIST" },
            ]
          : [{ type: "EstudiantesPorGrupo", id: "LIST" }],
      transformResponse: (response, meta, args) => {
        if (Array.isArray(response)) {
          return response?.map((el) => reshape_response(el, args.shape));
        } else {
          const resultsTransformed = response?.results?.map((el) =>
            reshape_response(el, args.shape)
          );

          // Devolvemos los resultados transformados junto con las propiedades de paginación
          return {
            results: resultsTransformed,
            count: response.count,
            next: response.next,
            previous: response.previous,
          };
        }
      },
    }),
    getEstudiantesPorGrupoById: builder.query({
      query: (id) => ({
        url: `/v1/api/distribuciondeestudianteporgrupo/${id}/`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [
        { type: "EstudiantesPorGrupo", id },
      ],
    }),
    createEstudiantesPorGrupo: builder.mutation({
      query: (data) => {
        data.estudiantes = data.estudiantes.map((e) => {
          return { estudiante: reemplazarUrl(e.estudiante, 1) };
        });
        return {
          url: "/v1/api/distribuciondeestudianteporgrupo/bulk/create/",
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: [{ type: "EstudiantesPorGrupo", id: "LIST" }],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          queryFulfilled.then(() => {
            dispatch(
              estudianteApi.util.invalidateTags([
                { type: "Estudiante", id: "LIST" },
              ])
            );
          });
        } catch (error) {
          console.log(error);
        }
      },
    }),
    deleteEstudiantesPorGrupo: builder.mutation({
      query: (id) => ({
        url: `/v1/api/distribuciondeestudianteporgrupo/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "EstudiantesPorGrupo", id: "LIST" }],
    }),
    editEstudiantesPorGrupo: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `/v1/api/distribuciondeestudianteporgrupo/${id}/`,
        method: "PATCH",
        body: rest,
      }),
      invalidatesTags: (result, error, { id }) =>
        result
          ? [
              { type: "EstudiantesPorGrupo", id },
              { type: "EstudiantesPorGrupo", id: "LIST" },
            ]
          : [{ type: "EstudiantesPorGrupo", id: "LIST" }],
    }),
  }),
});

export const {
  useGetEstudiantesPorGrupoQuery,
  useLazyGetEstudiantesPorGrupoQuery,
  useCreateEstudiantesPorGrupoMutation,
  useEditEstudiantesPorGrupoMutation,
  useDeleteEstudiantesPorGrupoMutation,
  useLazyGetEstudiantesPorGrupoByIdQuery,
  useGetEstudiantesPorGrupoByIdQuery,
} = estudiantesPorGrupoApi;
