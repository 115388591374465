// formik components
import {Form, Formik} from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// NewRegistro layout schemas for form and form
import validations from "./schemas/validations";
import form from "./schemas/form";
import {useNavigate, useParams} from "react-router-dom";
import * as React from "react";
import {useEffect, useState} from "react";
import MDTypography from "components/MDTypography";
import initialValues from "./schemas/initialValues";
import {useRedirectForm} from "hooks/useRedirectForm";
import {Step, StepLabel, Stepper} from "@mui/material";
import TelefonosCRUD, {telefonoIcon,} from "examples/TelefonosCRUD/telefonos.add";
import FirmanteInfo from "./components/add.firmante";
import {getBase64, getModifiedFields} from "helpers/functions";
import {
  useCreateFirmanteMutation,
  useEditFirmanteMutation,
  useGetGruposQuery,
  useLazyGetFirmanteByIdQuery,
} from "services";
import {FIRMANTE} from "roles";
import {FIRMANTES_LIST_URL} from "config/bases_url";
import FormErrorHandler from "../../../../handlers/FormErrorHandler/form-error.handler";
import {ErrorBundary} from "../../../../config/ErrorBundary/error-bundary";

function getSteps() {
  return ["Información del Firmante", "Teléfonos"];
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <FirmanteInfo formData={formData}/>;
    case 1:
      return <TelefonosCRUD formData={formData}/>;
    default:
      return null;
  }
}

// Función que filtra los campos a excluir
const filterFields = (data, excludeFields) => {
  return Object.fromEntries(
    Object.entries(data).filter(([key, value]) => {
      if (excludeFields.includes(key)) {
        if (key === "telefonos" && Array.isArray(value) && value.length === 0) {
          return false; // Excluir el campo "telefono" si es un arreglo vacío
        }
        if (key === "groups" || key === "url_cambio_password") {
          return false;
        }
        return !(typeof value === "string" || value === null || value === "");
      }
      return true;
    })
  );
};

function NewFirmante() {
  const {id} = useParams();
  const {formId, formField} = form;
  const currentValidation = validations[0];
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;
  const handleBack = () => setActiveStep(activeStep - 1);
  const [oldValues, setOldValues] = useState();

  const [
    createFirmante,
    {
      isSuccess: isSuccessC,
      isLoading: isLoadingC,
      isError: isErrorC,
      error: errorC,
    },
  ] = useCreateFirmanteMutation();

  const [
    editFirmante,
    {
      isSuccess: isSuccessE,
      isLoading: isLoadingE,
      isError: isErrorE,
      error: errorE,
    },
  ] = useEditFirmanteMutation();

  const {data: grupos} = useGetGruposQuery(undefined, {
    refetchOnReconnect: true,
  });
  const rol = grupos?.find((e) => e.name === FIRMANTE);

  const [getFirmanteById, {data: usuario}] = useLazyGetFirmanteByIdQuery(id);

  useRedirectForm(
    isLoadingC,
    isSuccessC,
    isErrorC,
    errorC,
    "Firmante creado",
    FIRMANTES_LIST_URL
  );

  useRedirectForm(
    isLoadingE,
    isSuccessE,
    isErrorE,
    errorE,
    "Firmante editado",
    FIRMANTES_LIST_URL
  );

  const submitForm = async (values, actions) => {
    const excludeFields = [
      "foto",
      "groups",
      "url_cambio_password",
      "telefonos",
    ]; // Campos a excluir

    try {
      if (!id) {
        if (values.foto && values.foto instanceof File) {
          values.foto = await getBase64(values.foto);
        }
        if (values.certificado && values.certificado instanceof File) {
          values.certificado = await getBase64(values.certificado);
        }
        if (values.firma && values.firma instanceof File) {
          values.firma = await getBase64(values.firma);
        }
        await createFirmante(values);
      } else {
        const modifiedFields = getModifiedFields(oldValues, values);
        if (Object.keys(modifiedFields).length !== 0) {
          const data = filterFields(modifiedFields, excludeFields);
          if (data.foto && data.foto instanceof File) {
            data.foto = await getBase64(data.foto);
          }
          if (data.certificado && data.certificado instanceof File) {
            data.certificado = await getBase64(data.certificado);
          }
          if (data.firma && data.firma instanceof File) {
            data.firma = await getBase64(data.firma);
          }
          await editFirmante({id: id, ...data});
        }
      }
      navigate(FIRMANTES_LIST_URL);
      if (isSuccessC || isSuccessE) {
        actions.setSubmitting(false);
        actions.resetForm();
      }
    } catch (error) {
      console.error(error);
      actions.setSubmitting(true);
    }
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  return (
    <MDBox mt={5} mb={20}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} lg={8}>
          <MDBox mt={6} mb={8} textAlign="center">
            <MDBox mb={1}>
              <MDTypography variant="h3" fontWeight="bold">
                {!id ? "Agregar Firmante" : "Editar Firmante"}
              </MDTypography>
            </MDBox>
            <MDTypography variant="h5" fontWeight="regular" color="secondary">
              {!id
                ? "Entre la información relacionada al firmante a agregar"
                : `Edite la información relacionada al firmante: ${usuario?.user.first_name} ${usuario?.user.apellido_paterno} ${usuario?.user.apellido_materno}`}
            </MDTypography>
          </MDBox>
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={handleSubmit}
          >
            {({
                values,
                errors,
                touched,
                isValid,
                setFieldValue,
                setErrors,
              }) => {
              useEffect(() => {
                if (id) {
                  getFirmanteById(id)
                    .unwrap()
                    .then((res) => {
                      setOldValues({
                        nombre: res.user.first_name,
                        apellido_materno: res.user.apellido_materno,
                        apellido_paterno: res.user.apellido_paterno,
                        correo: res.user.email,
                        telefonos:
                          res?.telefonos?.map((el) => ({
                            ...el,
                            isCreated: false,
                            icon: telefonoIcon(el.tipo),
                          })) || [],
                        foto: res.user.foto,
                        groups: res.user.groups,
                        cargo: res.cargo,
                        firma: res.firma,
                        certificado: res.certificado,
                      });
                      setFieldValue(
                        formField.apellido_paterno.name,
                        res.user.apellido_paterno,
                        true
                      );
                      setFieldValue(
                        formField.apellido_materno.name,
                        res.user.apellido_materno,
                        true
                      );
                      setFieldValue(
                        formField.telefonos.name,
                        res?.telefonos?.map((el) => ({
                          ...el,
                          isCreated: false,
                          icon: telefonoIcon(el.tipo),
                        })) || [],
                        true
                      );
                      setFieldValue(formField.foto.name, res.user.foto, true);
                      setFieldValue(
                        formField.correo.name,
                        res.user.email,
                        true
                      );
                      setFieldValue(
                        formField.nombre.name,
                        res.user.first_name,
                        true
                      );
                      setFieldValue(formField.cargo.name, res.cargo, true);
                      setFieldValue(
                        formField.certificado.name,
                        res.certificado,
                        true
                      );
                      setFieldValue(formField.firma.name, res.firma, true);
                    });
                }
              }, [id]);
              useEffect(() => {
                if (rol !== undefined) {
                  setFieldValue(formField.groups.name, [rol.url]);
                }
              }, [rol]);

              return (
                <ErrorBundary renderOnError={() => <FormErrorHandler/>}>
                  <Form id={formId} autoComplete="off">
                    <Card sx={{height: "100%"}}>
                      <MDBox mx={2} mt={-3}>
                        <Stepper activeStep={activeStep} alternativeLabel>
                          {steps.map((label) => (
                            <Step key={label}>
                              <StepLabel>{label}</StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                      </MDBox>
                      <MDBox p={3}>
                        <MDBox>
                          {getStepContent(activeStep, {
                            values,
                            touched,
                            formField,
                            errors,
                            setErrors,
                            setFieldValue,
                          })}
                          <MDBox
                            mt={2}
                            width="100%"
                            display="flex"
                            justifyContent="space-between"
                          >
                            <MDButton
                              onClick={(e) =>
                                activeStep === 0
                                  ? navigate(FIRMANTES_LIST_URL)
                                  : handleBack()
                              }
                              variant="gradient"
                              color="primary"
                            >
                              {activeStep === 0 ? "cancelar" : "atrás"}
                            </MDButton>

                            <MDButton
                              disabled={!isValid}
                              type="submit"
                              variant="gradient"
                              color="dark"
                            >
                              {isLastStep ? "aceptar" : "siguiente"}
                            </MDButton>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </Card>
                  </Form>
                </ErrorBundary>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default NewFirmante;
