import {administrativoApi} from "../view/administrativo.service";
import {appendFormData, transformTelefonos} from "../../../../helpers/functions";


export const editAdministrativo = administrativoApi.injectEndpoints({
  endpoints: (builder) => ({
    editAdministrativo: builder.mutation({
      query: (body) => {
        let {id, ...rest} = body;

        if (rest.telefonos)
          rest.telefonos = transformTelefonos((rest.telefonos))

        const formData = new FormData()
        Object.entries(rest).forEach(([key, value]) => {
          appendFormData(formData, value, key);
        });

        return {
          url: `/v1/api/no_docente/${id}/`,
          method: "PATCH",
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          body: formData,
        };
      },
      invalidatesTags: (result, error, {id}) =>
        result
          ? [
            {type: "Administrativo", id},
            {type: "Administrativo", id: "LIST"},
          ]
          : [{type: "Administrativo", id: "LIST"}],
    }),
  }),
})

export const {
  useEditAdministrativoMutation
} = editAdministrativo;