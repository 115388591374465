import * as React from "react";
import PropTypes from "prop-types";
import Tab from "@mui/material/Tab";
import MDBox from "components/MDBox";
import { Card, Icon } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import MDButton from "components/MDButton";

export default function TabsCard({ tabs, initial_tab, onAddTab }) {
  const [value, setValue] = React.useState(initial_tab);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <MDBox
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          borderBottom: 1,
          borderColor: "divider",
          borderRadius: "1rem",
        }}
      >
        <TabList value={value} onChange={handleChange} aria-label="tabs">
          {tabs.map((el) => (
            <Tab
              key={el.id}
              label={el.label}
              value={el.id}
              disabled={el.disabled}
              sx={{
                px: 1.5,
              }}
            />
          ))}
        </TabList>
        {onAddTab && (
          <MDButton color="success" variant="text" onClick={onAddTab}>
            <Icon fontSize="medium">add</Icon>
          </MDButton>
        )}
      </MDBox>
      {tabs.map((el) => (
        <Card key={el.id} sx={{ width: "100%" }}>
          <TabPanel key={el.id} value={el.id}>
            {el.element}
          </TabPanel>
        </Card>
      ))}
    </TabContext>
  );
}

TabsCard.propTypes = {
  tabs: PropTypes.array.isRequired,
  initial_tab: PropTypes.string.isRequired,
};
