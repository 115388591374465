import {cursosApi} from "../view/cursos.service";

export const deleteCurso = cursosApi.injectEndpoints({
    endpoints: (builder) => ({
        deleteCurso: builder.mutation({
            query: (id) => ({
                url: `/v1/api/curso/${id}/`,
                method: "DELETE",
            }),
            invalidatesTags: [{type: "Cursos", id: "LIST"}],
        }),
    }),
})

export const {
    useDeleteCursoMutation,
} = deleteCurso;