import { createApi } from "@reduxjs/toolkit/query/react";
import { buildFiltersUrl } from "helpers/functions";
import customFetchBase from "services/principal/customFetchBase";

export const reporteRegulacionesApi = createApi({
  reducerPath: "reporteRegulacionesApi",
  baseQuery: customFetchBase,
  tagTypes: ["Reportes Regulaciones"],
  endpoints: (builder) => ({
    makeRepRegUno: builder.mutation({
      query: ({ filter_params, body }) => ({
        url: buildFiltersUrl("/v1/api/repo-est-reg-uno/make/", filter_params),
        method: "POST",
        body: body,
      }),
      providesTags: (result) =>
        Array.isArray(result)
          ? [
              ...result.map(({ id }) => ({
                type: "Reportes Regulaciones",
                id,
              })),
              { type: "Reportes Regulaciones", id: "LIST" },
            ]
          : [{ type: "Reportes Regulaciones", id: "LIST" }],
    }),
    makeRepRegDos: builder.mutation({
      query: ({ filter_params, body }) => ({
        url: buildFiltersUrl("/v1/api/repo-est-reg-dos/make/", filter_params),
        method: "POST",
        body: body,
      }),
      providesTags: (result) =>
        Array.isArray(result)
          ? [
              ...result.map(({ id }) => ({
                type: "Reportes Regulaciones",
                id,
              })),
              { type: "Reportes Regulaciones", id: "LIST" },
            ]
          : [{ type: "Reportes Regulaciones", id: "LIST" }],
    }),
    makeHistorial: builder.mutation({
      query: ({ filter_params, body }) => ({
        url: buildFiltersUrl("/v1/api/repo-historial/make/", filter_params),
        method: "POST",
        body: body,
      }),
      providesTags: (result) =>
        Array.isArray(result)
          ? [
              ...result.map(({ id }) => ({
                type: "Reportes Regulaciones",
                id,
              })),
              { type: "Reportes Regulaciones", id: "LIST" },
            ]
          : [{ type: "Reportes Regulaciones", id: "LIST" }],
    }),
  }),
});

export const {
  useMakeRepRegUnoMutation,
  useMakeRepRegDosMutation,
  useMakeHistorialMutation,
} = reporteRegulacionesApi;
