import * as React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ModalBase from "components/ModalBase/modal_base";
import MDImage from "components/MDImage/image";
const style = {
  width: "100%",
  cursor: "pointer",
  borderRadius: "0.5rem",
  marginTop: "0.5rem",
  marginLeft: "0.5rem",
  height: "2cm",
};

const EntidadLogo = ({ logo }) => (
  <MDBox>
    <MDTypography fontWeight="bold">Logo:</MDTypography>
    <MDImage
      id="entidad-image"
      htmlFor="profile-image"
      disabled={false}
      style={style}
      src={logo}
    />
  </MDBox>
);

const LIST_ITEM_FONT_SIZE = "1.25rem";

const formatKey = (key) =>
  key
    .split("_")
    .map((word, index) =>
      index !== 1 ? word.charAt(0).toUpperCase() + word.slice(1) : word
    )
    .join(" ");

const ModalEntidadShow = ({ open, handleClose, entidad }) => {
  const { logo, id, url, ...rest } = entidad;

  return (
    <ModalBase open={open} handleClose={handleClose}>
      <Grid container>
        <Grid item xs={12} sx={{ mx: "auto" }}>
          <MDBox>
            <MDBox mb={1}>
              <Grid container>
                <Grid item xs={12} lg={10} mb={1}>
                  <MDTypography variant="h3" fontWeight="bold">
                    {entidad.nombre}
                  </MDTypography>
                </Grid>
                <MDBox
                  item
                  xs={12}
                  lg={12}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    position: "relative",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <EntidadLogo logo={logo} />
                </MDBox>
              </Grid>
            </MDBox>
            <MDBox component="ul" m={0} pl={4} mb={2}>
              {Object.entries(rest).map(([key, value]) => (
                <MDBox
                  component="li"
                  color="text"
                  fontSize={LIST_ITEM_FONT_SIZE}
                  lineHeight={1}
                  key={key}
                >
                  <MDTypography
                    variant="body2"
                    color="text"
                    fontWeight="regular"
                    verticalAlign="middle"
                  >
                    {`${formatKey(key)}: ${value || "**NO POSEE**"}`}
                  </MDTypography>
                </MDBox>
              ))}
            </MDBox>
          </MDBox>
        </Grid>
      </Grid>
    </ModalBase>
  );
};

ModalEntidadShow.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  entidad: PropTypes.shape({
    logo: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
    nombre: PropTypes.string.isRequired,
  }).isRequired,
};

export default ModalEntidadShow;
