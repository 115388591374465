import { createSlice } from "@reduxjs/toolkit";
import { cicloInternoApi } from "services";

const initialState = {
  ciclos_internos: [],
  asig: [],
};

export const carreraSlice = createSlice({
  name: "carrera",
  initialState,
  reducers: {
    addAsig: (state, action) => {
      state.asig = [...state.asig, action.payload];
    },
    setAsig: (state, action) => {
      state.asig = action.payload;
    },
    setCiclosI: (state, action) => {
      state.ciclos_internos = action.payload;
    },
    editAsig: (state, action) => {
      state.asig = [
        ...state.asig.map((asig) =>
          asig.asignatura.id === action.payload.asignatura.id
            ? action.payload
            : asig
        ),
      ];
    },
    deleteAsig: (state, action) => {
      state.asig = [
        ...state.asig.filter((el) => el.asignatura.id !== action.payload),
      ];
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      cicloInternoApi.endpoints.createCicloInterno.matchFulfilled,
      (state, { payload }) => {
        state.ciclos_internos = [...state.ciclos_internos, payload];
      }
    );
  },
});

export const { setAsig, setCiclosI, addAsig, deleteAsig, editAsig } =
  carreraSlice.actions;

export const carrera_state = (state) => state.carrera;

export default carreraSlice.reducer;
