/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import DataTable from "examples/Tables/DataTable";

//React Hooks and Others
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";

import PropTypes from "prop-types";

import { CircularProgress, Icon, Tooltip } from "@mui/material";

import {
  useDeleteUserMutation,
  useGetUsersQuery,
  useGetPlantillaQuery,
} from "services";
import { setEditAdmin, setNoHereAdmin } from "slices";
import { USER_EDIT_URL, USER_NEW_URL, USUARIOS_URL } from "config/bases_url";
import { style_icon } from "helpers/global_vars";
import { useRedirectForm } from "hooks/useRedirectForm";
import usePagination from "hooks/usePagination";

function DataTableUsers({ tableHeader }) {
  const dispatch = useDispatch();

  const {
    offset,
    setOffset,
    limit,
    busqueda,
    setBusqueda,
    handleNext,
    handlePrevious,
  } = usePagination();

  const {
    data: users,
    isLoading: isLoadingG,
    isError: isErrorG,
    error: errorG,
  } = useGetPlantillaQuery(
    {
      filter_params: {
        buscar: busqueda,
        offset: offset,
        limit: limit,
        is_staff: "false",
        query:
          "{id,first_name,apellido_materno,apellido_paterno,email,groups,perfil}",
      },
      shape: {
        id: "id",
        nombre: "first_name",
        apellido_materno: "apellido_materno",
        apellido_paterno: "apellido_paterno",
        email: "email",
        entidad: "perfil.entidad.nombre",
        groups: "groups[].name",
      },
    },
    {
      refetchOnReconnect: true,
    }
  );

  //constantes para manejar la paginacion
  const dataUsers = users?.results ? users.results : users;
  const countTotal = users?.count;
  const previous = users?.previous;
  const next = users?.next;

  const [
    deleteUser,
    {
      isSuccess: isSuccessD,
      isLoading: isLoadingD,
      isError: isErrorD,
      error: errorD,
    },
  ] = useDeleteUserMutation();

  const dataTable = {
    columns: [
      {
        Header: "Apellido Paterno",
        accessor: "apellido_paterno",
        Cell: ({ value }) => {
          return value ? value : "-------------";
        },
      },
      {
        Header: "Apellido Materno",
        accessor: "apellido_materno",
        Cell: ({ value }) => {
          return value ? value : "-------------";
        },
      },
      {
        Header: "Nombre",
        accessor: "nombre",
        Cell: ({ value }) => {
          return value ? value : "-------------";
        },
      },
      {
        Header: "Correo",
        accessor: "email",
        Cell: ({ value }) => {
          return value ? value : "-------------";
        },
      },
      {
        Header: "Entidad",
        accessor: "entidad",
        Cell: ({ value }) => {
          return value ? value : "-------------";
        },
      },
      {
        Header: "Rol",
        accessor: "groups",
        Cell: ({ value }) => {
          return value.length !== 0 ? value.join(", ") : "**NO POSEE**";
        },
      },
      {
        Header: "Acciones",
        accessor: "actions",
        width: "1%",
        Cell: ({ row }) => {
          const { original } = row;

          return (
            <MDBox display="flex">
              <MDBox px={0.2}>
                <Tooltip placement="top" title="Editar">
                  <Icon
                    style={style_icon}
                    onClick={() => handleClickEdit(original)}
                    fontSize="small"
                  >
                    edit
                  </Icon>
                </Tooltip>
              </MDBox>
              <MDBox px={0.2}>
                <Tooltip placement="top" title="Eliminar">
                  <Icon
                    style={style_icon}
                    onClick={() => handleClickErase(original)}
                    fontSize="small"
                  >
                    remove_circle
                  </Icon>
                </Tooltip>
              </MDBox>
            </MDBox>
          );
        },
      },
    ],
    rows: dataUsers ? dataUsers : [],
  };

  useRedirectForm(
    isLoadingG,
    null,
    isErrorG,
    errorG,
    "Hubo problemas para cargar la información",
    USUARIOS_URL
  );

  useRedirectForm(
    isLoadingD,
    isSuccessD,
    isErrorD,
    errorD,
    "Usuario eliminado",
    USUARIOS_URL
  );

  const handleClickErase = (original) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: `¡Si eliminas el usuario: "${original.nombre} ${original.apellido_paterno} ${original.apellido_materno}", esta acción no se podrá revertir!`,
      icon: "warning",
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Eliminar",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteUser(original.id);
      }
    });
  };

  const navigate = useNavigate();

  const handleClickEdit = (user) => {
    dispatch(setNoHereAdmin(false));
    navigate(`${USER_EDIT_URL}/${user.id}`);
  };

  const handleClickCreate = () => {
    dispatch(setEditAdmin(null));
    dispatch(setNoHereAdmin(false));
  };

  return (
    <MDBox pt={6} pb={3}>
      <Card>
        <MDBox
          display="flex"
          justifyContent={"space-between"}
          alignItems={"center"}
          m={2}
          p={3}
        >
          <MDTypography variant="h5" fontWeight="medium">
            {tableHeader}
          </MDTypography>

          <Link to={USER_NEW_URL}>
            <MDButton
              variant="gradient"
              color="info"
              size="medium"
              onClick={handleClickCreate}
            >
              nuevo
            </MDButton>
          </Link>
        </MDBox>

        <MDBox display="flex" justifyContent={"center"} alignItems={"center"}>
          {isLoadingG ? (
            <CircularProgress sx={{ marginBottom: "3rem" }} color="inherit" />
          ) : (
            <DataTable
              table={dataTable}
              canSearch
              paginationApi
              countTotal={countTotal}
              next={next}
              previous={previous}
              handleNext={handleNext}
              handlePrevious={handlePrevious}
              offset={offset}
              setOffset={setOffset}
              setBusqueda={setBusqueda}
            />
          )}
        </MDBox>
      </Card>
    </MDBox>
  );
}

DataTableUsers.propTypes = {
  tableHeader: PropTypes.string.isRequired,
};

export default DataTableUsers;
