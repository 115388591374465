import * as React from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("America/Guatemala");

// Extiende tu tema existente
const theme = createTheme({
  palette: {
    primary: {
      main: "#007bff", // Ajusta esto a tu color primario
    },
    secondary: {
      main: "#6c757d", // Ajusta esto a tu color secundario
    },
  },
  components: {
    MuiPickersDay: {
      styleOverrides: {
        dayWithMargin: {
          "&.Mui-selected": {
            backgroundColor: "#007bff", // Cambia esto al color deseado
            color: "white", // Cambia el color del texto
            "&:hover": {
              backgroundColor: "#0056b3", // Cambia esto al color deseado al pasar el ratón
            },
          },
          "&.Mui-selected:focus": {
            backgroundColor: "#0056b3", // Cambia esto al color deseado al enfocar
          },
        },
      },
    },
    MuiClock: {
      styleOverrides: {
        clock: {
          "& .MuiClock-pin, & .MuiClock-pointer": {
            backgroundColor: "#007bff", // Color del pin y la pointer
          },
          "& .MuiClock-amButton, & .MuiClock-pmButton": {
            color: "#007bff", // Color de los botones AM/PM
          },
        },
        numbers: {
          color: "#000000", // Color de los números del reloj
        },
      },
    },
  },
});

export default function MyTimePicker({
  label,
  name,
  value,
  disabled = false,
  onChange,
}) {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DateTimePicker"]}>
          <DateTimePicker
            disabled={disabled}
            label={label}
            value={value}
            name={name}
            format="DD/MM/YYYY hh:mm a"
            defaultValue={dayjs.tz("2024-06-30T15:30")}
            onChange={(newValue) => {
              if (onChange) {
                onChange(name, dayjs.tz(newValue));
              }
            }}
          />
        </DemoContainer>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
