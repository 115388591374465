import { firmaCursosApi } from "../view/firmaDeCursos.jsx";
import { estudiantesPorCursoApi } from "services";

export const firmarCursos = firmaCursosApi.injectEndpoints({
  endpoints: (builder) => ({
    firmarCursos: builder.mutation({
      query: (data) => {
        return {
          url: "/v1/api/firmaconstanciacurso/",
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: [{ type: "FirmaCursos", id: "LIST" }],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          queryFulfilled.then(() => {
            dispatch(
              estudiantesPorCursoApi.util.invalidateTags([
                { type: "EstudiantesPorCurso", id: "LIST" },
              ])
            );
          });
        } catch (error) {
          console.log(error);
        }
      },
    }),
  }),
});

export const { useFirmarCursosMutation } = firmarCursos;