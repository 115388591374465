import { useState, useEffect } from "react";

export default function useActualizarDivsion({
  carreraSelect,
  carreras,
  activo,
}) {
  //crear un objeto para poder acceder rapidamente a el segun la propiedad division, se usa para actualizar rapidamente los valores de la
  //reg2 ya que esta depende del valor de la division
  const [divisionesIndexada, setDivIndexada] = useState();
  //division es el valor que se usa para pedir las calificaciones ordinaria y reg1 ya que las calificaciones se piden por carrera y ciclo
  //interno por tanto es necesario saber en todo momento los ciclos internos d la carrera que se van a pedir los datos
  const [division, setDivision] = useState();
  const [loading, setLoading] = useState(true);

  // Inicialización de los estados con un useEffect para garantizar que carreras y activo estén definidos
  useEffect(() => {
    if (carreras && carreras.length > 0) {
      setDivIndexada(
        carreras[0].divisiones.reduce((acc, el) => {
          acc[el.division] = el;
          return acc;
        }, {})
      );

      setDivision(
        activo
          ? carreras[0].divisiones.find((e) => !e.cerrada) ??
              carreras[0].divisiones[0]
          : carreras[0].divisiones[0]
      );
      setLoading(false); // Valores iniciales cargados
    }
  }, [carreras, activo]);

  // Inicialización de los estados con un useEffect para garantizar que carreras y activo estén definidos
  useEffect(() => {
    if (Object.keys(carreraSelect).length !== 0) {
      setDivIndexada(
        carreraSelect?.divisiones.reduce((acc, el) => {
          acc[el.division] = el;
          return acc;
        }, {})
      );
      setDivision(
        activo
          ? carreraSelect?.divisiones.find((e) => e.cerrada === false) ??
              carreraSelect?.divisiones[0]
          : carreraSelect?.divisiones[0]
      );
      setLoading(false); // Valores iniciales cargados
    }
  }, [carreraSelect]);

  return { divisionesIndexada, division, setDivision, loading };
}
