import ModalBase from "../ModalBase/modal_base";
import { CircularProgress, MenuItem } from "@mui/material";
import MDBox from "../MDBox";
import MDButton from "../MDButton";
import * as React from "react";
import MDTypography from "../MDTypography";
import MDDropzone from "../MDDropzone";
import excel from "../../assets/images/tecnoschool/excel.png";
import Icon from "@mui/material/Icon";
import { useCloseModal } from "../../hooks/useCloseModal";
import { useBulkCreateMutation, useGetCarrerasQuery } from "../../services";
import { ErrorMessage, Form, Formik } from "formik";
import Grid from "@mui/material/Grid";
import FormField from "../FormField";
import * as Yup from "yup";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import { ESTUDIANTE } from "../../roles";
import { RESET_PASSWORD_CONFIRM } from "../../config/bases_url";

const style = {
  width: "40%",
  cursor: "pointer",
  borderRadius: "0.5rem",
  marginTop: "0.5rem",
  alignItems: "center",
};

export default function ExcelModal({ open, handleClose }) {
  const [bulk, { isSuccess, isLoading, isError, error }] =
    useBulkCreateMutation();

  const { data: carreras } = useGetCarrerasQuery(
    {
      filter_params: {
        query: "{id,url,nombre,plan_de_estudio}",
        ordering: "nombre,plan_de_estudio",
      },
      shape: {
        id: "id",
        nombre: "nombre",
        url: "url",
        plan_de_estudio: "plan_de_estudio",
      },
    },
    { refetchOnReconnect: true }
  );

  useCloseModal(
    isLoading,
    isSuccess,
    isError,
    error,
    "Excel importado con éxito",
    handleClose
  );

  const submitForm = async (values, action) => {
    const reader = new FileReader();

    reader.onload = (evt) => {
      const bstr = evt.target.result;
      let data;
      let fileType;

      if (
        values.file.name.endsWith(".xlsx") ||
        values.file.name.endsWith(".xls")
      ) {
        fileType = "xlsx";
      } else if (values.file.name.endsWith(".csv")) {
        fileType = "csv";
      }

      if (fileType === "xlsx") {
        const wb = XLSX.read(bstr, { type: "binary" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        data = XLSX.utils.sheet_to_json(ws, { header: 1 });

        data = data.map((fila, ind) =>
          ind > 0 && fila.some((el) => el !== "")
            ? {
                correo_personal: fila[6],
                nombre: fila[3],
                telefonos: [
                  {
                    tipo: "Móvil",
                    telefono: fila[8],
                  },
                ],
                apellido_paterno: fila[1],
                apellido_materno: fila[2],
                numero_de_control: fila[0],
                genero: fila[5],
                tipo_sangre: fila[7],
                curp: fila[4],
                usuario_wifi: fila[0],
                contrasena_wifi: fila[9],
                preasignacion_de_carrera: values.carrera,
                groups: [ESTUDIANTE],
                url_cambio_password: `${window.location.origin}${RESET_PASSWORD_CONFIRM}`,
                correo:
                  fila[0] +
                  JSON.parse(sessionStorage.getItem("user")).perfil.entidad
                    .dominio_de_correo,
              }
            : undefined
        );

        bulk(data.filter((el) => el !== undefined));
      } else if (fileType === "csv") {
        Papa.parse(values.file, {
          download: true,
          skipEmptyLines: true,
          complete: function (results) {
            const dataCSV = results.data.map((fila, ind) =>
              ind > 0 && fila.filter((row) => row === "").length !== fila.length
                ? {
                    correo_personal: fila[6],
                    nombre: fila[3],
                    telefonos: [
                      {
                        tipo: "Móvil",
                        telefono: fila[8],
                      },
                    ],
                    apellido_paterno: fila[1],
                    apellido_materno: fila[2],
                    numero_de_control: fila[0],
                    genero: fila[5],
                    tipo_sangre: fila[7],
                    curp: fila[4],
                    usuario_wifi: fila[0],
                    contrasena_wifi: fila[9],
                    preasignacion_de_carrera: values.carrera,
                    groups: [ESTUDIANTE],
                    url_cambio_password: `${window.location.origin}${RESET_PASSWORD_CONFIRM}`,
                    correo:
                      fila[0] +
                      JSON.parse(sessionStorage.getItem("user")).perfil.entidad
                        .dominio_de_correo,
                  }
                : undefined
            );

            bulk(dataCSV.filter((el) => el !== undefined));
          },
        });
      }
    };

    if (values.file)
      reader.readAsBinaryString(
        new Blob([values.file], { type: "application/octet-stream" })
      );
  };

  return (
    <ModalBase open={open} handleClose={handleClose}>
      <MDBox
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap={1}
        width="100%"
      >
        <Formik
          onSubmit={submitForm}
          initialValues={{
            carrera: "",
            file: null,
          }}
          validationSchema={Yup.object().shape({
            carrera: Yup.string()
              .url("El campo seleccionado no es valido")
              .required("Debe seleccionar una carrera"),
            file: Yup.mixed()
              .required("File is required")
              .test(
                "fileFormat",
                "Solo se aceptan ficheros de tipo excel",
                (value) => {
                  if (value) {
                    const supportedFormats = ["xls", "xlsx", "csv"];
                    return supportedFormats.includes(
                      value.name.split(".").pop()
                    );
                  }
                  return true;
                }
              ),
          })}
        >
          {({ values, errors, setFieldValue, touched, isValid }) => {
            const dropzoneOptions = {
              dictDefaultMessage: "Arrastra los archivos aquí para subirlos",
              init: function () {
                this.on("addedfile", (file) => {
                  const validExts = [".xlsx", ".xls", ".csv"];
                  let fileExt = file.name;
                  fileExt = fileExt.substring(fileExt.lastIndexOf("."));
                  if (validExts.includes(fileExt)) {
                    setFieldValue("file", file, true);
                  }
                });
              },
            };

            return (
              <Form
                style={{
                  width: "100%",
                }}
              >
                <MDBox>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <FormField
                        select
                        label={"Carrera"}
                        name={"carrera"}
                        value={values.carrera}
                        sucess={values.carrera.length > 0 && !errors.carrera}
                        error={errors.carrera && touched.carrera}
                      >
                        <MenuItem value={""}>No seleccionado</MenuItem>
                        {carreras?.map((el) => (
                          <MenuItem key={el.id} value={el.url}>
                            {`${el.nombre} ${el.plan_de_estudio}`}
                          </MenuItem>
                        ))}
                      </FormField>
                    </Grid>
                  </Grid>
                </MDBox>
                <MDTypography
                  variant="h6"
                  fontWeight="bold"
                  flex
                  justifyContent="center"
                  textAlign="center"
                >
                  Fichero Excel:
                </MDTypography>
                {values.file === null ? (
                  <MDDropzone
                    style={{ width: "100%" }}
                    options={dropzoneOptions}
                    file={!!values.file}
                    id="excels"
                  />
                ) : (
                  <MDBox
                    component="img"
                    src={excel}
                    variant="gradient"
                    bgColor="dark"
                    style={style}
                  />
                )}

                {isLoading && (
                  <CircularProgress
                    sx={{ marginBottom: "2rem", marginTop: "2rem" }}
                    color="inherit"
                  />
                )}

                {errors.file && (
                  <MDTypography
                    color="error"
                    variant="h6"
                    fontWeight="bold"
                    display="flex"
                    justifyContent="center"
                    textAlign="center"
                  >
                    <ErrorMessage name="file" />
                  </MDTypography>
                )}

                <MDBox
                  mt={2}
                  width="100%"
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <MDButton
                    type="submit"
                    variant="gradient"
                    color="error"
                    style={{
                      display: "flex",
                    }}
                    onClick={() => setFieldValue("file", null)}
                  >
                    <Icon fontSize={"medium"}>delete</Icon>&nbsp; Archivo
                  </MDButton>
                  <MDButton
                    disabled={!isValid}
                    type="submit"
                    variant="gradient"
                    color="dark"
                    onClick={submitForm}
                  >
                    Subir
                  </MDButton>
                </MDBox>
              </Form>
            );
          }}
        </Formik>
      </MDBox>
    </ModalBase>
  );
}
