import form from "./form";

const {
  formField: { fecha, cantidad },
} = form;

const initialValues = {
  [fecha.name]: new Date().toISOString().slice(0, 10),
  [cantidad.name]: 1,
};

export default initialValues;
