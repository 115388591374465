import {carrerasApi} from "../../view/carreras.service";
import {buildFiltersUrl, reshape_response} from "../../../../../helpers/functions";

export const getCarreras = carrerasApi.injectEndpoints({
  endpoints: (builder) => ({
    getCarreras: builder.query({
      query: ({filter_params}) => ({
        url: buildFiltersUrl("/v1/api/carreras/", filter_params),
        method: "GET",
      }),
      providesTags: (result) =>
        result
          ? [
            ...result.map(({id}) => ({
              type: "Carreras",
              id,
            })),
            {type: "Carreras", id: "LIST"},
          ]
          : [{type: "Carreras", id: "LIST"}],
      transformResponse: (response, meta, args) => {
        if (Array.isArray(response)) {
          return response?.map((el) => reshape_response(el, args.shape));
        } else {
          const resultsTransformed = response?.results?.map((el) =>
            reshape_response(el, args.shape)
          );
          
          // Devolvemos los resultados transformados junto con las propiedades de paginación
          return {
            results: resultsTransformed,
            count: response.count,
            next: response.next,
            previous: response.previous,
          };
        }
      },
    }),
  })
})

export const {
  useGetCarrerasQuery
} = getCarreras;