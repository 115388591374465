import { certificadoXMLApi } from "../view/xml";
import { signData } from "helpers/cryptography";
import { showErrorMessages } from "hooks/useRedirectForm";
import { enqueueSnackbar } from "notistack";

const descargarXML = (resultado) => {
  // Step 1: Decode Base64 to binary string
  const binaryString = atob(resultado);

  // Step 2: Convert binary string to Uint8Array
  const binaryLength = binaryString.length;
  const bytes = new Uint8Array(binaryLength);
  for (let i = 0; i < binaryLength; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  // Step 3: Decode Uint8Array to UTF-8 string
  const decodificadoBase64 = new TextDecoder("utf-8").decode(bytes);

  if (!decodificadoBase64 || typeof decodificadoBase64 !== "string") {
    throw new Error(
      "Invalid XML content provided. Please ensure it is a string.",
    );
  }

  const blob = new Blob([decodificadoBase64], {
    type: "text/xml;charset=utf-8;",
  });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute("download", `certificado.xml`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const sendXMLbase64 = certificadoXMLApi.injectEndpoints({
  endpoints: (builder) => ({
    sendXMLbase64: builder.mutation({
      query: (body) => {
        const { contenido } = body;

        return {
          url: "/v1/api/xml/edu/xml/ustring/",
          method: "POST",
          body: { contenido: contenido },
        };
      },
      invalidatesTags: [{ type: "CertificadoXML", id: "LIST" }],
      async onQueryStarted(
        { code64 },
        { dispatch, queryFulfilled, getCacheEntry },
      ) {
        try {
          await queryFulfilled;

          const {
            data: { cadena_unica, contenido },
          } = getCacheEntry();

          const { data: resp } = await dispatch(
            certificadoXMLApi.endpoints.sealCertificadoElectronico.initiate({
              contenido: contenido,
              sello: signData(code64, cadena_unica),
            }),
          );

          if (resp) {
            const documento_sellado = resp.contenido;

            descargarXML(documento_sellado);
          }
        } catch (error) {
          showErrorMessages(error, enqueueSnackbar);
        }
      },
    }),
  }),
});

export const { useSendXMLbase64Mutation } = sendXMLbase64;
