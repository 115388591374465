/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================
 
 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)
 
 Coded by www.creative-tim.com
 
 =========================================================
 
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DataTable from "examples/Tables/DataTable";

//React Hooks and Others
import { Link, useNavigate } from "react-router-dom";
import { CircularProgress, Icon, Tooltip } from "@mui/material";
import Swal from "sweetalert2";
import PropTypes from "prop-types";

import ComponentsPrivatization, {
  removeColumns,
} from "config/component_privatization";
import { style_icon } from "helpers/global_vars";
import {
  CARRERAS_EDIT_URL,
  CARRERAS_LIST_URL,
  CARRERAS_NEW_URL,
} from "config/bases_url";
import { useRedirectForm } from "hooks/useRedirectForm";
import { useDeleteCarreraMutation, useGetCarrerasQuery } from "services";
import { COORDINADOR_DOCENTE } from "roles";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { carrera_state, setAsig, setCiclosI } from "slices";

function DataTableCarreras({ tableHeader }) {
  const navigate = useNavigate();
  const { asig, ciclos_internos } = useSelector(carrera_state);
  const dispatch = useDispatch();

  const {
    data: carreras,
    isError: isErrorG,
    isLoading: isLoadingG,
    error: errorG,
  } = useGetCarrerasQuery(
    {
      filter_params: {
        ordering: "nombre,modalidad,plan_de_estudio",
        query: "{id,url,nombre,modalidad,plan_de_estudio}",
      },
      shape: {
        id: "id",
        url: "url",
        nombre: "nombre",
        modalidad: "modalidad",
        plan_de_estudio: "plan_de_estudio",
      },
    },
    {
      refetchOnReconnect: true,
    },
  );

  useEffect(() => {
    function clean_state() {
      if (asig.length > 0) dispatch(setAsig([]));
      if (ciclos_internos.length > 0) dispatch(setCiclosI([]));
    }

    clean_state();
  }, []);

  const [
    deleteCarreras,
    {
      isSuccess: isSuccessD,
      isLoading: isLoadingD,
      isError: isErrorD,
      error: errorD,
    },
  ] = useDeleteCarreraMutation();

  const dataTable = {
    columns: [
      {
        Header: "Nombre",
        accessor: "nombre",
        Cell: ({ value }) => {
          return value ? value : "-------------";
        },
      },
      {
        Header: "Modalidad",
        accessor: "modalidad",
        Cell: ({ value }) => {
          return value ? value : "-------------";
        },
      },
      {
        Header: "Plan de estudio",
        accessor: "plan_de_estudio",
        Cell: ({ value }) => {
          return value ? value : "-------------";
        },
      },
      {
        Header: "Acciones",
        accessor: "actions",
        width: "1%",
        Cell: ({ row }) => {
          const { original } = row;

          return (
            <MDBox display="flex">
              <ComponentsPrivatization permitted={COORDINADOR_DOCENTE}>
                <MDBox px={0.2}>
                  <Tooltip
                    placement="top"
                    title="Editar la informacion asociada a esta carrera"
                  >
                    <Icon
                      style={style_icon}
                      onClick={() => handleClickEdit(original)}
                      fontSize="small"
                    >
                      edit
                    </Icon>
                  </Tooltip>
                </MDBox>
                <MDBox px={0.2}>
                  <Tooltip
                    placement="top"
                    title="Eliminar la carrera y sus ciclos internos asociados del listado"
                  >
                    <Icon
                      style={style_icon}
                      onClick={() =>
                        handleClickErase(original.id, original.nombre)
                      }
                      fontSize="small"
                    >
                      remove_circle
                    </Icon>
                  </Tooltip>
                </MDBox>
              </ComponentsPrivatization>
            </MDBox>
          );
        },
      },
    ],

    rows: carreras ?? [],
  };

  useRedirectForm(
    isLoadingG,
    null,
    isErrorG,
    errorG,
    "Hubo problemas para cargar la información",
    CARRERAS_LIST_URL,
  );

  useRedirectForm(
    isLoadingD,
    isSuccessD,
    isErrorD,
    errorD,
    "Carrera eliminada",
    CARRERAS_LIST_URL,
  );

  const handleClickErase = (id, nombre) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: `¡Si eliminas "${nombre}", la acción no se podrá revertir!`,
      icon: "warning",
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Eliminar",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCarreras(id);
      }
    });
  };

  const handleClickEdit = (el) => {
    navigate(CARRERAS_EDIT_URL(el.id));
  };

  return (
    <MDBox pt={6} pb={3}>
      <Card>
        <MDBox
          display="flex"
          justifyContent={"space-between"}
          alignItems={"center"}
          m={2}
          p={3}
        >
          <MDTypography variant="h5" fontWeight="medium">
            {tableHeader}
          </MDTypography>
          <ComponentsPrivatization permitted={COORDINADOR_DOCENTE}>
            <Link to={CARRERAS_NEW_URL}>
              <Tooltip
                placement="top"
                title="Crear nueva carrera con toda su información"
              >
                <MDButton variant="gradient" color="info" size="medium">
                  nuevo
                </MDButton>
              </Tooltip>
            </Link>
          </ComponentsPrivatization>
        </MDBox>

        <MDBox display="flex" justifyContent={"center"} alignItems={"center"}>
          {isLoadingG ? (
            <CircularProgress sx={{ marginBottom: "3rem" }} color="inherit" />
          ) : (
            <DataTable
              table={removeColumns(
                dataTable,
                () =>
                  !JSON.parse(sessionStorage.getItem("user")).groups.some(
                    (role) => role.name === COORDINADOR_DOCENTE,
                  ),
                ["actions"],
              )}
              canSearch
            />
          )}
        </MDBox>
      </Card>
    </MDBox>
  );
}

DataTableCarreras.propTypes = {
  tableHeader: PropTypes.string.isRequired,
};

export default DataTableCarreras;
